import React, { useEffect } from 'react';
import { API_ENDPOINT_URL, APP_PREFIX } from '../../../utils/appConstants';
import { Link } from 'react-router-dom';
import { postRequest } from '../../../utils/requests';

const OnBoardingProcess = () => {

    useEffect(() => {
        postRequest(`${API_ENDPOINT_URL}/breeder/on-boarding/check`, null, true).then((res) => {
            if (res) {
                localStorage.setItem('on_board_status', res.data.data.on_board_status);
                localStorage.setItem('is_transfer_enabled', res.data.data.is_transfer_enabled);
            }
        });
    }, []);

    return (
        <>
            <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
                <div className="container">
                    <div className="arextraWrap">
                        <h1>from good homes… <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes</h1>
                    </div>
                    <div className="arbhImgHolder d-none d-md-block position-absolute">
                        <img src="/images/img07.png" className="img-fluid" alt="petbond"/>
                    </div>
                </div>
                <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
                    <i className="aricomoon-hearts"><span className="sr-only">icon</span></i>
                </span>
                <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
                    <i className="aricomoon-starDots"><span className="sr-only">icon</span></i>
                </span>
            </header>
            <article className="um-thkSubmttPage pt-5 pb-15 pb-lg-20">
                <div className="container">
                    <div className="um-thkPageWrap rounded shadow text-center overflow-hidden">
                        <div className="row">
                            <div className="col-12 col-md-10 offset-md-1">
                                <div className="thkTextWrap">
                                    <h2>Congratulations !<br/> Stripe account successfully connected.</h2>
                                    <p className="px-xl-5">You have successfully connected your Stripe account to
                                        Petbond to receive payments.</p>
                                </div>
                                <div>
                                    <Link to={`${APP_PREFIX}/overview`}
                                          className="btn btnFormWidth  btn-primary btnPurpStyle">
                                        View Dashboard
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <span className="bgCover d-block imgBgWarn"
                              style={{ backgroundImage: 'url(/images/imgdev1.png)' }}></span>
                    </div>
                </div>
            </article>
        </>
    );

};

export default OnBoardingProcess;
