import React from 'react';
import {Navigate} from 'react-router-dom';
import BuyerPetAlerts from "./BuyerPetAlerts";


const PetAlerts = () => {
    const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

    if (userRole === 'buyer') {
        return <BuyerPetAlerts />;
    }

    return <Navigate to='/' />;
};

export default PetAlerts;
