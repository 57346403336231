import React from "react";
import {Navigate} from "react-router-dom";
import BreederSales from "./BreederSales/BreederSales";

const MySales = () => {
  const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;
  if (userRole === 'breeder') {
    return <BreederSales />
  }

  return <Navigate to='/' />
};

export default MySales;
