import {Link} from "react-router-dom";
import  {ReactComponent as TickIcon}  from '../../../../../assets/images/tick.svg';
import  {ReactComponent as CrossIcon}  from '../../../../../assets/images/cross.svg';
import {useState} from "react";
import ReactGA from 'react-ga';
import trackerService from "../../../../../services/tracker.service";

const PaymentOptions = () => {
    const [showPopup, setShowPopup] = useState(false);

    function showPaymentPopup() {
        setShowPopup(true);

        trackerService.track('Paid advertising interest')

        ReactGA.event({
            category: 'User',
            action: 'Paid advertising interest'
        });
    }

    return (
      <div className="container-xl">
          <div className="shadow-sm py-5 px-2 px-md-10 rounded-lg payment-options">
              <h1 className="payment-options__heading pt-6">Pick the ad plan that best fits your needs</h1>

              <div className="row pt-8">
                  <div className="col-md-6 d-flex">
                    <div className="payment-option fontBase">
                        <div className="payment-option__header">
                            <div className="d-flex align-items-center">
                                <h3 className="payment-option__header__heading">Advertise for FREE</h3>
                                <span className="payment-option__pill">Popular</span>
                            </div>
                        </div>
                        <div className="payment-option__body">
                            <span className="payment-option__body__heading">Features</span>
                            <ul className="payment-option__list">
                                <li>
                                    <span><TickIcon/></span>
                                    <span>FREE to advertise for 3 months</span>
                                </li>
                                <li>
                                    <span><TickIcon/></span>
                                    <span>£30 Cash Back for every puppy vet vaccination.</span>
                                </li>
                                <li>
                                    <span><TickIcon/></span>

                                    <span>Achieve best price & best homes for puppies.</span>
                                </li>
                                <li>
                                    <span><TickIcon/></span>
                                    <span>24/7 Vet Support</span>
                                </li>
                                <li>
                                    <span><TickIcon/></span>
                                    <span>Deposit secured before viewings.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="payment-option__footer">
                            <Link className="btn btn-warning w-100" to={`/app/sell-pet`}>Advertise Now</Link>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex mt-6 mt-md-0">
                      <div className="payment-option fontBase">
                          <div className="payment-option__header">
                              <div className="d-flex align-items-center">
                                  <h3 className="payment-option__header__heading">Pay for Advertising</h3>
                              </div>
                          </div>
                          <div className="payment-option__body">
                              <span className="payment-option__body__heading">Features</span>
                              <ul className="payment-option__list">
                                  <li>
                                      <span><TickIcon/></span>
                                      <span>Only £1 per day</span>
                                  </li>
                                  <li>
                                      <span><TickIcon/></span>
                                      <span>Advert stays live for 6 weeks.</span>
                                  </li>
                                  <li>
                                      <span><TickIcon/></span>
                                      <span>Deposit secured before viewings.</span>
                                  </li>
                              </ul>
                          </div>
                          <div className="payment-option__footer">
                              <button onClick={() => showPaymentPopup()} className="btn btn-warning w-100">Advertise Now</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          {showPopup && (
              <div className="payment-option__popup">
                  <div className="payment-option__popup__body">
                      <button onClick={() => setShowPopup(false)} className="payment-option__popup__close"><CrossIcon/></button>
                      <div className="payment-option__popup__heading">Thanks for your interest!</div>
                      <p>We’re considering adding the ability for you to pay for your listings. You’ll get informed via email as soon as this feature becomes available.</p>
                  </div>
              </div>
          )}
      </div>
    );
}

export default PaymentOptions;