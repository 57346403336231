import React from "react";

const PetbondBenefitsWrapper = ({noStretch, children}) => {
    return (
        <div className={'benefits-box ' + (noStretch ? 'no-stretch' : '')}>
            <ul>{children}</ul>
        </div>
    )
}

export default PetbondBenefitsWrapper;
