import "tippy.js/dist/tippy.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../utils/requests";
import axios from "axios";
import oO from "../../assets/images/oO.png";
import { Helmet } from "react-helmet-async";
import bull from "../../assets/images/airways.jpg";

const AirwayTesting = () => {
  return (
    <>
      <Helmet>
        <title>Airway Testing | Buy Dogs & Puppies | PetBond</title>
        <meta
          name="description"
          content="PetBond is the safest and most ethical way to buy dogs in UK. We work with approved breeders to help you find a new best friend. Find your dog or puppy today."
        />
      </Helmet>
      {/* HEADER */}
      <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
        <div className="container">
          <div className="arextraWrap">
            <h2 className="h1">
              from good homes… <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes
            </h2>
          </div>
          <div className="arbhImgHolder d-none d-md-block position-absolute">
            <img src="/images/img07.png" className="img-fluid" alt="petbond" />
          </div>
        </div>
        <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
          <i className="aricomoon-hearts">
            <span className="sr-only">icon</span>
          </i>
        </span>
        <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
          <i className="aricomoon-starDots">
            <span className="sr-only">icon</span>
          </i>
        </span>
      </header>
      {/* CONTENT */}

      <section className="position-relative py-10 bg-white overflow-hidden">
        <div className="position-relative container" style={{ zIndex: "1" }}>
          <div className=" row align-items-center g-16">
            <div className="col-12">
              <span className="badge mb-4 bg-warning text-light text-uppercase shadow">
                Airway Testing
              </span>
              <h2 className="font-heading fs-6">
                Brachycephalic Airway Testing
              </h2>
              <p className="mb-6">Respiratory Functional Grading</p>
              <p className="text-primary" style={{ fontWeight: "bold" }}>
                {" "}
                <span>
                  {" "}
                  <img
                    src={oO}
                    className="img-fluid"
                    alt="dogstrust"
                    style={{ height: "20px" }}
                  />
                </span>{" "}
                Book Now: <span className="">healthtesting@mypetbond.com</span>
              </p>
            </div>
          </div>
          <div className="row g-8">
            <div className="col-12">
              <p className="mb-6 fs-10 fw-medium ">
                As only 1 of 3 recognised Veterinary assessors for this in UK, 
                our Founder &amp; Head Vet Dr. Tim Kirby is
                pioneering the testing of all susceptible breeds. Our PetBond
                RFG clinics assess the suitability of both male &amp; female
                brachycephalic breeds for breeding.
              </p>
              <p className="mb-6 fs-10 fw-medium">
                Given the prevalence of this condition and the devastating
                impact it has on animal welfare, we encourage all brachycephalic
                dog owners to avail of our amazing RFG clinics before you breed.
                This allows us together, to ensure that your puppies get off to
                the best possible start in life. To book an RFG test at our next
                clinic, please click the box below
              </p>
              <p className="text-primary" style={{ fontWeight: "bold" }}>
                {" "}
                <span>
                  {" "}
                  <img
                    src={oO}
                    className="img-fluid"
                    alt="dogstrust"
                    style={{ height: "20px" }}
                  />
                </span>{" "}
                Book Now: <span className="">healthtesting@mypetbond.com</span>
              </p>
            </div>
          </div>
          <div className="mt-4 d-flex flex-wrap flex-strecth justify-content-left">
            <Link
              to={`/genetic-testing`}
              className="btn-primary shadow-sm btn text-uppercase px-4 m-1"
            >
              Go to Genetic Testing
            </Link>
            <Link
              to={`/find-a-vet`}
              className="btn-warning shadow-sm btn text-uppercase px-4 m-1"
            >
              Back to Vet Teams
            </Link>
          </div>
        </div>
      </section>
      {/* End of text */}
    </>
  );
};

export default AirwayTesting;
