import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AUTH_PREFIX } from '../../utils/appConstants';

const NotFoundPage = () => {

    const {pathname} = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const hostName = 'https://mypetbond.com/petscorner';
        if (pathname === '/registration/') {
            navigate(`${AUTH_PREFIX}/signup-selector`);
        } else if (pathname === '/signup-for-our-newsletter/') {
            navigate(`/`);
        } else if (pathname === '/sell-my-dog/') {
            navigate(`/for-breeder`);
        } else if (pathname === '/supporter-brands/') {
            navigate(`/`);
        } else if (pathname === '/news/') {
            window.location.href = hostName;
        } else if (pathname === '/pet-care-education-hub/') {
            window.location.href = hostName;
        } else if (pathname === '/find-a-dog-groomer/') {
            navigate(`/`);
        } else if (pathname === '/allergy-intolerance-everything-you-need-to-know-about-hypoallergenic-dog-food/') {
            window.location.href = `${hostName}general-news/allergy-intolerance-everything-you-need-to-know-about-hypoallergenic-dog-food/`;
        } else if (pathname === '/website-terms-and-conditions/') {
            navigate(`/terms-conditions`);
        } else if (pathname === '/find-a-pet/') {
            navigate(`/pet-listing`);
        } else if (pathname === '/behaviourists/') {
            window.location.href = `${hostName}general-news/find-a-behaviorist/`;
        } else if (pathname === '/provider_type/adoption/') {
            navigate(`/pet-listing`);
        } else if (pathname === '/register-breeder/') {
            navigate(`${AUTH_PREFIX}/breeder-signup`);
        } else if (pathname === '/preparing-for-a-new-puppy-3/') {
            window.location.href = `${hostName}general-news/preparing-for-a-new-puppy-3/`;
        } else if (pathname === '/provider_type/approved-hobby-breeder/') {
            navigate(`/pet-listing`);
        } else if (pathname === '/join-our-network-of-care/') {
            navigate(`/about-us`);
        } else if (pathname === '/provider_type/stud/') {
            navigate(`/pet-listing`);
        } else if (pathname === '/about/') {
            navigate(`/about-us`);
        } else if (pathname === '/provider_type/approved-ikc-breeder/') {
            navigate(`/pet-listing`);
        } else if (pathname === '/register-vets/') {
            navigate(`${AUTH_PREFIX}/vet-signup`);
        } else if (pathname === '/vet/village-vets/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/ruth-mitchell/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/puppies-smuggled-into-ireland-for-re-sale/') {
            window.location.href = `${hostName}general-news/puppies-smuggled-into-ireland-for-re-sale/`;
        } else if (pathname === '/groomers/dottiesdoghouse/') {
            navigate(`/`);
        } else if (pathname === '/vet/castle-vets/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/glenina-veterinary-clinic/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/cleaghmore-vets/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/puppies-smuggled-into-ireland-for-re-sale/') {
            window.location.href = `${hostName}general-news/irish-kennel-club/`;
        } else if (pathname === '/vet/ark-vetcare/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/niamh-walch/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/crate-training-or-not/') {
            window.location.href = `${hostName}training/crate-training-or-not/`;
        } else if (pathname === '/vet/t-f-oleary-associates/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/why-puppy-socialisation-is-critical/') {
            window.location.href = `${hostName}health/why-puppy-socialisation-is-critical/`;
        } else if (pathname === '/vet/newbridge-veterinary-clinic-2/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/pause-dogs-puppies/') {
            window.location.href = `${hostName}general-news/pause-dogs-puppies/`;
        } else if (pathname === '/vet/claremorris-small-animal-practice/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/preparing-for-a-new-puppy/') {
            window.location.href = `${hostName}general-news/preparing-for-a-new-puppy/`;
        } else if (pathname === '/top-10-grooming-tips-by-deirdre-ryan/') {
            window.location.href = `${hostName}grooming/top-10-grooming-tips-by-deirdre-ryan/`;
        } else if (pathname === '/vet/dublin-bay-vets/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/arra-veterinary-clinic/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/leaving-lockdown-without-our-canine-companions/') {
            window.location.href = `${hostName}general-news/leaving-lockdown-without-our-canine-companions/`;
        } else if (pathname === '/vet/divilly-vet-clinic/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/all-creatures/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/lodge-veterinary-hospital/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/new-pet-vet-cure/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/news/page/2/') {
            window.location.href = `${hostName}category/general-news/page/2/`;
        } else if (pathname === '/groomers/dr-dogcare-grooming-and-training/') {
            navigate(`/`);
        } else if (pathname === '/vet/longford-vet-at-crandara/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/house-training-a-puppy/') {
            window.location.href = `${hostName}training/house-training-a-puppy/`;
        } else if (pathname === '/vet/deelside-veterinary-clinic/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/possessiveness-also-known-as-resource-guarding-what-does-it-mean/') {
            window.location.href = `${hostName}general-news/possessiveness-also-known-as-resource-guarding-what-does-it-mean/`;
        } else if (pathname === '/vet/smith-and-foleys/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/why-choose-petbond-to-meet-your-next-pet/') {
            window.location.href = `${hostName}general-news/why-choose-petbond-to-meet-your-next-pet/`;
        } else if (pathname === '/register/') {
            navigate(`${AUTH_PREFIX}/charity-signup`);
        } else if (pathname === '/vet/park-veterinary/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/brayvet/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/pick-and-collect-not-for-pets/') {
            window.location.href = `${hostName}general-news/pick-and-collect-not-for-pets/`;
        } else if (pathname === '/breed/west-highland-white-terrier/') {
            window.location.href = `${hostName}breed/west-highland-white-terrier/`;
        } else if (pathname === '/breed/labradoodle/') {
            window.location.href = `${hostName}breed/labradoodle/`;
        } else if (pathname === '/breed/boxer/') {
            window.location.href = `${hostName}breed/boxer/`;
        } else if (pathname === '/breed/cavachon/') {
            window.location.href = `${hostName}breed/cavachon/`;
        } else if (pathname === '/breed/german-shepherd/') {
            window.location.href = `${hostName}breed/german-shepherd/`;
        } else if (pathname === '/breed/cavalier-king-charles-spaniel/') {
            window.location.href = `${hostName}breed/cavalier-king-charles-spaniel/`;
        } else if (pathname === '/breed/shih-tzu/') {
            window.location.href = `${hostName}breed/shih-tzu/`;
        } else if (pathname === '/breed/poodles/') {
            window.location.href = `${hostName}breed/poodles/`;
        } else if (pathname === '/breed/dachshund/') {
            window.location.href = `${hostName}breed/dachshund/`;
        } else if (pathname === '/breed/rottweiler/') {
            window.location.href = `${hostName}breed/rottweiler/`;
        } else if (pathname === '/breed/cavapoo/') {
            window.location.href = `${hostName}breed/cavapoo/`;
        } else if (pathname === '/breed/Beagle/') {
            window.location.href = `${hostName}breed/Beagle/`;
        } else if (pathname === '/breed/border-collie/') {
            window.location.href = `${hostName}breed/border-collie/`;
        } else if (pathname === '/breed/pomeranian/') {
            window.location.href = `${hostName}breed/pomeranian/`;
        } else if (pathname === '/breed/cocker-spaniel/') {
            window.location.href = `${hostName}breed/cocker-spaniel/`;
        } else if (pathname === '/breed/labrador-retriever/') {
            window.location.href = `${hostName}breed/labrador-retriever/`;
        } else if (pathname === '/breed/pug/') {
            window.location.href = `${hostName}breed/pug/`;
        } else if (pathname === '/breed/chow-chow/') {
            window.location.href = `${hostName}breed/chow-chow/`;
        } else if (pathname === '/breed/bernese-mountain-dog/') {
            window.location.href = `${hostName}breed/bernese-mountain-dog/`;
        } else if (pathname === '/news/page/5/') {
            window.location.href = `${hostName}category/general-news/page/5/`;
        } else if (pathname === '/groomers/best-friends-world/') {
            navigate(`/`);
        } else if (pathname === '/love-you-to-death-how-we-hurt-animals-we-cherish/') {
            window.location.href = `${hostName}general-news/love-you-to-death-how-we-hurt-animals-we-cherish/`;
        } else if (pathname === '/vet/a-country-practice-vet-clinic/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/all-paws/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/rockhall-veterinary/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/kellys-vets/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/groomers/the-crate-escape/') {
            navigate(`/`);
        } else if (pathname === '/vet/tir-na-bho-vets/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/cherrywood-veterinary-clinic/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/hurleys-veterinary-hospital/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/deposit-system-for-buyers/') {
            window.location.href = `${hostName}general-news/by-using-petbonds-deposit-system-you-benefit/`;
        } else if (pathname === '/21370-2/') {
            navigate(`/`);
        } else if (pathname === '/vet/emma-plant-veterinary/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/lakeside-veterinary-centre/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/woodview-veterinary-clinic/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/celebrity-couple-where-to-source-a-puppy-online/') {
            window.location.href = `${hostName}celebrity-couple-where-to-source-a-puppy-online/`;
        } else if (pathname === '/vet/athboy-veterinary-hospital/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/blacklion-pet-hospital/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/minder-needed-for-older-dog/') {
            window.location.href = `${hostName}general-news/minder-needed-for-older-dog/`;
        } else if (pathname === '/find-a-pet/page/4/') {
            navigate(`/pet-listing`);
        } else if (pathname === '/vet/groome-vets/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/news/page/8/') {
            window.location.href = `${hostName}category/general-news/page/8/`;
        } else if (pathname === '/provider_type/approved-hobby-breeder/page/2/') {
            navigate(`/pet-listing`);
        } else if (pathname === '/vet/cuddy-vets-galway/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/smyth-vet-services/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/kinnegad-veterinary/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/cats-and-coronavirus-the-facts/') {
            window.location.href = `${hostName}general-news/cats-and-coronavirus-the-facts/`;
        } else if (pathname === '/easter-family-fun-2/') {
            window.location.href = `${hostName}easter-family-fun-2/`;
        } else if (pathname === '/vet/treaty-veterinary-clinic/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/killaloe-veterinary-clinic/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/find-a-pet/page/3/') {
            navigate(`/pet-listing`);
        } else if (pathname === '/vet/bairbre-omalley-veterinary-hospital/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/athlone-veterinary-hospital/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/allpets-veterinary-hospital/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/vet/borrisoleigh-veterinary-clinic/') {
            navigate(`/find-a-vet`);
        } else if (pathname === '/find-a-pet/page/2/') {
            navigate(`/pet-listing`);
        } else if (pathname === '/https-extra-ie-2020-06-06-news-real-life-vet-dog-puppy-breeders-tommy-molly-mae-petbond/') {
            window.location.href = `${hostName}media-performances/https-extra-ie-2020-06-06-news-real-life-vet-dog-puppy-breeders-tommy-molly-mae-petbond/`;
        } else if (pathname === '/risks-of-buying-a-pet-online-2/') {
            window.location.href = `${hostName}general-news/risks-of-buying-a-pet-online-2/`;
        } else if (pathname === '/provider_type/approved-ikc-breeder/page/2/') {
            navigate(`/pet-listing`);
        } else if (pathname === '/password-reset/') {
            navigate(`${AUTH_PREFIX}/login`);
        } else if (pathname === '/cheer-up-the-lonely-today/') {
            window.location.href = `${hostName}general-news/cheer-up-the-lonely-today/`;
        } else if (pathname === '/the-1st-month-guide-caring-for-your-new-pet/') {
            window.location.href = `${hostName}general-news/the-1st-month-guide-caring-for-your-new-pet/`;
        } else if (pathname === '/fostering-a-dog-has-proven-to-drastically-reduce-loneliness-and-depression/') {
            window.location.href = `${hostName}general-news/fostering-a-dog-has-proven-to-drastically-reduce-loneliness-and-depression/`;
        } else if (pathname === '/easter-family-fun/') {
            window.location.href = `${hostName}general-news/easter-family-fun/`;
        } else if (pathname === '/petbond-founder-tim-kirby-features-on-2fms-mullet-mix/') {
            window.location.href = `${hostName}general-news/petbond-founder-tim-kirby-features-on-2fms-mullet-mix/`;
        } else if (pathname === '/why-the-human-animal-bond-is-so-important/') {
            window.location.href = `${hostName}general-news/why-the-human-animal-bond-is-so-important/`;
        } else if (pathname === '/media-performances/') {
            window.location.href = `${hostName}category/media-performances/`;
        } else if (pathname === '/buying-a-pet-8-tips-for-excellence/') {
            window.location.href = `${hostName}general-news/buying-a-pet-8-tips-for-excellence/`;
        } else if (pathname === '/dogs-for-sale-where/') {
            window.location.href = `${hostName}general-news/dogs-for-sale-where/`;
        } else if (pathname === '/evoke-ie-article-on-our-instagram-live-feature-with-doireann-garrihy/') {
            window.location.href = `${hostName}general-news/evoke-ie-article-on-our-instagram-live-feature-with-doireann-garrihy/`;
        } else if (pathname === '/new-pet-parents-what-do-i-need-to-buy/') {
            window.location.href = `${hostName}general-news/new-pet-parents-what-do-i-need-to-buy/`;
        } else if (pathname === '/what-are-you-doing-for-international-day-of-families/') {
            window.location.href = `${hostName}general-news/what-are-you-doing-for-international-day-of-families/`;
        } else if (pathname === '/news/page/7/') {
            window.location.href = `${hostName}category/general-news/page/7/`;
        } else if (pathname === '/covid-19-pets/') {
            window.location.href = `${hostName}general-news/covid-19-pets/`;
        } else if (pathname === '/news/page/3/') {
            window.location.href = `${hostName}category/general-news/page/3/`;
        } else if (pathname === '/what-spending-time-with-animals-can-teach-us-about-empathy/') {
            window.location.href = `${hostName}general-news/what-spending-time-with-animals-can-teach-us-about-empathy/`;
        } else if (pathname === '/how-much-should-i-feed-my-pet/') {
            window.location.href = `${hostName}general-news/how-much-should-i-feed-my-pet/`;
        } else if (pathname === '/from-covid19-to-rabies-and-ringworm-how-wildlife-can-be-a-wolf-in-sheeps-clothing/') {
            window.location.href = `${hostName}health/from-covid19-to-rabies-and-ringworm-how-wildlife-can-be-a-wolf-in-sheeps-clothing/`;
        } else if (pathname === '/her-ie-coverage-of-petbonds-insta-live-with-doireann-garrihy/') {
            window.location.href = `${hostName}general-news/her-ie-coverage-of-petbonds-insta-live-with-doireann-garrihy/`;
        } else if (pathname === '/horses-coronavirus/') {
            window.location.href = `${hostName}health/horses-coronavirus/`;
        } else if (pathname === '/pet-fostering-adoption/') {
            window.location.href = `${hostName}general-news/pet-fostering-adoption/`;
        } else if (pathname === '/irish-times-11-9-2019/') {
            window.location.href = `${hostName}media-performances/irish-times-11-9-2019/`;
        } else if (pathname === '/in-business-radio-kerry-31-10-2019/') {
            window.location.href = `${hostName}media-performances/in-business-radio-kerry-31-10-2019/`;
        } else if (pathname === '/dublin-city-fm-2-10-19/') {
            window.location.href = `${hostName}media-performances/dublin-city-fm-2-10-19/`;
        } else if (pathname === '/im-a-gift-for-life-not-a-present/') {
            window.location.href = `${hostName}general-news/im-a-gift-for-life-not-a-present/`;
        } else if (pathname === '/news/page/4/') {
            window.location.href = `${hostName}category/general-news/page/4/`;
        } else if (pathname === '/a-bond-that-saved-my-life/') {
            window.location.href = `${hostName}general-news/a-bond-that-saved-my-life/`;
        } else if (pathname === '/evoke-19-9-19/') {
            window.location.href = `${hostName}media-performances/evoke-19-9-19/`;
        } else if (pathname === '/pets-and-hygiene-tips-for-humans/') {
            window.location.href = `${hostName}health/pets-and-hygiene-tips-for-humans/`;
        } else if (pathname === '/ray-darcy-12-09-2019/') {
            window.location.href = `${hostName}media-performances/ray-darcy-12-09-2019/`;
        } else if (pathname === '/extra-ie-27-09-2019/') {
            window.location.href = `${hostName}media-performances/extra-ie-27-09-2019/`;
        } else if (pathname === '/christmas-through-the-eyes-of-dream/') {
            window.location.href = `${hostName}general-news/christmas-through-the-eyes-of-dream/`;
        } else if (pathname === '/the-hub-podcast-25-11-2019/') {
            window.location.href = `${hostName}media-performances/the-hub-podcast-25-11-2019/`;
        } else if (pathname === '/the-big-bark-podcast-19-12-2019/') {
            window.location.href = `${hostName}media-performances/the-big-bark-podcast-19-12-2019/`;
        } else if (pathname === '/covid-19-your-dog/') {
            window.location.href = `${hostName}health/covid-19-your-dog/`;
        } else if (pathname === '/21082-2/') {
            window.location.href = `${hostName}health/21082-2/`;
        } else if (pathname === '/property-matters-podcast-29-10-2019/') {
            window.location.href = `${hostName}media-performances/property-matters-podcast-29-10-2019/`;
        } else if (pathname === '/news/page/6/') {
            window.location.href = `${hostName}category/general-news/page/6/`;
        } else if (pathname === '/walk-in-my-shoes-11-10-2019/') {
            window.location.href = `${hostName}media-performances/walk-in-my-shoes-11-10-2019/`;
        } else if (pathname === '/christmas-through-the-eyes-of-dream-safe-doggy-foods/') {
            window.location.href = `${hostName}general-news/christmas-through-the-eyes-of-dream-safe-doggy-foods/`;
        } else if (pathname === '/need-to-see-a-vet-during-covid-19/') {
            window.location.href = `${hostName}health/need-to-see-a-vet-during-covid-19/`;
        } else if (pathname === '/kildare-focus-18-09-2019/') {
            window.location.href = `${hostName}media-performances/kildare-focus-18-09-2019/`;
        } else if (pathname === '/world-pet-day-iradio-feature/') {
            window.location.href = `${hostName}media-performances/world-pet-day-iradio-feature/`;
        } else if (pathname === '/why-your-pet-is-always-your-best-friend/') {
            window.location.href = `${hostName}general-news/why-your-pet-is-always-your-best-friend/`;
        } else if (pathname === '/wishing-your-pets-a-happy-and-healthy-christmas-avoiding-emergency-visits/') {
            window.location.href = `${hostName}health/wishing-your-pets-a-happy-and-healthy-christmas-avoiding-emergency-visits/`;
        } else if (pathname === '/breed/french-bulldog/') {
            window.location.href = `${hostName}breed/french-bulldog/`;
        } else if (pathname === '/breed/great-danes/') {
            window.location.href = `${hostName}breed/great-danes/`;
        } else if (pathname === '/breed/schnauzers/') {
            window.location.href = `${hostName}breed/schnauzers/`;
        } else if (pathname === '/breed/cockapoo/') {
            window.location.href = `${hostName}breed/cockapoo/`;
        } else if (pathname === '/are-you-caring-for-your-senior-pet-properly/') {
            window.location.href = `${hostName}health/are-you-caring-for-your-senior-pet-properly/`;
        } else if (pathname === '/my-beautiful-prince-was-the-king-of-my-heart/') {
            window.location.href = `${hostName}general-news/my-beautiful-prince-was-the-king-of-my-heart/`;
        } else if (pathname === '/breed/bichon-frise/') {
            window.location.href = `${hostName}breed/bichon-frise/`;
        } else if (pathname === '/breed/bulldog/') {
            window.location.href = `${hostName}breed/bulldog/`;
        } else if (pathname === '/breed/greyhound/') {
            window.location.href = `${hostName}breed/greyhound/`;
        } else if (pathname === '/breed/golden-retriever/') {
            window.location.href = `${hostName}breed/golden-retriever/`;
        } else if (pathname === '/irish-kennel-club/') {
            window.location.href = `${hostName}general-news/irish-kennel-club/`;
        } else if (pathname === '/breed/great-danes/') {
            window.location.href = `${hostName}breed/great-danes/`;
        } else if (pathname === '/breed/irish-wolfhound/') {
            window.location.href = `${hostName}breed/irish-wolfhound/`;
        } else if (pathname === '/vet/lisburn-road-vets/') {
            navigate(`/find-a-vet`);
        }
    }, []);

    return (
        <>
            <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
                <div className="container">
                    <div className="arextraWrap">
                        <h1>from good homes… <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes</h1>
                    </div>
                    <div className="arbhImgHolder d-none d-md-block position-absolute">
                        <img src="/images/img07.png" className="img-fluid" alt="petbond"/>
                    </div>
                </div>
                <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
							<i className="aricomoon-hearts"><span className="sr-only">icon</span></i>
						</span>
                <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
							<i className="aricomoon-starDots"><span className="sr-only">icon</span></i>
						</span>
            </header>
            <section className="py-20 py-md-15 text-center pageErrorBlock position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3">
                            <strong
                                className="h2 d-inline-block font-weight-normal position-relative bg-primary text-white rounded-sm py-2 px-4">
                                404
                                <span className="position-absolute pgeErrImgWrap">
		            				<img src="/images/dummy-pet-404.png" className="img-fluid" alt="404"/>
		            			</span>
                            </strong>
                            <strong className="h3 d-block font-weight-normal mb-5">Page Not Found</strong>
                            <p>The page you are looking for doesn't exist or an other error occured.</p>
                            <div className="d-flex justify-content-center flex-wrap">
                                <Link className="btn btn-warning" to="/">Go Back to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

};

export default NotFoundPage;
