import React, { useEffect, useState } from "react";
import { getRequest } from "../../../../utils/requests";
import { API_ENDPOINT_URL } from "../../../../utils/appConstants";
import moment from "moment";
import BottomStripe from "../../bottomStripe";
import {CURRENCY_SYMBOL} from "../../../../utils/appConstants";

const BreederSales = () => {
    const [sales, setSales] = useState([]);

    useEffect(() => {
        getRequest(`${API_ENDPOINT_URL}/breeder/sale/list`, true).then((res) => {
            if (res) {
                setSales(res?.data?.data);
            }
        });
    }, []);

    return (
        <>
            <div className="aaContentWidget">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Advert Deposit and Sales</h1>
                </header>
                <div className="aaDbRow aaDbPetsListRow myAdvSales">
                    <ul className="aaAdvtPetList list-unstyled m-0 p-0">
                        {sales?.map((sale) => (
                            <li>
                                <article className="aaAdvPetPost">
                                    <div className="aaLeftWrap nospace">
                                        <div className="petImgWrap">
                                            <img
                                                src={
                                                    sale.pet.photo
                                                        ? `${API_ENDPOINT_URL}/image-retrieve/${sale.pet.photo}`
                                                        : "/images/image-placeholder.png"
                                                }
                                                alt="img"
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                    <div className="aaRightWrap">
                                        <h6 className="h6 aaAdvTitle fontNoto">
                                            Advert Name: {sale?.pet?.adverts[0]?.advert_name}
                                        </h6>
                                        <ul className="aaAdvdetailList list-unstyled p-0 m-0">
                                            {sale?.pet?.micro_chip?.breed?.name && (
                                                <li>Breed: {sale?.pet?.micro_chip?.breed?.name}</li>
                                            )}
                                            {sale?.pet?.name && <li>Pet name: {sale?.pet?.name}</li>}
                                            {sale?.pet?.adverts[0]?.dob && (
                                                <li>
                                                    Date of birth:{" "}
                                                    {moment(sale?.pet?.adverts[0]?.dob).format(
                                                        "DD MMM YYYY"
                                                    )}
                                                </li>
                                            )}
                                            {sale?.total_transactions_price?.total_amount && (
                                                <li>
                                                    Price: {CURRENCY_SYMBOL}{sale?.total_transactions_price?.total_amount}
                                                </li>
                                            )}
                                            {sale?.pet?.adverts[0]?.mother_name && (
                                                <li>Mother: {sale?.pet?.adverts[0]?.mother_name}</li>
                                            )}
                                            {sale?.pet?.adverts[0]?.father_name && (
                                                <li>Father: {sale?.pet?.adverts[0]?.father_name}</li>
                                            )}
                                            {sale.type && (
                                                <li>
                                                    Payment Type:{" "}
                                                    {sale.type === "full" ? "Full payment" : "Deposit"}
                                                </li>
                                            )}
                                            {sale.type !== "full" && (
                                                <li>Remaining amount: {CURRENCY_SYMBOL}{sale.remaining_amount}</li>
                                            )}
                                        </ul>
                                    </div>
                                </article>
                            </li>
                        ))}
                    </ul>
                    {sales?.length <= 0 && <p>No data found</p>}
                </div>
            </div>
            <BottomStripe />
        </>
    );
};

export default BreederSales;
