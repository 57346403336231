import React from 'react';
import { Link } from 'react-router-dom';
import { APP_PREFIX } from '../../../utils/appConstants';

const OnBoardingCancel = () => {

    return (
        <>
            <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
                <div className="container">
                    <div className="arextraWrap">
                        <h1>from good homes… <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes</h1>
                    </div>
                    <div className="arbhImgHolder d-none d-md-block position-absolute">
                        <img src="/images/img07.png" className="img-fluid" alt="petbond"/>
                    </div>
                </div>
                <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
                    <i className="aricomoon-hearts"><span className="sr-only">icon</span></i>
                </span>
                <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
                    <i className="aricomoon-starDots"><span className="sr-only">icon</span></i>
                </span>
            </header>
            <article className="um-thkSubmttPage pt-5 pb-15 pb-lg-20">
                <div className="container">
                    <div className="um-thkPageWrap rounded shadow text-center overflow-hidden">
                        <div className="row">
                            <div className="col-12 col-md-10 offset-md-1">
                                <div className="thkTextWrap">
                                    <h2>Oops !<br/> Failed to connect Stripe account.</h2>
                                    <p className="px-xl-10">Your Stripe account has not been connected, if you are
                                        having issues please contact us for assistance by emailing <a
                                            className="emailLink" href="mailto:info@mypetbond.com?subject=Stripe to Petbond connection issue">info@mypetbond.com</a></p>
                                </div>
                                <div>
                                    <Link to={`${APP_PREFIX}/overview`}
                                          className="btn btnFormWidth  btn-primary btnPurpStyle">
                                        View Dashboard
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <span className="bgCover d-block imgBgWarn"
                              style={{backgroundImage: 'url(/images/imgdev1.png)'}}></span>
                    </div>
                </div>
            </article>
        </>
    );

};

export default OnBoardingCancel;
