import React from "react";
import {ReactComponent as ChevronDownIcon} from '../../../assets/icons/chevron-down-icon.svg'

const SelectWithIcon = ({error, children, bindings}) => {
    return (
        <div className="position-relative icnInputWrap select-with-icon">
            <select
                className={`form-control  ${error && "fcError"}`}
                {...bindings}
            >
                {children}
            </select>

            <ChevronDownIcon className="icn" style={{pointerEvents: 'none'}} />
        </div>
    )
}

export default SelectWithIcon;
