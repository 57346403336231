import React from 'react';
import BreederProfile from './BreederProfile';
import VetProfile from './VetProfile';
import CharityProfile from './CharityProfile';
import SubVetProfile from './SubVetProfile';
import {Navigate} from "react-router-dom";

const Profile = () => {

    const passwordInfo = 'Minimum 8 Characters, must contain at least one uppercase letter, one number. Special character (like []/()*%#$) are optional';
    const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

    if (userRole === 'breeder') {
        return (
            <BreederProfile/>
        );
    } else if (userRole === 'veterinarian') {
        return (
            <VetProfile passwordInfo={passwordInfo}/>
        );
    } else if (userRole === 'sub_veterinarian') {
        return (
            <SubVetProfile passwordInfo={passwordInfo}/>
        );
    } else if (userRole === 'charity') {
        return (
            <CharityProfile/>
        );
    }

    return <Navigate to='/' />
};

export default Profile;