import { Navigate } from 'react-router-dom';
import { AUTH_PREFIX } from '../../../utils/appConstants';

function PrivateRoute({children}) {

    const isAuthenticated = localStorage.getItem('access_token');
    const redirectPath = `${AUTH_PREFIX}/login`;

    return isAuthenticated ? children : <Navigate to={redirectPath}/>;

}

export default PrivateRoute;