import React, { useEffect, useState } from 'react';
import ViewAdvert from './ViewAdvert';

const PuppyList = ({setShowPuppyList, puppyList, selectedAdvert}) => {
    const [showViewAdvert, setShowViewAdvert] = useState(false);
    const [selectedPuppy, setSelectedPuppy] = useState('');

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            {showViewAdvert ? (
                <>
                    <>
                        <ViewAdvert
                            setShowViewAdvert={setShowViewAdvert}
                            setShowPuppyList={setShowPuppyList}
                            selectedAdvert={selectedAdvert}
                            selectedPuppy={selectedPuppy}
                        />
                    </>
                </>
            ) : (
                <>
                    <header className="aaPtbdHeader">
                        <h1 className="h3 aaPtbHeading">
                            Advert Puppy's List
                        </h1>
                    </header>
                    <div className="connectbreeder">
                        <ul className="list-unstyled ftList">
                            {puppyList &&
                                puppyList?.map((puppy, index) => (
                                    <li key={index}>
                                        <span className="left">
                                            {puppy.name}
                                        </span>
                                        <div className="right">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setSelectedPuppy(puppy);
                                                    setShowViewAdvert(true);
                                                }}
                                                className="btn btnFormWidth btnFormStyle small-xs ml-0"
                                            >
                                                Edit Puppy
                                            </button>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                        {puppyList <= 0 && <p>No Puppy found</p>}
                        <button
                            type="button"
                            onClick={() => {
                                setShowPuppyList(false);
                            }}
                            className="btn btnFormWidth btnFormStyle small-xs p-0 ml-0 mt-4"
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="227.394"
                                height="41.9"
                                viewBox="0 0 227.394 41.9"
                            >
                                <g
                                    id="Group_1385"
                                    data-name="Group 1385"
                                    transform="translate(-445.974 -796.786)"
                                >
                                    <rect
                                        id="Rectangle_235"
                                        data-name="Rectangle 235"
                                        width="227.394"
                                        height="41.9"
                                        rx="7"
                                        transform="translate(445.974 796.786)"
                                        fill="#e4a039"
                                    ></rect>
                                    <text
                                        id="Back_to_Breeders_List"
                                        data-name="Back to Breeders List"
                                        transform="translate(494.069 824.945)"
                                        fill="#fff"
                                        fontSize="17"
                                        fontFamily="NunitoSans-Regular, Nunito Sans"
                                    >
                                        <tspan x="0" y="0">
                                            Back to Advert List
                                        </tspan>
                                    </text>
                                    <path
                                        id="Path_60025"
                                        data-name="Path 60025"
                                        d="M4454.664,5767.453a1.554,1.554,0,0,1,.008-2.222l7.463-7.328a1.264,1.264,0,1,1,1.789,1.785l-4.987,4.972c-.246.245-.159.444.189.444h13.758a1.262,1.262,0,0,1,1.269,1.262h0a1.265,1.265,0,0,1-1.269,1.263h-13.758c-.35,0-.434.2-.189.443l4.987,4.973a1.264,1.264,0,1,1-1.789,1.785Z"
                                        transform="translate(-3988.334 -4947.7)"
                                        fill="#fff"
                                        fillRule="evenodd"
                                    ></path>
                                </g>
                            </svg>
                        </button>
                    </div>
                </>
            )}
        </>
    );
};

export default PuppyList;
