const groupCitiesByCountry = (cities) => {
    return cities.reduce((acc, city) => {
        if (!acc[city.country]) {
            acc[city.country] = [];
        }
        acc[city.country].push(city);
        return acc;
    }, {});
}

export default groupCitiesByCountry;