import { useLocation } from "react-router-dom";
import { API_ENDPOINT_URL } from "../../utils/appConstants";
import { postRequest } from "../../utils/requests";

const AppPages = () => {
  const location = useLocation();
  const userRole = JSON.parse(localStorage.getItem("currentUser"))?.role;
  const emailVerified = JSON.parse(
    localStorage.getItem("currentUser")
  )?.email_verified_at;
  const onBoardStatus = JSON.parse(localStorage.getItem("on_board_status"));
  const isTransferEnabled = JSON.parse(
    localStorage.getItem("is_transfer_enabled")
  );

  const connectStripe = () => {
    postRequest(
      `${API_ENDPOINT_URL}/breeder/on-boarding/create/account`,
      null,
      true
    ).then((res) => {
      if (res) {
        const data = res.data.data;
        localStorage.setItem("on_board_status", data.on_board_status);
        localStorage.setItem("is_transfer_enabled", data.is_transfer_enabled);
        if (data?.url) {
          window.location.href = data?.url;
        } else {
          window.location.reload();
        }
      }
    });
  };

  const transferPayments = () => {
    postRequest(
      `${API_ENDPOINT_URL}/breeder/on-boarding/create/account`,
      null,
      true
    ).then((res) => {
      if (res) {
        const data = res.data.data;
        if (data.is_transfer_enabled) {
          localStorage.setItem("is_transfer_enabled", data.is_transfer_enabled);
          window.location.reload();
        } else {
          window.location.href = "https://dashboard.stripe.com/login";
        }
      }
    });
  };
  // NOTE BY ROBERT 25/01/2023
  // This component was created to seperate the payment prompt from all the other pages. The strip code was originally located in index.js

  return (
    <div className="dshSdBrWrp">
      <section className="dashboardWrap position-relative pt-5 pb-5">
        <div className="container">
          <div>
            {emailVerified && !onBoardStatus && userRole === "breeder" && (
              <div
                className={`${
                  location.pathname === "/app/sell-pet" ? " noticeNoOffset" : ""
                }`}
              >
                <section className="py-5 bg-primary rounded shadow-sm">
                  <div className="row">
                    <div className="col-11 mx-auto text-left">
                      <h4 className="mt-3 mb-3 fs-1 fw-bold text-light">
                        How to get paid
                      </h4>

                      <div className="text-white my-3">
                        <span className="bg-warning px-2 py-1 rounded">
                          Bank Transfer:
                        </span>{" "}
                        If you are having issues setting up your stripe account
                        you can request a bank transfer by contacting us.
                        <p className="mt-2">How to get bank trasfer: </p>
                        <li>
                          You must email us from the same email that is used in
                          your petbond account
                        </li>
                        <li>
                          You must include your full details name, address,
                          phone and advert details so we can verify it is you
                        </li>
                        <li>
                          You must include your bank details IBAN, BIC, Account
                          Name
                        </li>
                        <li>Send email to info@mypetbond.com</li>
                        <li>Please allow up to 5 business days</li>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppPages;
