import React, { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../utils/requests";
import {API_ENDPOINT_URL, AUTH_PREFIX, CURRENCY_SYMBOL} from "../../utils/appConstants";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import PuppyDepositInfoCard from "../../components/shared/PuppyDepositInfoCard";
import  {ReactComponent as SecureIcon}  from '../../assets/images/secure.svg';

const VisitPet = () => {
  const [selectedAdvert, setSelectedAdvert] = useState(null);
  const [pet, setPet] = useState(null);
  const [depositPrice, setDepositPrice] = useState(null);
  const [search, setSearch] = useSearchParams();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const id = search.get("id");
    getRequest(
      `${API_ENDPOINT_URL}/f-end/advert/view-pet/${params?.breed}/${params?.advertName}/${params?.petName}/${id}`
    ).then((res) => {
      if (res) {
        setSelectedAdvert(res.data.data);
        const pet = res.data.data.pets[0];
        setPet(pet);
        const petDepositPrice = pet?.price * 0.2;
        setDepositPrice(petDepositPrice.toFixed(2) % 1 === 0 ? petDepositPrice.toFixed(0) : petDepositPrice.toFixed(2))
      } else {
        navigate(`/pet-listing/${params?.breed}`);
      }
    });
  }, []);

  const handlePayments = () => {
    const isAuthenticated = localStorage.getItem('access_token');
    if (isAuthenticated) {
      const body = {pet_id: search.get("id"), is_deposit: 1};
      postRequest(`${API_ENDPOINT_URL}/buyer/checkout`, body, true).then((res) => {
        if (res) {
          window.location.href = res.data.data.url;
        }
      });
    } else {
      navigate(`${AUTH_PREFIX}/buyer-signup`);
    }
  };

  return (
    <>
      <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
        <div className="container">
          <div className="arextraWrap">
            <h2 className="h1">
              from good homes… <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes
            </h2>
          </div>
          <div className="arbhImgHolder d-none d-md-block position-absolute">
            <img src="/images/img07.png" className="img-fluid" alt="petbond" />
          </div>
        </div>
        <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
          <i className="aricomoon-hearts">
            <span className="sr-only">icon</span>
          </i>
        </span>
        <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
          <i className="aricomoon-starDots">
            <span className="sr-only">icon</span>
          </i>
        </span>
      </header>
      <div className="container pt-5 pb-10 pb-md-15 pb-lg-20 petDetailWrap petVisit">
        <article className="arptBoxWrap arptBoxWrapvii shadow rounded bg-white">
          <header className="pb-2 pb-md-8">
              <div className="row">
                <div className="col-md-8">
                  <h1 className="h3">How can I meet the puppy?</h1>

                  <div className="d-md-none pt-1 pb-4">
                    <img
                        src={
                          pet?.photo
                              ? `${API_ENDPOINT_URL}/image-retrieve/${pet?.photo}`
                              : "/images/image-placeholder.png"
                        }
                        alt={pet?.name}
                        className="img-fluid w-100 h-auto rounded-sm "
                    />
                  </div>

                  <p>Deposit are designed to bring more transparency for the transactions,
                    we want to make sure you really bond with the amazing puppy you have
                    picked. You’ll be asked to make a 20% deposit ({CURRENCY_SYMBOL}{ depositPrice }) so you can start a
                    conversation with the breeder and get all your questions answered. </p>
                </div>
                <div className="col-md-4 d-none d-md-block">
                  <img
                      src={
                        pet?.photo
                            ? `${API_ENDPOINT_URL}/image-retrieve/${pet?.photo}`
                            : "/images/image-placeholder.png"
                      }
                      alt={pet?.name}
                      className="img-fluid w-100 h-auto rounded-sm"
                  />
                </div>
              </div>
          </header>

          <PuppyDepositInfoCard />

          <div className="row instructions__container pt-10">
            <div className="col-md-5 instructions">
              <div className="instructions__content">
                <div className="number">01</div>
                <div>
                  <h3 className="heading fontFredoka">Make a deposit at 20%</h3>
                  <p>At PetBond we take pride in the service we provide for you. The deposit you’re making today will ensure the health and welfare of your puppy, and protect your money.</p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <img
                  className="img-fluid drop-shadow"
                  src="/images/stripe-checkout.png"
                  alt=""
              />
            </div>
          </div>

          <div className="row instructions__container pt-8">
            <div className="col-md-6 order-2 order-md-1">
              <img
                  className="img-fluid drop-shadow"
                  src="/images/message-panel.png"
                  alt=""
              />
            </div>
            <div className="col-md-6 order-1 order-md-2 instructions instructions--2">
              <div className="instructions__content">
                <div className="number">02</div>
                <div>
                  <h3 className="heading fontFredoka">Start a conversation</h3>
                  <p>After you have made the deposit you’ll be able to initiate a conversation with the breeder so they can answer your questions and arrange for you to meet the puppy.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row instructions__container pt-10">
            <div className="col-md-5 instructions instructions--3">
              <div className="instructions__content">
                <div className="number">03</div>
                <div>
                  <h3 className="heading fontFredoka">Arrange to meet your amazing puppy today :)</h3>
                  <p>We guarantee peace of mind that you’ll be refunded 100% for the deposit you placed if you decide not to take the puppy home.</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 d-flex d-md-block justify-content-center">
              <img
                  width="176px"
                  className="instructions--3__image"
                  src="/images/girl-with-dog.png"
                  alt=""/>
            </div>
            <div className="instructions__meet col-lg-4 pt-10 pt-lg-30">
                <button type="button"
                        onClick={() => handlePayments()}
                        className="btn bg-warning text-uppercase d-block text-white font-weight-bold">
                  Meet the puppy today!
              </button>

              <div className="py-4 d-flex align-content-center">
                <SecureIcon/>
                <span className="pl-3 small">Your deposit is 100% safe!</span>
              </div>
            </div>
          </div>

        </article>
      </div>
    </>
  );
};

export default VisitPet;
