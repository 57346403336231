import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getRequest } from '../../../../utils/requests';
import { APP_PREFIX, API_ENDPOINT_URL } from '../../../../utils/appConstants';

const BuyerOverview = () => {
    const [alertCount, setAlertCount] = useState('');
    const [ordersFullCount, setOrdersFullCount] = useState('');
    const [ordersDepositCount, setOrdersDepositCount] = useState('');

    useEffect(() => {

        getRequest(`${API_ENDPOINT_URL}/buyer/alert/index`, true).then((res) => {
            if (res) {
                setAlertCount(res.data.data.length);
            }
        });

        getRequest(`${API_ENDPOINT_URL}/buyer/sales/list`, true).then((res) => {
            if (res) {
                let depositCounter = 0;
                for (const obj of res.data.data) {
                    if (obj.type === 'deposit') depositCounter++;
                }
                setOrdersDepositCount(depositCounter);
                let fullCounter = 0;
                for (const obj of res.data.data) {
                    if (obj.type === 'full') fullCounter++;
                }
                setOrdersFullCount(fullCounter);
            }
        });

    }, []);


    return (
        <>
            <div className="aaContentWidget buyerDashboardView">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Welcome to Pet Bond</h1>
                </header>
                <div className="aaDbRow aaDbInfoRow ">
                    <h6 className="h6 aaAdvTitle fontNoto mb-3">You have {alertCount} Pet alerts created</h6>
                    <div className="aa-formBtnWrap mb-7">
                        {/* <Link to={`${APP_PREFIX}/create-pet-alert`}
	                        className="btn btnGrey btnFormStyle small-xs mr-3"
	                    >
	                        Edit Pet Alert
						</Link> */}
                        <Link to={`${APP_PREFIX}/create-pet-alert`}
                              className="btn btn-warning btnFormStyle small-xs"
                        >
                            Create Pet Alert
                        </Link>
                    </div>
                    <div className="um-formBtnWrap pb-1">
                        <h6 className="h6 aaAdvTitle fontNoto">Deposit Paid: {ordersFullCount}</h6>
                    </div>
                    <div className="um-formBtnWrap mb-2">
                        <h6 className="h6 aaAdvTitle fontNoto">Balances waiting to be paid: {ordersDepositCount}</h6>
                    </div>
                </div>
            </div>
        </>
    );

};

export default BuyerOverview;
