import React, { useEffect } from 'react';
import { getRequest, postRequest } from '../../../utils/requests';
import { API_ENDPOINT_URL, APP_PREFIX } from '../../../utils/appConstants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const LoginWithLink = () => {

    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!search.get('email') || !search.get('code')) {
            return navigate(`${APP_PREFIX}/overview`);
        }
        const loginHandler = async () => {
            const isLoggedIn = localStorage.getItem('access_token');
            if (isLoggedIn) {
                const logoutRes = await getRequest(`${API_ENDPOINT_URL}/logout`, true);
                if (logoutRes) {
                    localStorage.clear();
                }
            }

            const data = {
                email: search.get('email'),
                code: search.get('code')
            };
            const loginRes = await postRequest(`${API_ENDPOINT_URL}/login-with-code`, data);
            if (loginRes) {
                const data = loginRes.data.data;
                if (data.user.role !== 'admin') {
                    localStorage.setItem('currentUser', JSON.stringify(data.user));
                    localStorage.setItem('access_token', data.access_token);
                    localStorage.setItem('on_board_status', data.on_board_status);
                    localStorage.setItem('is_transfer_enabled', data.is_transfer_enabled);
                    navigate(`${APP_PREFIX}/overview`);
                } else {
                    toast.error('Access denied');
                }
            }
        };
        loginHandler();
    }, []);

    return (
        <div className="loaderWrap">
            <div className="loaders">
                <div className="loader">
                    <div className="loader-inner ball-clip-rotate-multiple">
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default LoginWithLink;