import React, { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../../../utils/requests";
import { API_ENDPOINT_URL } from "../../../../../utils/appConstants";
import { toFormData } from "../../../../../utils";
import { toast } from "react-toastify";

const StepOne = () => {
  const [licenseCheck, setLicenseCheck] = useState("no");
  const [regCheck, setRegCheck] = useState("no");
  const [kennyClubId, setKennyClubId] = useState("");
  const [regNumber, setRegNumber] = useState("");

  useEffect(() => {
    getRequest(`${API_ENDPOINT_URL}/breeder/profile/view`, true).then((res) => {
      const detail = res.data.data.detail;

      if (detail.reg_number) {
        setRegCheck("yes");
        setRegNumber(detail.reg_number);
      }
      if (detail.kenney_club) {
        setLicenseCheck("yes");
        setKennyClubId(detail.kenney_club);
      }
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const getRequestBody = () => {
    let body = {};
    if (kennyClubId) {
      body.kenney_club = kennyClubId;
    }
    if (regNumber) {
      body.reg_number = regNumber;
    }

    body = toFormData(body);
    return body;
  };

  const saveProgress = (e) => {
    e.preventDefault();
    const body = getRequestBody();
    postRequest(
      `${API_ENDPOINT_URL}/breeder/profile/info/update`,
      body,
      true
    ).then((res) => {
      if (res) {
        toast.success("Progress saved successfully");
      }
    });
  };

  return (
    <section className="um-brdRegForm mb-10">
      <form className="um-bddFormWrap" onSubmit={saveProgress}>
        <div className="container">
          <div className="shadow-sm p-5 rounded-lg">
            <header className="aaPtbdHeader margin-zero">
              <h4 className="aaPtbHeading">Step 1: Other Details</h4>
              <hr />
            </header>
            <div className="advertpet">
              <div className="row">
                <div className="col-12 col-md-7">
                  <div>
                    <p> Do you have a Kennel Club Membership ID</p>
                  </div>
                  <div className="form-group">
                    <label className="checkboxlabel">
                      Yes
                      <input
                        className="css3checkbox"
                        type="radio"
                        name="licenseCheck"
                        value={"yes"}
                        checked={licenseCheck === "yes"}
                        onChange={(event) =>
                          setLicenseCheck(event.target.value)
                        }
                      />
                    </label>
                    <label className="checkboxlabel">
                      No
                      <input
                        className="css3checkbox"
                        type="radio"
                        name="licenseCheck"
                        value={"no"}
                        checked={licenseCheck === "no"}
                        onChange={(event) =>
                          setLicenseCheck(event.target.value)
                        }
                      />
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-7 mb-6">
                      <div className="um-bddInputWrap">
                        {licenseCheck === "yes" && (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Kennel Club id..."
                            value={kennyClubId}
                            onChange={(e) => setKennyClubId(e.target.value)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    {" "}
                    <p>Do you sell more than 6 pets per year:</p>
                  </div>
                  <div className="form-group">
                    <label className="checkboxlabel">
                      Yes
                      <input
                        className="css3checkbox"
                        type="radio"
                        name="regCheck"
                        value={"yes"}
                        checked={regCheck === "yes"}
                        onChange={(event) => setRegCheck(event.target.value)}
                      />
                    </label>
                    <label className="checkboxlabel">
                      No
                      <input
                        className="css3checkbox"
                        type="radio"
                        name="regCheck"
                        value={"no"}
                        checked={regCheck === "no"}
                        onChange={(event) => setRegCheck(event.target.value)}
                      />
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-7 mb-6">
                      <div className="um-bddInputWrap">
                        {regCheck === "yes" && (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Registration number..."
                            value={regNumber}
                            onChange={(e) => setRegNumber(e.target.value)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <div className="btn-box ">
                    <button
                      className="nodesign shadow-sm rounded"
                      type="submit"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="157.421"
                        height="41.9"
                        viewBox="0 0 157.421 41.9"
                      >
                        <g
                          id="Group_1386"
                          data-name="Group 1386"
                          transform="translate(-722.624 -1338.86)"
                        >
                          <rect
                            id="Rectangle_535"
                            data-name="Rectangle 535"
                            width="157.421"
                            height="41.9"
                            rx="7"
                            transform="translate(722.624 1338.86)"
                            fill="#e4a039"
                          />
                          <text
                            id="Next_Step"
                            data-name="Next Step"
                            transform="translate(741.717 1367.017)"
                            fill="#fff"
                            fontSize="17"
                            fontFamily="NunitoSans-Regular, Nunito Sans"
                          >
                            <tspan className="text-center">
                              Save & Continue
                            </tspan>
                          </text>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default StepOne;
