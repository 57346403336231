import bgImg from '../../../assets/images/imgdev1.png';
import { Link } from 'react-router-dom';
import { APP_PREFIX } from '../../../utils/appConstants';

const SignupCongrats = () => {

    const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

    return (
        <section className="um-sectionWrap um-thkSubmttPage mb-15">
            <div className="container">
                <div className="um-thkPageWrap rounded shadow text-center">
                    <div className='row'>
                        <div className='col-12 col-md-10 offset-md-1 px-lg-5'>
                            <div className="thkTextWrap">
                                <div className='px-lg-10'>
                                    {userRole === 'veterinarian' ?
                                        <h2>Congratulations !<br/> Your Veterinary Account has been sent for review</h2> :
                                        <h2>Congratulations !<br/> We Are Reviewing You’re Petbond <span
                                                className="text-capitalize">{userRole}</span> Account.</h2>
                                    }
                                </div>
                                {userRole === 'veterinarian' ?
                                    <p>
                                        Your account request has been submitted to Petbond and you should receive an email
                                        when your account has been fully activated.
                                        <br/>
                                        <br/>
                                        You can log into your dashboard to update profile information (please note account
                                        access is restricted until you verify your email address)
                                    </p> :
                                    <p>
                                        You can log into your draft account but features are restructed until <br/>
                                        you confirm your email address and your account is fully approved by a <br/>
                                        Petbond admin.
                                    </p>
                                }
                                <div className="um-formBtnWrapPurp">
                                    <Link to={`${APP_PREFIX}/overview`}
                                        className="btn btnFormWidth btnPurpStyle btnWhiteStyle">View Dashboard</Link>
                                    {userRole !== 'veterinarian' &&
                                        <Link to={`${APP_PREFIX}/sell-pet`} className="btn btnFormWidth  btn-primary btnPurpStyle">Place Advert</Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="bgCover d-block imgBgWarn" style={{backgroundImage: `url(${bgImg})`}}/>
                </div>
            </div>
        </section>
    );

};

export default SignupCongrats;
