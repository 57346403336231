import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {arrangeViewing, sendMessage, viewConversation} from "../../../../services/authorized/chat.service";
import {initPusher, subscribeChat} from "../../../../services/authorized/pusher.service";

const AdvertConversation = () => {
    let pusher = null;
    let channel = null;
    const { chatId } = useParams();
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const doNotLoad = user?.role !== 'breeder' && user?.role !== 'buyer' && user?.role !== 'charity';
    const [arrangeViewingDisabled, setArrangeViewingDisabled] = useState(false);
    const [recipientName, setRecipientName] = useState('');
    const [advert, setAdvert] = useState({});
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const el = useRef(null);
    const featuresOffered = {
        buyer: [
            'All PetBond breeders are approved breeders and not puppy farmers or unethical breeders.',
            'Receive confirmation of puppy’s health directly from the veterinary surgeon who examined and vaccinated it correctly.',
            'Your money is protected and safe, as full payment is not made unless you are fully satisfied with puppy.'
        ],
        advertOwner: [
            'FREE Vet Vaccinations & Health Checks for all puppies sold through our site',
            'FREE to advertise on our site',
            'FREE Genetic testing for parent dogs before breeding when a puppy is sold through PetBond'
        ]
    };

    useEffect(() => {
        if (!doNotLoad) {
            viewConversation(user, chatId).then((data) => {
                window.setMessages = setMessages;
                setAdvert(data.advert);
                setRecipientName(data.recipientName);
                window.setMessages(data.messages);
                initSocketConnection();
            });
        }

        return () => {
            if (channel) {
                channel.unbind();
            }

            if (pusher) {
                pusher.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        el.current.scrollTop = el.current.scrollHeight;
    }, [messages]);

    const initSocketConnection = () => {
        pusher = initPusher();

        channel = subscribeChat(pusher, chatId, user);
    }

    const send = () => {
        if (newMessage !== '') {
            const tmpMessage = newMessage;
            const message = {
                id: `dummy-id`,
                message: tmpMessage,
                is_read: 0,
                sender_id: user.id,
                chatId: chatId,
                userName: 'You',
            };
            window.setMessages([...messages, message]);
            setNewMessage('');
            sendMessage(user?.role, chatId, tmpMessage);
        }
    }

    const arrangePetViewing = async () => {
        if (advert.id) {
            setArrangeViewingDisabled(true);
            if (!(await arrangeViewing(advert.id))) {
                setArrangeViewingDisabled(false);
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            send();
        }
    }

    return (
        <div className="adMessageWrap">
            <div className="aaContentWidget">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">{advert?.advert_name}</h1>
                </header>
                <div className="aaScrollWrap" id={'el'} ref={el}>
                    <div className="aaContectScroll" style={{ minHeight: messages.length > 0 ? '630px' : '30px'}}>
                        {messages && messages.map((message, index) => (
                            <span key={index}>
                                {index === 0 && <div className="customerMessage fixMessage">
                                    <span className={'customerName'}>Stay protected And Use The PetBond Payment System</span>
                                    <p>
                                        <ul>
                                            {
                                                user?.role === 'buyer' ?
                                                    featuresOffered.buyer.map((feature) => (<li>{feature}</li>))
                                                    : featuresOffered.advertOwner.map((feature) => (<li>{feature}</li>))
                                            }
                                        </ul>
                                    </p>
                                </div>
                                }
                                <div className={'customerMessage' + (user?.id === message.sender_id ? ' senderMessage' : '')}>
                                    <span className="customerName">{user?.id === message.sender_id ? 'You' : recipientName}</span>
                                    <p>
                                        {message.message.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                {index !== message.message.split('\n').length - 1 && <br />}
                                            </React.Fragment>
                                        ))}
                                    </p>
                                </div>
                            </span>
                        ))}
                    </div>
                </div>
                <form action="" className="messageTypeBox">
                    <label>Reply To {user.role === 'buyer' ? 'Advert Owner' : 'Buyers Question'}</label>
                    <textarea
                        value={newMessage}
                        onKeyDown={handleKeyDown}
                        onChange={(event) => setNewMessage(event.target.value)}
                    ></textarea>
                </form>
                <div className="advertMessageBtn">
                    <button type="button" className="btn btn-warning" onClick={send}>Send Message</button>
                    <Link
                        to={user.role === 'buyer' ? `/app/messages` : `/app/messages/view-advert/${advert.id}`}
                        className="btn btn-primary"
                    >Back To Advert Messages</Link>
                    {user.role === 'buyer' && (
                        <button type="button" className="btn btn-primary" disabled={arrangeViewingDisabled} onClick={arrangePetViewing}>Arrange Viewing</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdvertConversation;