import React from "react";

import {Navigate} from "react-router-dom";
import BreederFreeServices from "./BreederFreeServices";

const FreeServices = () => {
  const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

  if (userRole === 'breeder') {
    return <BreederFreeServices />;
  }

  return <Navigate to='' />
};

export default FreeServices;
