import Slider from "react-slick";
import { AUTH_PREFIX } from "../../utils/appConstants";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import React from "react";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slickNext ssArrowVii d-none d-md-block aricomoon-ararrowRight position-absolute slick-arrow`}
      style={{ ...style, display: "block", color: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slickPrev ssArrowVii d-none d-md-block aricomoon-ararrowLeft position-absolute slick-arrow`}
      style={{ ...style, display: "block", color: "black" }}
      onClick={onClick}
    />
  );
}

const ForBreeder = () => {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    arrows: true,
    appendDots: (dots) => (
      <div>
        <ul className="ardotsList ardotsListvi d-flex justify-content-center d-md-block list-unstyled mb-0">
          {" "}
          {dots}{" "}
        </ul>
      </div>
    ),
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    speed: 500,
    autoplay: false,
    fade: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <>
      <Helmet>
        <title>Sell Dogs & Puppies Safely in the UK | PetBond</title>
        <meta
          name="description"
          content="PetBond is the safest and most ethical way to sell dogs in UK. Register as a breeder and access thousands of dog buyers."
        />
      </Helmet>
      {/* HEADER */}
      <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
        <div className="container">
          <div className="arextraWrap">
            <h2 className="h1">
              from good homes… <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes
            </h2>
          </div>
          <div className="arbhImgHolder d-none d-md-block position-absolute">
            <img src="/images/img07.png" className="img-fluid" alt="petbond" />
          </div>
        </div>
        <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
          <i className="aricomoon-hearts">
            <span className="sr-only">icon</span>
          </i>
        </span>
        <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
          <i className="aricomoon-starDots">
            <span className="sr-only">icon</span>
          </i>
        </span>
      </header>

      <article className="text-center wcbCaringBond pt-10">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1">
              <h1 className="text-capitalize h3">
                Where caring Sellers standout. <br />
                Sell your puppies & Dogs in Confidence for{" "}
                <span className="text-warning">FREE</span>
              </h1>
              <br />
              <br />
              <h3 className="text-capitalize">How It Works</h3>
              <p>
                As experienced and caring vets, we know how much you love your
                dogs. We see you everyday! We also know how much you only want
                the most caring and loving homes for your puppies.
              </p>
              <p>
                With PetBond, you can now find a great home for every puppy and
                with pet parents who truly understand your breed. You are at the
                core of what we do, join up today and leave the paperwork and
                hassle with us. We want you to spend all your time on doing what
                you do best – caring &amp; loving your puppies.
              </p>
            </div>
          </div>
        </div>
      </article>
      <article className="wupPetbondBlock py-10 pb-10">
        <div className="container">
          <div className="bg-white shadow rounded wupTextDescr">
            <h3 className="text-center">Benefits of using PetBond</h3>
            <ul className="list-unstyled wupBondList">
              <li>
                <span className="d-block countList"></span>Number 1 Choice for
                Safe, Ethical & Trusted Breeders & Sellers. Petbond is designed
                and managed by vets, who only have the best interests of your
                puppy’s health & welfare at heart. We care as much about your
                pet’s health & welfare as you do, this is why we want to support
                you.
              </li>
              <li>
                <span className="d-block countList"></span>Find the Very Best
                Homes for your puppies As vets we meet great breeders & sellers
                like you every-day, and we know how much you only want the very
                best & most loving homes for your puppies. This is why we ensure
                that all new pet parents are choosing the right pet and at the
                right time. This way all pets & people win!
              </li>
              <li>
                <span className="d-block countList"></span>Stand Out as a
                Petbond Approved Breeder & Seller In times where puppy farmers &
                profiteers thrive on other sites, we stand out from the crowd.
                Just like you, as a PetBond Approved Breeder & seller, animal
                health & welfare is our driving force. We are the very best at
                what we do, and we do it for our pets. As vets we dedicate our
                lives to animals, and PetBond is committed to you.
              </li>
              <li>
                <span className="d-block countList"></span>
                <strong className="text-warning textSizeLarge h6">
                  Free to Use Pet Listing Service&hellip;&hellip;&hellip;.
                </strong>{" "}
                Our Way of Saying Thanks as vets, we appreciate the effort and
                love you have invested in your dogs & puppies. Having such a
                great start in life shapes their future, and we know that this
                is priceless for new pet parents. Our system is hassle free,
                welcoming to you and as a way of saying ‘Thank You’ for being a
                distinguished Petbond approved breeder & seller. Our service is
                free to use.
              </li>
            </ul>
            <div className="btnWupWrap d-flex justify-content-center">
              <Link
                to={`${AUTH_PREFIX}/breeder-signup`}
                className="btn btn-info btnScnStyl"
              >
                List my Puppy or Dog for Free
              </Link>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default ForBreeder;
