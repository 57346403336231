import React from 'react';
import BreederAdvert from './BreederAdvert';
import CharityAdvert from './CharityAdvert';
import {Navigate} from "react-router-dom";

const Advert = () => {

    const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

    if (userRole === 'breeder') {
        return (
            <BreederAdvert/>
        );
    } else if (userRole === 'charity') {
        return (
            <CharityAdvert/>
        );
    }

    return <Navigate to='/' />
};

export default Advert;