import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './Login';
import BuyerSignup from './BuyerSignup';
import BreederSignup from './BreederSignup';
import CharitySignup from './CharitySignup';
import VetSignup from './VetSignup';
import SignupSelector from './SignupSelector';
import SignupCongrats from './SignupCongrats';
import ResetPassword from './ResetPassword';

const AuthPages = (props) => {
    const passwordInfo = 'Minimum 8 Characters, must contain at least one uppercase letter, one number. Special character (like []/()*%#$) are optional';
    const newsletterInfo = 'Get the latest news and offers in the newsletters';

    return (
        <Routes>
            <Route path="/login" element={<Login {...props}/>}/>
            <Route path="/signup-selector" element={<SignupSelector/>}/>
            <Route path="/signup-congrats" element={<SignupCongrats/>}/>
            <Route path="/buyer-signup"
                   element={<BuyerSignup newsletterInfo={newsletterInfo} passwordInfo={passwordInfo} {...props}/>}/>
            <Route path="/breeder-signup"
                   element={<BreederSignup newsletterInfo={newsletterInfo} passwordInfo={passwordInfo} {...props}/>}/>
            <Route path="/charity-signup"
                   element={<CharitySignup newsletterInfo={newsletterInfo} passwordInfo={passwordInfo} {...props}/>}/>
            <Route path="/vet-signup"
                   element={<VetSignup newsletterInfo={newsletterInfo} passwordInfo={passwordInfo} {...props}/>}/>
            <Route path="/reset-password" element={<ResetPassword passwordInfo={passwordInfo}/>}/>
        </Routes>
    );

};

export default AuthPages;
