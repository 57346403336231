import React, { useEffect, useState } from "react";
import { getRequest } from "../../utils/requests";
import {
  API_ENDPOINT_URL,
  AUTH_PREFIX,
  CURRENCY_SYMBOL,
  GOOGLE_KEY, PET_LISTING_BREED_CONTENT_API_URL,
  PET_LISTING_LOCATION_CONTENT_API_URL
} from "../../utils/appConstants";
import { Link, useNavigate, useParams } from "react-router-dom";
import { capitalizeTheFirstLetterOfEachWord, getStars } from "../../utils";
import { Helmet } from "react-helmet-async";
import {Map} from "../../components/shared/map/map.component";
import {Wrapper} from "@googlemaps/react-wrapper";
import groupCitiesByCountry from "../../utils/groupCitiesByCountry";

const PetListing = () => {
  const [adverts, setAdverts] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [fetchedBreeds, setFetchedBreeds] = useState([]);
  const [fetchedLocations, setFetchedLocations] = useState([]);
  const [fetchedGroupedLocations, setFetchedGroupedLocations] = useState({});
  const [searchedBreed, setSearchedBreed] = useState("");
  const [searchedSize, setSearchedSize] = useState("");
  const [searchedLocation, setSearchedLocation] = useState("");
  const [searchedPetSource, setSearchedPetSource] = useState("");
  const [searchedLivingSpace, setSearchedLivingSpace] = useState("");
  const [firstTimeCheck, setFirstTimeCheck] = useState("");
  const [showSideBar, setShowSideBar] = useState(false);
  const [tagTitle, setTagTitle] = useState(
    "Find Dogs & Puppies Near You | Approved Breeders | PetBond"
  );
  const [tagDesc, setTagDesc] = useState(
    "Search Dogs and Puppies for sale in the UK. Choose from PetBond approved dog breeders or registered rescue centres. Search by breed, location, or size."
  );
  const [h1Tag, setH1Tag] = useState("Find a Pet");
  const [postalCode, setPostalCode] = useState("");
  const [distance, setDistance] = useState("10");
  const [isValidPostalCode, setIsValidPostalCode] = useState(false);
  const [managedContent, setManagedContent] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params?.search) {
      getRequest(`${API_ENDPOINT_URL}/f-end/advert/list`).then((res) => {
        if (res) {
          setAdverts(res.data.data.data);
          setPaginationData(res.data.data);
        }
      });
    }
    const getBreedsNCities = async () => {
      const breedsRes = await getRequest(
        `${API_ENDPOINT_URL}/basic-listing/breeds`
      );
      if (breedsRes) {
        setFetchedBreeds(breedsRes.data.data);
      }
      const citiesRes = await getRequest(
        `${API_ENDPOINT_URL}/basic-listing/cities`
      );
      if (citiesRes) {
        setFetchedLocations(citiesRes.data.data);
        setFetchedGroupedLocations(groupCitiesByCountry(citiesRes.data.data));
      }
      if (params?.search) {
        if (params?.search?.includes("-breed")) {
          setSearchedSize(params?.search);
        }
        if (params?.search?.includes("-living-space")) {
          setSearchedLivingSpace(params?.search);
        }
        if (params?.search?.includes("suitable-first-time-pet-parents")) {
          setFirstTimeCheck(params?.search);
        }
        if (
          params?.search === "hobbybreeder" ||
          params?.search === "ikcbreeder" ||
          params?.search === "adoption"
        ) {
          setSearchedPetSource(params?.search);
        }
        if (
          !params?.search?.includes("-living-space") &&
          !params?.search?.includes("-breed")
        ) {
          const foundBreed = breedsRes?.data?.data?.find(
            (breed) => breed.slug === params?.search
          );
          const foundLocation = citiesRes?.data?.data?.find(
            (city) => city.slug === params?.search
          );
          if (foundBreed) {
            setSearchedBreed(foundBreed?.slug);
          }
          if (foundLocation) {
            setSearchedLocation(foundLocation?.slug);
          }
        }
      }
    };
    getBreedsNCities();
  }, []);

  useEffect(() => {
    searchAdverts();
  }, [
    searchedBreed,
    searchedSize,
    searchedLivingSpace,
    searchedLocation,
    searchedPetSource,
    firstTimeCheck,
    isValidPostalCode,
    distance,
  ]);

  useEffect(() => {
    setManagedContent(null);

    if(searchedLocation === '') {
      return;
    }

    getRequest(`${PET_LISTING_LOCATION_CONTENT_API_URL}${searchedLocation}`).then((response) => {
      if(response.data.length === 0) {
        return;
      }

      setManagedContent(response.data[0]?.acf?.content.replace(/\r\n\r\n/g, '<br>').replace(/&nbsp;/g, ''));
    })
  }, [searchedLocation])

  useEffect(() => {
    setManagedContent(null);

    if(searchedBreed === '') {
      return;
    }

    getRequest(`${PET_LISTING_BREED_CONTENT_API_URL}${searchedBreed}`).then((response) => {
      if(response.data.length === 0) {
        return;
      }

      setManagedContent(response.data[0]?.acf?.content.replace(/\r\n\r\n/g, '<br>').replace(/&nbsp;/g, ''));
    })
  }, [searchedBreed])

  const searchAdverts = async () => {
    const params = {};
    if (searchedBreed) {
      params.search = searchedBreed;
    }
    if (searchedSize) {
      params.search = searchedSize;
    }
    if (searchedLivingSpace) {
      params.search = searchedLivingSpace;
    }
    if (firstTimeCheck) {
      params.suitable_for_first = firstTimeCheck;
    }
    if (searchedLocation) {
      params.city = searchedLocation;
    }
    if (searchedPetSource) {
      params.adoption_type = searchedPetSource;
    }
    if (isValidPostalCode && postalCode && distance) {
      try {
        const geocoder = new window.google.maps.Geocoder();
        const response = await geocoder.geocode({'address': postalCode});
        params.lat = response.results[0].geometry.location.lat();
        params.lng = response.results[0].geometry.location.lng();
        params.distance = distance;
      } catch (err) {
        console.log(err);
      }
    }

    if (isValidPostalCode || postalCode === '') {
      getRequest(`${API_ENDPOINT_URL}/f-end/advert/list`, false, params).then(
          (res) => {
            if (res) {
              setPaginationData(res.data.data);
              setAdverts(res.data.data.data);
              if (
                  !params?.search?.includes("-living-space") &&
                  !params?.search?.includes("-breed")
              ) {
                const foundBreed = fetchedBreeds?.find(
                    (breed) => breed.slug === params?.search
                );
                const foundLocation = fetchedLocations?.find(
                    (loc) => loc.slug === params?.city
                );
                if (foundBreed) {
                  setTagTitle(
                      `Find ${foundBreed?.name} Dogs and Puppies for Sale in the UL | PetBond`
                  );
                  setTagDesc(
                      `${foundBreed?.name} dogs and puppies for sale in the UK. Choose from PetBond approved dog breeders or registered rescue centres. See pictures and breeder information.`
                  );
                  setH1Tag(`${foundBreed?.name} Dogs and Puppies for Sale`);
                }
                if (foundLocation) {
                  setTagTitle(
                      `Find Dogs & Puppies for Sale in ${foundLocation?.name} | PetBond`
                  );
                  setTagDesc(
                      `Search Dogs and Puppies for sale around ${foundLocation?.name}. Choose from PetBond approved dog breeders or registered rescue centres. Filter by breed and size.`
                  );
                  setH1Tag(`Dogs and Puppies for Sale in ${foundLocation?.name}`);
                }
              }
              if (
                  params.search ||
                  params.city ||
                  params.adoption_type ||
                  params.suitable_for_first
              ) {
                navigate(
                    `/pet-listing/${
                        params.search ||
                        params.city ||
                        params.suitable_for_first ||
                        params.adoption_type
                    }`
                );
              }
            }
          }
      );
    }
  };

  const searchByPostalCode = (event) => {
    const postalCode = event.target.value;
    setPostalCode(postalCode);
    if (postalCode) {
      const pattern = /^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/;
      const isValid = pattern.test(event.target.value);

      setIsValidPostalCode(isValid);
    } else {
      setIsValidPostalCode(false);
    }
  }

  const goToNextPage = () => {
    let url = paginationData?.next_page_url;
    if (searchedPetSource) {
      url = `${url}&adoption_type=${searchedPetSource}`;
    }
    getRequest(url, true).then((res) => {
      if (res) {
        setPaginationData(res.data.data);
        setAdverts(res.data.data.data);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const goToPrevPage = () => {
    let url = paginationData?.prev_page_url;
    if (searchedPetSource) {
      url = `${paginationData?.prev_page_url}&adoption_type=${searchedPetSource}`;
    }
    getRequest(url, true).then((res) => {
      if (res) {
        setPaginationData(res.data.data);
        setAdverts(res.data.data.data);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const resetSearch = () => {
    setSearchedBreed("");
    setSearchedSize("");
    setSearchedLocation("");
    setSearchedPetSource("");
    setSearchedLivingSpace("");
    setFirstTimeCheck("");
    setTagTitle("Find Dogs & Puppies | Approved Breeders | for Sale | PetBond");
    setTagDesc(
      "Search Dogs and Puppies for sale around UK. Choose from PetBond approved dog breeders or registered rescue centres. Search by pet type, breed location."
    );
    setH1Tag("View All Dogs and Puppies for Sale");
    setShowSideBar(false);
    navigate(`/pet-listing`);
  };

  let pageH1 = "";

  const dynamicTags = () => {
    let tagTitleVal = tagTitle;
    let tagDescVal = tagDesc;
    let h1Val = h1Tag;

    if (params?.search) {
      const searchVal = params?.search;
      const formattedSearchVal = capitalizeTheFirstLetterOfEachWord(
        searchVal.replaceAll("-", " ")
      );
      if (searchVal?.includes("-living-space")) {
        tagTitleVal = `Find ${formattedSearchVal} Dogs and Puppies for Sale | Approved Breeders | for Sale | PetBond`;
        tagDescVal = `${formattedSearchVal} Dogs and Puppies for sale. Choose from PetBond approved dog breeders or registered rescue centres. Search by living space.`;
        h1Val = `${formattedSearchVal} Living Space Dogs and Puppies for Sale`;
      } else if (searchVal?.includes("-breed")) {
        tagTitleVal = `Find ${formattedSearchVal} Dogs and Puppies for Sale | Approved Breeders | for Sale | PetBond`;
        tagDescVal = `${formattedSearchVal} breed Dogs and Puppies for sale. Choose from PetBond approved dog breeders or registered rescue centres. Search by breed size.`;
        h1Val = `${formattedSearchVal} Dogs and Puppies for Sale`;
      } else if (searchVal?.includes("not-suitable-first-time-pet-parents")) {
        tagTitleVal = `Not Suitable First Time Pets Dogs and Puppies for Sale | Approved Breeders | for Sale | PetBond`;
        tagDescVal = `Not Suitable First Time Dogs and Puppies for sale. Choose from PetBond approved dog breeders or registered rescue centres.`;
      } else if (searchVal?.includes("suitable-first-time-pet-parents")) {
        tagTitleVal = `Suitable First Time Pets Dogs and Puppies for Sale | Approved Breeders | for Sale | PetBond`;
        tagDescVal = `Suitable First Time Dogs and Puppies for sale. Choose from PetBond approved dog breeders or registered rescue centres.`;
      } else if (searchVal === "adoption") {
        tagTitleVal = `Adoption Dogs and Puppies for Sale | Approved Breeders | for Sale | PetBond`;
        tagDescVal = `Adoption Dogs and Puppies for sale. Choose from PetBond approved dog breeders or registered rescue centres. Search by pet source`;
        h1Val = `Adoption: Adopt a Dog or Puppy from a Charity or Rescue Centre`;
      } else if (searchVal === "ikcbreeder") {
        tagTitleVal = `Kennel Club Breeder Dogs and Puppies for Sale | Approved Breeders | for Sale | PetBond`;
        tagDescVal = `Kennel Club Breeder Dogs and Puppies for sale. Choose from PetBond approved dog breeders or registered rescue centres. Search by pet source`;
        h1Val = `Kennel Club Seller: Dogs and Puppies for sale from Kennel Club registered sellers`;
      } else if (searchVal === "hobbybreeder") {
        tagTitleVal = `Hobby Breeder Dogs and Puppies for Sale | Approved Breeders | for Sale | PetBond`;
        tagDescVal = `Hobby Breeder Dogs and Puppies for sale. Choose from PetBond approved dog breeders or registered rescue centres. Search by pet source.`;
        h1Val = `Hobby Seller: Dogs and Puppies for sale from PetBond approved sellers`;
      }
    }

    pageH1 = h1Val;

    return (
      <div>
        <Helmet>
          <title>{tagTitleVal}</title>
          <meta name="description" content={tagDescVal} />
        </Helmet>
      </div>
    );
  };

  return (
    <div className={`dshSdBrWrp ${showSideBar ? "showSidebar" : ""}`}>
      {dynamicTags()}
      {/* HEADER */}
      <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
        <div className="container">
          <div className="arextraWrap">
            <h2 className="h1">
              from good homes… <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes
            </h2>
          </div>
          <div className="arbhImgHolder d-none d-md-block position-absolute">
            <img src="/images/img07.png" className="img-fluid" alt="petbond" />
          </div>
        </div>
        <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
          <i className="aricomoon-hearts">
            <span className="sr-only">icon</span>
          </i>
        </span>
        <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
          <i className="aricomoon-starDots">
            <span className="sr-only">icon</span>
          </i>
        </span>
      </header>
      {/* MAIN */}
      <section className="um-sertForFrind um-petListSec">
        <div className="container aaContainerLarge">
          <button
            type="button"
            className="bspOpener d-lg-none text-left border-0 shadow-sm"
            onClick={() => {
              setShowSideBar(!showSideBar);
            }}
          >
            MORE SEARCH FILTERS
          </button>
          <aside className="dbSidebar shadow-sm">
            <header className="hedadingHeadAside d-flex align-items-center justify-content-between">
              <h6 className="h6 dbsHeader text-white fontNoto">
                Search for your
                <br /> forever friend
                <button
                  type="button"
                  className="bspClose d-lg-none p-0 border-0 bg-transparent"
                  onClick={() => {
                    setShowSideBar(!showSideBar);
                  }}
                >
                  <i className="icn fas fa-times" />
                </button>
              </h6>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36.936"
                  height="36.937"
                  viewBox="0 0 36.936 36.937"
                >
                  <g
                    id="Group_1246"
                    data-name="Group 1246"
                    transform="translate(-1725.191 -435.884)"
                  >
                    <g id="Group_1245" data-name="Group 1245">
                      <path
                        id="Path_82989"
                        data-name="Path 82989"
                        d="M1761.631,467.892l-7.155-7.157.078-.078a1.714,1.714,0,0,0,0-2.411l-1.05-1.049a14.965,14.965,0,1,0-7,7l1.05,1.05a1.714,1.714,0,0,0,2.411,0l.078-.077,7.155,7.155a1.714,1.714,0,0,0,2.411,0l2.022-2.022a1.713,1.713,0,0,0,0-2.411Zm-32.474-17.113a10.948,10.948,0,1,1,10.929,10.967A10.98,10.98,0,0,1,1729.157,450.779Z"
                        fill="#fff"
                      />
                      <path
                        id="Path_82990"
                        data-name="Path 82990"
                        d="M1740.086,443.35a1.751,1.751,0,0,0,0,3.5,3.983,3.983,0,0,1,3.967,3.966,1.75,1.75,0,0,0,3.5,0A7.469,7.469,0,0,0,1740.086,443.35Z"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            </header>
            <form className="um-srcFrieForm">
              <div className="form-group formInputPlaceHolder">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search by post code i.e. E1W 1BQ"
                    value={postalCode}
                    onChange={searchByPostalCode}
                />
                {(postalCode && !isValidPostalCode) && <span className="text-danger">Please enter valid postal code</span>}
              </div>
              {(postalCode && isValidPostalCode) && <div className="form-group um-bddFormSlct um-slctEtcDesg">
                <select
                    className="form-control"
                    value={distance}
                    onChange={(e) => setDistance(e.target.value)}
                >
                  <option value="10">Distance (Default: 10)</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>}
              <div className="form-group um-bddFormSlct um-slctEtcDesg">
                <select
                  className="form-control"
                  value={searchedBreed}
                  onChange={(e) => {
                    setSearchedBreed(e.target.value);
                    setShowSideBar(false);
                  }}
                  {...(searchedSize !== "" ? { disabled: "disabled" } : {})}
                  {...(searchedLivingSpace !== ""
                    ? { disabled: "disabled" }
                    : {})}
                >
                  <option value="">Breed&hellip;</option>
                  {fetchedBreeds.map((breed) => (
                    <option key={breed.id} value={breed.slug}>
                      {breed.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group um-bddFormSlct um-slctEtcDesg">
                <select
                  className="form-control"
                  value={searchedSize}
                  onChange={(e) => {
                    setSearchedSize(e.target.value);
                    setShowSideBar(false);
                  }}
                  {...(searchedBreed !== "" ? { disabled: "disabled" } : {})}
                  {...(searchedLivingSpace !== ""
                    ? { disabled: "disabled" }
                    : {})}
                >
                  <option value="">Size&hellip;</option>
                  <option value="small-breed">Small breed</option>
                  <option value="small-medium-breed">
                    Small to medium breed
                  </option>
                  <option value="medium-breed">Medium breed</option>
                  <option value="medium-large-breed">
                    Medium to large breed
                  </option>
                  <option value="large-giant-breed">Large/Giant breed</option>
                </select>
              </div>
              <div className="form-group um-bddFormSlct um-slctEtcDesg">
                <select
                  className="form-control"
                  value={searchedLocation}
                  onChange={(e) => {
                    setSearchedLocation(e.target.value);
                    setShowSideBar(false);
                  }}
                >
                  <option value="">Location&hellip;</option>
                  {Object.keys(fetchedGroupedLocations).map(country => (
                      <optgroup label={country.toUpperCase()} key={country}>
                        {fetchedGroupedLocations[country].map(city => (
                            <option key={city.id} value={city.slug} data-url={city.city_url}>
                              {" "}
                              {city.name}{" "}
                            </option>
                        ))}
                      </optgroup>
                  ))}
                </select>
              </div>
              <div className="form-group um-bddFormSlct um-slctEtcDesg">
                <select
                  className="form-control"
                  value={searchedLivingSpace}
                  onChange={(e) => {
                    setSearchedLivingSpace(e.target.value);
                    setShowSideBar(false);
                  }}
                  {...(searchedBreed !== "" ? { disabled: "disabled" } : {})}
                  {...(searchedSize !== "" ? { disabled: "disabled" } : {})}
                >
                  <option value="">Living Space&hellip;</option>
                  <option value="small-living-space">
                    Small space (apartment living)
                  </option>
                  <option value="small-medium-living-space">
                    Small to medium (town house no garden)
                  </option>
                  <option value="medium-living-space">
                    Medium (town house with medium sized garden)
                  </option>
                  <option value="medium-large-living-space">
                    Medium to large (large house with big garden)
                  </option>
                  <option value="large-living-space">
                    Large (suitable for country houses with wide open spaces)
                  </option>
                </select>
              </div>
              <div className="form-group um-bddFormSlct um-slctEtcDesg">
                <select
                  className="form-control"
                  value={searchedPetSource}
                  onChange={(e) => {
                    setSearchedPetSource(e.target.value);
                    setShowSideBar(false);
                  }}
                >
                  <option value="">Pet Source&hellip;</option>
                  <option value="hobbybreeder">Hobby Breeder</option>
                  <option value="ikcbreeder">Kennel Club Breeder</option>
                  <option value="adoption">Adoption</option>
                </select>
              </div>
              <div className="um-parentStstus">
                <strong>First time parents?</strong>
                <div className="partQues d-flex align-items-center">
                  <div className="um-bddInputWrap um-checkExtStyle">
                    <span className="um-cusCheckBox">
                      <input
                        className="form-control"
                        type="radio"
                        id="check4569"
                        name="firstTimeCheck"
                        value="suitable-first-time-pet-parents"
                        checked={
                          firstTimeCheck === "suitable-first-time-pet-parents"
                        }
                        onChange={(event) => {
                          setFirstTimeCheck(event.target.value);
                          setShowSideBar(false);
                        }}
                      />
                      <label className="cusCheckLabel" htmlFor="check4569">
                        Yes
                      </label>
                    </span>
                  </div>
                  <div className="um-bddInputWrap um-checkExtStyle">
                    <span className="um-cusCheckBox">
                      <input
                        className="form-control"
                        type="radio"
                        id="check4563"
                        name="firstTimeCheck"
                        value="not-suitable-first-time-pet-parents"
                        checked={
                          firstTimeCheck ===
                          "not-suitable-first-time-pet-parents"
                        }
                        onChange={(event) => {
                          setFirstTimeCheck(event.target.value);
                          setShowSideBar(false);
                        }}
                      />
                      <label className="cusCheckLabel" htmlFor="check4563">
                        No
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="morOptList">
                                <a href="#" className="d-block">More Option</a>
                            </div> */}
              {/* <div className="form-group um-bddInputWrap srcFriInput">
                                <input type="text" className="form-control" placeholder="Search"/>
                                <button type="button" className="arbddInpSubmitBtn"><span
                                    className="sr-only">search</span></button>
                            </div> */}
              <div>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={resetSearch}
                >
                  Reset search
                </button>
              </div>
            </form>
            <div className="seo-only">
              <Link to={`/pet-listing/small-breed`}>Small breed</Link>
              <Link to={`/pet-listing/small-medium-breed`}>
                Small to medium breed
              </Link>
              <Link to={`/pet-listing/medium-breed`}>Medium breed</Link>
              <Link to={`/pet-listing/medium-large-breed`}>
                Medium to large breed
              </Link>
              <Link to={`/pet-listing/large-giant-breed`}>
                Large/Giant breed
              </Link>
              <Link to={`/pet-listing/small-living-space`}>Small space</Link>
              <Link to={`/pet-listing/small-medium-living-space`}>
                Small to medium (town house no garden)
              </Link>
              <Link to={`/pet-listing/medium-living-space`}>
                Medium (town house with medium sized garden)
              </Link>
              <Link to={`/pet-listing/medium-large-living-space`}>
                Medium to large (large house with big garden)
              </Link>
              <Link to={`/pet-listing/large-living-space`}>
                Large (suitable for country houses with wide open spaces)
              </Link>
              {fetchedBreeds.map((breed) => (
                <Link key={breed.id} to={`/pet-listing/${breed.slug}`}>
                  {breed.name}
                </Link>
              ))}
              {fetchedLocations.map((loc) => (
                <Link key={loc.id} to={`/pet-listing/${loc.slug}`}>
                  {loc.name}
                </Link>
              ))}
            </div>
          </aside>
          <div
            // className="um-offsetWrapper"
            className="offsetWrapper"
          >
            <div className="w-50">
              <select
                className="form-control"
                value={searchedPetSource}
                onChange={(e) => {
                  setSearchedPetSource(e.target.value);
                  setShowSideBar(false);
                }}
              >
                <option value="/">Choose your pet Source&hellip;</option>
                <option value="hobbybreeder">
                  PetBond Approved Hobby Seller
                </option>
                <option value="ikcbreeder">Kennel Club Registered Sellers</option>
                <option value="adoption">
                  Adoption from Charity or Rescue Center
                </option>
              </select>
            </div>
            <h1 className="h2 mx-2 my-5">{pageH1}</h1>
            <div className="row">
              {adverts?.map((ad) => {
                return (
                  <React.Fragment key={ad.id}>
                    <div className="mt-5 col-12 col-sm-6 col-md-4">
                      <article className="um-petInfoIndiWrap rounded shadow">
                        <div className="um-petInfoIndi">
                          <div className="petPrice rounded shadow">
                            <strong className="d-block font-weight-normal">
                              {CURRENCY_SYMBOL}
                              {Number.isSafeInteger(ad.price)
                                ? ad.price
                                : ad?.price?.toFixed(2)}
                            </strong>
                          </div>
                          <div className="imgHolderPet rounded">
                            <img
                              src={
                                ad.cover_photo
                                  ? `${API_ENDPOINT_URL}/image-retrieve/${ad.cover_photo}`
                                  : "/images/image-placeholder.png"
                              }
                              className="img-fluid rounded w-100 h-100 position-absolute"
                              alt="img"
                            />
                          </div>
                          <div className="petInfoWrap">
                            <h3>{ad.advert_name}</h3>
                            <ul className="petInfoList list-unstyled">
                              <li className="d-flex align-items-center justify-content-between">
                                <strong>Size</strong>
                                <ul className="gradList list-unstyled d-flex">
                                  {getStars(ad.size).map((star, i) => (
                                    <li
                                      className={star ? "active" : ""}
                                      key={i}
                                    />
                                  ))}
                                </ul>
                              </li>
                              <li className="d-flex align-items-center justify-content-between">
                                <strong>Energy</strong>
                                <ul className="gradList list-unstyled d-flex">
                                  {getStars(ad.energy).map((star, i) => (
                                    <li
                                      className={star ? "active" : ""}
                                      key={i}
                                    />
                                  ))}
                                </ul>
                              </li>
                              <li className="d-flex align-items-center justify-content-between">
                                <strong>Lifespan</strong>
                                <ul className="gradList list-unstyled d-flex">
                                  {getStars(ad.life_span).map((star, i) => (
                                    <li
                                      className={star ? "active" : ""}
                                      key={i}
                                    />
                                  ))}
                                </ul>
                              </li>
                              <li className="d-flex align-items-center justify-content-between">
                                <strong>Grooming</strong>
                                <ul className="gradList list-unstyled d-flex align-items-center">
                                  {getStars(ad.grooming).map((star, i) => (
                                    <li
                                      className={star ? "active" : ""}
                                      key={i}
                                    />
                                  ))}
                                </ul>
                              </li>
                            </ul>
                            <div className="um-btnformWrap">
                              <Link
                                to={ad?.advert_url}
                                className="btn btnScnWidth btn-info btnScnStyl text-uppercase"
                              >
                                View Pet
                              </Link>
                              <Link
                                to="#"
                                className="btn btnScnWidth btn-warning btnScnStyl text-uppercase"
                              >
                                Favourite
                              </Link>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </React.Fragment>
                );
              })}
              {adverts?.length <= 0 && (
                <p className="um-nhcBannerWrap">No Data Found</p>
              )}
            </div>

            <ul className="um-paginationList list-unstyled d-flex justify-content-center align-items-center pb-3">
              <li>
                {paginationData?.prev_page_url && (
                  <button
                    type="button"
                    className="btn btnScnWidth text-uppercase btn-info btnScnStyl"
                    onClick={goToPrevPage}
                  >
                    Previous
                  </button>
                )}
              </li>
              <li>
                Page {paginationData?.current_page} of{" "}
                {paginationData?.last_page}
              </li>
              <li>
                {paginationData?.next_page_url && (
                  <button
                    type="button"
                    className="btn btnScnWidth text-uppercase btn-info btnScnStyl"
                    onClick={goToNextPage}
                  >
                    Next
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="haveNotFoundSection">
        <div className="container-fluid">
          <div className="row h-100">
            <div className="col-12 col-lg-6 py-5">
              <div className="row h-100">
                <div className="col-12 mx-auto d-flex align-items-left">
                  <img
                    className="img-fluid rounded shadow-sm"
                    src="https://images.pexels.com/photos/6234610/pexels-photo-6234610.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 py-5 py-lg-0 d-flex flex-column align-items-left justify-content-center">
              <h2 className="mb-4 fs-1 text-white">
                Haven't found your forever friend?
              </h2>
              <p className="mb-4 text-white">Sign up and set an alert now !</p>
              <div className="d-flex flex-wrap flex-strecth justify-content-left">
                <Link
                  to={`${AUTH_PREFIX}/buyer-signup`}
                  className="btn-warning shadow-sm btn text-uppercase px-4"
                >
                  Sign Up for Alerts
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Wrapper apiKey={GOOGLE_KEY}>
        <Map
            center={{
              lat: 52.3555,
              lng: 1.1743
            }}
            zoom={16}
            style={{flexGrow: '1', height: '0%'}}
            options={'s'}
        >
        </Map>
      </Wrapper>

      <div
          // className="um-offsetWrapper"
          className="mt-10 container aaContainerLarge"
      >            {managedContent && (<div className="mt-2 mx-2 mb-5 wordpress-content" dangerouslySetInnerHTML={{__html:managedContent}} />)}
      </div>
    </div>
  );
};

export default PetListing;
