import React from 'react';
import {Navigate} from 'react-router-dom';
import CreateBuyerPetAlerts from "./CreateBuyerPetAlert";

const CreatePetAlerts = () => {
    const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

    if (userRole === 'buyer') {
        return <CreateBuyerPetAlerts />;
    }

    return <Navigate to='/' />;
};

export default CreatePetAlerts;
