import { Helmet } from 'react-helmet-async';
import React from 'react';

const TermsConditions = () => {

    return (
        <>
            <Helmet>
                <title>Website Terms And Conditions | Petbond</title>
                <meta name="description"
                      content='Last updated:  October 2020 Introduction The following terms and conditions (the "
                      Terms") apply to your use of https://myPetBond.com/ (the " Website") and any'/>
            </Helmet>
            <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
                <div className="container">
                    <div className="arextraWrap">
                        <h2 className="h1">from good homes… <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes</h2>
                    </div>
                    <div className="arbhImgHolder d-none d-md-block position-absolute">
                        <img src="/images/img07.png" className="img-fluid" alt="petbond"/>
                    </div>
                </div>
                <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
							<i className="aricomoon-hearts"><span className="sr-only">icon</span></i>
						</span>
                <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
							<i className="aricomoon-starDots"><span className="sr-only">icon</span></i>
						</span>
            </header>
            <section className="contentSection pt-5 pb-15 pb-lg-20">
                <div className="container">
                    <article className="contentWrap rounded shadow bg-white">
                        <h1 className="h2">Website Terms And Conditions</h1>
                        <p><strong>Last Updated: &nbsp;[October 2020]</strong></p>
                        <h6 className="fontNoto h6 pt-5">Introduction:</h6>
                        <ul className="contentList list-unstyled">
                            <li>The following terms and conditions (the “<strong>Terms</strong>“) apply to your use
                                of <a href="https://mypetbond.com/">https://mypetbond.com/</a> (the “<strong>Website</strong>“)
                                and any related services provided by PetBond in connection with the Website (the
                                “<strong>Services</strong>“). For the purpose of this document, “use” includes visiting
                                the Website, browsing any information or material which has been made available on the
                                Website (“<strong>Content</strong>“), uploading Content, or registering to use any
                                Services.
                            </li>
                            <li>The website is operated by PetBond Limited (“<strong>we</strong>” /
                                “<strong>our</strong>” / “<strong>PetBond</strong>“) and these Terms set out the rights
                                and obligations of all users (“<strong>you</strong>” / “<strong>your</strong>” /
                                “<strong>User</strong>“) in relation to your use of the Website.
                            </li>
                            <li>Please review these Terms carefully. By entering our site or creating an account you are
                                accepting these Terms, you agree to the following Terms and our Privacy Policy <a
                                    href="https://mypetbond.com/privacy-policy/">https://mypetbond.com/privacy-policy/</a>. If
                                you do not agree to these Terms or the Privacy Policy, please refrain from using the
                                Website.
                            </li>
                        </ul>
                        <h6 className="fontNoto h6 pt-5">Acceptable Use:</h6>
                        <ul className="contentList list-unstyled">
                            <li><strong>Responsibility for information uploaded: </strong>You accept sole responsibility
                                for your conduct and any information that you upload, share, exchange or display on the
                                Website, including the accuracy of the information uploaded.
                            </li>
                            <li>
                                <strong>Requirements for placing an advertisement: </strong>You may only offer a pet for
                                adoption/sale/foster on the Website provided that the pet is at least eight weeks old,
                                socialised, and fully vaccinated by a registered veterinary practice. If you are
                                offering a dog for adoption/sale/foster, the dog must be microchipped in accordance with
                                the relevant legislation. You must include full and accurate information for any pet
                                which you are seeking to offer for adoption/sale/foster on the Website. This includes
                                but is not limited to age, sex, breed, vaccinations, microchipping status, known
                                behavioural issues, location and country of birth. By offering a pet for adoption/foster
                                as a member of the general public or rescue centre, you agree that you will not seek to
                                make a profit by charging fees for the adoption and that you will not use the Website in
                                furtherance of a business interest. If you decide to post an advert for a pet on the
                                Website, you agree to comply with the following requirements:
                                <ul className="contChildList list-unstyled m-0 pt-5">
                                    <li>You may only advertise the following animals for adoption or rehoming on the
                                        website: cats, dogs, horses and donkeys.
                                    </li>
                                    <li>The pet must be more than eight weeks old.</li>
                                    <li>You are the owner or person responsible for breeding the animal.</li>
                                    <li>That you have provided all of the relevant details of the pet and categorised it
                                        correctly. Any health or welfare issues must be clearly stated and sufficiently
                                        details.
                                    </li>
                                    <li>All photos provided must be of the actual pet that you are offering for
                                        adoption, and must not be taken from other sources.
                                    </li>
                                    <li>ou will not advertise a pet as being suitable as a gift or present, or advertise
                                        a “designer” pet (or other similar expression).
                                    </li>
                                    <li>You will not offer a pet for the purpose of breeding, or offer a pet which is
                                        pregnant.
                                    </li>
                                    <li>You will not offer a pet which is intended to be used as food.</li>
                                    <li>You will place all advertisements for pets under your own name, and will not
                                        create multiple separate accounts using different details for the purpose of
                                        posting advertisements.
                                    </li>
                                    <li>Please note that if you are a member of the general public, advertisements for
                                        the following restricted dog breeds will not be permitted: American Pit Bull
                                        Terrier, Bull Mastiff, Doberman Pinscher, English Bull Terrier, German Shepherd
                                        (Alsatian), Japanese Akita, Japanese Tosa, Rhodesian Ridgeback, Rottweiler,
                                        Staffordshire Bull Terrier, and other dogs commonly known as a “Ban Dog” or
                                        “Bandog”.
                                    </li>
                                    <li>If offering a dog for adoption, the dog must be intended as a family pet. Dogs
                                        which have been trained for security, protection, hunting, guarding or any
                                        purpose other than as a family pet will not be permitted.
                                    </li>
                                    <li>You may not advertise a dog which has had its tail docked or dew claws removed
                                        unlawfully.
                                    </li>
                                    <li>If offering a horse or donkey for adoption, you must be able to provide the new
                                        owner with the horse’s original equine identification documents.
                                    </li>
                                </ul>
                            </li>
                            <li><strong>Adopting a pet: </strong>If you agree to adopt a pet from the Website, you must
                                comply with all of the relevant legislation for the care and protection of animals
                                including but not limited to the Protection of Animals Acts 1911 and 1965, Control of
                                Dogs Act 1986, and the Control of Dogs (Amendment) Act 1992.
                            </li>
                            <li><strong>Registered breeders</strong>: If applying to be recognised as a PetBond approved
                                breeder you must confirm whether you are IKC registered or not, whether you hold a
                                council licence for the purposes of breeding, and are able to meet all of our animal
                                health and welfare standards &amp; requirements. If these details cannot be provided, we
                                reserve the right to decline your application. You also agree to Petbond conducting
                                unannounced visits to your premises at PetBond’s discretion. Additionally, PetBond
                                reserves the right to request other veterinary colleagues to conduct such visits as
                                deemed necessary.
                            </li>
                            <li><strong>Charity or rescue centre accounts</strong>: If applying to be recognised as a
                                charity or rescue centre, you warrant and agree that you are a bona fide registered
                                charity or that your organisation is run completely on a “not for profit” basis and that
                                you comply with any obligations, including any applicable registrations, imposed on you
                                by law.
                            </li>
                            <li><strong>Responsibility for your Account: </strong>You are responsible for any activity
                                or communications that occur through the use of an Account created by you or a legal
                                entity on the Website (an “<strong>Account</strong>“). If you create an account with us,
                                you must treat any information provided as part of our security and authentication
                                procedures (such as a password) as confidential. You must not disclose such information
                                to any person or legal entity that is not otherwise a party to these terms. The use of
                                the Website by a minor under the age of 18 is subject to the consent of their parent or
                                guardian. Our contract in such cases is with the parent or guardian of the minor. Any
                                minors using the service should be made fully aware of the potential risks to their
                                safety online when using an interactive service.
                            </li>
                            <li><strong>Prohibited uses:</strong> You must not use the Website or Services for any
                                illegal or unauthorised purpose. Users residing outside of Ireland agree to comply with
                                all local laws regarding acceptable online conduct. You must not abuse, harass,
                                threaten, impersonate or intimidate other users of the Website. This includes sending
                                unwanted messages or comments to other users of the Website. You must not engage in any
                                attempt to modify, adapt, copy, or hack the Website. This includes falsely implying that
                                another service or Third Party is affiliated with the Website. You must not crawl,
                                scrape, or otherwise cache any content from the Website, including (but not limited to)
                                other user profiles, personal details, photos, graphics, scripts or other content. You
                                must not intentionally upload or transmit any viruses, malware, trojans, worms, spyware,
                                adware, or any other malicious or destructive software to the Website.
                            </li>
                            <li><strong>WARRANTY:</strong> YOU WARRANT THAT ANY CONTRIBUTION TO THE WEBSITE COMPLIES
                                WITH THE ACCEPTABLE USE STANDARDS SET OUT IN THIS SECTION 2 (<em>ACCEPTABLE USE</em>) OF
                                THE TERMS, AND YOU WILL BE LIABLE TO US AND INDEMNIFY US FOR ANY BREACH OF THAT
                                WARRANTY. THIS MEANS YOU WILL BE RESPONSIBLE FOR ANY LOSS OR DAMAGE WE SUFFER AS A
                                RESULT OF YOUR BREACH OF WARRANTY.
                            </li>
                        </ul>
                        <h6 className="fontNoto h6 pt-5">User Accounts and Content:</h6>
                        <ul className="contentList list-unstyled">
                            <li>PetBond reserves the right to remove any Account or User Content that we determine to be
                                illegal, offensive, threatening, libellous, defamatory, obscene or otherwise
                                objectionable. We reserve the right to take similar action if we determine that the
                                Account or User Content infringes the rights and freedoms of others, or violates the
                                terms set out in these Terms. Such actions will be undertaken at our sole discretion,
                                but we assume no obligation to do so in every circumstance.
                            </li>
                            <li>By submitting Content which is subsequently made available on the Website to the general
                                public, you assume all risks associated with the Content. This includes any reliance
                                placed on the quality, accuracy, and completeness of the information provided by other
                                visitors to the Website.
                            </li>
                            <li>Purposefully submitting Content which is known to be incorrect or misleading may attract
                                personal liability. You also assume the risk associated with submitting illegal or
                                immoral Content which violates any law or regulation.
                            </li>
                            <li>You may not sell, copy, reproduce, distribute, modify or otherwise exploit intellectual
                                property owned by PetBond. We are the owner or the licensee of all intellectual property
                                rights in the Website (except where specifically identified as third party content), and
                                in the material published on it. The PetBond intellectual property includes, but is not
                                limited to, graphics, design, branding, computer code and interactive features.
                            </li>
                            <li>Except for the making of a hard copy print for your own personal use only or the use of
                                others in your organisation (or downloading the material for your own personal use or
                                the use of others in your organisation only provided that you retain all copyright and
                                proprietary notices), the materials on this Website may not be copied, reproduced,
                                transmitted, distributed or displayed, by any means, without the express written consent
                                of the Company.
                            </li>
                            <li>You must not use any part of the materials on the Website for commercial purposes
                                without obtaining a licence to do so from us or our licensors.
                            </li>
                        </ul>
                        <h6 className="fontNoto h6 pt-5">Privacy and Data Protection:</h6>
                        <ul className="contentList list-unstyled">
                            <li>Any personal data which you provide in connection with your use of the Website or
                                Services will be processed in accordance with our Privacy Policy, which is available via
                                the following link: <a
                                    href="https://mypetbond.com/privacy-policy/">https://mypetbond.com/privacy-policy/</a>.
                            </li>
                        </ul>
                        <h6 className="fontNoto h6 pt-5">Liability and Disclaimer:</h6>
                        <ul className="contentList list-unstyled">
                            <li>TO THE EXTENT PERMITTED BY LAW, PETBOND EXCLUDES ALL EXPRESS OR IMPLIED CONDITIONS,
                                WARRANTIES, REPRESENTATIONS, OR OTHER TERMS WHICH MAY APPLY TO THE CONTENT ON THE
                                WEBSITE OR THE SERVICES THAT WE PROVIDE.
                            </li>
                            <li>PETBOND WILL NOT BE HELD LIABLE TO ANY USER FOR LOSS OR DAMAGE ARISING FROM THE USE OF
                                OUR WEBSITE OR SERVICES, WHETHER ARISING IN CONTRACT, TORT, STATUTORY DUTY, OR
                                OTHERWISE.
                            </li>
                            <li>WE DO NOT GUARANTEE THE ACCURACY OR COMPLETENESS OF INFORMATION PROVIDED BY OTHER USERS
                                OF THE WEBSITE, AND DISCLAIM ALL LIABILITY FOR ANY DETRIMENT SUFFERED AS A RESULT OF
                                RELIANCE ON SUCH INFORMATION.
                            </li>
                            <li>PETBOND RESERVES THE RIGHT TO AMEND OR UPDATE INACCURATE INFORMATION ON THE WEBSITE AT
                                OUR OWN DISCRETION, BUT WE ASSUME NO OBLIGATION TO DO SO IN EVERY CIRCUMSTANCE.
                            </li>
                            <li>IN CASES WHERE LINKS TO THIRD PARTY SERVICES ARE PROVIDED ON OUR WEBSITE, PETBOND
                                ASSUMES NO RESPONSIBILITY FOR THESE SERVICES. SUCH LINKS ARE PROVIDED FOR YOUR
                                INFORMATION ONLY, AND YOUR USE OR RELIANCE ON THE THIRD PARTY INFORMATION IS AT YOUR OWN
                                RISK.
                            </li>
                            <li>ANY SERVICES PROVIDED BY A THIRD PARTY IN CONNECTION WITH OUR WEBSITE MAY BE SUBJECT TO
                                ADDITIONAL TERMS OF SERVICE. IT IS YOUR RESPONSIBILITY TO REVIEW AND FAMILIARISE
                                YOURSELF WITH THESE TERMS.
                            </li>
                            <li>WE ACCEPT NO RESPONSIBILITY FOR ANY FINANCIAL TRANSACTIONS THAT YOU CONDUCT WITH ANOTHER
                                USER, EITHER IN PERSON OR VIA AN ONLINE PAYMENT SERVICE. YOU CARRY OUT SUCH TRANSACTIONS
                                AT YOUR OWN RISK.
                            </li>
                        </ul>
                        <h6 className="fontNoto h6 pt-5">Website Availability:</h6>
                        <ul className="contentList list-unstyled">
                            <li>The Website is provided on an “as available” basis. Although we will make every
                                reasonable effort to ensure continued uninterrupted access, there may be occasions where
                                such access will be unavailable.
                            </li>
                        </ul>
                        <h6 className="fontNoto h6 pt-5">Variations to the Website and Terms of Use:</h6>
                        <ul className="contentList list-unstyled">
                            <li>PetBond reserves the right to modify the Website, Content, and Services at any time
                                without the requirement to provide notice.
                            </li>
                            <li>If we make changes to these Terms or to the Privacy Policy, we will notify you by
                                providing appropriate notice on our website. The date on which this agreement was last
                                updated will also be indicated at the top of this webpage. Once you have received
                                notification of such changes, your continued use of our Website and Services will
                                indicate your awareness and understanding of those changes.
                            </li>
                            <li>If you do not agree to be bound by any changes to these Terms or Privacy Policy, you
                                will not be able to continue to use our Website and Services.
                            </li>
                        </ul>
                        <h6 className="fontNoto h6 pt-5">Governing Law, Jurisdiction and Venue:</h6>
                        <ul className="contentList list-unstyled">
                            <li>The  courts will have exclusive jurisdiction over any claim arising from, or
                                related to, a visit to the Website although we retain the right to bring proceedings
                                against you for breach of these conditions in your country of residence or any other
                                relevant country.
                            </li>
                            <li>These Terms and any dispute or claim arising out of or in connection with them or their
                                subject matter or formation (including non-contractual disputes or claims) shall be
                                governed by and construed in accordance with the law of Ireland.
                            </li>
                        </ul>
                        <h6 className="fontNoto h6 pt-5">Termination:</h6>
                        <ul className="contentList list-unstyled">
                            <li>PetBond reserves the right to restrict, remove, or prevent access to our Website and
                                Services if you fail to comply with these Terms. Such actions are at our sole
                                discretion.
                            </li>
                            <li>We also the reserve the right to reclaim an Account on behalf of a business or
                                individual which is legally entitled to it, in cases where an Account has been created
                                fraudulently.
                            </li>
                            <li>If any of these Terms are determined by any competent authority to be invalid, unlawful
                                or unenforceable to any extent, such term, condition or provision will to the extent be
                                severed from the remaining terms, conditions and provisions which will continue to be
                                valid to the fullest extent permitted by law.
                            </li>
                        </ul>
                        <h6 className="fontNoto h6 pt-5">Complaints:</h6>
                        <ul className="contentList list-unstyled">
                            <li>Should you have any complaints regarding the Website, Services, or these Terms, please
                                direct these to the following email address: <a
                                    href="mailto:info@mypetbond.com">info@mypetbond.com</a>.
                            </li>
                            <li>If you have any concerns, queries, or complaints regarding your data privacy, please
                                refer to the relevant section in our Privacy Policy: <a
                                    href="https://mypetbond.com/privacy-policy/">https://mypetbond.com/privacy-policy/</a>.
                            </li>
                        </ul>


                    </article>
                </div>
            </section>
        </>
    );

};

export default TermsConditions;
