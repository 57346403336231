import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../../../utils/requests';
import { API_ENDPOINT_URL } from '../../../../utils/appConstants';
import Modal from 'react-bootstrap/Modal';
import { toFormData } from '../../../../utils';
import { toast } from 'react-toastify';

const CharityProfile = () => {

    const [editInfoModel, setEditInfoModelShow] = useState(false);
    const [uploadImagePreview, setUploadImagePreview] = useState('');
    const [bio, setBio] = useState('');
    const [popUpBio, setPopUpBio] = useState('');
    const [popUpUploadImage, setPopUpUploadImage] = useState(null);
    const [popUpUploadImagePreview, setPopUpUploadImagePreview] = useState('');
    const [summary, setSummary] = useState([]);
    const [breeds, setBreeds] = useState([]);
    const [chipNumber, setChipNumber] = useState('');
    const [breedId, setBreedId] = useState('');

    useEffect(() => {
        getRequest(`${API_ENDPOINT_URL}/charity/profile/view`, true).then((res) => {
            if (res) {
                if (res.data.data.avatar) {
                    setUploadImagePreview(`${API_ENDPOINT_URL}/image-retrieve/${res.data.data.avatar}`);
                }
                setBio(res.data.data.detail.bio);
            }
        });

        getRequest(`${API_ENDPOINT_URL}/basic-listing/breeds`, true).then((res) => {
            setBreeds(res.data.data);
        });

        getRequest(`${API_ENDPOINT_URL}/breeder/microchip/summary`, true).then((res) => {
            setSummary(res.data.data);
        });
    }, []);

    const showModal = () => {
        setPopUpBio(bio);
        setPopUpUploadImagePreview(uploadImagePreview);
        setEditInfoModelShow(true);
    };

    const hideModal = () => {
        setEditInfoModelShow(false);
        setPopUpUploadImage(null);
        setPopUpUploadImagePreview('');
        setPopUpBio('');
    };

    const updateBioFunction = () => {

        let requestBody = {
            avatar: popUpUploadImage,
            bio: popUpBio
        };
        requestBody = toFormData(requestBody);

        postRequest(`${API_ENDPOINT_URL}/charity/profile/info/update`, requestBody, true).then((res) => {
            if (res) {
                setEditInfoModelShow(false);
                window.location.reload();
            }
        });
    };

    const updateBreedingInfo = (e) => {
        e.preventDefault();
        const requestBody = {
            chip_number: chipNumber,
            breed_id: breedId
        };

        postRequest(`${API_ENDPOINT_URL}/breeder/microchip/store`, requestBody, true).then((res) => {
            if (res) {
                setSummary((prevState => [...prevState, requestBody]));
                toast.success('Breeding bitch info added successfully');
            }
        });
    };

    return (
        <>
            <div className="aaContentWidget">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Profile Information</h1>
                    <p>Please complete your bio information to attract the most attention for your litter.</p>
                </header>
                <div className="aaDbRow aaDbInfoRow">
                    <h6 className="h6 aaRwTitle fontNoto">
                        <span>Your bio</span>
                        <button
                            className="editButton border-0 bg-white "
                            onClick={showModal}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="103.023" height="32.597"
                                 viewBox="0 0 103.023 32.597">
                                <g id="Group_1347" data-name="Group 1347" transform="translate(-602.89 -780.565)">
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="103.022" height="32.597"
                                          rx="5.446" transform="translate(602.89 780.565)" fill="#ccc"/>
                                    <text id="Edit_bio" data-name="Edit bio" transform="translate(617.456 801.693)"
                                          fill="#fff" fontSize="14" fontFamily="NunitoSans-Regular, Nunito Sans">
                                        <tspan x="0" y="0">Edit bio</tspan>
                                    </text>
                                    <g id="Group_1346" data-name="Group 1346">
                                        <path id="Path_139041" data-name="Path 139041"
                                              d="M692.683,787.876a1.732,1.732,0,0,0-2.443,0L683.4,794.72a.819.819,0,0,0-.208.361l-.71,2.761a.789.789,0,0,0,.967.967l2.761-.71a.835.835,0,0,0,.36-.208l6.845-6.839a1.732,1.732,0,0,0,0-2.443Z"
                                              fill="#fff"/>
                                        <path id="Path_139042" data-name="Path 139042"
                                              d="M691.085,795.8a.794.794,0,0,0-.794.793v5.271a.322.322,0,0,1-.321.322H679.428a.322.322,0,0,1-.322-.322V791.322a.322.322,0,0,1,.322-.322H684.7a.793.793,0,0,0,0-1.587h-5.271a1.911,1.911,0,0,0-1.909,1.909v10.542a1.911,1.911,0,0,0,1.909,1.909H689.97a1.91,1.91,0,0,0,1.908-1.909v-5.271A.793.793,0,0,0,691.085,795.8Z"
                                              fill="#fff"/>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </h6>
                    <div className="bio-holder">
                        <div className="bio-pic">
                            <div className="pichold">
                                {uploadImagePreview ?
                                    <img
                                        src={uploadImagePreview}
                                        className="img-fluid"
                                        alt="uploaded-img"
                                    /> :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="76.817" height="76.817"
                                         viewBox="0 0 76.817 76.817">
                                        <g id="Group_1343" data-name="Group 1343"
                                           transform="translate(-462.746 -649.968)">
                                            <path id="Path_139040" data-name="Path 139040"
                                                  d="M501.155,649.968a38.408,38.408,0,1,1-38.409,38.409A38.336,38.336,0,0,1,501.155,649.968Zm0,18.586A14.276,14.276,0,0,1,515.4,682.8a14.009,14.009,0,0,1-8.672,13.009,23.8,23.8,0,0,1,17.347,17.551,33.866,33.866,0,1,0-45.845,0,23.791,23.791,0,0,1,17.347-17.551,14.186,14.186,0,0,1,5.576-27.256Zm19,47.909a.753.753,0,0,0-.207-.619,19.013,19.013,0,0,0-37.58,0c0,.207-.207.415-.207.619a34.1,34.1,0,0,0,37.994,0Zm-19-43.366a9.7,9.7,0,1,0,9.7,9.7A9.693,9.693,0,0,0,501.155,673.1Z"
                                                  fill="#fff"/>
                                        </g>
                                    </svg>
                                }
                            </div>
                        </div>
                        <div className="biotextblock">
                            <p>{bio}</p>
                        </div>
                    </div>
                </div>
                <div className="aaDbRow aaDbInfoRow bitches-attach">
                    <h6 className="h6 aaRwTitle fontNoto"><span>Breeding Bitches attached to account</span></h6>
                    <form onSubmit={updateBreedingInfo}>
                        <div className="row">
                            <div className="col-sm-5 col-md-4">
                                <div className="form-group">
                                    <input className="form-control" type="text" name="" placeholder="Chip number..."
                                           value={chipNumber} onChange={(e) => setChipNumber(e.target.value)}/>
                                </div>
                            </div>
                            <div className="col-sm-5 col-md-4 col-lg-3">
                                <div className="form-group">
                                    <div className="um-bddFormSlct um-slctEtcDesg">
                                        <select className="form-control"
                                                value={breedId} onChange={(e) => setBreedId(e.target.value)}>
                                            <option>Select a breed</option>
                                            {
                                                breeds.map((breed) => (
                                                    <option key={breed.id} value={breed.id}>{breed.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5 col-md-4">
                                <div className="um-formBtnWrap">
                                    <button type="submit" className="btn btnFormWidth btnFormStyle small">Add Breeding
                                        Bitch chip number
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <p>{summary.length} Breeding Bitch(es) added to account</p>
                    <ul>
                        {
                            summary.map((sum) => (
                                <li key={sum.id}
                                    className={sum.breeding_count === 3 ? 'text-danger' : ''}>{sum.chip_number} has
                                    had {sum.breeding_count} out of 3 litters</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <Modal
                show={editInfoModel}
                className="editInfoModal generalPopupStyles"
                centered
                onHide={hideModal}
            >
                <Modal.Body>
                    <div id="popupbio">
                        <form className="bio-form">
                            <div className="row">
                                <div className="col-xs-12 col-sm-6 col-md-4 text-center">
                                    <div className="bio-pic">
                                        <div className="pichold">
                                            {popUpUploadImagePreview ?
                                                <img
                                                    src={popUpUploadImagePreview}
                                                    className="img-fluid"
                                                    alt="uploaded-img"
                                                /> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="76.817" height="76.817"
                                                     viewBox="0 0 76.817 76.817">
                                                    <g id="Group_1343" data-name="Group 1343"
                                                       transform="translate(-462.746 -649.968)">
                                                        <path id="Path_139040" data-name="Path 139040"
                                                              d="M501.155,649.968a38.408,38.408,0,1,1-38.409,38.409A38.336,38.336,0,0,1,501.155,649.968Zm0,18.586A14.276,14.276,0,0,1,515.4,682.8a14.009,14.009,0,0,1-8.672,13.009,23.8,23.8,0,0,1,17.347,17.551,33.866,33.866,0,1,0-45.845,0,23.791,23.791,0,0,1,17.347-17.551,14.186,14.186,0,0,1,5.576-27.256Zm19,47.909a.753.753,0,0,0-.207-.619,19.013,19.013,0,0,0-37.58,0c0,.207-.207.415-.207.619a34.1,34.1,0,0,0,37.994,0Zm-19-43.366a9.7,9.7,0,1,0,9.7,9.7A9.693,9.693,0,0,0,501.155,673.1Z"
                                                              fill="#fff"/>
                                                    </g>
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <span className="customFileBtnWrap w-100 text-center">
                                        <input id="proofOAddress" type="file" accept="image/jpeg" onChange={(e) => {
                                            setPopUpUploadImage(e.target.files[0]);
                                            setPopUpUploadImagePreview(URL.createObjectURL(e.target.files[0]));
                                        }}/>
                                        <label htmlFor="proofOAddress" className="edit_photo">
											Edit photo
                                        </label>
                                    </span>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-8 textarea">
									<textarea
                                        id="bio"
                                        value={popUpBio}
                                        onChange={(e) => {
                                            setPopUpBio(e.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Type your info&hellip;"
                                    >
									</textarea>
                                </div>
                            </div>
                            <div className="row no-margin">
                                <div className="col-xs-12 col-sm-12">
                                    <button
                                        type="button"
                                        onClick={updateBioFunction}
                                        className="btn-warning btn btnFormWidth btnFormStyle small"
                                    >
                                        <span>Save</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.462" height="15.829"
                                             viewBox="0 0 26.462 15.829">
                                            <g id="Group_1294" data-name="Group 1294"
                                               transform="translate(-787.452 -2816.781)">
                                                <g id="Group_1293" data-name="Group 1293">
                                                    <path id="Path_134323" data-name="Path 134323"
                                                          d="M793.053,2821.408a4.007,4.007,0,0,1,1.38.163,6.454,6.454,0,0,1,6.25-4.79,6.625,6.625,0,0,1,6.332,4.79,3.344,3.344,0,0,1,1.3-.163,5.6,5.6,0,0,1,0,11.2h-15.26a5.6,5.6,0,0,1,0-11.2Zm15.26,9.822a4.192,4.192,0,0,0,4.221-4.221,4.261,4.261,0,0,0-4.221-4.3,4.1,4.1,0,0,0-.974.162,1.452,1.452,0,0,1-1.7-.974,5.069,5.069,0,0,0-4.952-3.734,5.214,5.214,0,0,0-4.952,3.734,1.326,1.326,0,0,1-1.623.974,4.777,4.777,0,0,0-1.055-.162,4.261,4.261,0,0,0-4.221,4.3,4.192,4.192,0,0,0,4.221,4.221Z"
                                                          fill="#fff" fillRule="evenodd"></path>
                                                    <path id="Path_134324" data-name="Path 134324"
                                                          d="M797.355,2826.6a.661.661,0,0,1,.893-.975l1.461,1.462,3.409-3.41a.69.69,0,0,1,.974.974l-3.9,3.9a.74.74,0,0,1-.974,0Z"
                                                          fill="#fff"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );

};

export default CharityProfile;
