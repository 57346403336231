import React, { useEffect, useState } from "react";
import {getRequest, postRequest} from "../../../../utils/requests";
import {API_ENDPOINT_URL} from "../../../../utils/appConstants";
import {useNavigate, useSearchParams} from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const AdvertSoldPets = () => {
  const userRole = JSON.parse(localStorage.getItem("currentUser"))?.role.replace('_', '-')
  const [puppies, setPuppies] = useState([]);
  const [search, setSearch] = useSearchParams();
  const [errors, setErrors] = useState([]);
  const [microchipModal, setMicrochipModal] = useState({
    chip_number: "",
    pet_id: "",
    pupIndex: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalErrors, setModalErrors] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const id = search.get("id");
    const userId = search.get("user_id");
    let url = `${API_ENDPOINT_URL}/${userRole}/advert/sold-pets/${id}/list`;
    if (userId) {
      url += `?user_id=${userId}`
    }
    if (id) {
      getRequest(url, true).then(
        (res) => {
          if (res) {
            setPuppies(res.data.data);
          }
        }
      );
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (e, i) => {
    const pups = [...puppies];
    const { name, value } = e.target;
    pups[i][name] = value;
    setPuppies(pups);
  };

  const validateChipNumber = () => {
    let errors = {};
    let isValid = true;

    if (!microchipModal.chip_number) {
      isValid = false;
      errors["chip_number"] = "This field is required";
    }
    if (microchipModal.chip_number) {
      if (microchipModal?.chip_number?.length !== 15) {
        isValid = false;
        errors["chip_number"] =
          "Please add a chip number containing 15 characters";
      }
    }
    setModalErrors(errors);
    return isValid;
  };

  const updateChipNumber = (e) => {
    e.preventDefault();
    if (validateChipNumber()) {
      const advertId = search.get("id");
      const body = { ...microchipModal };
      delete body.pupIndex;
      postRequest(
        `${API_ENDPOINT_URL}/${userRole}/advert/${advertId}/pet/update-micro-chip-number`,
        body,
        true
      ).then((res) => {
        if (res) {
          const updatedPuppies = [...puppies];
          const data = updatedPuppies[microchipModal.pupIndex];
          data.micro_chip.chip_number = body.chip_number;
          setPuppies(updatedPuppies);
          setShowModal(false);
        }
      });
    }
  };

  return (
    <div>
      <section className="um-brdRegForm">
        <div className="container">
          <div className="shadow-sm p-5 rounded-lg">
            <header className="aaPtbdHeader margin-zero">
              <h4 className="aaPtbHeading">Sold Pets</h4>
              <hr />
            </header>
            <div className="advertpet">
              <div className="um-bddFormWrap">
                <div className="row">
                  <div className="col-12 col-md-12">
                    {puppies.length === 0 && <p className="text-sm ">
                      Currently no pet(s) sold for this advert
                    </p>}
                    {puppies.map((pup, i) => {
                      return (
                        <div key={i}>
                          <div className="row">
                            <div className="col-md-12 col-lg-6">
                              <label>Puppy {i + 1}</label>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12 col-lg-4">
                              <div className="um-bddInputWrap">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Puppy Name"
                                  name="name"
                                  disabled={true}
                                  readOnly={true}
                                  value={pup.name}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                              <div className="um-bddInputWrap">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Gender"
                                  name="name"
                                  disabled={true}
                                  readOnly={true}
                                  value={pup.gender}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                              <div className="um-bddInputWrap position-relative icnInputWrap">
                                <input
                                  type="number"
                                  className="form-control w-100"
                                  placeholder="Microchip No"
                                  name="chip_number"
                                  value={pup.micro_chip.chip_number}
                                  disabled={true}
                                  onChange={(e) => handleChange(e, i)}
                                />
                                {pup.micro_chip.chip_number.includes("pet_bond_chip") && (
                                  <i
                                    className="far fa-edit icn"
                                    onClick={() => {
                                      setMicrochipModal({
                                        chip_number: "",
                                        pet_id: pup.id,
                                        pupIndex: i,
                                      });
                                      setShowModal(true);
                                    }}
                                  />
                                )}
                                {errors[i]?.chip_number && (
                                  <small className="support-error">
                                    {errors[i]?.chip_number}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                              <div className="um-bddInputWrap">
                                <input
                                  type="number"
                                  className="form-control w-100"
                                  placeholder="Price you want for puppy"
                                  name="price"
                                  value={pup.price}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                              <div className="um-bddInputWrap">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Puppy Colour"
                                  name="color"
                                  value={pup.color}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={showModal}
        className="editInfoModal addChipModal generalPopupStyles"
        centered
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <h4 className="h4 text-center mb-8">Update Chip Number</h4>
          <form onSubmit={updateChipNumber}>
            <div className="row">
              <div className="col-12 col-sm-12">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Enter new chip number here..."
                    value={microchipModal.chip_number}
                    onChange={(e) =>
                      setMicrochipModal({
                        ...microchipModal,
                        chip_number: e.target.value,
                      })
                    }
                  />
                  {modalErrors.chip_number && (
                    <small className="support-error">
                      {modalErrors.chip_number}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="um-formBtnWrap text-center">
                <button
                  type="submit"
                  className="btn btnFormWidth btnFormStyle small btn-warning mx-auto"
                >
                  Save chip number
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdvertSoldPets;
