import {Wrapper} from "@googlemaps/react-wrapper";
import {API_ENDPOINT_URL, GOOGLE_KEY} from "../../../utils/appConstants";
import {Map} from "./map.component";
import {Marker} from "./marker.component";
import {HalfCircleSpinner} from "react-epic-spinners";
import React, {useEffect, useState} from "react";
import {getRequest, postRequest} from "../../../utils/requests";
import {toast} from "react-toastify";
import {toFormData} from "../../../utils";

export const ProfileMapComponent = ({profileData, setProfileData}) => {
    const userRole = JSON.parse(localStorage.getItem("currentUser"))?.role;
    const [showLoader, setShowLoader] = useState(false);
    const [zoom, setZoom] = useState(16); // initial zoom
    const [mapPinAddress, setMapPinAddress] = useState('');
    const [mapPin, setMapPin] = useState({
        lat: 52.3555,
        lng: 1.1743
    });
    const [center, setCenter] = useState({
        lat: 52.3555,
        lng: 1.1743
    });

    useEffect(() => {
        if (profileData) {
            setMapPinAddress(profileData.address);
            setMapPin({
                lat: Number(profileData.lat),
                lng: Number(profileData?.long)
            });
        }
    }, []);

    //Send the Map Marker on form submission
    const mapMarkerInfo = (event) => {
        event.preventDefault();
        setProfileData();
        // pulling the latest record
        getRequest(`${API_ENDPOINT_URL}/${userRole}/profile/view`, true).then((res) => {
            if (res) {
                const data = res.data.data;
                postRequest(
                  `${API_ENDPOINT_URL}/${userRole}/profile/update`,
                  toFormData({
                      first_name: data.first_name,
                      last_name: data.last_name,
                      phone_number: data.phone_number,
                      postal_code: data.postal_code,
                      city_id: data.city_id,
                      address: mapPinAddress,
                      lat: mapPin.lat,
                      long: mapPin.lng,
                  }),
                  true
                ).then((res) => {
                    if (res) {
                        toast.success('Location updated successfully');
                    }
                });
            }
        });

    };

    const dragend = (m) => {
        setMapPin({
            lat: m.latLng.lat(),
            lng: m.latLng.lng()
        });
    };

    const drawPin = () => {
        if (mapPinAddress !== '') {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({'address': mapPinAddress}, function (results, status) {
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();
                setMapPin({
                    lat: latitude,
                    lng: longitude
                });
            });
        }
    };

    return (
        <div className="aaDbRow aaDbInfoRow mb-5">
            <h6 className="h6 aaRwTitle fontNoto">Map marker location</h6>
            <form className="um-bddFormWrap um-chrtFormInf" onSubmit={(event) => mapMarkerInfo(event)}>
                <div className="um-bddInputWrap">
                    <label>Search map for location</label>
                    <input
                        className="form-control"
                        placeholder="Type you address e.g. 5 Sutherland Avenue, London, UK"
                        value={mapPinAddress}
                        onChange={e => setMapPinAddress(e.target.value)}
                    />
                </div>
                <button type="button" className="btn btn-warning btnFormStyle btnFormWidth"
                        onClick={drawPin}>Draw Pin
                </button>
                <div className="um-bddInputWrap margin-bottom-20 mt-4" style={{height: '500px'}}>
                    <Wrapper apiKey={GOOGLE_KEY}>
                        <Map
                            center={center}
                            zoom={zoom}
                            style={{flexGrow: '1', height: '100%'}}
                            options={'s'}
                        >
                            <Marker position={mapPin} dragend={dragend} drag={true}/>
                        </Map>
                    </Wrapper>
                </div>
                <div className="um-formBtnWrap pt-4">
                    <button
                        type="submit"
                        className="btn btn-warning btnFormStyle btnFormWidth"
                        disabled={showLoader}
                    >
                        {showLoader && (
                            <HalfCircleSpinner
                                size={18}
                                style={{
                                    display: 'inline-block',
                                    marginRight: '5px'
                                }}
                            />
                        )}
                        Update Marker Location
                    </button>
                </div>
            </form>
        </div>
    );
}