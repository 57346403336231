import React, {useEffect, useState} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import {viewAdvertMessages} from "../../../../services/authorized/chat.service";

const ViewAdvertMessages = () => {
    const { advertId } = useParams();
    const userRole = JSON.parse(localStorage.getItem("currentUser"))?.role;
    const doNotLoad = userRole !== 'breeder' && userRole !== 'charity';
    const [advertName, setAdvertName] = useState('');
    const [adverts, setAdverts] = useState([]);

    useEffect(() => {
        if (!doNotLoad) {
            loadData();
        }
    }, []);

    useEffect(() => {
        // Make a copy of the original array
        const arrClone = [...adverts];

        // Find the index of the object to modify
        const objectIndex = arrClone.findIndex(obj => obj.chats[0].id === window.unreadMessagesObj?.chat_info?.chat_id);

        if (objectIndex !== -1) {
            // Create a copy of the object to modify
            const modifiedObject = { ...arrClone[objectIndex] };

            // Make the necessary modifications
            modifiedObject.chats[0].messages_count += window.unreadMessagesObj.chat_info.unread_count;
            modifiedObject.chats[0].unread_messages_count = window.unreadMessagesObj.chat_info.unread_count;

            // Replace the object in the new array
            arrClone[objectIndex] = modifiedObject;

            // Update the state with the new array
            setAdverts(prevState => arrClone);
        } else {
            // reload advert to get latest data in case new advert (advert message in case of buyer) is created
            loadData();
        }
    }, [window.unreadMessagesObj]);

    const loadData = () => {
        viewAdvertMessages(userRole, advertId).then((adverts) => {
            setAdvertName(adverts.advert_name);
            setAdverts(adverts.advert_buyers);
        });
    }

    if (doNotLoad) {
        return <Navigate to='/' />;
    }

    return (
        <div className="adMessageWrap">
            <div className="aaContentWidget">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">{advertName}</h1>
                </header>
                <ul className="adMessageLists">
                    {
                        adverts && adverts.map((advertMessage, index) => (
                            <li key={index}>
                                <span className="msgText">{advertMessage.first_name} {advertMessage.last_name}</span>
                                <div className="messageCountWrap">
                                    <Link
                                        to={advertMessage?.chats[0]?.messages_count > 0 ? `/app/messages/view-chat/${advertMessage.chats[0].id}` : '#'}
                                        className="btn btn-warning btnMessageWidth"
                                    >
                                        {
                                            advertMessage?.chats[0]?.messages_count > 0 ?
                                                'View Messages' : 'No Messages'
                                        }
                                    </Link>
                                    {
                                        advertMessage?.chats[0]?.unread_messages_count > 0
                                        &&  <span className="messageCount">{advertMessage?.chats[0]?.unread_messages_count > 99 ?
                                            '+99' :
                                            advertMessage?.chats[0]?.unread_messages_count}</span>
                                    }
                                </div>
                            </li>
                        ))
                    }
                    {
                        adverts.length === 0 && <li>No messages</li>
                    }
                </ul>
                <div className="advertMessageBtn">
                    <Link
                        to="/app/messages"
                        className="btn btn-primary"
                    >
                        Back To Advert Messages
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ViewAdvertMessages;