import { Navigate } from 'react-router-dom';
import { APP_PREFIX } from '../../../utils/appConstants';

function AuthProtection({children}) {

    const isLoggedIn = localStorage.getItem('access_token');
    const afterLoginRedirection = () => {
        const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;
        if (userRole === 'sub_veterinarian') {
            return (
                `${APP_PREFIX}/connected-breeder`
            );
        }
        return (
            `${APP_PREFIX}/overview`
        );
    };

    return isLoggedIn ? <Navigate to={afterLoginRedirection()}/> : children;

}

export default AuthProtection;