import React, { useState, useEffect } from "react";
import { useInput } from "../../../components/hooks/useInput";
import classNames from "classnames";
import { HalfCircleSpinner } from "react-epic-spinners";
import { getRequest, postRequest } from "../../../utils/requests";
import {
  API_ENDPOINT_URL,
  AUTH_PREFIX, GOOGLE_KEY,
  RECAPTCHA_SITE_KEY,
} from "../../../utils/appConstants";
import { useNavigate } from "react-router-dom";
import {enforceFormat, formatToPhone, validatePassword} from "../../../utils";
import { Helmet } from "react-helmet-async";
import {toast} from "react-toastify";
import {Wrapper} from "@googlemaps/react-wrapper";
import {Map} from "../../../components/shared/map/map.component";
import groupCitiesByCountry from "../../../utils/groupCitiesByCountry";

const CharitySignup = (props) => {
  const [firstName, bindFirstName] = useInput("");
  const [lastName, bindLastName] = useInput("");
  const [phone, bindPhone] = useInput("+44");
  const [email, bindEmail] = useInput("");
  const [charityNumber, bindCharityNumber] = useInput("");
  const [postalCode, bindPostalCode] = useInput("");
  const [address, bindAddress] = useInput("");
  const [cityId, bindCityId] = useInput("");
  const [charityName, bindCharityName] = useInput("");
  const [password, bindPassword] = useInput("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, bindConfirmPassword] = useInput("");
  const [showCP, setShowCP] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [newsletterChecked, setNewsletterChecked] = useState(false);
  const [errors, setErrors] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [fetchedCities, setFetchedCities] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getRequest(`${API_ENDPOINT_URL}/basic-listing/cities`).then((res) => {
      setFetchedCities(groupCitiesByCountry(res?.data?.data));
    });
    const captchaScript = document.createElement("script");
    captchaScript.src = "https://www.google.com/recaptcha/api.js";
    captchaScript.async = true;
    captchaScript.defer = true;
    document.body.appendChild(captchaScript);
    props?.captcha?.setIsCaptchaValid(false);
    return () => {
      document.body.removeChild(captchaScript);
    };
  }, []);

  // validation
  const validateForm = () => {
    let errors = {};
    let isValid = true;
    const vp = validatePassword(password);

    if (!firstName) {
      isValid = false;
      errors["firstName"] = "This filed is required.";
    }
    if (!lastName) {
      isValid = false;
      errors["lastName"] = "This filed is required.";
    }
    if (!phone) {
      isValid = false;
      errors["phone"] = "This filed is required.";
    }
    if (phone) {
      let res = phone.replace(/\D/g, "");
      if (res.length < 10) {
        isValid = false;
        errors["phone"] = "Please enter a Valid Phone number.";
      }
    }
    if (!email) {
      isValid = false;
      errors["email"] = "This filed is required.";
    }
    if (email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }
    if (!charityNumber) {
      isValid = false;
      errors["charityNumber"] = "This filed is required.";
    }
    if (!postalCode) {
      isValid = false;
      errors["postalCode"] = "This filed is required.";
    }
    if (!address) {
      isValid = false;
      errors["address"] = "This filed is required.";
    }
    if (!cityId) {
      isValid = false;
      errors["cityId"] = "This filed is required.";
    }
    if (!vp.isValid) {
      isValid = false;
      errors["password"] = vp.message;
    }
    if (!password) {
      isValid = false;
      errors["password"] = "This filed is required.";
    }
    if (password !== confirmPassword) {
      isValid = false;
      errors["matchPassword"] = "Passwords do not match.";
    }
    if (!termsChecked) {
      isValid = false;
      errors["termsChecked"] = "Please agree with the terms and conditions.";
    }
    setErrors(errors);
    return isValid;
  };



  //Send the data on form submission
  const signUpHandler = async (event) => {
    event.preventDefault();
    if (validateForm() && props?.captcha?.isCaptchaValid) {
      try {
        setShowLoader(true);

        const geocoder = new window.google.maps.Geocoder();
        const response = await geocoder.geocode({
          'address': `${address}, ${postalCode}`,
        });

        let unformmatedPhone = phone
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("-", "")
          .replaceAll(" ", "");

        let requestBody = {
          first_name: firstName,
          last_name: lastName,
          phone_number: unformmatedPhone,
          email,
          charity_number: charityNumber,
          postal_code: postalCode,
          address,
          city_id: cityId,
          password,
          password_confirmation: confirmPassword,
          subscribe_to_emails: newsletterChecked === true ? 1 : 0,
          lat: response.results[0].geometry.location.lat(),
          long: response.results[0].geometry.location.lng(),
        };

        if (charityName !== "") {
          requestBody.charity_name = charityName;
        }

        postRequest(`${API_ENDPOINT_URL}/register/charity`, requestBody).then(
          (res) => {
            if (res) {
              props?.captcha?.setIsCaptchaValid(false);
              localStorage.setItem(
                "currentUser",
                JSON.stringify(res.data.data.user)
              );
              localStorage.setItem("access_token", res.data.data.access_token);
              setShowLoader(false);
              navigate(`${AUTH_PREFIX}/signup-congrats`);
            } else {
              setShowLoader(false);
            }
          }
        );
      } catch (err) {
        console.log(err);
        toast.error("Unable to validate address.");
        setShowLoader(false);
      }
    }
  };

  return (
    <section className="um-sectionWrap um-charityFormBlock pt-5 pb-15">
      <Helmet>
        <title>Register (Charity/Rescue Centre) | Petbond</title>
        <meta
          name="description"
          content="If you are a registered rescue centre you can use PetBond to rehome any pets online for free."
        />
      </Helmet>
      <div className="container">
        <div className="um-bdShadowComp shadow rounded-lg">
          <header className="um-formHddComp">
            <h1 className="h2">
              Register A Charity/Rescue Centre with Petbond
            </h1>
            <strong className="d-inline-block">
              Please complete the registration form below to become a Petbond
              approved Charity.
            </strong>
          </header>
          <div className="um-formBrdd">
            <form
              className="um-bddFormWrap"
              onSubmit={(event) => signUpHandler(event)}
            >
              <label className="um-bddFormlb">Your details:</label>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group um-bddInputWrap">
                    <input
                      type="text"
                      id="first_name"
                      placeholder="First Name&hellip;"
                      className={`form-control w-100 ${
                        errors?.firstName && "fcError"
                      }`}
                      {...bindFirstName}
                    />
                    {errors.firstName && (
                      <small className="support-error">
                        {errors.firstName}
                      </small>
                    )}
                  </div>
                  <div className="form-group um-bddInputWrap">
                    <input
                      type="text"
                      id="last_name"
                      placeholder="Last Name&hellip;"
                      className={`form-control w-100 ${
                        errors?.lastName && "fcError"
                      }`}
                      {...bindLastName}
                    />
                    {errors.lastName && (
                      <small className="support-error">{errors.lastName}</small>
                    )}
                  </div>
                  <div className="form-group um-bddInputWrap">
                    <input
                      type="tel"
                      id="phone"
                      placeholder="Mobile&hellip;"
                      className={`form-control w-100 ${
                        errors?.phone && "fcError"
                      }`}
                      maxLength={20}
                      onKeyUp={formatToPhone}
                      onKeyDown={enforceFormat}
                      {...bindPhone}
                    />
                    {errors.phone && (
                      <small className="support-error">{errors.phone}</small>
                    )}
                  </div>
                  <div className="form-group um-bddInputWrap">
                    <input
                      type="email"
                      id="email"
                      placeholder="Email&hellip;"
                      className={`form-control w-100 ${
                        errors?.email && "fcError"
                      }`}
                      {...bindEmail}
                    />
                    {errors.email && (
                      <small className="support-error">{errors.email}</small>
                    )}
                  </div>
                  <div className="form-group um-bddInputWrap">
                    <input
                      type="text"
                      id="charityNumber"
                      placeholder="Charity Number&hellip;"
                      className={`form-control w-100 ${
                        errors?.charityNumber && "fcError"
                      }`}
                      {...bindCharityNumber}
                    />
                    {errors.charityNumber && (
                      <small className="support-error">
                        {errors.charityNumber}
                      </small>
                    )}
                  </div>
                  <div className="form-group um-bddInputWrap">
                    <input
                      type="text"
                      id="postalCode"
                      placeholder="Post Code&hellip;"
                      className={`form-control w-100 ${
                        errors?.postalCode && "fcError"
                      }`}
                      {...bindPostalCode}
                    />
                    {errors.postalCode && (
                      <small className="support-error">
                        {errors.postalCode}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="um-chrtFormInfii">
                    <div className="form-group um-bddInputWrap">
                      <input
                        type="address"
                        id="address"
                        placeholder="Address 1&hellip;"
                        className={`form-control w-100 ${
                          errors?.address && "fcError"
                        }`}
                        {...bindAddress}
                      />
                      {errors.address && (
                        <small className="support-error">
                          {errors.address}
                        </small>
                      )}
                    </div>
                    <div className="form-group um-bddFormSlct um-slctEtcDesg">
                      <select
                        className={`form-control w-100 ${
                          errors?.cityId && "fcError"
                        }`}
                        {...bindCityId}
                      >
                        <option>County</option>
                        {Object.keys(fetchedCities).map(country => (
                            <optgroup label={country.toUpperCase()} key={country}>
                              {fetchedCities[country].map(city => (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                              ))}
                            </optgroup>
                        ))}
                      </select>
                      {errors.cityId && (
                        <small className="support-error">{errors.cityId}</small>
                      )}
                    </div>
                    <div className="form-group um-bddInputWrap">
                      <input
                        type="text"
                        id="charity_name"
                        placeholder="Charity Name&hellip;"
                        className="form-control w-100"
                        {...bindCharityName}
                      />
                    </div>
                    <div className="form-group um-bddInputWrap">
                      <div className="position-relative icnInputWrap">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          placeholder="Password"
                          className={`form-control w-100 ${
                            errors?.password && "fcError"
                          }`}
                          {...bindPassword}
                        />
                        {showPassword === false ? (
                          <i
                            className="far fa-eye-slash icn"
                            onClick={() => setShowPassword(true)}
                          />
                        ) : (
                          <i
                            className="far fa-eye icn"
                            onClick={() => setShowPassword(false)}
                          />
                        )}
                      </div>
                      <small>{props.passwordInfo}</small>
                      {errors.password && (
                        <small className="support-error">
                          {errors.password}
                        </small>
                      )}
                    </div>
                    <div className="form-group um-bddInputWrap">
                      <div className="position-relative icnInputWrap">
                        <input
                          type={showCP ? "text" : "password"}
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          className={`form-control w-100 ${
                            errors?.confirmPassword && "fcError"
                          }`}
                          {...bindConfirmPassword}
                        />
                        {showCP === false ? (
                          <i
                            className="far fa-eye-slash icn"
                            onClick={() => setShowCP(true)}
                          />
                        ) : (
                          <i
                            className="far fa-eye icn"
                            onClick={() => setShowCP(false)}
                          />
                        )}
                      </div>
                      {errors.matchPassword && (
                        <small className="support-error">
                          {errors.matchPassword}
                        </small>
                      )}
                    </div>
                    <div className="um-bddInputWrap um-checkExtStyle">
                      <div className="um-cusCheckBox">
                        <input
                          type="checkbox"
                          className="form-control"
                          id="termsChecked"
                          checked={termsChecked}
                          onChange={() => setTermsChecked(!termsChecked)}
                        />
                        <label className="cusCheckLabel" htmlFor="termsChecked">
                          “I ACCEPT” Terms and Conditions of the Petbond
                          platform.
                        </label>
                      </div>
                      <span
                        className={classNames(
                          errors.termsChecked
                            ? "checkError checkmark"
                            : "checkmark"
                        )}
                      >
                        <small className="support-error">
                          {errors.termsChecked}
                        </small>
                      </span>
                    </div>
                    <div className="">
                      <div className="um-cusCheckBox">
                        <input
                          type="checkbox"
                          className="form-control"
                          id="newsletterChecked"
                          checked={newsletterChecked}
                          onChange={() =>
                            setNewsletterChecked(!newsletterChecked)
                          }
                        />
                        <label
                          className="cusCheckLabel"
                          htmlFor="newsletterChecked"
                        >
                          {props.newsletterInfo}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2 g-recaptcha"
                  data-sitekey={RECAPTCHA_SITE_KEY}
                  data-callback="captchaConfirmation"
                />
                <div className="col-12">
                  <div className="um-formBtnWrap">
                    <button
                      type="submit"
                      className="btn btn-warning btnFormStyle btnFormWidth"
                      disabled={showLoader}
                    >
                      {showLoader && (
                        <HalfCircleSpinner
                          size={18}
                          style={{
                            display: "inline-block",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Wrapper apiKey={GOOGLE_KEY}>
        <Map
          center={{
            lat: 52.3555,
            lng: 1.1743
          }}
          zoom={16}
          style={{flexGrow: '1', height: '0%'}}
          options={'s'}
        >
        </Map>
      </Wrapper>
    </section>
  );
};

export default CharitySignup;
