import React, {useState} from "react";
import {ReactComponent as CircleQuestionIcon} from '../../../assets/icons/circle-question-icon.svg'

const InputWithHelpTooltip = ({type, id, placeholder, error, bindings, helpText}) => {
    const [displayTooltip, setDisplayTooltip] = useState(false);

    return (
        <div className="position-relative icnInputWrap inputWithHelp">
            <input
                type={type || 'text'}
                id={id}
                placeholder={placeholder}
                className={`form-control  ${
                    error && "fcError"
                }`}
                {...bindings}
            />

            <CircleQuestionIcon
                className="icn"
                onMouseEnter={() => setDisplayTooltip(true)}
                onMouseLeave={() => setDisplayTooltip(false)}
            />

            <div className="input-help-tooltip" style={{opacity: displayTooltip ? '100%' : '0%'}}>{helpText}</div>
        </div>
    )
}

export default InputWithHelpTooltip;
