import React from 'react';
import BreederListedPets from './BreederListedPets';
import CharityListedPets from './CharityListedPets';
import {Navigate} from 'react-router-dom';

const ListedPets = () => {

    const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

    if (userRole === 'breeder') {
        return (
            <BreederListedPets/>
        );
    } else if (userRole === 'charity') {
        return (
            <CharityListedPets/>
        );
    }

    return <Navigate to='/' />
};

export default ListedPets;