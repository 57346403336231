import React, { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../../../utils/requests";
import {
  API_ENDPOINT_URL,
  APP_PREFIX, MIX_BREED_ID,
} from "../../../../../utils/appConstants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { buildFormData } from "../../../../../utils";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import oO from "../../../../../assets/images/oO.png";
import CheckboxGroup from "react-checkbox-group";
import puppyList from "../../../ConnectBreeder/VetConnectedBreeder/PuppyList";

const StepThree = ({ advertId, commissionPercent, taxPercent }) => {
  const [advertName, setAdvertName] = useState("");
  const [advertDescription, setAdvertDescription] = useState("");
  const [motherName, setMotherName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [chipNumber, setChipNumber] = useState("");
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [motherTestCheck, setMotherTestCheck] = useState("no");
  const [motherTest, setMotherTest] = useState([]);
  const [motherReportDetails, setMotherReportDetails] = useState([]);
  const [motherDeletedReports, setMotherDeletedReports] = useState([]);
  const [fatherTestCheck, setFatherTestCheck] = useState("no");
  const [fatherTest, setFatherTest] = useState([]);
  const [fatherReportDetails, setFatherReportDetails] = useState([]);
  const [fatherDeletedReports, setFatherDeletedReports] = useState([]);
  const [motherReportDetailsPreview, setMotherReportDetailsPreview] = useState(
      []
  );
  const [defaultMotherReportPreview, setDefaultMotherReportPreview] = useState(
      []
  );
  const [defaultFatherReportPreview, setDefaultFatherReportPreview] = useState(
      []
  );
  const [fatherReportDetailsPreview, setFatherReportDetailsPreview] = useState(
      []
  );
  const [fetchedExaminations, setFetchedExaminations] = useState([]);
  const [fetchedMicroChips, setFetchedMicroChips] = useState([]);
  const [fetchedBreeds, setFetchedBreeds] = useState([]);
  const [modalBreedId, setModalBreedId] = useState("");
  const [defaultBreedId, setDefaultBreedId] = useState(false);

  const [dob, setDob] = useState("");
  const [puppies, setPuppies] = useState([]);
  const [errors, setErrors] = useState([]);
  const [serverErrors, setServerErrors] = useState([]);
  const [buyerPrice, setBuyerPrice] = useState([]);
  const [microchipModal, setMicrochipModal] = useState({
    chip_number: "",
    pet_id: "",
    pupIndex: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [showMotherChip, setShowMotherChip] = useState("no");
  const [motherChipNumber, setMotherChipNumber] = useState("");
  const [defaultChipNumber, setDefaultChipNumber] = useState(false);
  const [modalErrors, setModalErrors] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (advertId) {
      loadAdvertData();
    } else {
      getRequest(`${API_ENDPOINT_URL}/breeder/microchip/list`, true).then(
          (res) => {
            if (res) {
              setFetchedMicroChips(res.data.data);
            }
          }
      );
      console.log('added here');
      addPuppy();
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [advertId]);


  useEffect(() => {
    if (
        (motherTestCheck === "yes" || fatherTestCheck === "yes") &&
        fetchedExaminations.length === 0
    ) {
      getRequest(`${API_ENDPOINT_URL}/basic-listing/examinations`).then(
          (res) => {
            if (res) {
              setFetchedExaminations(res.data.data);
            }
          }
      );
    }
  }, [motherTestCheck, fatherTestCheck]);

  const loadAdvertData = () => {
    if (advertId) {
      getRequest(`${API_ENDPOINT_URL}/breeder/advert/edit/${advertId}`, true).then(
          (res) => {
            if (res) {
              const data = res.data.data;
              setMotherName(data.mother_name);
              setFatherName(data.father_name);
              setChipNumber(data?.chip_number);
              setDefaultBreedId(true);
              setModalBreedId(data?.breed_id);
              if (data?.description) {
                setAdvertDescription(data?.description);
              }
              if (data?.advert_name) {
                setAdvertName(data.advert_name);
              }
              if (data?.cover_photo) {
                setImagePreview(
                    `${API_ENDPOINT_URL}/image-retrieve/${data.cover_photo}`
                );
              }
              if (data?.mother_advert_examinations?.length) {
                let temp_exams = [];
                data?.mother_advert_examinations?.forEach((ad) => {
                  temp_exams.push(ad?.examination_id);
                });
                setMotherTest(temp_exams);
                setMotherTestCheck("yes");
              }
              if (data?.test_report_detail?.mother_reports?.length) {
                let tempPreviews = [];
                data.test_report_detail?.mother_reports?.map((rep) => {
                  tempPreviews.push(`${API_ENDPOINT_URL}/image-retrieve/${rep}`);
                });
                setMotherReportDetailsPreview(tempPreviews);
                setDefaultMotherReportPreview(tempPreviews);
                setMotherTestCheck("yes");
              }
              if (data?.father_advert_examinations?.length) {
                let temp_exams = [];
                data?.father_advert_examinations?.forEach((ad) => {
                  temp_exams.push(ad?.examination_id);
                });
                setFatherTest(temp_exams);
                setFatherTestCheck("yes");
              }
              if (data?.test_report_detail?.father_reports?.length) {
                let tempPreviews = [];
                data.test_report_detail?.father_reports?.map((rep) => {
                  tempPreviews.push(`${API_ENDPOINT_URL}/image-retrieve/${rep}`);
                });
                setFatherReportDetailsPreview(tempPreviews);
                setDefaultFatherReportPreview(tempPreviews);
                setFatherTestCheck("yes");
              }

              setDob(data.dob);
              if (!data?.chip_number.includes("pet_bond_chip")) {
                setMotherChipNumber(data?.chip_number);
                if (data?.chip_number != "") {
                  setShowMotherChip("yes");
                  setDefaultChipNumber(true);
                }
              }

              if(data?.pets && puppies.length > 0) {
                removePuppy(0);
              }

              data?.pets?.forEach((pet) => {
                let certPrev = pet?.certificate_detail?.map(
                    (c) => `${API_ENDPOINT_URL}/image-retrieve/${c}`
                );
                addPuppy(
                    true,
                    pet?.name,
                    pet?.chip_number,
                    pet?.price,
                    pet?.color,
                    "",
                    pet?.photo
                        ? `${API_ENDPOINT_URL}/image-retrieve/${pet?.photo}`
                        : "",
                    pet?.gender,
                    [],
                    certPrev,
                    pet?.pet_id
                );
              });
              if (data?.pets?.length <= 0) {
                addPuppy();
              }
            }
          }
      );
    }
  }

  useEffect(() => {
    getRequest(`${API_ENDPOINT_URL}/basic-listing/breeds`).then((res) => {
      if (res) {
        const breeds = res.data.data.filter((x) => x.id !== MIX_BREED_ID);
        setFetchedBreeds(breeds);
      }
    });
  }, []);

  const addPuppy = (
    isFetched = false,
    name = "",
    chip_number = "",
    price = "",
    color = "",
    photo = "",
    photoPreview = "",
    gender = "",
    certificates = [],
    certificatesPreview = [],
    pet_id = null
  ) => {
    const pup = {
      isFetched,
      name,
      chip_number,
      price,
      color,
      photo,
      photoPreview,
      gender,
      certificates,
      certificatesPreview,
      pet_id,
    };

    if (chip_number?.length === 0 || chip_number.includes("pet_bond_chip")) {
      delete pup.chip_number;
    }
    setPuppies((prev) => [...prev, pup]);
  };

  const removePuppy = (i) => {
    const pups = [...puppies];
    pups.splice(i, 1);
    setPuppies(pups);
  };

  const handleChange = (e, i) => {
    const pups = [...puppies];
    const { name, value } = e.target;
    pups[i][name] = value;
    setPuppies(pups);
  };

  const priceChanged = (e, i) => {
    const { name, value } = e.target;
    const price = calcBuyerPrice(value, commissionPercent, taxPercent);
    const x = [...buyerPrice];
    x[i] = price;
    setBuyerPrice([...x]);
  };

  const reversePriceChanged = (e, i) => {
    const { value } = e.target;

    const x = [...buyerPrice];
    x[i] = value;
    setBuyerPrice([...x]);
    const price = calcReversePrice(value, commissionPercent, taxPercent);
    const pups = [...puppies];
    pups[i]["price"] = price;
    setPuppies(pups);
  };

  const calcBuyerPrice = (price, commissionPercent, taxPercent) => {
    price = Number(price);
    price = Math.round((price + Number.EPSILON) * 100) / 100;
    const commPrice = (price / 100) * parseInt(commissionPercent);
    const commissionPrice =
      Math.round((commPrice + Number.EPSILON) * 100) / 100;
    const tPrice = (commissionPrice / 100) * parseInt(taxPercent);
    const taxPrice = Math.round((tPrice + Number.EPSILON) * 100) / 100;
    return (
      Math.round((taxPrice + commissionPrice + price + Number.EPSILON) * 100) /
      100
    );
  };

  const calcReversePrice = (
    priceIncTaxAndCommission,
    commissionPercent,
    taxPercent
  ) => {
    priceIncTaxAndCommission = Number(priceIncTaxAndCommission);
    priceIncTaxAndCommission =
      !isNaN(priceIncTaxAndCommission) && priceIncTaxAndCommission !== ""
        ? priceIncTaxAndCommission
        : 0;
    priceIncTaxAndCommission =
      Math.round((priceIncTaxAndCommission + Number.EPSILON) * 100) / 100;
    const reversePrice =
      priceIncTaxAndCommission /
      (1 + (commissionPercent + taxPercent / commissionPercent) / 100);
    return Math.round((reversePrice + Number.EPSILON) * 100) / 100;
  };

  useEffect(() => {
    if (puppies.length > 0) {
      puppies.forEach((pup, i) => {
        if (!isNaN(pup.price) && pup.price != "") {
          const price = calcBuyerPrice(
            pup.price,
            commissionPercent,
            taxPercent
          );
          const x = [...buyerPrice];
          x.push(price);
          setBuyerPrice([...x]);
        } else {
          setBuyerPrice([...buyerPrice, ""]);
        }
      });
    }
  }, [puppies]);

  const handleImageChange = (e, idx) => {
    const pups = [...puppies];
    const { name, files } = e.target;
    if (name === "photo") {
      pups[idx][name] = files[0];
      pups[idx]["photoPreview"] = URL.createObjectURL(files[0]);
    } else if (name === "certificates") {
      const tempFiles = [];
      const tempFilePreviews = [];
      for (let i = 0; i < files?.length; i++) {
        tempFiles.push(files[i]);
        tempFilePreviews.push(URL.createObjectURL(files[i]));
      }
      pups[idx][name] = tempFiles;
      pups[idx]["certificatesPreview"] = tempFilePreviews;
    }
    setPuppies(pups);
  };

  const removeImage = (idx, type, imageIdx) => {
    const pups = [...puppies];
    if (type === "photoPreview") {
      pups[idx]["photo"] = "";
      pups[idx][type] = "";
    } else if (type === "certificatesPreview") {
      pups[idx]["certificates"].splice(imageIdx, 1);
      pups[idx]["certificatesPreview"].splice(imageIdx, 1);
    }
    setPuppies(pups);
  };

  const removeImg = (i, type) => {
    if (type === "mother") {
      let filesArray = [...motherReportDetails];
      let previewsArray = [...motherReportDetailsPreview];
      const deletedImage =
          motherReportDetailsPreview[i].split("image-retrieve/");
      setMotherDeletedReports((prev) => [...prev, deletedImage[1]]);
      filesArray.splice(i, 1);
      previewsArray.splice(i, 1);
      setMotherReportDetails(filesArray);
      setMotherReportDetailsPreview(previewsArray);
    } else if (type === "father") {
      let filesArray = [...fatherReportDetails];
      let previewsArray = [...fatherReportDetailsPreview];
      const deletedImage =
          fatherReportDetailsPreview[i].split("image-retrieve/");
      setFatherDeletedReports((prev) => [...prev, deletedImage[1]]);
      filesArray.splice(i, 1);
      previewsArray.splice(i, 1);
      setFatherReportDetails(filesArray);
      setFatherReportDetailsPreview(previewsArray);
    }
  };

  const validate = (isPublish = false) => {
    setServerErrors([]);
    let errors = [];
    let isValid = true;
    if (!modalBreedId) {
      isValid = false;
      errors["breedId"] = "This field is required";
    }

    if(!advertName) {
      isValid = false;
      errors['advertName'] = "This field is required"
    }

    if (isPublish && !dob) {
      isValid = false;
      errors['dob'] = "This field is required"
    }

    if(isPublish && !imagePreview) {
      isValid = false;
      errors['coverImage'] = "This field is required"
    }

    errors['puppies'] = [];
     puppies.forEach((puppy, i) => {
       errors['puppies'][i] = [];
       if (! puppy.name) {
         isValid = false;
         errors['puppies'][i]['name'] = "This field is required";
       }
       if (puppy.chip_number && puppy?.chip_number?.length !== 15) {
         isValid = false;
         errors['puppies'][i]['chip_number'] = "Please add a chip number containing 15 characters";
       }
       if(isPublish) {
         if(! puppy.color) {
           isValid = false;
           errors['puppies'][i]['color'] = "This field is required";
         }
         if(! puppy.price) {
           isValid = false;
           errors['puppies'][i]['price'] = "This field is required";
         }
         if(! puppy.gender) {
           isValid = false;
           errors['puppies'][i]['gender'] = "This field is required";
         }
       }
     })

    if (defaultChipNumber == false) {
      if (
        showMotherChip == "yes" &&
        (motherChipNumber == undefined || motherChipNumber == "")
      ) {
        isValid = false;
        errors["chipNumber"] = "please Add Chip Number";
      }
      if (motherChipNumber && motherChipNumber.length !== 15) {
        isValid = false;
        errors['chipNumber'] = "Please add a chip number containing 15 characters";
      }
    }

    if(!isValid) {
      setErrors(errors);
      toast.error("You have validation errors");
    }

    return isValid;
  };

  useEffect(() => {
    const firstErrorElement = document.querySelector('.support-error');
    if(firstErrorElement) {
      const scrollToPosition = firstErrorElement.getBoundingClientRect().top - 200;

      window.scrollTo({
        top: window.scrollY + scrollToPosition,
        behavior: 'smooth'
      });
    }
  }, [errors]);


  const getRequestBody = () => {
    let motherTestData = [];
    let motherDataReportDetails = [];
    let motherDataDeletedReports = [];
    if (motherTestCheck === "yes") {
      motherTestData = motherTest;
      motherDataReportDetails = motherReportDetails;
      motherDataDeletedReports = motherDeletedReports;
    } else {
      if (defaultMotherReportPreview.length > 0) {
        const deletedImages = defaultMotherReportPreview.map(
            (mr) => mr.split("image-retrieve/")[1]
        );
        motherDataDeletedReports = deletedImages;
      }
    }
    let fatherTestData = [];
    let fatherDataReportDetails = [];
    let fatherDataDeletedReports = [];
    if (fatherTestCheck === "yes") {
      fatherTestData = fatherTest;
      fatherDataReportDetails = fatherReportDetails;
      fatherDataDeletedReports = fatherDeletedReports;
    } else {
      if (defaultFatherReportPreview.length > 0) {
        const deletedImages = defaultFatherReportPreview.map(
            (mr) => mr.split("image-retrieve/")[1]
        );
        fatherDataDeletedReports = deletedImages;
      }
    }
    let body = {
      mother_test: motherTestData,
      father_test: fatherTestData,
      description: advertDescription,
    };
    if (defaultBreedId == false) {
      body.breed_id = modalBreedId;
    }
    if (advertName) {
      body.advert_name = advertName;
    }
    if (motherName) {
      body.mother_name = motherName;
    }
    if (fatherName) {
      body.father_name = fatherName;
    }
    if (image) {
      body.cover_photo = image;
    }
    if (motherTestCheck === "yes") {
      if (motherDataReportDetails.length) {
        body.mother_report_details = motherDataReportDetails;
      }
    }
    if (fatherTestCheck === "yes") {
      if (fatherDataReportDetails.length) {
        body.father_report_details = fatherDataReportDetails;
      }
    }
    if (motherDataDeletedReports.length) {
      body.delete_mother_report_details = motherDataDeletedReports;
    }
    if (fatherDataDeletedReports.length) {
      body.delete_father_report_details = fatherDataDeletedReports;
    }

    let formattedPuppies = [...puppies];
    body.dob = dob;
    if (showMotherChip == "yes" && defaultChipNumber == false) {
      body.mother_chip_number = motherChipNumber;
    }
    let newPuppies = [];
    let updatedPuppies = [];
    formattedPuppies.forEach((pup) => {
      if (pup.isFetched) {
        if (!pup.photo) {
          delete pup.photo;
        }
        if (pup?.certificates?.length <= 0) {
          delete pup.certificates;
        }
        updatedPuppies.push(pup);
      } else {
        delete pup.pet_id;
        if (!pup.photo) {
          delete pup.photo;
        }
        if (pup?.certificates?.length <= 0) {
          delete pup.certificates;
        }
        if (!pup.chip_number) {
          delete pup.chip_number;
        }
        if (!pup.color) {
          delete pup.color;
        }
        if (!pup.gender) {
          delete pup.gender;
        }
        if (!pup.price) {
          delete pup.price;
        }
        if (!pup.name) {
          delete pup.name;
          pup?.certificatesPreview?.length <= 0 &&
            delete pup.certificatesPreview;
          delete pup.isFetched;
          !pup.photoPreview && delete pup.photoPreview;
        }
        newPuppies.push(pup);
      }
    });
    if (updatedPuppies?.length) {
      body.update_puppies = updatedPuppies;
    }
    if (newPuppies?.length) {
      body.new_puppies = newPuppies;
    }
    const formData = new FormData();
    buildFormData(formData, body);
    body = formData;

    return body;
  };

  const saveProgress = (e) => {
    e.preventDefault();
    if (validate()) {
      const body = getRequestBody();
      body.append("is_publish", false);
      postRequest(
        advertId ? `${API_ENDPOINT_URL}/breeder/advert/update/${advertId}` : `${API_ENDPOINT_URL}/breeder/advert/store`,
        body,
        true
      ).then((res) => {
        if (res) {
          toast.success("Progress saved successfully");
          navigate(`${APP_PREFIX}/listed-pets`);
        }
      }).catch(err => {
        const validationErrors = err?.response?.data?.errors;
        if(validationErrors) {
          setServerErrors(Object.values(validationErrors));
        }
      });
    }
  };

  const finishProcess = () => {
    if (validate(true)) {
      let body = getRequestBody();
      body.append("is_publish", true);
      postRequest(
          advertId ? `${API_ENDPOINT_URL}/breeder/advert/update/${advertId}` : `${API_ENDPOINT_URL}/breeder/advert/store`,
        body,
        true
      ).then((res) => {
        if (res) {
          toast.success(
            "Thanks for submitting your advert, it has been sent for approval by our team!"
          );
          navigate(`${APP_PREFIX}/sell-pet/benefits`);
        }
      }).catch(err => {
        const validationErrors = err?.response?.data?.errors;
        if(validationErrors) {
          setServerErrors(Object.values(validationErrors));
        }
      });
    }
  };

  const validateChipNumber = () => {
    let errors = {};
    let isValid = true;

    if (!microchipModal.chip_number) {
      isValid = false;
      errors["chip_number"] = "This field is required";
    }
    if (microchipModal.chip_number) {
      if (microchipModal?.chip_number?.length !== 15) {
        isValid = false;
        errors["chip_number"] =
          "Please add a chip number containing 15 characters";
      }
    }
    setModalErrors(errors);
    return isValid;
  };

  const updateChipNumber = (e) => {
    e.preventDefault();
    if (validateChipNumber()) {
      const body = { ...microchipModal };
      delete body.pupIndex;
      postRequest(
        `${API_ENDPOINT_URL}/breeder/advert/${advertId}/pet/update-micro-chip-number`,
        body,
        true
      ).then((res) => {
        if (res) {
          const updatedPuppies = [...puppies];
          const data = updatedPuppies[microchipModal.pupIndex];
          data.chip_number = body.chip_number;
          setPuppies(updatedPuppies);
          setShowModal(false);
        }
      });
    }
  };

  return (
    <div>
      <section className="um-brdRegForm">
        <div className="container">
          <div className="shadow-sm p-5 rounded-lg">
            <header className="aaPtbdHeader margin-zero">
              <h4 className="aaPtbHeading">Step 2: Advert Details</h4>
              <hr />
            </header>
            <div className="advertpet">
              <form className="um-bddFormWrap" onSubmit={saveProgress}>
                <div className="row">
                  <div className="container">
                    <div className="advertpet">
                      <h6 className="poaAdd text-left">Advert name
                        <span className="field-required">* required for Draft and Publish</span>
                      </h6>

                      <div className="row">
                        <div className="col-12 col-md-7 mb-3">
                          <input
                              type="text"
                              className="form-control w-100"
                              placeholder="Write advert name here..."
                              value={advertName}
                              onChange={(e) => setAdvertName(e.target.value)}
                          />
                          {errors.advertName && (
                              <small className="support-error">
                                {errors.advertName}
                              </small>
                          )}
                          <div className="my-3">
                            <h6 className="poaAdd text-left">Advert Description</h6>
                            <textarea
                                className="form-control"
                                placeholder="Write advert description here..."
                                value={advertDescription}
                                onChange={(e) => setAdvertDescription(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-5">
                          <div className="um-profilePicWrap">
                            <h6 className="poaAdd d-flex flex-column align-items-center justify-content-center">
                              Main Advert Image
                            </h6>
                            {errors.coverImage && (
                                <small className="support-error pb-2">
                                  {errors.coverImage}
                                </small>
                            )}
                            <div className="um-imgWrap rounded d-flex align-items-center justify-content-center shadow-sm">
                              {imagePreview ? (
                                  <img
                                      src={imagePreview}
                                      className="img-fluid"
                                      alt="uploaded-img"
                                  />
                              ) : (
                                  <i className="umicomoonGroup-1087 empFileIcn" />
                              )}
                            </div>
                            <div className="um-btnformWrap d-flex">
                              <a
                                  href="#"
                                  className="btn btnScnWidth btn-info btnScnStyl text-uppercase d-none"
                              >
                                Scan{" "}
                                <i className="umicomoonGroup-1095 btnIcnUpload" />
                              </a>
                              <span className="customFileBtnWrap w-100 text-center">
                          <input
                              id="cover_photo"
                              type="file"
                              accept="image/jpeg"
                              onChange={(e) => {
                                setImage(e.target.files[0]);
                                setImagePreview(
                                    URL.createObjectURL(e.target.files[0])
                                );
                              }}
                          />
                          <label
                              htmlFor="cover_photo"
                              className="fileBtn btn-info text-uppercase shadow-sm"
                          >
                            Upload{" "}
                            <i className="umicomoonGroup-1095 btnIcnUpload" />
                          </label>
                        </span>
                            </div>
                            <div className="field-required field-required--lg text-center">* required for Publish
                              <p>
                                Image of mother with puppies is recommended for
                                better sales. Max size 12mb.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-7">
                          <h6 className="aaPtbHeading">Mother & Father Details</h6>
                          <div className="row mb-3">
                            <div className="col-12 col-md-6 mb-3">
                              <input
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Mother Name"
                                  value={motherName}
                                  onChange={(e) => setMotherName(e.target.value)}
                              />
                            </div>
                            <div className="col-12 col-md-6 mb-3">
                              <input
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Father Name"
                                  value={fatherName}
                                  onChange={(e) => setFatherName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <div className="col-12 col-xl-6">
                              <select
                                  className="form-control"
                                  value={modalBreedId}
                                  onChange={(e) => setModalBreedId(e.target.value)}
                                  disabled={defaultBreedId == true}
                              >
                                <option>Select a breed</option>
                                {fetchedBreeds.map((breed) => (
                                    <option key={breed.id} value={breed.id}>
                                      {breed.name}
                                    </option>
                                ))}
                              </select>
                              <span className="field-required">* required for Draft and Publish</span>
                              {errors.breedId && (
                                  <small className="support-error">
                                    {errors.breedId}
                                  </small>
                              )}
                            </div>
                          </div>

                          <div className="form-group">
                            Is the mother of puppies health tested
                            <label className="checkboxlabel">
                              Yes{" "}
                              <input
                                  className="css3checkbox"
                                  type="radio"
                                  name="motherTestCheck"
                                  value="yes"
                                  onChange={(e) => setMotherTestCheck(e.target.value)}
                                  checked={motherTestCheck === "yes"}
                              />
                            </label>
                            <label className="checkboxlabel">
                              No{" "}
                              <input
                                  className="css3checkbox"
                                  type="radio"
                                  name="motherTestCheck"
                                  value="no"
                                  onChange={(e) => setMotherTestCheck(e.target.value)}
                                  checked={motherTestCheck === "no"}
                              />
                            </label>
                          </div>
                          {motherTestCheck === "yes" && (
                              <>
                                <div className="form-group">Tests carried out:</div>
                                <div className="form-group mb-4">
                                  <div className="row">
                                    <CheckboxGroup
                                        name="motherTest"
                                        value={motherTest}
                                        onChange={setMotherTest}
                                    >
                                      {(Checkbox) => {
                                        return fetchedExaminations.map((exam) => (
                                            <div
                                                className="col-12 col-sm-6 col-md-12 col-lg-5"
                                                key={exam.id}
                                            >
                                              <label className="checkboxlabel right text-capitalize">
                                                <Checkbox
                                                    className="css3checkbox"
                                                    value={exam.id}
                                                />
                                                {exam.name}
                                              </label>
                                            </div>
                                        ));
                                      }}
                                    </CheckboxGroup>
                                  </div>
                                </div>
                                <div className="row mb-10">
                                  <div className="col-md-12">
                                    <div className="um-btnformWrap">
                              <span className="customFileBtnWrap w-100 text-center">
                                <input
                                    id="mother_report_details"
                                    type="file"
                                    multiple={true}
                                    accept="image/jpeg"
                                    onChange={(event) => {
                                      const files = event.target.files;
                                      let filesArray = [];
                                      let previewsArray = [];
                                      for (let i = 0; i < files.length; i++) {
                                        filesArray.push(files[i]);
                                        previewsArray.push(
                                            URL.createObjectURL(files[i])
                                        );
                                      }
                                      setMotherReportDetailsPreview(
                                          previewsArray
                                      );
                                      setMotherReportDetails(filesArray);
                                    }}
                                />
                                <label
                                    htmlFor="mother_report_details"
                                    className="fileBtn btn-info text-uppercase"
                                >
                                  Upload Results{" "}
                                  <i className="umicomoonGroup-1095 btnIcnUpload" />
                                </label>
                              </span>
                                    </div>
                                  </div>
                                  {motherReportDetailsPreview.map((report, i) => (
                                      <div className="col-12 mt-4" key={i}>
                                        <div className="position-relative mrdpEditorView">
                                          <img
                                              className="img-fluid"
                                              src={report}
                                              width="20%"
                                          />
                                          <button
                                              type="button"
                                              className="btn rounded-circle d-flex align-items-center justify-content-center btn-danger p-0 btnMrdpRemover position-absolute"
                                              onClick={() => removeImg(i, "mother")}
                                          >
                                            X
                                          </button>
                                        </div>
                                      </div>
                                  ))}
                                </div>
                              </>
                          )}
                          <div className="form-group mb-4">
                            Is the father of puppies health tested
                            <label className="checkboxlabel">
                              Yes{" "}
                              <input
                                  className="css3checkbox"
                                  type="radio"
                                  name="fatherTestCheck"
                                  value="yes"
                                  onChange={(e) => setFatherTestCheck(e.target.value)}
                                  checked={fatherTestCheck === "yes"}
                              />
                            </label>
                            <label className="checkboxlabel">
                              No{" "}
                              <input
                                  className="css3checkbox"
                                  type="radio"
                                  name="fatherTestCheck"
                                  value="no"
                                  onChange={(e) => setFatherTestCheck(e.target.value)}
                                  checked={fatherTestCheck === "no"}
                              />
                            </label>
                          </div>
                          {fatherTestCheck === "yes" && (
                              <>
                                <div className="form-group">Tests carried out:</div>
                                <div className="form-group mb-4">
                                  <div className="row">
                                    <CheckboxGroup
                                        name="fatherTest"
                                        value={fatherTest}
                                        onChange={setFatherTest}
                                    >
                                      {(Checkbox) => {
                                        return fetchedExaminations.map((exam) => (
                                            <div
                                                className="col-12 col-sm-6 col-md-12 col-lg-5"
                                                key={exam.id}
                                            >
                                              <label className="checkboxlabel right text-capitalize">
                                                <Checkbox
                                                    className="css3checkbox"
                                                    value={exam.id}
                                                />
                                                {exam.name}
                                              </label>
                                            </div>
                                        ));
                                      }}
                                    </CheckboxGroup>
                                  </div>
                                </div>
                                <div className="row mb-10">
                                  <div className="col-md-12">
                                    <div className="um-btnformWrap">
                              <span className="customFileBtnWrap w-100 text-center">
                                <input
                                    id="father_report_details"
                                    type="file"
                                    multiple={true}
                                    accept="image/jpeg"
                                    onChange={(event) => {
                                      const files = event.target.files;
                                      let filesArray = [];
                                      let previewsArray = [];
                                      for (let i = 0; i < files.length; i++) {
                                        filesArray.push(files[i]);
                                        previewsArray.push(
                                            URL.createObjectURL(files[i])
                                        );
                                      }
                                      setFatherReportDetailsPreview(
                                          previewsArray
                                      );
                                      setFatherReportDetails(filesArray);
                                    }}
                                />
                                <label
                                    htmlFor="father_report_details"
                                    className="fileBtn btn-info text-uppercase"
                                >
                                  Upload Results{" "}
                                  <i className="umicomoonGroup-1095 btnIcnUpload" />
                                </label>
                              </span>
                                    </div>
                                  </div>
                                  {fatherReportDetailsPreview.map((report, i) => (
                                      <div className="col-12 mt-4" key={i}>
                                        <div className="position-relative mrdpEditorView">
                                          <img
                                              className="img-fluid"
                                              src={report}
                                              width="20%"
                                          />
                                          <button
                                              type="button"
                                              className="btn rounded-circle d-flex align-items-center justify-content-center btn-danger p-0 btnMrdpRemover position-absolute"
                                              onClick={() => removeImg(i, "father")}
                                          >
                                            X
                                          </button>
                                        </div>
                                      </div>
                                  ))}
                                </div>
                              </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-12">
                    <div className="row mb-6">
                      <div className="col-12 col-lg-4">
                        <h6 className="aaPtbHeading">Date of birth
                          <span className="field-required">* required for Publish</span></h6>
                        <div className="um-bddInputWrap">
                          <input
                            type="date"
                            className="form-control"
                            value={dob}
                            max={moment().format("YYYY-MM-DD")}
                            onChange={(e) => setDob(e.target.value)}
                          />
                          <span className="icon d-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25.299"
                              height="24.314"
                              viewBox="0 0 25.299 24.314"
                            >
                              <g
                                id="Group_1033"
                                data-name="Group 1033"
                                transform="translate(-401.777 -14772.647)"
                              >
                                <g id="Group_1032" data-name="Group 1032">
                                  <path
                                    id="Path_111911"
                                    data-name="Path 111911"
                                    d="M423.937,14774.536h-3.273v-.692a1.2,1.2,0,0,0-2.394,0v.692h-7.741v-.692a1.2,1.2,0,0,0-2.394,0v.692h-3.246a3.116,3.116,0,0,0-3.112,3.112v16.2a3.116,3.116,0,0,0,3.112,3.113h19.074a3.117,3.117,0,0,0,3.113-3.113v-16.2A3.139,3.139,0,0,0,423.937,14774.536Zm-19.048,2.394h3.272v.692a1.2,1.2,0,1,0,2.394,0v-.692H418.3v.692a1.2,1.2,0,1,0,2.394,0v-.692h3.272a.715.715,0,0,1,.718.719v3.166h-20.51v-3.166A.715.715,0,0,1,404.889,14776.93Zm19.048,17.639H404.889a.716.716,0,0,1-.718-.72v-10.667h20.51v10.667A.756.756,0,0,1,423.937,14794.568Z"
                                    fill="#a0ccec"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                        </div>
                        {errors?.dob && (
                            <small className="support-error">
                              {errors?.dob}
                            </small>
                        )}
                      </div>
                    </div>
                    <div className="row mb-6">
                      {defaultChipNumber == false && (
                        <div className="col-12 col-md-12">
                          <h6 className="aaPtbHeading">Mothers Microchip</h6>
                          <div className="form-group">
                            Do you have Mother Chip Number?
                            <label className="checkboxlabel">
                              Yes
                              <input
                                className="css3checkbox"
                                type="radio"
                                name="motherchipNumber"
                                value="yes"
                                onChange={(e) =>
                                  setShowMotherChip(e.target.value)
                                }
                                checked={showMotherChip === "yes"}
                              />
                            </label>
                            <label className="checkboxlabel">
                              No
                              <input
                                className="css3checkbox"
                                type="radio"
                                name="motherchipNumber"
                                value="no"
                                onChange={(e) =>
                                  setShowMotherChip(e.target.value)
                                }
                                checked={showMotherChip === "no"}
                              />
                            </label>
                          </div>
                        </div>
                      )}
                      {showMotherChip === "yes" && (
                        <div className="col-12 col-lg-4">
                          <div className="um-bddInputWrap">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Add Chip Number"
                              name="chipNumber"
                              value={motherChipNumber}
                              onChange={(e) =>
                                setMotherChipNumber(e.target.value)
                              }
                              readOnly={defaultChipNumber == true}
                            />
                            {errors?.chipNumber && (
                              <small className="support-error">
                                {errors?.chipNumber}
                              </small>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <h6 className="aaPtbHeading">Puppies</h6>
                    <p className="text-sm ">
                      <img
                        src={oO}
                        className="img-fluid"
                        alt="dogstrust"
                        style={{ height: "15px" }}
                      />{" "}
                      You must "Add a Puppy" for each individual pup in the
                      litter{" "}
                      <img
                        src={oO}
                        className="img-fluid"
                        alt="dogstrust"
                        style={{ height: "15px" }}
                      />
                    </p>

                    {puppies.map((pup, i) => {
                      return (
                        <div key={i}>
                          <div className="row">
                            <div className="col-md-12 col-lg-6">
                              <label>Puppy {i + 1}</label>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12 col-lg-4">
                              <div className="um-bddInputWrap">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Puppy Name"
                                  name="name"
                                  value={pup.name}
                                  onChange={(e) => handleChange(e, i)}
                                />
                                <div className="field-required">* required for Draft and Publish</div>
                                {errors.puppies && errors.puppies[i] && errors.puppies[i]['name'] && (
                                    <small className="support-error">
                                      {errors.puppies[i]['name']}
                                    </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                              <div className="um-bddInputWrap position-relative icnInputWrap">
                                <input
                                  type="number"
                                  className="form-control w-100"
                                  placeholder="Microchip No"
                                  name="chip_number"
                                  value={pup.chip_number}
                                  disabled={pup.pet_id}
                                  onChange={(e) => handleChange(e, i)}
                                />
                                {pup.pet_id && (
                                  <i
                                    className="far fa-edit icn"
                                    onClick={() => {
                                      setMicrochipModal({
                                        chip_number: "",
                                        pet_id: pup.pet_id,
                                        pupIndex: i,
                                      });
                                      setShowModal(true);
                                    }}
                                  />
                                )}
                                {errors.puppies && errors.puppies[i] && errors.puppies[i]['chip_number'] && (
                                    <small className="support-error">
                                      {errors.puppies[i]['chip_number']}
                                    </small>
                                )}

                              </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                              <div className="um-bddInputWrap">
                                <input
                                  type="number"
                                  className="form-control w-100"
                                  placeholder="Price"
                                  name="price"
                                  value={pup.price}
                                  onChange={(e) => {
                                    handleChange(e, i);
                                    priceChanged(e, i);
                                  }}
                                />
                                <div className="field-required">* required for Publish</div>
                                {errors.puppies && errors.puppies[i] && errors.puppies[i]['price'] && (
                                    <small className="support-error">
                                      {errors.puppies[i]['price']}
                                    </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                              <div className="um-bddInputWrap">
                                <input
                                  type="text"
                                  className="form-control w-100"
                                  placeholder="Puppy Colour"
                                  name="color"
                                  value={pup.color}
                                  onChange={(e) => handleChange(e, i)}
                                />
                                <div className="field-required">* required for Publish</div>
                                {errors.puppies && errors.puppies[i] && errors.puppies[i]['color'] && (
                                    <small className="support-error">
                                      {errors.puppies[i]['color']}
                                    </small>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                              <div className="um-bddInputWrap">
                                <select
                                  className="form-control"
                                  placeholder="Puppy Gender"
                                  name="gender"
                                  value={pup.gender}
                                  onChange={(e) => handleChange(e, i)}
                                >
                                  <option>Select a gender</option>
                                  <option value="male"> Male</option>
                                  <option value="female"> Female</option>
                                </select>
                                <div className="field-required">* required for Publish</div>
                                {errors.puppies && errors.puppies[i] && errors.puppies[i]['gender'] && (
                                    <small className="support-error">
                                      {errors.puppies[i]['gender']}
                                    </small>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row mb-6">
                            <div className="col-md-6">
                              <div className="mb-4">
                                <h6 className="aaPtbHeading">Photo</h6>
                                <div className="mb-2 position-relative mrdpEditorView">
                                {pup.photoPreview && (
                                  <>
                                    <img src={pup.photoPreview} width="20%" />
                                    <button
                                      className="position-absolute btnMrdpRemover btn-danger rounded-circle d-flex align-items-center justify-content-center p-0 btn"
                                      type="button"
                                      name="photoPreview"
                                      onClick={() =>
                                        removeImage(i, "photoPreview")
                                      }
                                    >
                                      X
                                    </button>
                                  </>
                                )}
                              </div>

                                <span
                                  className="customFileBtnWrap text-center btncert"
                                  style={{display: 'flex', flexDirection: 'column'}}
                                >
                                  <input
                                    id={`photo${i + 1}`}
                                    type="file"
                                    name="photo"
                                    onChange={(e) => handleImageChange(e, i)}
                                  />
                                  <label
                                    htmlFor={`photo${i + 1}`}
                                    className="mb-0 fileBtn btn-info text-uppercase shadow-sm"
                                  >
                                    UPLOAD PHOTO{" "}
                                    <i className="umicomoonGroup-1095 btnIcnUpload" />
                                  </label>
                                  <small className="text-left">Max size 12mb</small>
                                </span>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div>
                                <h6 className="aaPtbHeading">Vaccination Certificate</h6>

                                <div className="mb-2 position-relative mrdpEditorView">
                                {pup?.certificatesPreview?.map(
                                  (cp, imageIdx) => (
                                    <div
                                      key={imageIdx}
                                      className="position-relative mb-1"
                                    >
                                      <img src={cp} width="20%" />
                                      <button
                                        className="position-absolute btnMrdpRemover btn-danger rounded-circle d-flex align-items-center justify-content-center p-0 btn"
                                        type="button"
                                        name="certificatesPreview"
                                        onClick={() =>
                                          removeImage(
                                            i,
                                            "certificatesPreview",
                                            imageIdx
                                          )
                                        }
                                      >
                                        X
                                      </button>
                                    </div>
                                  )
                                )}
                              </div>

                                <span
                                  className="customFileBtnWrap text-center btncert"
                                  style={{display: 'flex', flexDirection: 'column'}}
                                >
                                  <input
                                    id={`certificates${i + 1}`}
                                    type="file"
                                    multiple={true}
                                    name="certificates"
                                    onChange={(e) => handleImageChange(e, i)}
                                  />
                                  <label
                                    htmlFor={`certificates${i + 1}`}
                                    className="mb-0 fileBtn btn-info text-uppercase shadow-sm"
                                  >
                                    UPLOAD CERTIFICATE{" "}
                                    <i className="umicomoonGroup-1095 btnIcnUpload" />
                                  </label>
                                  <small className="text-left">Max size 12mb</small>
                                </span>
                              </div>

                              {puppies?.length > 1 &&
                                pup.isFetched === false && (
                                  <button
                                    type="button"
                                    className="btn btn-danger shadow-sm rounded"
                                    onClick={() => removePuppy(i)}
                                  >
                                    Remove puppy
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="row mb-10">
                      <div className="col-md-12 col-lg-6">
                        <button
                          type="button"
                          onClick={() => addPuppy()}
                          className="btn btnFormWidth btnFormStyle bg-primary small grey shadow-sm"
                        >
                          Add a Puppy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    {serverErrors && serverErrors.length > 0 && (
                        <small className="support-error server-errors">
                          {serverErrors.map(error => <p>{error}</p>)}
                        </small>
                    )}
                    <div className="btn-box">
                      <button
                        className="nodesign shadow-sm rounded"
                        type="submit"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="206.839"
                          height="41.9"
                          viewBox="0 0 206.839 41.9"
                        >
                          <g
                            id="Group_1385"
                            data-name="Group 1385"
                            transform="translate(-495.812 -1338.86)"
                          >
                            <rect
                              id="Rectangle_536"
                              data-name="Rectangle 536"
                              width="206.839"
                              height="41.9"
                              rx="7"
                              transform="translate(495.812 1338.86)"
                              fill="#999"
                            />
                            <text
                              id="Save_progress"
                              data-name="Save progress"
                              transform="translate(514.534 1366.02)"
                              fill="#fff"
                              fontSize="17"
                              fontFamily="NunitoSans-Regular, Nunito Sans"
                            >
                              <tspan x="0" y="0">
                                Save as Draft
                              </tspan>
                            </text>
                            <g
                              id="Group_1132"
                              data-name="Group 1132"
                              transform="translate(-16.204 -10141.388)"
                            >
                              <g id="Group_1131" data-name="Group 1131">
                                <path
                                  id="Path_121340"
                                  data-name="Path 121340"
                                  d="M676.994,11496.692a5.186,5.186,0,0,1,1.774.208,8.3,8.3,0,0,1,8.034-6.156,8.514,8.514,0,0,1,8.139,6.156,4.334,4.334,0,0,1,1.669-.208,7.2,7.2,0,1,1,0,14.4H676.994a7.2,7.2,0,1,1,0-14.4Zm19.616,12.625a5.389,5.389,0,0,0,5.426-5.427,5.478,5.478,0,0,0-5.426-5.529,5.182,5.182,0,0,0-1.252.209,1.87,1.87,0,0,1-2.191-1.252,6.514,6.514,0,0,0-6.365-4.8,6.7,6.7,0,0,0-6.365,4.8,1.708,1.708,0,0,1-2.087,1.252,6.038,6.038,0,0,0-1.356-.209,5.478,5.478,0,0,0-5.426,5.529,5.389,5.389,0,0,0,5.426,5.427Z"
                                  fill="#fff"
                                  fillRule="evenodd"
                                />
                                <path
                                  id="Path_121341"
                                  data-name="Path 121341"
                                  d="M682.524,11503.369a.85.85,0,0,1,1.148-1.252l1.878,1.878,4.382-4.382a.887.887,0,0,1,1.252,1.252l-5.008,5.009a.951.951,0,0,1-1.252,0Z"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </button>
                      <button
                        className="nodesign shadow-sm rounded"
                        type="button"
                        onClick={finishProcess}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="157.421"
                          height="41.9"
                          viewBox="0 0 157.421 41.9"
                        >
                          <g
                            id="Group_1386"
                            data-name="Group 1386"
                            transform="translate(-722.624 -1338.86)"
                          >
                            <rect
                              id="Rectangle_535"
                              data-name="Rectangle 535"
                              width="157.421"
                              height="41.9"
                              rx="7"
                              transform="translate(722.624 1338.86)"
                              fill="#e4a039"
                            />
                            <text
                              id="Next_Step"
                              data-name="Next Step"
                              transform="translate(741.717 1367.017)"
                              fill="#fff"
                              fontSize="17"
                              fontFamily="NunitoSans-Regular, Nunito Sans"
                            >
                              <tspan x="0" y="0">
                                Save & Finish
                              </tspan>
                            </text>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={showModal}
        className="editInfoModal addChipModal generalPopupStyles"
        centered
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <h4 className="h4 text-center mb-8">Update Chip Number</h4>
          <form onSubmit={updateChipNumber}>
            <div className="row">
              <div className="col-12 col-sm-12">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Enter new chip number here..."
                    value={microchipModal.chip_number}
                    onChange={(e) =>
                      setMicrochipModal({
                        ...microchipModal,
                        chip_number: e.target.value,
                      })
                    }
                  />
                  {modalErrors.chip_number && (
                    <small className="support-error">
                      {modalErrors.chip_number}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="um-formBtnWrap text-center">
                <button
                  type="submit"
                  className="btn btnFormWidth btnFormStyle small btn-warning mx-auto"
                >
                  Save chip number
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StepThree;
