import PasswordValidator from 'password-validator';

export const toFormData = (obj, form, namespace) => {
    const fd = form || new FormData();
    let formKey;

    for (const property in obj) {
        if (typeof obj[property] === 'boolean') {
            obj[property] = obj[property].toString();
        }

        if (obj.hasOwnProperty(property) && obj[property]) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File, use recursivity.
            if (obj[property] instanceof Date) {
                fd.append(formKey, obj[property].toISOString());
            } else if (
                typeof obj[property] === 'object' &&
                !(obj[property] instanceof File)
            ) {
                toFormData(obj[property], fd, formKey);
            } else {
                // if it's a string or a File object
                fd.append(formKey, obj[property]);
            }
        }
    }

    return fd;
};

export function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}

export const getStars = (quantity) => {
    const round = Math.round(quantity);
    let stars = [];
    for (let i = 0; i < 5; i++) {
        if (round >= i + 1) {
            stars.push(true);
        } else {
            stars.push(false);
        }
    }
    return stars;
};

export const validatePassword = (pass) => {
    const passwordSchema = new PasswordValidator();
    passwordSchema
        .is().min(8)
        .has().lowercase()
        .has().uppercase()
        .has().digits(1);
    return {
        isValid: passwordSchema.validate(pass),
        message: 'Password do not match the requirements'
    };
};

export const capitalizeTheFirstLetterOfEachWord = (words) => {
    let separateWord = words?.toLowerCase()?.split(' ');
    for (let i = 0; i < separateWord?.length; i++) {
        separateWord[i] = separateWord[i]?.charAt(0)?.toUpperCase() + separateWord[i]?.substring(1);
    }
    return separateWord?.join(' ');
};

export const formatToPhone = (event) => {
    const target = event.target;

    if (isModifierKey(event)) {
        return;
    }

    const input = target.value.replace(/\D/g, "").substring(0, 17);
    const countryCode = input.substring(0, 2);
    const middle = input.substring(2, 6);
    const last = input.substring(6, 17);

    if (input.length > 6) {
        target.value = `+${countryCode} ${middle} ${last}`;
    } else if (input.length > 2) {
        target.value = `+${countryCode} ${middle}`;
    } else if (input.length > 0) {
        target.value = `+${countryCode}`;
    }
};

export const isModifierKey = (event) => {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 ||
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 ||
      (key > 36 && key < 41) ||
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
};

export const isNumericInput = (event) => {
    const key = event.keyCode;
    return (key >= 48 && key <= 57) || (key >= 96 && key <= 105);
};

export const enforceFormat = (event) => {
    if (!isNumericInput(event) && !isModifierKey(event)) {
        event.preventDefault();
    }
};

export const lowerCaseHyphenate = (string) => {
    let hyphenatedString = string.toLowerCase().replace(/\s+/g, '-');

    return hyphenatedString.replace(/-$/, '');
}