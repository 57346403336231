import React from 'react';
import VetConnectedBreeder from './VetConnectedBreeder';
import SubVetConnectedBreeder from './SubVetConnectedBreeder';
import {Navigate} from "react-router-dom";

const ConnectBreeder = () => {

    const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

    if (userRole === 'veterinarian') {
        return (
            <VetConnectedBreeder/>
        );
    } else if (userRole === 'sub_veterinarian') {
        return (
            <SubVetConnectedBreeder/>
        );
    }

    return <Navigate to='/' />
};

export default ConnectBreeder;