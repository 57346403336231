import React, { useState, useEffect } from 'react';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { postRequest, getRequest } from '../../../../utils/requests';
import { API_ENDPOINT_URL, APP_PREFIX } from '../../../../utils/appConstants';
import { toast } from 'react-toastify';
import { useSearchParams, useNavigate } from 'react-router-dom';
import groupCitiesByCountry from "../../../../utils/groupCitiesByCountry";

const CreateBuyerPetAlerts = () => {
    const [alertName, setAlertName] = useState('');
    const [county, setCounty] = useState('');
    const [fetchedCities, setFetchedCities] = useState({});
    const [fetchSizes, setFetchSizes] = useState([]);
    const [fetchSpaces, setFetchSpaces] = useState([]);
    const [breeds, setBreeds] = useState([]);
    const [breedId, setBreedId] = useState('');
    const [size, setSize] = useState('');
    const [livingSpace, setLivingSpace] = useState('');
    const [errors, setErrors] = useState(false);

    const [updateAlert, setUpdateAlert] = useState(false);
    const [search, setSearch] = useSearchParams();

    const [alertId, setAlertId] = useState('');

    const [showLoader, setShowLoader] = useState(false);

    const [selectedOption, setSelectedOption] = useState('');
    const [optionBreed, SetBreedShow] = useState(false);
    const [optionSize, SetSizeShow] = useState(false);
    const [optionSpace, SetSpaceShow] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {

        getRequest(`${API_ENDPOINT_URL}/basic-listing/cities`).then((res) => {
            setFetchedCities(groupCitiesByCountry(res?.data?.data));
        });

        getRequest(`${API_ENDPOINT_URL}/basic-listing/breeds`, true).then((res) => {
            setBreeds(res.data.data);
        });

        getRequest(`${API_ENDPOINT_URL}/basic-listing/breed-capabilities?type=size`, true).then((res) => {
            setFetchSizes(res.data.data);
        });

        getRequest(`${API_ENDPOINT_URL}/basic-listing/breed-capabilities?type=living_space`, true).then((res) => {
            setFetchSpaces(res.data.data);
        });

        const id = search.get('alert_id');
        setAlertId(id);
        if (id) {
            setUpdateAlert(true);

            getRequest(`${API_ENDPOINT_URL}/buyer/alert/edit/${id}`, true).then((res) => {
                if (res) {
                    setAlertName(res.data.data.alert_name);
                    if (res.data.data.breed_id) {
                        setSelectedOption('breed');
                        SetBreedShow(true);
                        setBreedId(res.data.data.breed_id);
                    }
                    if (res.data.data.size) {
                        setSelectedOption('size');
                        SetSizeShow(true);
                        setSize(res.data.data.size);
                    }
                    if (res.data.data.living_space) {
                        setSelectedOption('space');
                        SetSpaceShow(true);
                        setLivingSpace(res.data.data.living_space);
                    }
                    setCounty(res.data.data.city_id);
                }
            });
        }

    }, []);

    const validateUpdateInfo = () => {
        let errors = {};
        let isValid = true;

        if (!alertName) {
            isValid = false;
            errors['alertName'] = 'This filed is required.';
        }
        // if (!county) {
        //     isValid = false;
        //     errors['county'] = 'This filed is required.';
        // }
        if (!selectedOption || selectedOption === 'option') {
            isValid = false;
            errors['selectedOption'] = 'Please select a option.';
        }
        if (!breedId && optionBreed) {
            isValid = false;
            errors['breedId'] = 'Please select a breed.';
        }
        if (!size && optionSize) {
            isValid = false;
            errors['size'] = 'Please select a breed size.';
        }
        if (!livingSpace && optionSpace) {
            isValid = false;
            errors['livingSpace'] = 'Please select a living space.';
        }
        setErrors(errors);
        return isValid;
    };

    //Send the Info data on form submission
    const createAlert = (event) => {
        event.preventDefault();
        if (validateUpdateInfo()) {

            let requestBody = {
                alert_name: alertName
            };

            if (county !== '') {
                requestBody.city_id = county;
            }
            if (breedId !== '') {
                requestBody.breed_id = breedId;
            }
            if (size !== '') {
                requestBody.size = size;
            }
            if (livingSpace !== '') {
                requestBody.living_space = livingSpace;
            }

            postRequest(`${API_ENDPOINT_URL}/buyer/alert/store`, requestBody, true).then((res) => {
                if (res) {
                    toast.success('Alert created successfully');
                    navigate(`${APP_PREFIX}/pet-alerts`);
                }
            });
        }
    };

    //Send the Info data on form submission
    const updateAlertInfo = (event) => {
        event.preventDefault();
        if (validateUpdateInfo()) {

            // params object
            const params = {
                alert_id: alertId,
                alert_name: alertName
            };

            if (county !== '') {
                params.city_id = county;
            }
            if (breedId !== '') {
                params.breed_id = breedId;
            }
            if (size !== '') {
                params.size = size;
            }
            if (livingSpace !== '') {
                params.living_space = livingSpace;
            }

            postRequest(`${API_ENDPOINT_URL}/buyer/alert/update`, null, true, params).then((res) => {
                if (res) {
                    toast.success('Alert updated successfully');
                    navigate(`${APP_PREFIX}/pet-alerts`);
                }
            });
        }
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);

        if (event.target.value === 'option') {
            SetBreedShow(false);
            SetSizeShow(false);
            SetSpaceShow(false);
            setBreedId('');
            setSize('');
            setLivingSpace('');

        } else if (event.target.value === 'breed') {
            SetBreedShow(true);
            SetSizeShow(false);
            SetSpaceShow(false);
            setSize('');
            setLivingSpace('');

        } else if (event.target.value === 'size') {
            SetSizeShow(true);
            SetSpaceShow(false);
            SetBreedShow(false);
            setBreedId('');
            setLivingSpace('');

        } else if (event.target.value === 'space') {
            SetSpaceShow(true);
            SetSizeShow(false);
            SetBreedShow(false);
            setBreedId('');
            setSize('');
        }
    };

    return (
        <>
            <div className="aaContentWidget buyerDashboardView">
                <header className="aaPtbdHeader">
                    {updateAlert ?
                        <h1 className="h3 aaPtbHeading"> Edit Pet Alert</h1>
                        :
                        <h1 className="h3 aaPtbHeading"> Create Pet Alert</h1>
                    }
                </header>
                <div className="aaDbRow aaDbInfoRow mb-8">
                    {updateAlert ?
                        <form className="um-bddFormWrap um-chrtFormInf" onSubmit={(event) => updateAlertInfo(event)}>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="um-bddInputWrap">
                                        <label>Alert Name</label>
                                        <input
                                            type="text"
                                            className={`form-control  ${errors?.alertName && 'fcError'}`}
                                            value={alertName}
                                            onChange={(e) => setAlertName(e.target.value)}
                                        />
                                        {errors.alertName && (
                                            <small className="support-error">
                                                {errors.alertName}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="um-bddInputWrap">
                                        <label>County</label>
                                        <select className="form-control w-100"
                                                value={county}
                                                onChange={(e) => setCounty(e.target.value)}
                                        >
                                            <option value="">Search all counties&hellip; </option>
                                                {Object.keys(fetchedCities).map(country => (
                                                    <optgroup label={country.toUpperCase()} key={country}>
                                                        {fetchedCities[country].map(city => (
                                                            <option key={city.id} value={city.id}>
                                                                {city.name}
                                                            </option>
                                                        ))}
                                                    </optgroup>
                                                ))}
                                        </select>
                                        {errors.county && (
                                            <small className="support-error">
                                                {errors.county}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <h3 className="h6 createpetHeding pt-7 pb-3">Refine Pet Alerts by selecting either Breed,
                                Size, and Living Space</h3>
                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="um-bddInputWrap">
                                        <select className="form-control"
                                                value={selectedOption}
                                                onChange={handleSelectChange}
                                        >
                                            <option value="option">Select an option&hellip;</option>
                                            <option value="breed">Breed</option>
                                            <option value="size">Size</option>
                                            <option value="space">Living Space</option>
                                        </select>
                                        {errors.selectedOption && (
                                            <small className="support-error">
                                                {errors.selectedOption}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {optionBreed ?
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <select className="form-control"
                                                    value={breedId}
                                                    onChange={(e) => setBreedId(e.target.value)}
                                            >
                                                <option>Select a breed</option>
                                                {
                                                    breeds.map((breed) => (
                                                        <option key={breed.id} value={breed.id}>{breed.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.breedId && (
                                                <small className="support-error">
                                                    {errors.breedId}
                                                </small>
                                            )}
                                        </div>
                                    </div> : ''
                                }
                                {optionSize ?
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <select
                                                className="form-control w-100"
                                                value={size}
                                                onChange={(e) => setSize(e.target.value)}
                                            >
                                                <option defaultValue>Select a breed size&hellip;</option>
                                                {
                                                    fetchSizes.map(size => (
                                                        <option key={size.val}
                                                                value={size.val}>{size.description}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.size && (
                                                <small className="support-error">
                                                    {errors.size}
                                                </small>
                                            )}
                                        </div>
                                    </div> : ''
                                }
                                {optionSpace ?
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <select
                                                className="form-control w-100"
                                                value={livingSpace}
                                                onChange={(e) => setLivingSpace(e.target.value)}
                                            >
                                                <option defaultValue>Select a living space&hellip;</option>
                                                {
                                                    fetchSpaces.map(space => (
                                                        <option key={space.val}
                                                                value={space.val}>{space.description}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.livingSpace && (
                                                <small className="support-error">
                                                    {errors.livingSpace}
                                                </small>
                                            )}
                                        </div>
                                    </div> : ''
                                }
                                <div className="col-12">
                                    <div className="um-formBtnWrap mt-5">
                                        <button
                                            type="submit"
                                            className="btn btn-warning btnFormStyle btnFormWidth"
                                            disabled={showLoader}
                                        >
                                            {showLoader && (
                                                <HalfCircleSpinner
                                                    size={18}
                                                    style={{
                                                        display: 'inline-block',
                                                        marginRight: '5px'
                                                    }}
                                                />
                                            )}
                                            Update Alert
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        :
                        <form className="um-bddFormWrap um-chrtFormInf" onSubmit={(event) => createAlert(event)}>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="um-bddInputWrap">
                                        <label>Alert Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={alertName}
                                            onChange={(e) => setAlertName(e.target.value)}
                                        />
                                        {errors.alertName && (
                                            <small className="support-error">
                                                {errors.alertName}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="um-bddInputWrap">
                                        <label>County</label>
                                        <select className="form-control w-100"
                                                value={county}
                                                onChange={(e) => setCounty(e.target.value)}
                                        >
                                            <option value="">Search all counties&hellip; </option>
                                            {Object.keys(fetchedCities).map(country => (
                                                <optgroup label={country.toUpperCase()} key={country}>
                                                    {fetchedCities[country].map(city => (
                                                        <option key={city.id} value={city.id}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </optgroup>
                                            ))}
                                        </select>
                                        {errors.county && (
                                            <small className="support-error">
                                                {errors.county}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <h3 className="h6 createpetHeding pt-7 pb-3">Refine Pet Alerts by selecting either Breed,
                                Size, and Living Space</h3>
                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="um-bddInputWrap">
                                        <select className="form-control"
                                                value={selectedOption}
                                                onChange={handleSelectChange}
                                        >
                                            <option value="option">Select an option&hellip;</option>
                                            <option value="breed">Breed</option>
                                            <option value="size">Size</option>
                                            <option value="space">Living Space</option>
                                        </select>
                                        {errors.selectedOption && (
                                            <small className="support-error">
                                                {errors.selectedOption}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {optionBreed ?
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <select className="form-control"
                                                    value={breedId}
                                                    onChange={(e) => setBreedId(e.target.value)}
                                            >
                                                <option>Select a breed</option>
                                                {
                                                    breeds.map((breed) => (
                                                        <option key={breed.id} value={breed.id}>{breed.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.breedId && (
                                                <small className="support-error">
                                                    {errors.breedId}
                                                </small>
                                            )}
                                        </div>
                                    </div> : ''
                                }
                                {optionSize ?
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <select
                                                className="form-control w-100"
                                                value={size}
                                                onChange={(e) => setSize(e.target.value)}
                                            >
                                                <option defaultValue>Select a breed size&hellip;</option>
                                                {
                                                    fetchSizes.map(size => (
                                                        <option key={size.val}
                                                                value={size.val}>{size.description}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.size && (
                                                <small className="support-error">
                                                    {errors.size}
                                                </small>
                                            )}
                                        </div>
                                    </div> : ''
                                }
                                {optionSpace ?
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <select
                                                className="form-control w-100"
                                                value={livingSpace}
                                                onChange={(e) => setLivingSpace(e.target.value)}
                                            >
                                                <option defaultValue>Select a living space&hellip;</option>
                                                {
                                                    fetchSpaces.map(space => (
                                                        <option key={space.val}
                                                                value={space.val}>{space.description}</option>
                                                    ))
                                                }
                                            </select>
                                            {errors.livingSpace && (
                                                <small className="support-error">
                                                    {errors.livingSpace}
                                                </small>
                                            )}
                                        </div>
                                    </div> : ''
                                }
                                <div className="col-12">
                                    <div className="um-formBtnWrap mt-5">
                                        <button
                                            type="submit"
                                            className="btn btn-warning btnFormStyle btnFormWidth"
                                            disabled={showLoader}
                                        >
                                            {showLoader && (
                                                <HalfCircleSpinner
                                                    size={18}
                                                    style={{
                                                        display: 'inline-block',
                                                        marginRight: '5px'
                                                    }}
                                                />
                                            )}
                                            Create Alert
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </>
    );

};

export default CreateBuyerPetAlerts;
