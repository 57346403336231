import React, { useState, useEffect } from 'react';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { getRequest, postRequest } from '../../../../utils/requests';
import { API_ENDPOINT_URL } from '../../../../utils/appConstants';
import { toast } from 'react-toastify';
import {ProfileMapComponent} from "../../../../components/shared/map/profile_map.component";
import {enforceFormat, formatToPhone} from "../../../../utils";
import groupCitiesByCountry from "../../../../utils/groupCitiesByCountry";

const VetProfile = (props) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('+44');
    const [email, setEmail] = useState('');
    const [rcvsNumber, setRcvsNumber] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [address, setAddress] = useState('');
    const [county, setCounty] = useState('');
    const [practiceName, setPracticeName] = useState('');
    const [fb, setFb] = useState('');
    const [insta, setInsta] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [fetchedCities, setFetchedCities] = useState({});
    const [errors, setErrors] = useState(false);
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        getRequest(`${API_ENDPOINT_URL}/basic-listing/cities`).then((res) => {
            setFetchedCities(groupCitiesByCountry(res?.data?.data));
        });
        getRequest(`${API_ENDPOINT_URL}/veterinarian/profile/view`, true).then((res) => {
            if (res) {
                setProfileData(res.data.data);
                setFirstName(res.data.data.first_name);
                setLastName(res.data.data.last_name);
                setMobile(res.data.data.phone_number);
                setEmail(res.data.data.email);
                setRcvsNumber(res.data.data.detail.rcvs_number);
                setPostalCode(res.data.data.postal_code);
                setAddress(res.data.data.address);
                setCounty(res.data.data.city_id);
                if (res.data.data.detail.practice_name) {
                    setPracticeName(res.data.data.detail.practice_name);
                }
                if (res.data.data.detail.fb_url) {
                    setFb(res.data.data.detail.fb_url);
                }
                if (res.data.data.detail.insta_url) {
                    setInsta(res.data.data.detail.insta_url);
                }
            }
        });
    }, []);

    const validateUpdateInfo = () => {
        let errors = {};
        let isValid = true;

        if (!firstName) {
            isValid = false;
            errors['firstName'] = 'This filed is required.';
        }
        if (!lastName) {
            isValid = false;
            errors['lastName'] = 'This filed is required.';
        }
        if (!mobile) {
            isValid = false;
            errors['mobile'] = 'This filed is required.';
        }
        if (mobile) {
            let res = mobile.replace(/\D/g, '');
            if (res.length < 10) {
                isValid = false;
                errors['mobile'] = 'Please enter a Valid Phone number.';
            }
        }
        if (!rcvsNumber) {
            isValid = false;
            errors['rcvsNumber'] = 'This filed is required.';
        }
        if (!address) {
            isValid = false;
            errors['address'] = 'This filed is required.';
        }
        if (!postalCode) {
            isValid = false;
            errors['postalCode'] = 'This filed is required.';
        }
        if (!county) {
            isValid = false;
            errors['county'] = 'This filed is required.';
        }
        setErrors(errors);
        return isValid;
    };

    //Send the Info data on form submission
    const updateInfo = async (event) => {
        event.preventDefault();
        if (validateUpdateInfo()) {
            try {
                const geocoder = new window.google.maps.Geocoder();
                const response = await geocoder.geocode({'address': address});

                const unFormattedPhone = mobile
                  .replaceAll('(', '')
                  .replaceAll(')', '')
                  .replaceAll('-', '')
                  .replaceAll(' ', '');

                const requestBody = {
                    first_name: firstName,
                    last_name: lastName,
                    phone_number: unFormattedPhone,
                    postal_code: postalCode,
                    city_id: county,
                    address,
                    rcvs_number: rcvsNumber,
                    lat: response.results[0].geometry.location.lat(),
                    long: response.results[0].geometry.location.lng(),
                };

                if (practiceName !== '') {
                    requestBody.practice_name = practiceName;
                }
                if (fb !== '') {
                    requestBody.fb_url = fb;
                }
                if (insta !== '') {
                    requestBody.insta_url = insta;
                }

                postRequest(`${API_ENDPOINT_URL}/veterinarian/profile/update`, requestBody, true).then((res) => {
                    if (res) {
                        toast.success('Profile updated successfully');
                        window.location.reload();
                    }
                });
            } catch (err) {
                console.log(err);
                toast.error("Unable to validate address.");
            }
        }
    };

    return (
        <>
            <div className="aaContentWidget">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Profile Information</h1>
                </header>
                <div className="aaDbRow aaDbInfoRow">
                    <h6 className="h6 aaRwTitle fontNoto">Veterinarian practice profile</h6>
                    <form className="um-bddFormWrap um-chrtFormInf" onSubmit={(event) => updateInfo(event)}>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="um-bddInputWrap">
                                    <label>Vet Practice Name</label>
                                    <input
                                        type="text"
                                        id="practice_name"
                                        className="form-control"
                                        value={practiceName}
                                        onChange={(e) => setPracticeName(e.target.value)}
                                    />
                                </div>
                                <div className="um-bddInputWrap">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        id="first_name"
                                        className="form-control"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    {errors.firstName && (
                                        <small className="support-error">
                                            {errors.firstName}
                                        </small>
                                    )}
                                </div>
                                <div className="um-bddInputWrap">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        id="last_name"
                                        className="form-control"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                    {errors.lastName && (
                                        <small className="support-error">
                                            {errors.lastName}
                                        </small>
                                    )}
                                </div>
                                <div className="um-bddInputWrap">
                                    <label>Mobile</label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        className="form-control"
                                        maxLength={20}
                                        onKeyUp={formatToPhone}
                                        onKeyDown={enforceFormat}
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                    />
                                    {errors.mobile && (
                                        <small className="support-error">
                                            {errors.mobile}
                                        </small>
                                    )}
                                </div>
                                <div className="um-bddInputWrap">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        value={email}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="um-bddInputWrap">
                                    <label>Principal Veterinarian RCVS number</label>
                                    <input
                                        type="text"
                                        id="charityNumber"
                                        className="form-control"
                                        value={rcvsNumber}
                                        onChange={(e) => setRcvsNumber(e.target.value)}
                                    />
                                    {errors.rcvsNumber && (
                                        <small className="support-error">
                                            {errors.rcvsNumber}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="um-bddInputWrap">
                                    <label>Post Code</label>
                                    <input
                                        type="text"
                                        id="postalCode"
                                        className="form-control"
                                        value={postalCode}
                                        onChange={(e) => setPostalCode(e.target.value)}
                                    />
                                    {errors.postalCode && (
                                        <small className="support-error">
                                            {errors.postalCode}
                                        </small>
                                    )}
                                </div>
                                <div className="um-bddInputWrap">
                                    <label>Address 1</label>
                                    <input
                                        type="text"
                                        id="address"
                                        className="form-control"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                    {errors.address && (
                                        <small className="support-error">
                                            {errors.address}
                                        </small>
                                    )}
                                </div>
                                <div className="um-bddFormSlct um-slctEtcDesg">
                                    <label>County</label>
                                    <select className="form-control w-100"
                                            value={county}
                                            onChange={(e) => setCounty(e.target.value)}
                                    >
                                        <option>Select a County</option>
                                        {Object.keys(fetchedCities).map(country => (
                                            <optgroup label={country.toUpperCase()} key={country}>
                                                {fetchedCities[country].map(city => (
                                                    <option key={city.id} value={city.id}>
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </select>
                                    {errors.county && (
                                        <small className="support-error">
                                            {errors.county}
                                        </small>
                                    )}
                                </div>
                                <div className="um-bddInputWrap">
                                    <label>Facebook Profile URL</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        value={fb}
                                        onChange={(e) => setFb(e.target.value)}
                                    />
                                </div>
                                <div className="um-bddInputWrap">
                                    <label>Instagram Profile URL</label>
                                    <input
                                        type="url"
                                        className="form-control"
                                        value={insta}
                                        onChange={(e) => setInsta(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="um-formBtnWrap pt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-warning btnFormStyle btnFormWidth"
                                        disabled={showLoader}
                                    >
                                        {showLoader && (
                                            <HalfCircleSpinner
                                                size={18}
                                                style={{
                                                    display: 'inline-block',
                                                    marginRight: '5px'
                                                }}
                                            />
                                        )}
                                        Update Profile
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {profileData && <ProfileMapComponent profileData={profileData} setProfileData={setProfileData}></ProfileMapComponent>}
            </div>
        </>
    );

};

export default VetProfile;
