import React from 'react';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet-async';
import  {ReactComponent as SpeechMarkIcon}  from '../../assets/icons/speech-marks.svg';
import  {ReactComponent as CircleArrowIcon}  from '../../assets/icons/circle-arrow-icon.svg';
import {Link} from "react-router-dom";

const AboutUs = () => {

    const slider = React.useRef(null);

    const testimonials = [
        {
            'quote': 'Thank you so much Tim and PetBond for helping us find our beautiful cocker spaniel ‘Frankie’. The reassurance of knowing that our boy was sourced responsibly and from a reliable breeder made the decision to go with PetBond easy. Tim was so pleasant to deal with, and we highly recommend PetBond to anyone wishing to meet the puppy of their dreams.',
            'author': "Conal & Alison (First-time puppy parents)"
        },
        {
            'quote': 'We were delighted to have a large litter of 11 Golden Retriever babies and with a lot of thought we decided to only advertise with PetBond. It was the best decision we ever made as all our puppies sold to great homes with the help of the PetBond team. We had a terrific response from families looking for a new furry baby and this is the only way we will do this going forward.',
            'author': "Rachel & John (breeders/sellers)"
        },
        {
            'quote': 'Nisha has transformed my life. I\'m waking everyday and I\'ve located terrific pet friendly cafes where I now meet other people. You gave me the confidence to buy her, as I had dithered for so long, afraid that I would be entrapped by a puppy farmer. In short, all is good and I\'m ever so grateful to the PetBond team, thank you once again.',
            'author': "Alice"
        },
        {
            'quote': 'We had the most amazing experience while purchasing a puppy through PetBond. The breeder\'s professional manner and attention to detail was immense. The experts at PetBond were always there to support us, and if anyone is thinking of buying a new addition to the family we would highly recommend this route.',
            'author': "Ger & Joyce"
        },
    ];

    const settings = {
        arrows: false,
        speed: 500,
        autoplaySpeed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: 'unslick'
            }
        ]
    };

    return (
        <>
            <Helmet>
                <title>About PetBond | Dogs & Puppies | Approved Breeders | Rescue Pets</title>
                <meta name="description"
                      content="Whether it's a puppy from an approved dog breeder or a rescue pet, we are UK’s most safe & trusted online platform where anyone can source a happy & healthy pet."/>
            </Helmet>
            <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
                <div className="container">
                    <div className="arextraWrap">
                        <h2 className="h1">
                            from good homes… <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes
                        </h2>
                    </div>
                    <div className="arbhImgHolder d-none d-md-block position-absolute">
                        <img src="/images/img07.png" className="img-fluid" alt="petbond" />
                    </div>
                </div>
                <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
                  <i className="aricomoon-hearts">
                    <span className="sr-only">icon</span>
                  </i>
                </span>
                        <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
                  <i className="aricomoon-starDots">
                    <span className="sr-only">icon</span>
                  </i>
                </span>
            </header>

            <div className="container">
                <article>
                    <h3>About PetBond</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <p>Our mission is simple yet profound—to connect ethical dog breeders and individuals or families who share our unwavering commitment to the wellbeing of dogs. At PetBond, every puppy is a promise of lifelong joy, guaranteed to be healthy, vaccinated, and well-socialised.</p>

                            <p>PetBond has <strong>helped match over 500 puppies</strong> with new homes, all with the support of ethical breeders and sellers, vets and veterinary nurses, behaviourists, trainers, and academics.
                            </p>

                            <p>We only work with safe, ethical, and trusted breeders whom we have certified as PetBond approved breeders. By choosing PetBond, you are not just choosing a dog; you are embracing a philosophy that values the lifetime care and happiness of your new furry family member.
                            </p>

                            <p>For new pet owners, we don’t expect you to know everything, which is why we take you by the hand to ensure that you <Link to={`/petbond-match`}>find the right puppy for you</Link>. And you’ll have the support of the <Link to={`/find-a-vet`}>veterinary nurses and surgeons</Link> across every village, town, and city in the UK.</p>
                        </div>
                        <div className="col-md-6">
                            <img src="/images/about-us.jpg" className="w-100 pb-6" alt="Dr Tim Kirby"/>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <h4>Founded by Pet Experts</h4>

                            <p>PetBond was founded in 2020 by <strong>Dr. Tim Kirby</strong>, MVBGpCert(EqP)MRCVS. Dr. Kirby has over 20 years of practical clinical experience with all species of dogs, has served as a trustee for the <a href="https://www.uspca.co.uk/" target="_blank">Ulster Society Prevention for Cruelty to Animals</a> (USPCA) and is a sitting trustee for the <a href="https://www.thedonkeysanctuary.org.uk/" target="_blank">Donkey Sanctuary</a>.
                            </p>
                            <p>He is member of the <a href="https://wsava.org/associations/vicas/" target="_blank">Veterinary Ireland Companion Animal Committee</a> and is a national representative on the <a href="https://www.icecdogs.com/home/news" target="_blank">International Collaboration for Extreme Conformation in Dogs</a> (ICEC). He previously was an expert consultant to the <a href="https://www.ikc.ie/" target="_blank">Irish Kennel Club</a> and is a member of the <a href="https://www.fecava.org/" target="_blank">Federation of European Companion Animal Veterinary Associations</a> (FECAVA).</p>
                            <p>In 2019, Tim was nominated by <a href="https://www.socialentrepreneurs.ie/" target="_blank">Social Entrepreneurs Ireland</a> as one of the Top 15 Start Up Entrepreneurs from 230 nominees. In 2020, PetBond received the prestigious <a href="https://aibf.ie/" target="_blank">All Ireland Business Foundation</a> (AIBF) winners award for the best pet care service, and in 2021 and 2022 received the <a href="https://www.lux-review.com/lux_awards/pet-products-and-services-awards/" target="_blank">LUXlife award</a> as the leading digital platform for adopting and rescuing pets.
                            </p>
                            <p>Growing up as a young boy always surrounded by animals, Dr. Kirby always wanted to become a vet. PetBond started out as an idea sketched out on his bedroom wall in Belfast, and it has quickly become one of the most trusted and in-demand professional services matching ethical caring breeders with responsible new puppy parents in the UK.
                            </p>

                            <h4 className="pt-4">From Life-Saving Surgery to a Revolutionary Pet Adoption Platform</h4>
                            <p>Tim was called to emergency surgery one night to see a critically-ill 9-week-old puppy named “Sparky.” His parents Marie and Conor bought Sparky from a classified ad earlier that day, and now he was collapsed and fighting for his life. After 3 days of critical and intensive care, Dr. Kirby miraculously saved Sparky’s life.
                            </p>
                            <p>Tim had seen too many cases of puppies being sold with health issues and behavioural problems with a complete lack of breeder accountability. Classified ads and similar websites are often used by puppy mills and backyard breeders who prioritise profit over the health and well-being of the animals.</p>
                            <p>It was at that point that Tim promised to create a better world than classified ads websites for pets, and the idea of PetBond was born.</p>
                        </div>
                    </div>
                </article>
            </div>

            <div className="container testimonials my-6">
                <h3 className="testimonials__heading">What people say<br/>about PetBond?</h3>

                <Slider ref={slider} className="testimonials__slider" {...settings}>
                    {
                        testimonials && testimonials.map((data, index) => (
                            <div className="testimonials__slide" key={index}>
                                <div className="testimonial">
                                    <SpeechMarkIcon/>
                                    <p className="testimonial__quote">{data.quote}</p>
                                    <span className="testimonial__author">{data.author}</span>
                                </div>
                            </div>
                        ))
                    }
                </Slider>

                <div className="testimonials__slider__navs d-none d-md-flex">
                    <button title="Previous" className="testimonials__slider__nav" onClick={() => slider?.current?.slickPrev()}><CircleArrowIcon/></button>
                    <button title="Next" className="testimonials__slider__nav testimonials__slider__nav--next" onClick={() => slider?.current?.slickNext()}><CircleArrowIcon/></button>
                </div>
            </div>

            <div className="container about-us__quote mt-6">
                <div className="about-us__quote__content">
                    <span>
                        As experts in pet health, care and welfare, everything we do sets you and your pet on the path to success for life. Every person is different, every pet is unique, and PetBond is where it happens.
                    </span>
                    <span>Tim Kirby</span>
                </div>

                <div className="about-us__quote__img">
                    <img alt="Dr Tim Kirby" src="/images/about-us-tim-kirby.png"/>
                </div>
            </div>
        </>
    );

};

export default AboutUs;
