import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';
import { GOOGLE_ANALYTICS_TRACKING_ID } from '../../../utils/appConstants';
import ReactGA from 'react-ga';
import Loader from '../Loader';
import trackerService from '../../../services/tracker.service'

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

const Layout = ({ children }) => {

    const location = useLocation();
    const [isPublic, setIsPublic] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    useEffect(() => {
        if (location.pathname.includes('/app') || location.pathname.includes('/auth')) {
            setIsPublic(false);
        } else {
            setIsPublic(true);
        }
        setIsLoggedIn(!!localStorage.getItem('access_token'));
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        trackerService.pageview(window.location.pathname + window.location.search)
    }, [location.pathname]);

    return (
        <div id="pageWrapper">
            <Loader/>
            <Header isLoggedIn={isLoggedIn}/>
            <main>
                {
                    !isPublic &&
                    <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
                        <div className="container">
                            <div className="arextraWrap">
                                <h2 className="h1">from good homes… <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good
                                    homes</h2>
                            </div>
                            <div className="arbhImgHolder d-none d-md-block position-absolute">
                                <img src="/images/img07.png"
                                     className="img-fluid" alt="petbond"/>
                            </div>
                        </div>
                        <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
							<i className="aricomoon-hearts"><span className="sr-only">icon</span></i>
						</span>
                        <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
							<i className="aricomoon-starDots"><span className="sr-only">icon</span></i>
						</span>
                    </header>
                }
                {children}
            </main>
            <Footer/>
        </div>
    );
};

export default Layout;
