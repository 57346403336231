import React, { useEffect, useState } from 'react';
import { API_ENDPOINT_URL } from '../../../../utils/appConstants';
import { getRequest, postRequest } from '../../../../utils/requests';
import { toast } from 'react-toastify';
import { toFormData } from '../../../../utils';

const ViewAdvert = ({setShowViewAdvert, setShowPuppyList, selectedAdvert, selectedPuppy}) => {

    const [verificationId, setVerificationId] = useState('');
    const [chipNumber, setChipNumber] = useState('');
    const [selectedSubVet, setSelectedSubVet] = useState('');
    const [note, setNote] = useState('');
    const [certificates, setCertificates] = useState([]);
    const [certificatesPreview, setCertificatesPreview] = useState([]);
    const [deleteCertificates, setDeleteCertificates] = useState([]);
    const [fetchedSubVets, setFetchedSubVets] = useState([]);
    const [physicalDefectCheck, setPhysicalDefectCheck] = useState('no');
    const [physicalDefect, setPhysicalDefect] = useState('');
    const [significanceCheck, setSignificanceCheck] = useState('no');
    const [significance, setSignificance] = useState('');
    const [certifyCheck, setCertifyCheck] = useState(false);
    const [errors, setErrors] = useState(false);
    const user = JSON.parse(localStorage.getItem('currentUser'));

    useEffect(() => {
        getRequest(`${API_ENDPOINT_URL}/veterinarian/sub-veterinarian/list`, true).then((res) => {
            if (res) {
                setFetchedSubVets(res.data.data);
            }
        });
        if (selectedPuppy?.vet_pet_verification) {
            const data = selectedPuppy?.vet_pet_verification;
            setVerificationId(data.id);
            setChipNumber(data.chip_number);
            setSelectedSubVet(data.user_id);
            setNote(data?.note?.note);
            if (data?.note?.physical_defect) {
                setPhysicalDefectCheck('yes');
                setPhysicalDefect(data?.note?.physical_defect);
            }
            if (data?.note?.significance) {
                setPhysicalDefectCheck('yes');
                setSignificanceCheck('yes');
                setSignificance(data?.note?.significance);
            }
            data.certificate_details.forEach(c => {
                certificatesPreview.push(`${API_ENDPOINT_URL}/image-retrieve/${c}`);
            });
        } else {
            setChipNumber(selectedPuppy?.chip_number);
        }
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    const validate = () => {
        let errors = {};
        let isValid = true;

        if (certifyCheck === false) {
            isValid = false;
            errors['certifyCheck'] = 'This filed is required.';
        }
        setErrors(errors);
        return isValid;
    };

    const getRequestBody = () => {
        let body = {};
        if (verificationId) {
            body.verification_id = verificationId;
        }
        if (selectedSubVet) {
            body.user_id = selectedSubVet;
        }
        if (chipNumber) {
            body.chip_number = chipNumber;
        }
        if (note) {
            body.note = note;
        }
        if (certificates.length) {
            body.certificate_details = certificates;
        }
        if (deleteCertificates.length) {
            body.delete_certificates = deleteCertificates;
        }
        if (physicalDefectCheck === 'yes') {
            if (physicalDefect) {
                body.physical_defect = physicalDefect;
            }
            if (significanceCheck === 'yes' && significance) {
                body.significance = significance;
            }
        }
        body = toFormData(body);
        return body;
    };

    const handleCertificatesChange = (e) => {
        const files = e.target.files;
        const tempFiles = [...certificates];
        const tempFilePreviews = [...certificatesPreview];
        for (let i = 0; i < files.length; i++) {
            tempFiles.push(files[i]);
            tempFilePreviews.push(URL.createObjectURL(files[i]));
        }
        setCertificates(tempFiles);
        setCertificatesPreview(tempFilePreviews);
    };

    const removeImage = (i) => {
        const certs = [...certificates];
        const certPrevs = [...certificatesPreview];
        certs.splice(i, 1);
        certPrevs.splice(i, 1);
        if (selectedPuppy?.vet_pet_verification) {
            let deleteCerts = [...deleteCertificates];
            const img = certificatesPreview[i].split('image-retrieve/')[1];
            if (selectedPuppy?.vet_pet_verification?.certificate_details.includes(img)) {
                deleteCerts.push(img);
            }
            setDeleteCertificates(deleteCerts);
        }
        setCertificates(certs);
        setCertificatesPreview(certPrevs);
    };

    const savePuppyInfo = (event) => {
        event.preventDefault();
        if (validate()) {
            const body = getRequestBody();
            postRequest(`${API_ENDPOINT_URL}/veterinarian/connect/breeder/advert/pet-verification/${selectedPuppy?.id}`, body, true).then((res) => {
                if (res) {
                    setShowViewAdvert(false);
                    setShowPuppyList(false);
                    toast.success('Puppy saved successfully');
                }
            });
        }
    };

    return (
        <>
            <header className="aaPtbdHeader">
                <h1 className="h3 aaPtbHeading">{selectedAdvert}</h1>
            </header>
            <div className="connectbreeder vetPuppyEditWrap">
                <form className="um-bddFormWrap" onSubmit={(event) => savePuppyInfo(event)}>
                    <ul className="connectbreeder list-unstyled">
                        <li>
                            <h3 className="no-padding margin-bottom-30">{selectedPuppy.name}</h3>
                            <div className="row margin-bottom-20">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="um-profilePicWrap margin-zero">
                                        <div className="poaAdd d-flex align-items-center">
                                            Upload Vaccination Certificates
                                        </div>
                                        <span
                                            className="customFileBtnWrap w-100 text-center btncert">
                                            <input id="certificates" type="file" multiple={true}
                                                   name="certificates"
                                                   onChange={handleCertificatesChange}/>
                                            <label htmlFor="certificates"
                                                   className="fileBtn btn-info text-uppercase">
                                                UPLOAD VACCINATION CERTIFICATES <i
                                                className="umicomoonGroup-1095 btnIcnUpload"/>
                                            </label>
                                        </span>
                                    </div>
                                    {
                                        certificatesPreview?.map((cp, idx) => (
                                            <div className="position-relative mrdpEditorView" key={idx}>
                                                <div className="mt-1">
                                                    <img src={cp} width="30%"/>
                                                    <button
                                                        className="position-absolute btnMrdpRemover btn-danger rounded-circle d-flex align-items-center justify-content-center p-0 btn"
                                                        type="button"
                                                        name="certificatesPreview"
                                                        onClick={() => removeImage(idx)}>
                                                        X
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label>Microchip Number</label>
                                        <input type="text" className="form-control"
                                               value={chipNumber}
                                               onChange={e => setChipNumber(e.target.value)}/>
                                        <span>
                                            If Microchip number was uploaded
                                            please confirm number is correct
                                            above (adjust number if
                                            necessary)
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-2 d-flex">
                                Any physical defect or infirmity detected?
                                <span className="d-flex ml-5">
                                    <label className="checkboxlabel d-flex mr-3">Yes <input
                                        className="css3checkbox"
                                        type="radio"
                                        name="physicalDefectCheck"
                                        value="yes"
                                        onChange={e => setPhysicalDefectCheck(e.target.value)}
                                        checked={physicalDefectCheck === 'yes'}/></label>
                                    <label className="checkboxlabel d-flex">No <input className="css3checkbox"
                                                                                      type="radio"
                                                                                      name="physicalDefectCheck"
                                                                                      value="no"
                                                                                      onChange={e => setPhysicalDefectCheck(e.target.value)}
                                                                                      checked={physicalDefectCheck === 'no'}/></label>
                                </span>
                            </div>
                            {
                                physicalDefectCheck === 'yes' &&
                                <div>
                                    <div className="form-group">
                                        <label>Please state body system and actual defect</label>
                                        <textarea className="form-control"
                                                  placeholder="Write the description here..."
                                                  value={physicalDefect}
                                                  onChange={e => setPhysicalDefect(e.target.value)}/>
                                    </div>
                                    <div className="form-group mt-5 d-flex">
                                        Is this clinically significant?
                                        <span className="d-flex ml-5">
                                            <label className="checkboxlabel d-flex mr-3">Yes <input
                                                className="css3checkbox"
                                                type="radio"
                                                name="significanceCheck"
                                                value="yes"
                                                onChange={e => setSignificanceCheck(e.target.value)}
                                                checked={significanceCheck === 'yes'}/></label>
                                            <label className="checkboxlabel d-flex">No <input
                                                className="css3checkbox"
                                                type="radio"
                                                name="significanceCheck"
                                                value="no"
                                                onChange={e => setSignificanceCheck(e.target.value)}
                                                checked={significanceCheck === 'no'}/></label>
                                        </span>
                                    </div>
                                    {
                                        significanceCheck === 'yes' &&
                                        <div className="form-group">
                                            <label>State the significance</label>
                                            <textarea className="form-control"
                                                      placeholder="Write the description here..."
                                                      value={significance}
                                                      onChange={e => setSignificance(e.target.value)}/>
                                        </div>
                                    }
                                </div>
                            }
                            <div className="form-group">
                                <label>Examination notes</label>
                                <textarea className="form-control"
                                          placeholder="Breeder bio..."
                                          value={note}
                                          onChange={e => setNote(e.target.value)}/>
                            </div>
                            <div className="form-group mt-5 d-flex">
                                <span className="d-flex">
                                    <label className="checkboxlabel d-flex mr-3">
                                        <input
                                            className="css3checkbox mr-3 flex-shrink-0"
                                            type="checkbox"
                                            name="certifyCheck"
                                            onChange={() => setCertifyCheck(!certifyCheck)}
                                            checked={certifyCheck}/>
                                I certify that today I examined the above animal. In my opinion, the animal is in
                                good health and is free from any physical defect or infirmity other than
                                as listed above
                                    </label>
                                </span>
                            </div>
                            {errors.certifyCheck && (
                                <small className="support-error">
                                    {errors.certifyCheck}
                                </small>
                            )}
                        </li>
                    </ul>
                    <div className="connectbreederfooter">
                        <div className="um-bddFormSlct">
                            <select className="form-control" value={selectedSubVet}
                                    onChange={e => setSelectedSubVet(e.target.value)}>
                                <option value="">{
                                    user?.detail?.practice_name ? user?.detail?.practice_name : user?.first_name + ' ' + user?.last_name
                                }</option>
                                {
                                    fetchedSubVets.map((sv) => (
                                        <option key={sv.id} value={sv.id}>{sv.first_name} {sv.last_name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <button
                            type="submit"
                            className="btn btnFormWidth btnFormStyle small-xs p-0 ml-0 mt-1"
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="227.394"
                                height="41.9"
                                viewBox="0 0 227.394 41.9"
                            >
                                <g
                                    id="Group_1385"
                                    data-name="Group 1385"
                                    transform="translate(-445.974 -796.786)"
                                >
                                    <rect
                                        id="Rectangle_235"
                                        data-name="Rectangle 235"
                                        width="227.394"
                                        height="41.9"
                                        rx="7"
                                        transform="translate(445.974 796.786)"
                                        fill="#e4a039"
                                    ></rect>
                                    <text
                                        id="Back_to_Breeders_List"
                                        data-name="Back to Breeders List"
                                        transform="translate(494.069 824.945)"
                                        fill="#fff"
                                        fontSize="17"
                                        fontFamily="NunitoSans-Regular, Nunito Sans"
                                    >
                                        <tspan x="0" y="0">
                                            Save Advert
                                        </tspan>
                                    </text>
                                </g>
                            </svg>
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ViewAdvert;
