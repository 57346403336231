import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const FAQ = () => {

    const [faqData, setFpData] = useState([
        {
            'id': 1,
            'question': 'What is PetBond?',
            'answer': '<p>PetBond is a novel and ground breaking website which allows new pet parents to safely meet happy & healthy pets. PetBond was founded and is managed by our CEO and renowned Veterinary Surgeon Dr. Tim Kirby.  Tim has years of clinical experience in improving the lives of thousands of pets, whilst also understanding how ethical breeders love their dogs. Listening to the worries and horrific experiences of new pet parents such as you every single day, prompted Tim to creating PetBond which has quickly become the single most trusted place for anyone looking to meet a healthy & happy puppy with peace of mind.</p><p>As a Veterinary expert with years of experience, puppy farmers and unethical breeders who see puppies as mere commodities are not eligible to use our platform. Pet-Bond is the leader & champion for best animal health & welfare standards, and we proudly work with safe, ethical and trusted breeders who match our standards.</p>',
            'isActive': false
        },
        {
            'id': 2,
            'question': 'Does it cost me as a Breeder to use PetBond?',
            'answer': '<p>At Pet-Bond, our Veterinary Team are here to support and assist safe, ethical & trusted breeders. Our breeders love and care for their pets as much as we care for them as experienced vets. Our way of saying “Thank You” to our ethical breeders for caring so much is not by penalising you financially…..instead we ensure that your puppies are matched with caring and loving new pet parents for every day of their lives. By working with Pet-Bond, our trusted breeders are valued partners as we change how new pet parents meet puppies forever!</p>',
            'isActive': false
        },
        {
            'id': 3,
            'question': 'Why doesn’t Pet Bond allow puppy dealers, puppy farmers and unscrupulous people to advertise on it’s site like the classified ads websites?',
            'answer': '<p>At Pet-Bond, we have caring Veterinary professionals with years of real life clinical experience running our all aspects of what we do. We are experts at what we do, and we trained for many years in achieving this excellence. Our team devote their lives to animals and your pets, and most importantly we care so much more than anyone else does, or ever has before. We know how important each and every single puppy is, it’s a precious life that means the world to you as it does to us. This is why we don’t compromise and we don’t view pets as ‘commodities’ like other websites do. At PetBond, every puppy is a sentient being with feelings & emotions, which deserves all the care & respect in the world. We are very proud to be very different, because we  really care.</p>',
            'isActive': false
        },
        {
            'id': 4,
            'question': 'If I can’t find the puppy I want right now, can I still apply to Pet-Bond?',
            'answer': '<p>Yes for sure! The pet parent journey is a very exciting one, and we encourage that it’s done over a period of time where the experts at PetBond can work with you along every step of the way. As demand is always so high for our featured puppies, you can join our “PetBond Waiting” list for free, and our expert team will notify you as soon as your ideal puppy match becomes available. You therefore can have the peace of mind that your new best friend will be matched with you in the near future, knowing that it has been born & reared to the highest Pet Bond health & welfare standards.</p>',
            'isActive': false
        }
    ]);

    const handleToggle = (i) => {
        let temp = [...faqData];
        temp[i].isActive = !temp[i].isActive;
        setFpData(temp);
    };

    return (
        <>
            <Helmet>
                <title>Frequently Asked Questions (FAQ) | PetBond</title>
                <meta name="description"
                      content="Frequently Asked Questions on being a PetBond approved breeder or buying dogs from ethical and trusted breeders in the UK."/>
            </Helmet>
            <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
                <div className="container">
                    <div className="arextraWrap">
                        <h2 className="h1">from good homes… <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes</h2>
                    </div>
                    <div className="arbhImgHolder d-none d-md-block position-absolute">
                        <img src="/images/img07.png" className="img-fluid" alt="petbond"/>
                    </div>
                </div>
                <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
							<i className="aricomoon-hearts"><span className="sr-only">icon</span></i>
						</span>
                <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
							<i className="aricomoon-starDots"><span className="sr-only">icon</span></i>
						</span>
            </header>
            <section className="contentSection pt-5 pb-15 pb-lg-20">
                <div className="container">
                    <h1 className="text-center mb-10 h2">Frequently Asked Questions</h1>
                    <ul className="list-unstyled faqAccordionList">
                        {
                            faqData && faqData.map((data, index) => (
                                <li
                                    className={data.isActive ? 'active' : null}
                                    key={data.id}
                                >
                                    <button
                                        type="button"
                                        className="accOpener"
                                        onClick={() => handleToggle(index)}
                                    >
                                        {data.question}
                                        <i className="icn">
                                            <span className="sr-only">icon</span>
                                        </i>
                                    </button>
                                    <div className="accSlide">
                                        <div className="accsHolder">
                                            <span dangerouslySetInnerHTML={{__html: data.answer}}/>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </section>
        </>
    );

};

export default FAQ;
