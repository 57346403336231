import {
  Routes,
  Navigate,
  Route,
  Link,
  useNavigate,
  NavLink,
  useLocation,
} from "react-router-dom";
import {
  API_ENDPOINT_URL,
  APP_PREFIX,
  AUTH_PREFIX,
} from "../../utils/appConstants";
import ListedPets from "./ListedPets";
import Overview from "./Overview";
import MySales from "./MySales";
import Profile from "./Profile";
import Settings from "./Settings";
import { getRequest, postRequest } from "../../utils/requests";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RegisterBreeder from "./RegisterBreeder";
import ConnectBreeder from "./ConnectBreeder";
import PetAlerts from "./PetAlerts";
import CreatePetAlert from "./CreatePetAlert";
import PaymentsDeposit from "./PaymentsDeposit";
import ReportIssue from "./ReportIssue";
import ChangePassword from "./ChangePassword";
import Advert from "./Advert";
import Download from "./Download";
import FreeServices from "./FreeServices";
import ListAdvertMessagesSummary from "./AdvertMessages";
import AdvertConversation from "./AdvertMessages/AdvertConversation";
import ViewAdvertMessages from "./AdvertMessages/ViewAdvertMessage";
import {unreadMessagesCountApi} from "../../services/authorized/chat.service";
import {generalNotifications, initPusher, subscribeChat} from "../../services/authorized/pusher.service";
import AdvertSoldPets from "./Advert/SoldPets";
import PaymentOptions from "./Advert/BreederAdvert/PaymentOptions";
import Benefits from "./Advert/BreederAdvert/Benefits";

const AppPages = () => {
  let pusher = null;
  let channel = null;
  const advertMessageButtonTitle = 'Advert Messages';
  const [unreadMessagesObj, setUnreadMessagesObj] = useState({
    'recipient_id': 0,
    'total_unread_count': 0,
    'advert_info': {
      'advert_id': 0,
      'unread_count': 0
    },
    'chat_info': {
      'chat_id': 0,
      'unread_count': 0,
    }
  });
  window.unreadMessagesObj = unreadMessagesObj;
  const [sideNav, setSideNav] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("currentUser"));
  const [emailVerified, setEmailVerified] = useState(user?.email_verified_at);
  const onBoardStatus = JSON.parse(localStorage.getItem("on_board_status"));
  const isTransferEnabled = JSON.parse(
    localStorage.getItem("is_transfer_enabled")
  );
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    if (!emailVerified) {
      getRequest(`${API_ENDPOINT_URL}/verify-email-status`, true).then((res) => {
        if (res) {
          if (res.data.data.email_verified_at) {
            setEmailVerified(true);
            let newState = { ...user };
            newState.email_verified_at = true;
            localStorage.setItem("currentUser", JSON.stringify(newState));
          }
        }
      });
    }

    if (user?.role === "buyer") {
      setSideNav([
        { component: "overview", name: "Overview" },
        { component: "pet-alerts", name: "Pet Alerts" },
        { component: "create-pet-alert", name: "Create Pet Alert" },
        { component: "payments-deposit", name: "Final Payments & Deposits" },
        { component: "messages", name: advertMessageButtonTitle },
        { component: "report-issue", name: "Report An Issue With Breeder" },
        { component: "change-password", name: "Change Password" },
      ]);
    } else if (user?.role === "veterinarian") {
      setSideNav([
        { component: "overview", name: "Overview" },
        { component: "register-breeder", name: "Register Breeder" },
        { component: "connected-breeder", name: "Connected Breeders" },
        { component: "download", name: "Download" },
        { component: "profile", name: "Profile" },
        { component: "settings", name: "Settings" },
      ]);
    } else if (user?.role === "sub_veterinarian") {
      setSideNav([
        { component: "connected-breeder", name: "Connected Breeders" },
        { component: "profile", name: "Profile" },
      ]);
    } else if (user?.role === "charity") {
      setSideNav([
        { component: "overview", name: "Overview" },
        { component: "sell-pet/payment-options", name: "Sell a pet" },
        // {component: 'profile', name: 'Profile'},
        { component: "listed-pets", name: "Listed pets" },
        { component: "messages", name: advertMessageButtonTitle },
        { component: "settings", name: "Settings" },
      ]);
    } else if (user?.role === "breeder") {
      setSideNav([
        { component: "overview", name: "Overview" },
        { component: "sell-pet/payment-options", name: "Sell a pet" },
        { component: "profile", name: "Profile" },
        { component: "listed-pets", name: "Listed pets" },
        { component: "my-sales", name: "My Sales" },
        { component: "messages", name: advertMessageButtonTitle },
        { component: "settings", name: "Settings" },
        { component: "freeservices", name: "Free Services" },
      ]);
    }

    if (user?.role === 'breeder' || user?.role === 'buyer' || user?.role === 'charity') {
      initSocketConnection();
      unreadMessagesCountApi(user?.role).then(count => {
        window.setUnreadMessagesObj = setUnreadMessagesObj;
        window.setUnreadMessagesObj(prevState => ({
          ...prevState,
          'total_unread_count': count,
        }))
      });
    }

    return () => {
      if (channel) {
        channel.unbind();
      }

      if (pusher) {
        pusher.disconnect();
      }
    };
  }, []);

  const initSocketConnection = () => {
    pusher = initPusher();

    channel = generalNotifications(pusher, user);
  }

  const logout = () => {
    getRequest(`${API_ENDPOINT_URL}/logout`, true).then((res) => {
      if (res) {
        localStorage.clear();
        navigate(`${AUTH_PREFIX}/login`);
      }
    });
  };

  const verifyEmail = () => {
    getRequest(`${API_ENDPOINT_URL}/email/resend`, true).then((res) => {
      if (res) {
        if (res.data.data === null) {
          toast.success(res.data.message);
        } else {
          toast.success(res.data.message);
          setEmailVerified(true);
          const currentUser = JSON.parse(localStorage.getItem("currentUser"));
          let newState = { ...currentUser };
          newState.email_verified_at = true;
          localStorage.setItem("currentUser", JSON.stringify(newState));
          window.location.reload();
        }
      }
    });
  };

  return (
    <div className={`dshSdBrWrp ${showSideBar ? "showSidebar" : ""}`}>
      <section
        className={`dashboardWrap position-relative pt-5 pb-10 pb-md-15 pb-lg-20 ${
          location.pathname.includes("/app/sell-pet") ? "hideNav" : ""
        }`}
      >
        <div className="container aaContainerLarge">
          <button
            type="button"
            className="bspOpener d-lg-none text-left border-0 shadow-sm border-1 border-white"
            onClick={() => {
              setShowSideBar(!showSideBar);
            }}
          >
            <span className="text-capitalize">Open Menu</span>
            <i className="icn fas fa-cog"></i>
          </button>
          <aside className="dbSidebar">
            <h6 className="h6 dbsHeader text-white fontNoto">
              Dashboard Pages
              <button
                type="button"
                className="bspClose d-lg-none p-0 border-0 bg-transparent"
                onClick={() => {
                  setShowSideBar(!showSideBar);
                }}
              >
                <i className="icn fas fa-times"></i>
              </button>
            </h6>
            <nav className="mainNav">
              <ul className="bdList list-unstyled p-0 m-0">
                {sideNav.map((nav) => (
                  <li key={nav.component} style={(advertMessageButtonTitle === nav.name) ? {position: "relative"} : {}}>
                    <NavLink
                      className={(n) => (n.isActive ? "active" : "")}
                      to={`${APP_PREFIX}/${nav.component}`}
                      onClick={() => setShowSideBar(false)}
                    >
                      {nav.name}
                    </NavLink>
                    {
                      advertMessageButtonTitle === nav.name && unreadMessagesObj.total_unread_count > 0 &&
                        <span className="messageCount">{unreadMessagesObj.total_unread_count}</span>
                    }
                  </li>
                ))}
                <li className="logout">
                  <button type="button" onClick={logout}>
                    Logout
                  </button>
                </li>
              </ul>
            </nav>
          </aside>
          <div className="offsetWrapper">
            {!emailVerified && (
              <div
                className={`notiAlert${
                  location.pathname === "/app/sell-pet" ? " noticeNoOffset" : ""
                }`}
              >
                <p>
                  Looks like your Email Address is not verified yet,{" "}
                  <button
                    type="button"
                    className="linkonly"
                    onClick={verifyEmail}
                  >
                    click here
                  </button>{" "}
                  to resend again.
                </p>
              </div>
            )}

            <Routes>
              <Route path="/overview" element={<Overview />} />
              <Route path="/freeservices" element={<FreeServices />} />
              <Route path="/listed-pets" element={<ListedPets />} />
              <Route path="/messages" element={<ListAdvertMessagesSummary />} />
              <Route path="/messages/view-chat/:chatId" element={<AdvertConversation />} />
              <Route path="/messages/view-advert/:advertId" element={<ViewAdvertMessages />} />
              <Route path="/my-sales" element={<MySales />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/sell-pet" element={<Advert />} />
              <Route path="/sell-pet/payment-options" element={<PaymentOptions />} />
              <Route path="/sell-pet/benefits" element={<Benefits />} />
              <Route path="/sold-pets" element={<AdvertSoldPets />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/register-breeder" element={<RegisterBreeder />} />
              <Route path="/connected-breeder" element={<ConnectBreeder />} />
              <Route path="/download" element={<Download />} />
              <Route path="/pet-alerts" element={<PetAlerts />} />
              <Route path="/create-pet-alert" element={<CreatePetAlert />} />
              <Route path="/payments-deposit" element={<PaymentsDeposit />} />
              <Route path="/report-issue" element={<ReportIssue />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route
                path=""
                element={<Navigate to={`${APP_PREFIX}/overview`} />}
              />
              <Route
                path="*"
                element={<Navigate to={`${APP_PREFIX}/overview`} />}
              />
            </Routes>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppPages;
