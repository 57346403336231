import "tippy.js/dist/tippy.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import oO from "../../assets/images/oO.png";
import { Helmet } from "react-helmet-async";
import lab from "../../assets/images/lab.jpg";

const GenecticTesting = () => {
  return (
    <>
      <Helmet>
        <title>Genetic Testing | Buy Dogs & Puppies | PetBond</title>
        <meta
          name="description"
          content="PetBond is the safest and most ethical way to buy dogs in UK. We work with approved breeders to help you find a new best friend. Find your dog or puppy today."
        />
      </Helmet>
      {/* HEADER */}
      <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
        <div className="container">
          <div className="arextraWrap">
            <h2 className="h1">
              from good homes… <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes
            </h2>
          </div>
          <div className="arbhImgHolder d-none d-md-block position-absolute">
            <img src="/images/img07.png" className="img-fluid" alt="petbond" />
          </div>
        </div>
        <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
          <i className="aricomoon-hearts">
            <span className="sr-only">icon</span>
          </i>
        </span>
        <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
          <i className="aricomoon-starDots">
            <span className="sr-only">icon</span>
          </i>
        </span>
      </header>
      {/* CONTENT */}

      <section className="position-relative py-10 bg-white overflow-hidden">
        <div className="position-relative container" style={{ zIndex: "1" }}>
          <div className=" row align-items-center g-16">
            <div className="col-12">
              <span className="badge mb-4 bg-warning text-light text-uppercase shadow">
                Genetic Testing
              </span>
              <h2 className="font-heading mb-6 fs-6">
                PetBond Pre-Breeding Genetic Testing
              </h2>
              <p className="text-primary" style={{ fontWeight: "bold" }}>
                {" "}
                <span>
                  {" "}
                  <img
                    src={oO}
                    className="img-fluid"
                    alt="dogstrust"
                    style={{ height: "20px" }}
                  />
                </span>{" "}
                Book Now: <span className="">healthtesting@mypetbond.com</span>
              </p>
            </div>
          </div>
          <div className="row g-8">
            <div className="col-12">
              <p className="mb-6 fs-10 fw-medium ">
                At PetBond, our years of veterinary expertise and clinical
                professionalism have shaped the highest standards which we
                uphold. To anyone breeding a litter of puppies, our top tip is
                that you “Breed to Improve”. By breeding to improve the health,
                welfare &amp; temperament of the next generation of your
                puppies, you help prevent un-necessary animal suffering and
                pain. You ensure your puppies live much better lives as a
                result, and PetBond Health Testing is now here to help you make
                that massive difference.
              </p>
              <p className="mb-6 fs-10 fw-medium">
                By choosing a PetBond Pre-Breeding offer, you can have both the
                dam &amp; sire health tested at a PetBond Centre of Excellence.
                This expertise is available on request and please click below to
                find out more or to book your dog’s health test ….
              </p>
              <p className="text-primary" style={{ fontWeight: "bold" }}>
                {" "}
                <span>
                  {" "}
                  <img
                    src={oO}
                    className="img-fluid"
                    alt="dogstrust"
                    style={{ height: "20px" }}
                  />
                </span>{" "}
                Book Now: <span className="">healthtesting@mypetbond.com</span>
              </p>
            </div>
          </div>
          <div className="mt-4 d-flex flex-wrap flex-strecth justify-content-left">
            <Link
              to={`/airway-testing`}
              className="btn-primary shadow-sm btn text-uppercase px-4 m-1"
            >
              Go to Airway Testing
            </Link>
            <Link
              to={`/find-a-vet`}
              className="btn-warning shadow-sm btn text-uppercase px-4 m-1"
            >
              Back to Vet Teams
            </Link>
          </div>
        </div>
      </section>
      {/* End of text */}
    </>
  );
};

export default GenecticTesting;
