import { Helmet } from 'react-helmet-async';
import React from 'react';

const PrivacyPolicy = () => {

    return (
        <>
            <Helmet>
                <title>Privacy Policy | Petbond</title>
                <meta name="description"
                      content='PRIVACY NOTICE PetBond Limited ("PetBond", "we", "us" or "our") is committed to protecting and respecting your privacy. This Privacy Notice tells you'/>
            </Helmet>
            <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
                <div className="container">
                    <div className="arextraWrap">
                        <h2 className="h1">from good homes… <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes</h2>
                    </div>
                    <div className="arbhImgHolder d-none d-md-block position-absolute">
                        <img src="/images/img07.png" className="img-fluid" alt="petbond"/>
                    </div>
                </div>
                <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
							<i className="aricomoon-hearts"><span className="sr-only">icon</span></i>
						</span>
                <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
							<i className="aricomoon-starDots"><span className="sr-only">icon</span></i>
						</span>
            </header>
            <section className="contentSection pt-5 pb-15 pb-lg-20">
                <div className="container">
                    <article className="contentWrap rounded shadow bg-white">
                        <h1 className="h2">Privacy Policy</h1>
                        <strong>PRIVACY NOTICE</strong>
                        <p>PetBond Limited (“<strong>PetBond</strong>“, “<strong>we”, “us” or “our</strong>“) is
                            committed to protecting and respecting your privacy. This Privacy Notice tells you about
                            your privacy rights and sets out how we, as a data controller, collect and process personal
                            data relating to you.</p>
                        <h6 className="fontNoto h6 pt-5">1. About Us</h6>
                        <p><strong>PetBond is UK’s most safe &amp; trusted online platform where anyone can source
                            a happy &amp; healthy pet. With peace of mind, you can safely choose from any of our PetBond
                            approved breeder or rescue centre options. We are run by experienced veterinary surgeons,
                            who care about you and your pet.</strong></p>
                        <p>Your privacy is important to us and maintaining your trust is paramount to us. We are fully
                            committed to keeping your personal information safe. This privacy notice is intended to
                            provide you with information about the personal information we collect and how that
                            information is used and shared.</p>
                        <h6 className="fontNoto h6 pt-5">2. Information we may collect from you</h6>
                        <p> “Personal data” means any information about an individual from which that person can be
                            identified. We collect personal data on our website through our online registration and
                            submission forms and during the process of rehoming pets and certifying PetBond approved
                            breeders. We collect personal data on our website (<a
                                href="https://mypetbond.com/">https://mypetbond.com/</a>) about the following categories of
                            users<strong>:</strong></p>
                        <div className="tableWrap table-responsive">
                            <table className="table mt-10">
                                <thead>
                                <tr>
                                    <th><strong>User</strong></th>
                                    <th><strong>Types of data </strong></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Members of the public, who would like adopt a pet or put a pet up for adoption.
                                        <p></p>
                                    </td>
                                    <td>
                                        <ul className="list-unstyled contChildList list-unstyled pl-3">
                                            <li>Name;</li>
                                            <li>Email address;</li>
                                            <li>Phone number;</li>
                                            <li>Copies of any correspondence you send to us or other users.</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>PetBond Approved Breeders.</td>
                                    <td>
                                        <ul className="list-unstyled contChildList list-unstyled pl-3">
                                            <li>Name;</li>
                                            <li>Email address;</li>
                                            <li>Phone number;</li>
                                            <li>Website and/or social media URLs;</li>
                                            <li>Postal address;</li>
                                            <li>ID and/or proof of address documents (e.g. passports or utility
                                                bills);
                                            </li>
                                            <li>Photos; and</li>
                                            <li>License information.</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Charities, rescue centres, or veterinary practices that would like to partner
                                        with us.
                                    </td>
                                    <td>
                                        <ul className="list-unstyled contChildList list-unstyled pl-3">
                                            <li>Name;</li>
                                            <li>Email address;</li>
                                            <li>Phone number;</li>
                                            <li>Postal address;</li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p><br/></p>
                        <p>If you do not provide us with the requested information, we may not be able to provide you
                            with our services.</p>
                        <p><strong><u>Note:</u></strong> If you provide us with personal data relating to other people
                            you must first: (a) inform the person about the content of this Privacy Notice; and (b)
                            obtain any legally required consent from that person to the sharing of their personal data
                            in this manner.</p>
                        <p>In addition to the information provided to us, we may collect certain information when you
                            visit our website, as explained below under <strong>‘Cookies’</strong>. In providing our
                            services, we may also receive personal data indirectly. Categories of such personal data
                            include names, addresses, contact information and other information that is relevant to the
                            provision of our services.</p>
                        <h6 className="fontNoto h6 pt-5">3. How we use the personal data we collect</h6>
                        <p>We will only use your personal data for the purposes and legal bases set out in the table
                            below.</p>
                        <div className="tableWrap table-responsive">
                            <table className="table mt-10">
                                <thead>
                                <tr>
                                    <th width="355"><strong>Purpose(s) for Processing</strong></th>
                                    <th width="318"><strong>Legal Basis </strong></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>To provide our services to members of the public, registered breeders,
                                        charities, rescue centres, or veterinary practices, including:<p></p>
                                        <p>Ø providing a platform where anyone can source a happy &amp; healthy pet;</p>
                                        <p>Ø ensuring that pets have been sourced from safe, ethical, and trusted
                                            breeders or animal centres;</p>
                                        <p>Ø creating a Network of Care and raising awareness of animal welfare
                                            issues.</p>
                                        <p>Processing applications to register as a certified breeder. This includes
                                            contacting vets or other 3rd parties in order to certify the
                                            application.</p>
                                    </td>
                                    <td>This processing is necessary to support our legitimate interests in managing our
                                        business, provided such interests are not overridden by your rights and
                                        interests.<p></p>
                                        <p><strong>You have the right to object at any time where processing is based on
                                            our legitimate interests</strong>.</p>
                                        <p>We have carried out a balancing test to ensure that your interests do not
                                            override our legitimate business interest. For more information on this
                                            balancing test, please contact <a
                                                href="mailto:info@mypetbond.com">info@mypetbond.com</a>.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="355">Managing and operating our website – to keep it updated and
                                        relevant, to develop our business and to inform our marketing strategy. This
                                        includes:<p></p>
                                        <p>Ø website metrics and other analytics data;</p>
                                        <p>Ø cookies; and</p>
                                        <p>Ø feedback and comments we receive.</p>
                                        <p>Such data will be used in optimising marketing strategies and customer
                                            communications.</p>
                                        <p><strong></strong></p>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td width="355">Ensuring that pets and animals that are rehomed via the website
                                        requires adherence to all applicable animal welfare legislation and standards.
                                        Such legislation includes but is not limited to:<p></p>
                                        <p>Ø Control of Dogs Regulations 1998;</p>
                                        <p>Ø Prohibition on Tail Docking and Dew Claw Removal (Dogs) Regulations
                                            2014;</p>
                                        <p>Ø Regulation (EU) 2015/262;</p>
                                        <p>Ø S.I. No. 201/2016 – Equidae (Change of Ownership);</p>
                                        <p>Ø Microchipping of Dogs Regulations 2015;</p>
                                        <p>Ø Dog Breeding Establishments Act 2010;</p>
                                        <p>Ø Protection of Animals Acts 1911 and 1965;</p>
                                        <p>Ø Control of Dogs Act 1986; and</p>
                                        <p>Ø Control of Dogs (Amendment) Act 1992.</p>
                                    </td>
                                    <td width="318">This processing of your data is necessary in order for us to comply
                                        with any legal or regulatory obligations.
                                    </td>
                                </tr>
                                <tr>
                                    <td width="355">Marketing and providing you with information about our services.
                                        This may include:<p></p>
                                        <p>Ø newsletters;</p>
                                        <p>Ø email lists; and</p>
                                        <p>Ø surveys.</p>
                                    </td>
                                    <td width="318">We will obtain your consent prior to sending you marketing materials
                                        or other communications for the purpose of advertising our services.<p></p>
                                        <p><strong>You can withdraw your consent at any time.</strong></p>
                                        <p><strong></strong></p>
                                        <p>To opt out of receiving marketing materials, please email <a
                                            href="mailto:info@mypetbond.com">info@mypetbond.com</a>.</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p><br/></p>
                        <p><strong><em>Other processing purposes</em></strong></p>
                        <p>If we intend to process your personal data for any other purpose, we will inform you of this
                            purpose and the legal basis for the processing.</p>
                        <h6 className="fontNoto h6 pt-5">4. Data Retention</h6>
                        <p className="mb-1">Your information is securely stored on our document storage platforms.</p>
                        <p>We will retain personal data for the following retention periods:</p>
                        <div className="tableWrap table-responsive">
                            <table className="table mt-10">
                                <thead>
                                <tr>
                                    <th width="355"><strong>Documents Retained</strong></th>
                                    <th width="318"><strong>Retention Period </strong></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>User account information (<em>e.g. usernames, passwords, email addresses).</em>
                                        <p></p>
                                    </td>
                                    <td>7 years:
                                        <p><em>Actions founded on breach of contract may be brought for up to six years
                                            from the date on which the cause of action occurred, plus a 1 year period to
                                            allow the service of the claim (Statute of Limitations 1957, s.11).</em></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Contact details for marketing purposes (<em>e.g. email addresses, social media
                                        data</em>).<p></p>
                                    </td>
                                    <td>We will retain this data for as long as you opt in to receive marketing
                                        communications.<p></p>
                                        <p>Should you inform us that you wish to opt out of receiving such
                                            communications, this information will be securely deleted without undue
                                            delay.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Supporting documents for registered breeder applications (<em>e.g. copy of ID,
                                        license number, utility bills</em>).
                                    </td>
                                    <td>7 years:
                                        <p><em>Actions founded on breach of contract may be brought for up to six years
                                            from the date on which the cause of action occurred, plus a 1 year period to
                                            allow the service of the claim </em>(Statute of Limitations 1957, s.11).</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Record of all correspondence (<em>e.g. requests, complaints, advice, warnings,
                                        information</em>)
                                    </td>
                                    <td>7 years:
                                        <p><em>Actions founded on breach of contract may be brought for up to six years
                                            from the date on which the cause of action occurred, plus a 1 year period to
                                            allow the service of the claim </em>(Statute of Limitations 1957, s.11).</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tax records (<em>e.g. records in relation to Income Tax, Corporation Tax,
                                        Capital Gains Tax, and VAT</em>).
                                    </td>
                                    <td>11 Years:
                                        <p><em>Tax offences in relation to income tax, corporation tax and capital gains
                                            tax may be prosecuted for up to 10 years from the date of committing an
                                            offence plus a1 year period to allow for the service of the claim (Taxes
                                            Consolidation Act, 1997, Sections 886 and 1064).</em></p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <br/>
                        <h6 className="fontNoto h6 pt-5">5. Disclosure of your information</h6>
                        <p>Personal data may be disclosed to:</p>
                        <ul className="contChildList list-unstyled pl-3">
                            <li>Other users of the website or services.</li>
                            <li>Vets or other third parties.</li>
                            <li>Subcontractors and service providers.</li>
                            <li>Professional advisers (lawyers, accountants, financial advisers etc.)</li>
                            <li>Regulators and government authorities in connection with our compliance procedures and
                                obligations.
                            </li>
                            <li>A purchaser or prospective purchaser of all or part of our assets or our business, and
                                their professional advisers, in connection with the purchase
                            </li>
                            <li>Other third parties on occasions where we are authorised or required by law to do so.
                            </li>
                        </ul>
                        <h6 className="fontNoto h6 pt-5">6. Security and where we store your personal data</h6>
                        <p>We cannot guarantee the complete security of our database, nor can we guarantee that
                            information you supply will not be intercepted while being transmitted to us over the
                            Internet. Although we will do our best to protect your personal data, we cannot guarantee
                            the security of your personal data transmitted through our website. Any transmission of
                            personal data is at your own risk.</p>
                        <p>We have put in place appropriate security measures to prevent your personal information from
                            being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. We
                            securely store your personal data in a centralised database, with controlled access to such
                            database. Access to personal data in both electronic and paper form is restricted to PetBond
                            employees who have a legitimate and justifiable reason to view such data. Any hard copies of
                            your personal data are kept in UK by PetBond. Electronic copies of your personal data
                            are stored online using WordPress. These third parties will only process your personal
                            information on our instructions and where they have agreed to treat the information
                            confidentially and to keep it secure.</p>
                        <p>In the event of a suspected data security breach, we will follow required procedure and will
                            notify you and the Data Protection Commission of a suspected breach where we are
                            legally required to do so.</p>
                        <h6 className="fontNoto h6 pt-5">7. Transfers of personal data outside the European Economic
                            Area (EEA)</h6>
                        <p>The personal data that we collect from you may be transferred to, and stored at, a
                            destination outside the EEA (including to service providers who may be located, or store
                            data, outside the EEA) for the purposes described above. We will ensure suitable safeguards
                            are in place to protect the privacy and integrity of your personal data in such
                            circumstances. You can obtain information pertaining to these safeguards by contacting us
                            at <a href="mailto:info@mypetbond.com">info@mypetbond.com</a>.</p>
                        <h6 className="fontNoto h6 pt-5">8. Links to other websites</h6>
                        <p>Our website may, from time to time, contain links to and from other websites. If you follow a
                            link to any of those websites, please note that those websites have their own privacy
                            policies and we do not accept any responsibility or liability for those policies. Please
                            check those policies before you submit any personal data to those websites.</p>
                        <h6 className="fontNoto h6 pt-5">9. Your rights</h6>
                        <p>You have various rights under the GDPR, including the right to access, rectification, and
                            erasure. In each case, these rights are subject to restrictions as laid down by law.</p>
                        <p>The following is a summary of your rights:</p>
                        <ul className="contChildList list-unstyled pl-3">
                            <li><strong>The right of access</strong> enables you to receive a copy of your personal
                                data;
                            </li>
                            <li><strong>The right to rectification</strong> enables you to correct any inaccurate or
                                incomplete personal data we hold about you;
                            </li>
                            <li><strong>The right to erasure</strong> enables you to request that we delete your
                                personal data in certain circumstances;
                            </li>
                            <li><strong>The right to restrict processing </strong>enables you to ask us to halt the
                                processing of your personal data in certain circumstances;
                            </li>
                            <li><strong>The right to object </strong>enables you to object to us processing your
                                personal data on the basis of our legitimate interests (or those of a third party);
                            </li>
                            <li><strong>The right to data portability </strong>enables you to request us to transmit
                                personal data that you have provided to us, to a third party without hindrance, or to
                                give you a copy of it so that you can transmitit to a third party, where technically
                                feasible.
                            </li>
                        </ul>
                        <p>You have the right to lodge a complaint with the Data Protection Commission, if you
                            consider that our processing of your personal data infringes the GDPR. The Data Protection
                            Commission’s Data Protection Commission address 21 Fitzwilliam Square South, Dublin 2, D02
                            RD28, Ireland (Helpline: +353 578 684 800).</p>
                        <p>If you wish to exercise any of these rights, please contact us (see Contact Us below). We
                            will respond to your request within one month. That period may be extended by two further
                            months where necessary, taking into account the complexity and number of requests. We will
                            inform you of any such extension within one month of receipt of your request. We may request
                            proof of identification to verify your request. We have the right to refuse your request
                            where there is a basis to do so in law, or if it is manifestly unfounded or excessive, or to
                            the extent necessary for important objectives of public interest.</p>
                        <h6 className="fontNoto h6 pt-5">10. Cookies</h6>
                        <p> When you visit the PetBond website, browser data (e.g. the IP address) is collected
                            anonymously from you by Google Analytics in order to improve the site and customer service.
                            This data is not used to identify individuals and will be automatically deleted 14 months
                            after collection.</p>
                        <p>For further details on how we use cookies, please refer to our separate cookie policy: <a
                            href="https://mypetbond.com/cookie-policy/">https://mypetbond.com/cookie-policy/</a></p>
                        <h6 className="fontNoto h6 pt-5">11. Links to other websites</h6>
                        <p> This Privacy Notice applies only to our website. The website may contain links to other web
                            sites not operated or controlled by us (“<strong>Third Party Sites</strong>”). The policies
                            and procedures we describe here do not apply to Third Party Sites and we are not responsible
                            for Third Party Sites’ content, any use of Third Party Sites, or the privacy practices of
                            Third Party Sites. Any links from this website do not imply that we endorse or have reviewed
                            Third Party Sites. We suggest contacting those sites directly for information on their
                            privacy policies.</p>
                        <h6 className="fontNoto h6 pt-5">12. Changes to this Privacy Notice</h6>
                        <p>We reserve the right to change this Privacy Notice from time to time at our sole discretion.
                            If we make any changes, we will post those changes here and update the “Last Updated” date
                            at the bottom of this Privacy Notice. However, if we make material changes to this Privacy
                            Notice, we will notify you by means of a prominent notice on the website prior to the change
                            becoming effective. Please review this Privacy Notice periodically for updates.</p>
                        <h6 className="fontNoto h6 pt-5">13. Contact Us</h6>
                        <p>PetBond Limited is a registered company (no. 622820) with an address at Liscahane, Ardfert,
                            V92 NH36. Questions, comments, requests and complaints regarding this Privacy Notice and the
                            personal data we hold are welcome and should be addressed to <a
                                href="mailto:info@mypetbond.com">info@mypetbond.com</a> or by phone at 087 2743675</p>
                        <p>All requests will be dealt with promptly and efficiently.</p>
                        <p><strong>Last Updated: &nbsp;[25 October 2019]</strong></p>
                    </article>
                </div>
            </section>
        </>
    );

};

export default PrivacyPolicy;
