import React, { useEffect, useState } from 'react';
import { getRequest, deleteRequest } from '../../../../utils/requests';
import {API_ENDPOINT_URL, APP_PREFIX, CURRENCY_SYMBOL} from '../../../../utils/appConstants';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const BreederListedPets = () => {
    const [advrtList, setAdvertList] = useState([]);

    useEffect(() => {
        getRequest(`${API_ENDPOINT_URL}/breeder/advert/list`, true).then((res) => {
            if (res) {
                setAdvertList(res?.data?.data);
            }
        });
    }, []);

    const removeListing = (id) => {
        deleteRequest(`${API_ENDPOINT_URL}/breeder/advert/delete/${id}`, true).then((res) => {
            if (res) {
                toast.success('Advert removed successfully');
                window.location.reload();
            }
        });
    };

    return (
        <div>
            <div className="aaContentWidget">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Your Listed Pets</h1>
                </header>
                <div className="aaDbRow aaDbPetsListRow">
                    <ul className="aaAdvtPetList list-unstyled m-0 p-0">
                        {
                            advrtList && advrtList.map((advert, index) => (
                                <li key={index}>
                                    <article className="aaAdvPetPost">
                                        <div className="aaLeftWrap">
                                            <div className="petImgWrap">
                                                <img
                                                    src={advert.cover_photo ? `${API_ENDPOINT_URL}/image-retrieve/${advert.cover_photo}` : '/images/image-placeholder.png'}
                                                    className="img-fluid"
                                                    alt="img description"
                                                />
                                            </div>
                                            {
                                                !(advert.status === 'approved' && advert.pet_count === advert.sold_count) &&
                                                <>
                                                    <Link to={`${APP_PREFIX}/sold-pets?id=${advert.id}`}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="130.022"
                                                             height="32.597"
                                                             viewBox="0 0 130.022 32.597">
                                                            <g id="Group_1349" data-name="Group 1349"
                                                               transform="translate(-435.31 -1098.222)">
                                                                <rect id="Rectangle_631" data-name="Rectangle 631"
                                                                      width="130.022"
                                                                      height="32.597" rx="5.446"
                                                                      transform="translate(435.31 1098.222)" fill="#ccc"/>
                                                                <text id="View_sold_pets" data-name="View Sold Pets"
                                                                      transform="translate(449.876 1119.35)" fill="#fff"
                                                                      fontSize="14"
                                                                      fontFamily="NunitoSans-Regular, Nunito Sans">
                                                                    <tspan x="0" y="0">View Sold Pets</tspan>
                                                                </text>
                                                            </g>
                                                        </svg>
                                                    </Link>
                                                    <div>
                                                        <button
                                                            onClick={() => {
                                                                removeListing(advert.id);
                                                            }}
                                                            type="button"
                                                            className="btn btnFormWidth btnFormStyle small-xs red p-0"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="130.022"
                                                                 height="32.597"
                                                                 viewBox="0 0 130.022 32.597">
                                                                <g id="Group_1233" data-name="Group 1233"
                                                                   transform="translate(-456.597 -5002.229)">
                                                                    <rect id="Rectangle_576" data-name="Rectangle 576"
                                                                          width="130.022" height="32.597" rx="5.446"
                                                                          transform="translate(456.597 5002.229)"
                                                                          fill="red"/>
                                                                    <text id="Delete_listing" data-name="Delete listing"
                                                                          transform="translate(471.164 5023.359)"
                                                                          fill="#fff"
                                                                          fontSize="14"
                                                                          fontFamily="NunitoSans-Regular, Nunito Sans">
                                                                        <tspan x="0" y="0">Delete listing</tspan>
                                                                    </text>
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                            <div className="btnbox">
                                                {
                                                    !(advert.status === 'approved' && advert.pet_count === advert.sold_count) &&
                                                    <Link to={`${APP_PREFIX}/sell-pet?id=${advert.id}`}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="35.691"
                                                             height="37.444"
                                                             viewBox="0 0 35.691 37.444">
                                                            <g id="Group_1350" data-name="Group 1350"
                                                               transform="translate(-588.379 -950.888)">
                                                                <path id="Rectangle_632" data-name="Rectangle 632"
                                                                      d="M5,0H28.959a6.732,6.732,0,0,1,6.732,6.732v23.98a6.732,6.732,0,0,1-6.732,6.732H6.732A6.732,6.732,0,0,1,0,30.712V5A5,5,0,0,1,5,0Z"
                                                                      transform="translate(588.379 950.888)"
                                                                      fill="#00d819"/>
                                                                <path id="Path_139045" data-name="Path 139045"
                                                                      d="M612.806,956.3a.722.722,0,0,0-1.021,1.021l1.069,1.07-3.8,3.8-.059-.059-.009-.009-1.879-1.879a.722.722,0,0,0-1.022,1.021l1.378,1.378-9.41,9.409a.723.723,0,0,0-.178.295l-1.839,5.868-2.161,2.162A.722.722,0,0,0,594.9,981.4l2.162-2.162,5.868-1.838a.733.733,0,0,0,.295-.178l9.41-9.41,1.378,1.377a.722.722,0,0,0,1.021-1.021l-1.883-1.883-.006-.006-.06-.061,3.8-3.8,1.071,1.072a.723.723,0,0,0,1.022-1.022l-1.551-1.55-.031-.033-3.005-3.005-.033-.032Zm1.069,3.112,1.984,1.984-3.651,3.651-1.984-1.984Zm-5.39,4.251,3.125,3.125-9.284,9.285-4.55,1.424,1.425-4.549,1.669-1.669,1.562,1.562a.722.722,0,0,0,1.022-1.021l-1.563-1.563,1.115-1.115,1.563,1.563a.722.722,0,1,0,1.021-1.022l-1.562-1.562,1.115-1.115,1.562,1.562a.723.723,0,0,0,1.022-1.022l-1.563-1.562Z"
                                                                      fill="#fff" fillRule="evenodd"/>
                                                            </g>
                                                        </svg>
                                                    </Link>
                                                }
                                                <Link to={advert?.advert_url}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="35.691" height="37.444"
                                                         viewBox="0 0 35.691 37.444">
                                                        <g id="Group_1353" data-name="Group 1353"
                                                           transform="translate(-588.379 -997.086)">
                                                            <path id="Rectangle_633" data-name="Rectangle 633"
                                                                  d="M5,0H28.959a6.732,6.732,0,0,1,6.732,6.732v23.98a6.732,6.732,0,0,1-6.732,6.732H6.732A6.732,6.732,0,0,1,0,30.712V5A5,5,0,0,1,5,0Z"
                                                                  transform="translate(588.379 997.086)" fill="#00d819"/>
                                                            <g id="Group_1352" data-name="Group 1352">
                                                                <g id="Group_1351" data-name="Group 1351">
                                                                    <path id="Path_139046" data-name="Path 139046"
                                                                          d="M595.38,1014.51c2.723-3.21,7.082-6.174,11.451-6.174,4.464,0,8.838,3.039,11.5,6.426a1.816,1.816,0,0,1,0,2.25,19.159,19.159,0,0,1-2.657,2.782,14.687,14.687,0,0,1-8.839,3.527c-2.774,0-6.261-1.632-8.38-3.293a21.136,21.136,0,0,1-3.071-2.984,2.057,2.057,0,0,1,0-2.534Zm10.313-1.2a1.138,1.138,0,1,1-1.138,1.138A1.139,1.139,0,0,1,605.693,1013.314Zm1.138-1.01a3.525,3.525,0,1,1-3.524,3.524A3.524,3.524,0,0,1,606.831,1012.3Zm6.369-.422a7.49,7.49,0,0,1-.1,8.045,16.36,16.36,0,0,0,4.109-3.792.393.393,0,0,0,0-.5A17.072,17.072,0,0,0,613.2,1011.882Zm-12.792,7.806a7.5,7.5,0,0,1,.077-7.845,18.209,18.209,0,0,0-4.022,3.585.665.665,0,0,0,0,.7A18.607,18.607,0,0,0,600.408,1019.688Zm6.423-9.807a5.947,5.947,0,1,1-5.947,5.947A5.948,5.948,0,0,1,606.831,1009.881Z"
                                                                          fill="#fff" fillRule="evenodd"/>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="aaRightWrap">
                                            {
                                                advert.advert_name &&
                                                <h6 className="h6 aaAdvTitle fontNoto">{advert.advert_name}</h6>
                                            }
                                            <ul className="aaAdvdetailList list-unstyled p-0 m-0">
                                                {
                                                    advert.name &&
                                                    <li>Breed: {advert.name}</li>
                                                }
                                                {
                                                    advert.pet_count > 0 &&
                                                    <li>Puppies sold: {advert.sold_count} of {advert.pet_count}</li>
                                                }
                                                {
                                                    advert.dob &&
                                                    <li>Date of birth: {advert.dob}</li>
                                                }
                                                {
                                                    advert.pets &&
                                                    <li>Price from: {' '}
                                                        {
                                                            advert.pets && advert.pets.map((price, index) => (
                                                                <span key={index}>
                                                                       {CURRENCY_SYMBOL}{price?.price?.toFixed(2)}
                                                                    {
                                                                        advert?.pets?.length - 1 !== index &&
                                                                        <span>
                                                                        , {' '}
                                                                        </span>
                                                                    }
                                                                </span>
                                                            ))
                                                        }
                                                    </li>
                                                }
                                                {
                                                    advert.mother_name &&
                                                    <li>Mother: {advert.mother_name}</li>
                                                }
                                                {
                                                    advert.father_name &&
                                                    <li>Father: {advert.father_name}</li>
                                                }
                                                {
                                                    advert.status &&
                                                    <li className="text-capitalize">Status: {advert.status}</li>
                                                }
                                            </ul>
                                        </div>
                                    </article>
                                </li>
                            ))
                        }
                    </ul>
                    {
                        advrtList.length <= 0 &&
                        <p>No data found</p>
                    }
                </div>
            </div>
        </div>
    );

};

export default BreederListedPets;
