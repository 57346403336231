import {API_ENDPOINT_URL} from "../../utils/appConstants";
import {deleteRequest, getRequest, postRequest} from "../../utils/requests";
import {toast} from "react-toastify";

const listAdvertMessageSummary = async (userRole) => {
    const response = await getRequest(`${API_ENDPOINT_URL}/${userRole}/messages/adverts`, true);

    if (response) {
        const adverts = response?.data?.data ?? [];

        // calculate unread message count for each adverts
        adverts.map((advert) => {
            let unreadMessage = 0;
            advert?.chats.map((chat) => {
                unreadMessage += chat.unread_messages_count;
            });
            advert.unreadMessageCount = unreadMessage;
        });

        return adverts;
    }

    return [];
}

const viewAdvertMessages = async (userRole, advertId) => {
    const response = await getRequest(`${API_ENDPOINT_URL}/${userRole}/messages/${advertId}/advert-buyers`, true);

    if (response) {
        return response?.data?.data ?? [];
    }

    return [];
}

const viewConversation = async (user, chatId) => {
    const response = await getRequest(`${API_ENDPOINT_URL}/${user?.role}/messages/${chatId}/advert-conversation`, true);

    if (response) {
        const data = response?.data?.data;
        const messageIds = [];
        const recipientName = evaluateChatUserName(user, data);
        data.messages.map(message => {
            if (message.is_read === 0 && message.sender_id !== user?.id) {
                messageIds.push(message.id);
            }
        });

        markAsRead(user?.role, chatId, messageIds);

        return {
            recipientName: recipientName,
            advert: data.advert,
            messages: data.messages
        };
    }
}

const evaluateChatUserName = (user, data) => {
    let userName = '[username]';
    if (user.role === 'buyer') {
        userName = data?.advert?.user?.first_name ?? '[username]'
    } else if (user.role === 'breeder' || user.role === 'charity') {
        userName = data?.buyer?.first_name ?? '[username]'
    }

    return userName;
}

const markAsRead = (userRole, chatId, messageIds) => {
    if (messageIds.length > 0) {
        postRequest(
            `${API_ENDPOINT_URL}/${userRole}/messages/mark-as-read`,
            {
                messageIds: messageIds,
                chat_id: chatId,
            },
            true,
            {},
            {},
            null,
            false,
        ).then((res) => {
            if (res) {
                //
            }
        });
    }
}

const sendMessage = (userRole, chatId, message) => {
    postRequest(
        `${API_ENDPOINT_URL}/${userRole}/messages/${chatId}/send-message`,
        {
            message,
        },
        true,
        {},
        {},
        null,
        false,
    ).then((res) => {
        if (res) {
            //
        }
    }).catch((err) => {
        // error
    });
}

const arrangeViewing = async (advertId) => {
    let success = false;
    await postRequest(
        `${API_ENDPOINT_URL}/buyer/advert/arrange-viewing`,
        {advertId},
        true
    ).then((res) => {
        if (res) {
            toast.success(res.data.message);
            success = true;
        }
    }).catch((err) => {
        // error
    });

    return success;
}

const unreadMessagesCountApi = async (userRole) => {
    const response = await getRequest(
        `${API_ENDPOINT_URL}/${userRole}/messages/unread-messages-count`,
        true,
    );

    if (response) {
        return response.data.data.unread_messages_count;
    }

    return 0;
}

const deleteChat = async (userRole, chatId) => {
    return await deleteRequest(`${API_ENDPOINT_URL}/${userRole}/messages/${chatId}`, true).then((res) => {
        if(res) {
            toast.success('Chat deleted successfully');
        }
    });
}

export {
    arrangeViewing,
    deleteChat,
    listAdvertMessageSummary,
    viewAdvertMessages,
    viewConversation,
    sendMessage,
    evaluateChatUserName,
    markAsRead,
    unreadMessagesCountApi
};