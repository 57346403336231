import React, { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../../utils/requests";
import {API_ENDPOINT_URL, GOOGLE_KEY} from "../../../../utils/appConstants";
import {enforceFormat, formatToPhone, toFormData, validatePassword} from "../../../../utils";
import { toast } from "react-toastify";
import {Wrapper} from "@googlemaps/react-wrapper";
import {Map} from "../../../../components/shared/map/map.component";
import groupCitiesByCountry from "../../../../utils/groupCitiesByCountry";

const BreederSettings = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("+44");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [county, setCounty] = useState("");
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadImagePreview, setUploadImagePreview] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errors, setErrors] = useState(false);
  const [fetchedCities, setFetchedCities] = useState({});
  const onBoardStatus = JSON.parse(localStorage.getItem("on_board_status"));
  const [showPassword, setShowPassword] = useState(false);
  const [showCP, setShowCP] = useState(false);
  const [showCNP, setShowCNP] = useState(false);

  useEffect(() => {
    getRequest(`${API_ENDPOINT_URL}/basic-listing/cities`).then((res) => {
      setFetchedCities(groupCitiesByCountry(res?.data?.data));
    });
    getRequest(`${API_ENDPOINT_URL}/breeder/profile/view`, true).then((res) => {
      if (res) {
        setFirstName(res.data.data.first_name);
        setLastName(res.data.data.last_name);
        setMobile(res.data.data.phone_number);
        setEmail(res.data.data.email);
        setAddress(res.data.data.address);
        setPostalCode(res.data.data.postal_code);
        setCounty(res.data.data.city_id);
        setUploadImagePreview(
          `${API_ENDPOINT_URL}/image-retrieve/${res.data.data.detail.proof_of_address}`
        );
      }
    });
  }, []);

  // validation
  const validateChangeAddress = () => {
    let errors = {};
    let isValid = true;

    if (!firstName) {
      isValid = false;
      errors["firstName"] = "This filed is required.";
    }
    if (!lastName) {
      isValid = false;
      errors["lastName"] = "This filed is required.";
    }
    if (!mobile) {
      isValid = false;
      errors["mobile"] = "This filed is required.";
    }
    if (mobile) {
      let res = mobile.replace(/\D/g, "");
      if (res.length < 10) {
        isValid = false;
        errors["mobile"] = "Please enter a Valid Phone number.";
      }
    }
    if (!address) {
      isValid = false;
      errors["address"] = "This filed is required.";
    }
    if (!postalCode) {
      isValid = false;
      errors["postalCode"] = "This filed is required.";
    }
    if (!county) {
      isValid = false;
      errors["city"] = "This filed is required.";
    }
    setErrors(errors);
    return isValid;
  };

  const changeAddress = async (e) => {
    e.preventDefault();
    if (validateChangeAddress()) {
      try {
        const geocoder = new window.google.maps.Geocoder();
        const response = await geocoder.geocode({'address': address});

        const unFormattedPhone = mobile
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("-", "")
          .replaceAll(" ", "");

        let requestBody = {
          first_name: firstName,
          last_name: lastName,
          phone_number: unFormattedPhone,
          postal_code: postalCode,
          city_id: county,
          address,
          lat: response.results[0].geometry.location.lat(),
          long: response.results[0].geometry.location.lng(),
        };
        if (uploadImage) {
          requestBody.proof_of_address = uploadImage;
        }
        requestBody = toFormData(requestBody);

        postRequest(
          `${API_ENDPOINT_URL}/breeder/profile/update`,
          requestBody,
          true
        ).then((res) => {
          if (res) {
            toast.success("Address updated successfully");
          }
        });
      } catch (err) {
        console.log(err);
        toast.error("Unable to validate address.");
      }
    }
  };

  const validatePasswords = () => {
    let errors = {};
    let isValid = true;
    const vp = validatePassword(newPassword);

    if (!oldPassword) {
      isValid = false;
      errors["oldPassword"] = "This filed is required.";
    }
    if (!vp.isValid) {
      isValid = false;
      errors["newPassword"] = vp.message;
    }
    if (!newPassword) {
      isValid = false;
      errors["newPassword"] = "This filed is required.";
    }
    if (newPassword !== confirmNewPassword) {
      isValid = false;
      errors["matchPassword"] = "New passwords do not match.";
    }

    setErrors(errors);
    return isValid;
  };

  const changePassword = (e) => {
    e.preventDefault();
    if (validatePasswords()) {
      const requestBody = {
        current_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmNewPassword,
      };
      postRequest(
        `${API_ENDPOINT_URL}/breeder/profile/change-password`,
        requestBody,
        true
      ).then((res) => {
        if (res) {
          toast.success("Password updated successfully");
          setOldPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
        }
      });
    }
  };

  const onBoarding = async (e) => {
    e.preventDefault();
    const response = await postRequest(
      `${API_ENDPOINT_URL}/breeder/on-boarding/create/account`,
      {},
      true
    );
    if (response) {
      window.location.href = response.data.data;
    }
  };

  return (
    <>
      <div className="aaContentWidget forDashboardViews">
        <header className="aaPtbdHeader">
          <h1 className="h3 aaPtbHeading">Account Settings</h1>
        </header>

        <div className="aaDbRow aaDbInfoRow">
          <div className="row margin-bottom-50">
            <div className="col-12 col-md-6">
              <form className="um-bddFormWrap" onSubmit={changeAddress}>
                <label className="um-bddFormlb">Address</label>
                <div className="um-bddInputWrap">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name&hellip;"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    readOnly={true}
                  />
                  {errors.firstName && (
                    <small className="support-error">{errors.firstName}</small>
                  )}
                </div>
                <div className="um-bddInputWrap">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name&hellip;"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    readOnly={true}
                  />
                  {errors.lastName && (
                    <small className="support-error">{errors.lastName}</small>
                  )}
                </div>
                <div className="um-bddInputWrap">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Mobile&hellip;"
                    onKeyUp={formatToPhone}
                    onKeyDown={enforceFormat}
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    readOnly={true}
                  />
                  {errors.mobile && (
                    <small className="support-error">{errors.mobile}</small>
                  )}
                </div>
                <div className="um-bddInputWrap">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email&hellip;"
                    value={email}
                    readOnly={true}
                  />
                </div>
                <div className="um-bddInputWrap">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address 1&hellip;"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    readOnly={true}
                  />
                  {errors.address && (
                    <small className="support-error">{errors.address}</small>
                  )}
                </div>
                <div className="um-bddInputWrap">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Post Code&hellip;"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    readOnly={true}
                  />
                  {errors.postalCode && (
                    <small className="support-error">{errors.postalCode}</small>
                  )}
                </div>
                <div className="form-group um-bddFormSlct">
                  <select
                    className={`form-control  ${errors?.city && "fcError"}`}
                    value={county}
                    onChange={(e) => setCounty(e.target.value)}
                    disabled={true}
                  >
                    <option>Select a county</option>
                    {Object.keys(fetchedCities).map(country => (
                        <optgroup label={country.toUpperCase()} key={country}>
                          {fetchedCities[country].map(city => (
                              <option key={city.id} value={city.id}>
                                {city.name}
                              </option>
                          ))}
                        </optgroup>
                    ))}
                  </select>
                  {errors.county && (
                    <small className="support-error">{errors.county}</small>
                  )}
                </div>
                <div className="um-formBtnWrap">
                  <a className="btn btnFormWidth btnFormStyle" href="mailto:info@petbond.com">Request address change</a>
                </div>
              </form>
            </div>
            <div className="col-12 col-md-6">
              <div className="um-profilePicWrap">
                <div className="poaAdd d-flex align-items-center justify-content-center">
                  Proof of Address
                  <span className="popUpBlock">
                    <i className="umicomoonGroup-1118 qesIcn"></i>
                    <span className="popUp ronded">
                      Please upload a valid proof of address. A utility bill
                      within the last 3 months that clearly shows your name and
                      address.
                    </span>
                  </span>
                </div>
                <div className="um-imgWrap rounded d-flex align-items-center justify-content-center">
                  {uploadImagePreview ? (
                    <img
                      src={uploadImagePreview}
                      className="img-fluid"
                      alt="uploaded-img"
                    />
                  ) : (
                    <i className="umicomoonGroup-1087 empFileIcn" />
                  )}
                </div>
                <div className="um-btnformWrap d-flex">
                  <a
                    href="#"
                    className="btn btnScnWidth btn-info btnScnStyl text-uppercase d-none"
                  >
                    Scan <i className="umicomoonGroup-1095 btnIcnUpload" />
                  </a>
                  <span className="customFileBtnWrap w-100 text-center">
                    <input
                      id="proofOAddress"
                      type="file"
                      accept="image/jpeg"
                      onChange={(e) => {
                        setUploadImage(e.target.files[0]);
                        setUploadImagePreview(
                          URL.createObjectURL(e.target.files[0])
                        );
                      }}
                    />
                    <label
                      htmlFor="proofOAddress"
                      className="fileBtn btn-info text-uppercase"
                    >
                      Upload <i className="umicomoonGroup-1095 btnIcnUpload" />
                    </label>
                  </span>
                </div>
                <p>
                  To submit change of address, proof of new address is required{" "}
                  <strong>(in .jpg)</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-12 col-md-6">
            <form className="um-bddFormWrap" onSubmit={changePassword}>
              <div className="um-bddInputWrap">
                <div className="position-relative icnInputWrap">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Old Password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                  {showPassword === false ? (
                    <i
                      className="far fa-eye-slash icn"
                      onClick={() => setShowPassword(true)}
                    />
                  ) : (
                    <i
                      className="far fa-eye icn"
                      onClick={() => setShowPassword(false)}
                    />
                  )}
                </div>
                {errors.oldPassword && (
                  <small className="support-error">{errors.oldPassword}</small>
                )}
              </div>
              <div className="um-bddInputWrap">
                <div className="position-relative icnInputWrap">
                  <input
                    type={showCP ? "text" : "password"}
                    className="form-control"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  {showCP === false ? (
                    <i
                      className="far fa-eye-slash icn"
                      onClick={() => setShowCP(true)}
                    />
                  ) : (
                    <i
                      className="far fa-eye icn"
                      onClick={() => setShowCP(false)}
                    />
                  )}
                </div>
                <small>{props.passwordInfo}</small>
                {errors.newPassword && (
                  <small className="support-error">{errors.newPassword}</small>
                )}
              </div>
              <div className="um-bddInputWrap extrMbClsii">
                <div className="position-relative icnInputWrap">
                  <input
                    type={showCNP ? "text" : "password"}
                    className="form-control"
                    placeholder="Confirm New Password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                  {showCNP === false ? (
                    <i
                      className="far fa-eye-slash icn"
                      onClick={() => setShowCNP(true)}
                    />
                  ) : (
                    <i
                      className="far fa-eye icn"
                      onClick={() => setShowCNP(false)}
                    />
                  )}
                </div>
                {errors.matchPassword && (
                  <small className="support-error">
                    {errors.matchPassword}
                  </small>
                )}
              </div>
              <div className="um-formBtnWrap">
                <button type="submit" className="btn btnFormWidth btnFormStyle">
                  Change Password
                </button>
              </div>
            </form>
          </div>
          <div className="col-12 col-md-6">
          </div>
        </div>
        </div>
      </div>
      <Wrapper apiKey={GOOGLE_KEY}>
        <Map
          center={{
            lat: 52.3555,
            lng: 1.1743
          }}
          zoom={16}
          style={{flexGrow: '1', height: '0%'}}
          options={'s'}
        >
        </Map>
      </Wrapper>
    </>
  );
};

export default BreederSettings;
