import React, {useEffect, useState} from 'react';
import {Link, Navigate} from "react-router-dom";
import {deleteChat, listAdvertMessageSummary} from "../../../services/authorized/chat.service";

const ListAdvertMessagesSummary = () => {
    const userRole = JSON.parse(localStorage.getItem("currentUser"))?.role;
    const [advertMessageList, setAdvertMessageList] = useState([]);
    const isBuyer = userRole === 'buyer';

    const doNotLoad = userRole !== 'breeder' && userRole !== 'buyer' && userRole !== 'charity';

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        // Make a copy of the original array
        const arrClone = [...advertMessageList];

        // Find the index of the object to modify
        const objectIndex = arrClone.findIndex(obj => obj.id === window.unreadMessagesObj?.advert_info?.advert_id);

        if (objectIndex !== -1) {
            // Create a copy of the object to modify
            const modifiedObject = { ...arrClone[objectIndex] };

            // Make the necessary modifications
            modifiedObject.unreadMessageCount = window.unreadMessagesObj.advert_info.unread_count;
            modifiedObject.messages_count = modifiedObject.unreadMessageCount;

            // Replace the object in the new array
            arrClone[objectIndex] = modifiedObject;

            // Update the state with the new array
            setAdvertMessageList(prevState => arrClone);
        } else {
            // reload advert to get latest data in case new advert (advert message in case of buyer) is created
            loadData();
        }
    }, [window.unreadMessagesObj]);

    if (doNotLoad) {
        return <Navigate to='/' />;
    }

    const loadData = () => {
        if (!doNotLoad) {
            listAdvertMessageSummary(userRole).then((adverts) => {
                setAdvertMessageList(adverts);
            });
        }
    };

    const generateViewURL = (advertMessage) => {
        if (isBuyer) {
            return `/app/messages/view-chat/${advertMessage.chats[0].id}`;
        }

        return `/app/messages/view-advert/${advertMessage?.id}`;
    };

    const hasMessages = (chats) => {
        let found = false;
        if (chats.length > 0) {
            for (let chat of chats) {
                if (chat.messages_count > 0) {
                    found = true;
                    break;
                }
            }
        }

        return found;
    }

    const removeChat = (chatId) => {
        deleteChat(userRole, chatId).then(() => {
            loadData();
        });
    }

    return (
        <div className="adMessageWrap">
            <div className="aaContentWidget">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Advert Messages</h1>
                </header>
                <ul className="adMessageLists">
                    {
                      advertMessageList && advertMessageList.map((advertMessage, index) => (
                        <li key={index}>
                            <span className="msgText">{advertMessage.id === null ? 'Advert has been removed' : advertMessage.advert_name}</span>
                            {advertMessage.id !== null &&
                              <div className="messageCountWrap">
                                  <Link
                                    to={(hasMessages(advertMessage.chats) || (advertMessage.messages_count ?? 0) > 0) ? generateViewURL(advertMessage) : '#'}
                                    className="btn btn-warning btnMessageWidth"
                                  >{(hasMessages(advertMessage.chats) || (advertMessage.messages_count ?? 0) > 0) ? 'View Messages' : 'No Messages'}</Link>
                                  {advertMessage.unreadMessageCount > 0 &&  <span className="messageCount">{advertMessage.unreadMessageCount > 99 ? '+99' : advertMessage.unreadMessageCount}</span>}
                              </div>
                            }
                            {advertMessage.id === null &&
                              <div className="messageCountWrap">
                                  <button type="button" className="btn btn-danger btnMessageWidth" onClick={() => removeChat(advertMessage.chats[0].id)}>Delete</button>
                              </div>
                            }
                        </li>
                      ))
                    }
                    {
                        advertMessageList.length === 0 && <li>No messages</li>
                    }
                </ul>
            </div>
        </div>
    );
};

export default ListAdvertMessagesSummary;