import { Link } from 'react-router-dom';
import { AUTH_PREFIX } from '../../../utils/appConstants';
import { Helmet } from 'react-helmet-async';
import React from 'react';

const SignupSelector = () => {

    return (
        <section className="um-sectionWrap um-pbrRegForm pb-10 pb-md-15 pb-lg-20">
            <Helmet>
                <title>Registration | Dog Breeders, Vets & Rescue Centre | PetBond</title>
                <meta name="description"
                      content="Please register here if you are dog breeder, vet or a rescue centre interested in providing a listing or partnering with us."/>
            </Helmet>
            <div className="container">
                <div className="um-regMethodBlock text-center">
                    <h1 className="h2">Register an account with Petbond</h1>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 d-md-flex">
                            <article className="um-vlbAsidWrap rounded shadow w-100 d-md-flex flex-md-column">
                                <div className="flex-grow-1">
                                    <span className="vlbsIcnWrap"><i className="aricomoon-userSolid vlbIcn"></i></span>
                                    <h3>Buyer</h3>
                                    <p>Create Pet Alerts with PetBond and get email alerts when your new best friend is
                                        available to view on <a href="https://mypetbond.com/">MyPetBond.com</a></p>
                                </div>
                                <div className="um-formBtnWrap">
                                    <Link className="btn btnFormWidth btn-warning btnFormStyle"
                                          to={`${AUTH_PREFIX}/buyer-signup`}>
                                        Register
                                    </Link>
                                </div>
                            </article>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 d-md-flex">
                            <article className="um-vlbAsidWrap rounded shadow w-100 d-md-flex flex-md-column">
                                <div className="flex-grow-1">
                                    <span className="vlbsIcnWrap"><i className="aricomoon-pet vlbIcn"></i></span>
                                    <h3>Seller / Breeder</h3>
                                    <p>If you wish to be recognised as a PetBond Approved Breeder / Approved Hobby Breeder
                                        and Advertise on Petbond</p>
                                </div>
                                <div className="um-formBtnWrap">
                                    <Link className="btn btnFormWidth btn-warning btnFormStyle"
                                          to={`${AUTH_PREFIX}/breeder-signup`}>
                                        Register
                                    </Link>
                                </div>
                            </article>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 d-md-flex">
                            <article className="um-vlbAsidWrap rounded shadow w-100 d-md-flex flex-md-column">
                                <div className="flex-grow-1">
                                    <span className="vlbsIcnWrap"><i className="aricomoon-umheatbeat vlbIcn"></i></span>
                                    <h3>Veterinary Practice</h3>
                                    <p>If your veterinary practice would like to sign up to PetBond and be listed as a
                                        Petbond Recommended Veterinary Practice</p>
                                </div>
                                <div className="um-formBtnWrap">
                                    <Link className="btn btnFormWidth btn-warning btnFormStyle"
                                          to={`${AUTH_PREFIX}/vet-signup`}>
                                        Register
                                    </Link>
                                </div>
                            </article>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 d-md-flex">
                            <article className="um-vlbAsidWrap rounded shadow w-100 d-md-flex flex-md-column">
                                <div className="flex-grow-1">
                                    <span className="vlbsIcnWrap"><i className="aricomoon-umhomedoor vlbIcn"></i></span>
                                    <h3>Charity or Rescue Centre</h3>
                                    <p>If you are a charity or rescue centre that is interested in partnering to become a
                                        Petbond approved charity / rescue centre</p>
                                </div>
                                <div className="um-formBtnWrap">
                                    <Link className="btn btnFormWidth btn-warning btnFormStyle"
                                          to={`${AUTH_PREFIX}/charity-signup`}>
                                        Register
                                    </Link>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

};

export default SignupSelector;
