import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../../../utils/requests';
import { API_ENDPOINT_URL } from '../../../../utils/appConstants';
import {CURRENCY_SYMBOL} from "../../../../utils/appConstants";

const BuyerPaymentsDeposit = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        getRequest(`${API_ENDPOINT_URL}/buyer/sales/list`, true).then((res) => {
            if (res) {
                setOrders(res.data.data);
            }
        });
    }, []);

    const payRemaining = (order_id) => {
        postRequest(`${API_ENDPOINT_URL}/buyer/checkout/remaining/session`, {order_id}, true).then((res) => {
            if (res) {
                window.location.href = res.data.data.url;
            }
        });
    };

    return (
        <>
            <div className="aaContentWidget buyPaymentDeposit">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">
                        Final Payments &amp; Deposit
                    </h1>
                </header>
                <div className="aaDbRow aaDbPetsListRow">
                    <ul className="aaAdvtPetList list-unstyled m-0 p-0">
                        {
                            orders?.map((order, index) => (
                                <li key={index}>
                                    <article className="aaAdvPetPost mb-3">
                                        <div className="aaLeftWrap nospace">
                                            <div className="petImgWrap">
                                                <img
                                                    src={order?.pet?.photo
                                                        ? `${API_ENDPOINT_URL}/image-retrieve/${order?.pet?.photo}`
                                                        : '/images/image-placeholder.png'
                                                    }
                                                    className="img-fluid"
                                                    alt="img description"
                                                />
                                            </div>
                                        </div>
                                        <div className="aaRightWrap">
                                            {
                                                order?.pet?.micro_chip?.breed?.name && (
                                                    <h6 className="h6 aaAdvTitle fontNoto">
                                                        Breed Name:{' '}
                                                        {order?.pet?.micro_chip?.breed?.name}
                                                    </h6>)
                                            }
                                            <ul className="aaAdvdetailList list-unstyled p-0 m-0">
                                                {
                                                    order?.pet?.name && (
                                                        <li>
                                                            Puppy Name:{' '}
                                                            {order?.pet?.name}
                                                        </li>
                                                    )
                                                }
                                                {
                                                    order?.pet?.adverts[0]?.dob && (
                                                        <li>
                                                            Date of birth:{' '}
                                                            {order?.pet?.adverts[0]?.dob}
                                                        </li>
                                                    )
                                                }
                                                {
                                                    (order?.pet?.micro_chip?.chip_number && !order?.pet?.micro_chip?.chip_number?.includes('pet_bond_chip')) && (
                                                        <li>
                                                            Chip Number:{' '}
                                                            {order?.pet?.micro_chip?.chip_number}
                                                        </li>
                                                    )
                                                }
                                                {
                                                    order?.type && (
                                                        <li>
                                                            Deposit Paid:{' '}
                                                            {order?.type === 'full' ? 'No' : 'Yes'}
                                                        </li>
                                                    )
                                                }
                                                {
                                                    order?.type && (
                                                        <li>
                                                            Full Amount Paid:{' '}
                                                            {order?.type === 'full' ? 'Yes' : 'No'}
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            {
                                                order?.remaining_amount === 0 ?
                                                    <div className="btnWrap pt-2">
                                                        <span className="badge badge-success text-white">
                                                            Paid
                                                        </span>
                                                    </div> :
                                                    <div className="btnWrap pt-2">
                                                        <button type="button"
                                                                onClick={() => payRemaining(order.total_transactions_price?.order_id)}
                                                                className="btn small btn-warning text-white">
                                                            Pay Balance {CURRENCY_SYMBOL}{order?.remaining_amount}
                                                        </button>
                                                    </div>
                                            }
                                        </div>
                                    </article>
                                </li>
                            ))
                        }
                    </ul>
                    {orders.length <= 0 && <p>No data found</p>}
                </div>
            </div>
        </>
    );
};

export default BuyerPaymentsDeposit;
