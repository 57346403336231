import { Link } from 'react-router-dom';
import { APP_PREFIX } from '../../../../utils/appConstants';

const VetOverview = () => {

    return (
        <>
			<div className="aaContentWidget">
				<header className="aaPtbdHeader">
					<h1 className="h3 aaPtbHeading">Welcome to Pet Bond</h1>
				</header>
				<div className="aaDbRow aaDbInfoRow bitches-attach mb-5">
					<div className="um-formBtnWrap mb-2">
						<Link to={`${APP_PREFIX}/register-breeder`} className="btn btnFormWidth btnFormStyle small">
	                        Register a Breeder Account with Petbond
						</Link>
					</div>
					<div className="um-formBtnWrap mb-2">
						<Link to={`${APP_PREFIX}/connected-breeder`} className="btn btnFormWidth btnFormStyle small">
	                        View Beeders connected to this account
						</Link>
					</div>
					<div className="um-formBtnWrap mb-2">
						<Link to={`${APP_PREFIX}/settings`} className="btn btnFormWidth btnFormStyle small">
	                        Add Staff to account
						</Link>
					</div>
				</div>
			</div>
        </>
    );

};

export default VetOverview;
