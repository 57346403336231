import React, { useEffect, useState } from 'react';
import { useInput } from '../../../components/hooks/useInput';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { postRequest } from '../../../utils/requests';
import { Link, useNavigate } from 'react-router-dom';
import {API_ENDPOINT_URL, APP_PREFIX, APP_URL, AUTH_PREFIX, RECAPTCHA_SITE_KEY} from '../../../utils/appConstants';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

const Login = (props) => {
    const [email, bindEmail] = useInput('');
    const [password, bindPassword] = useInput('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const chatRedirectUrl = urlParams.get('redirectToChat');

    useEffect(() => {
        const captchaScript = document.createElement('script');
        captchaScript.src = 'https://www.google.com/recaptcha/api.js';
        captchaScript.async = true;
        captchaScript.defer = true;
        document.body.appendChild(captchaScript);
        props?.captcha?.setIsCaptchaValid(false);
        return (() => {
            document.body.removeChild(captchaScript);
        });
    }, []);

    const validateLogin = (login = true) => {
        let errors = {};
        let isValid = true;
        if (!email) {
            isValid = false;
            errors['email'] = 'Please enter your email address.';
        }
        if (email) {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,8}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(email)) {
                isValid = false;
                errors['email'] = 'Please enter valid email address.';
            }
        }
        if (!password && login) {
            isValid = false;
            errors['password'] = 'Please enter your password.';
        }
        setErrors(errors);
        return isValid;
    };

    const loginHandler = (event) => {
        event.preventDefault();
        if (validateLogin() && props?.captcha?.isCaptchaValid) {
            setShowLoader(true);
            if (email && password) {
                postRequest(`${API_ENDPOINT_URL}/login`, {email, password}).then((res) => {
                    setShowLoader(false);
                    if (res) {
                        if (res.data.data.user.role !== 'admin') {
                            props?.captcha?.setIsCaptchaValid(false);
                            localStorage.setItem('currentUser', JSON.stringify(res.data.data.user));
                            localStorage.setItem('access_token', res.data.data.access_token);
                            localStorage.setItem('on_board_status', res.data.data.on_board_status);
                            localStorage.setItem('is_transfer_enabled', res.data.data.is_transfer_enabled);

                            if (chatRedirectUrl) {
                                navigate(chatRedirectUrl.replace(APP_URL, ''));
                            } else if (res?.data?.data?.user?.role === 'sub_veterinarian') {
                                navigate(`${APP_PREFIX}/connected-breeder`);
                            } else {
                                navigate(`${APP_PREFIX}/overview`);
                            }
                        } else {
                            toast.error('Access denied');
                        }
                    }
                });
            }
        }
    };

    const forgotEmail = () => {
        if (validateLogin(false)) {
            postRequest(`${API_ENDPOINT_URL}/forgot/email`, {email}).then((res) => {
                if (res) {
                    toast.success(res?.data?.message);
                }
            });
        }
    };

    return (
        <section className="um-sectionWrap um-loginPgsSec pb-15">
            <Helmet>
                <title>Login | Petbond</title>
                <meta name="description" content="Login in to your PetBond Account."/>
            </Helmet>
            <div className="container">
                <div className="um-lgLogWrap rounded shadow">
                    <header className="um-formHddComp text-center">
                        <h1 className="h2">{(chatRedirectUrl ? 'Login to use Chat' : 'Petbond Login')}</h1>
                    </header>
                    <form className="um-loginPgForm" onSubmit={(event) => loginHandler(event)}>
                        <div className="um-logInWrap">
                            <label className="form-label">Email</label>
                            <input
                                type="email"
                                placeholder="Email Address"
                                className={`form-control  ${errors.email && 'fcError'}`}
                                {...bindEmail}
                            />
                            {errors.email && (
                                <div data-testid="emailError" className="formErrors">{errors.email}</div>
                            )}
                        </div>
                        <div className="um-logInWrap">
                            <label className="form-label">Password</label>
                            <div className="position-relative icnInputWrap">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    className={`form-control  ${errors.password && 'fcError'}`}
                                    {...bindPassword}
                                />
                                {
                                    showPassword === false ?
                                        <i className="far fa-eye-slash icn" onClick={() => setShowPassword(true)}/>
                                        : <i className="far fa-eye icn" onClick={() => setShowPassword(false)}/>
                                }
                            </div>
                            {errors.password && (
                                <div data-testid="pwdError" className="formErrors">{errors.password}</div>
                            )}
                            <small>
                                <button type="button" className="linkonly" onClick={forgotEmail}>
                                    Forgot your password?
                                </button>
                            </small>
                        </div>
                        <div className="mt-2 g-recaptcha" data-sitekey={RECAPTCHA_SITE_KEY}
                             data-callback="captchaConfirmation"/>
                        <div className="um-formBtnWrap">
                            <button
                                type="submit"
                                className="btn w-100 btn-warning"
                                disabled={showLoader}
                            >
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: 'inline-block',
                                            marginRight: '5px'
                                        }}
                                    />
                                )}
                                Sign in
                            </button>
                        </div>
                        {
                            chatRedirectUrl &&
                            <div className="um-formBtnWrap">
                                <Link className="btn w-100 btn-primary"
                                      to={`${AUTH_PREFIX}/buyer-signup`}>
                                    Register Account
                                </Link>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Login;
