import {postRequest} from "../utils/requests";
import {API_ENDPOINT_URL} from "../utils/appConstants";

const trackerService = {
    async track(event, properties = null) {
        return postRequest(`${API_ENDPOINT_URL}/track`, {
            event,
            properties
        }, true, {}, {}, '', false, false)
    },

   async pageview(url, properties = []) {
        properties = {
            url,
            ...properties,
        }

        return this.track('pageview', properties);
    }
}

export default trackerService;
