import React from "react";

const PetbondBenefitItem = ({icon, benefit, className}) => {
    return (
        <li className={className}>
            {icon}
            <span>{benefit}</span>
        </li>
    )
}

export default PetbondBenefitItem;
