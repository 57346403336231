import React, { useState, useEffect, useRef } from "react";
import { getRequest } from "../../utils/requests";
import { API_ENDPOINT_URL, GOOGLE_KEY } from "../../utils/appConstants";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Map } from "../../components/shared/map/map.component";
import { Marker } from "../../components/shared/map/marker.component";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import {Link} from "react-router-dom";

const FindAVet = () => {
  const [mapPins, setMapPins] = useState([]);
  const [zoom, setZoom] = useState(3); // initial zoom
  const [radiusField, setRadiusField] = useState("");
  const [searchField, setSearchField] = useState("");

  useEffect(() => {
    getRequest(`${API_ENDPOINT_URL}/f-end/veterinarian/list`).then((res) => {
      if (res) {
        setMapPins(
          res?.data?.data?.map((data) => ({
            lat: Number(data.lat),
            lng: Number(data.long),
            payload: data,
          }))
        );
      }
    });
  }, []);

  const searchVet = async (e) => {
    e.preventDefault();
    if (radiusField && searchField) {
      const geocoder = new window.google.maps.Geocoder();
      const { results, status } = await geocoder.geocode({
        address: searchField,
      });
      const latitude = results[0]?.geometry?.location?.lat();
      const longitude = results[0]?.geometry?.location?.lng();
      getRequest(`${API_ENDPOINT_URL}/f-end/veterinarian/list`, false, {
        lat: latitude,
        lng: longitude,
        radius: radiusField,
      }).then((res) => {
        if (res) {
          setMapPins(
            res?.data?.data?.map((data) => ({
              lat: Number(data.lat),
              lng: Number(data.long),
              payload: data,
            }))
          );
        }
      });
    } else {
      toast.error("Enter complete details to search a veterinarian");
    }
  };

  return (
    <>
      <Helmet>
        <title>Find a Local Vet | Vets In Your Area | PetBond UK</title>
        <meta
          name="description"
          content="Find a vet in your area. Register as a PetBond Recommended Vet and reach over 30,000 visitors a month. Together we can improve animal welfare."
        />
      </Helmet>
      <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
        <div className="container">
          <div className="arextraWrap">
            <h2 className="h1">
              from good homes… <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes
            </h2>
          </div>
          <div className="arbhImgHolder d-none d-md-block position-absolute">
            <img src="/images/img07.png" className="img-fluid" alt="petbond" />
          </div>
        </div>
        <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
          <i className="aricomoon-hearts">
            <span className="sr-only">icon</span>
          </i>
        </span>
        <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
          <i className="aricomoon-starDots">
            <span className="sr-only">icon</span>
          </i>
        </span>
      </header>

      <div className="container">
        <div className="vet-cta">
          <div className="fontFredoka">Join the cause and help find good homes for good pets</div>
          <div className="vet-cta__btn">
            <Link
                to="/auth/vet-signup"
                className="btn btnFormWidth btn-primary btnFormStyle w-100"
                type="submit"
            >
              Get your practice listed
            </Link>
          </div>
        </div>
      </div>

      <article className="um-fndMapDirect pb-10 pb-md-15 pb-lg-20 pt-lg-5">
        <div className="container">
          <div className="um-thkPageWrap rounded shadow">
            <header className="text-center fndMapHd mb-8">
              <h1 className="h2">
                Find a Petbond <br />
                Recommended Vet
              </h1>
            </header>
            <form className="um-thkPageForm mb-5" onSubmit={searchVet}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group um-logInWrap">
                    <label
                      htmlFor="exampleFormControlInput1999"
                      className="form-label"
                    >
                      Enter your address
                    </label>
                    <input
                      className="form-control"
                      value={searchField}
                      placeholder="Enter a location"
                      onChange={(event) => setSearchField(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group um-bddFormSlct um-slctEtcDesg">
                    <label
                      htmlFor="exampleFormControlInput1945"
                      className="form-label"
                    >
                      Travel distance
                    </label>
                    <select
                      className="form-control"
                      value={radiusField}
                      onChange={(e) => setRadiusField(e.target.value)}
                    >
                      <option value="">Select distance&hellip;</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="um-formBtnWrap">
                    <button
                      className="btn btnFormWidth btn-warning btnFormStyle w-100"
                      type="submit"
                    >
                      Find Veterinarian
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div
              className="um-bddInputWrap margin-bottom-20 mt-4"
              style={{ height: "500px" }}
            >
              <Wrapper apiKey={GOOGLE_KEY}>
                <Map
                  zoom={zoom}
                  style={{ flexGrow: "1", height: "100%" }}
                  options={"s"}
                >
                  {mapPins.map((map, i) => (
                    <Marker
                      mapPins={mapPins}
                      key={i}
                      drag={false}
                      position={map}
                      icon="/images/mapPin.png"
                      infoWindow={true}
                    />
                  ))}
                </Map>
              </Wrapper>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default FindAVet;
