import React, { useEffect, useState } from 'react';
import { postRequest, getRequest, deleteRequest } from '../../../../utils/requests';
import { API_ENDPOINT_URL } from '../../../../utils/appConstants';
import { toast } from 'react-toastify';
import AdvertList from './AdvertList';

const VetConnectedBreeder = () => {
    const [connectedRegisteredBreeder, setConnectedRegisteredBreeder] = useState([]);
    const [searchBreeder, setSearchBreeder] = useState('');
    const [connectedBreeder, setConnectedBreeder] = useState('');
    const [email, setEmail] = useState('');
    const [advrtList, setAdvertList] = useState([]);
    const [showAdvList, setShowAdvList] = useState(false);
    const [breederId, setBreederId] = useState('');

    useEffect(() => {
        getRequest(
            `${API_ENDPOINT_URL}/veterinarian/connect/allConnects`,
            true
        ).then((res) => {
            setConnectedRegisteredBreeder(res?.data?.data);
        });
    }, []);

    const searchConnectBreeder = (event) => {
        event.preventDefault();

        let requestBody = {
            email: email
        };

        postRequest(`${API_ENDPOINT_URL}/veterinarian/connect/search`, requestBody, true).then((res) => {
            if (res) {
                setSearchBreeder(res.data.data);
            } else {
                setSearchBreeder('');
            }
            setEmail('');
        });
    };

    const connectBreeder = (event) => {
        event.preventDefault();

        let requestBody = {
            breeder_id: searchBreeder.id
        };

        postRequest(
            `${API_ENDPOINT_URL}/veterinarian/connect/connectUser`,
            requestBody,
            true
        ).then((res) => {
            if (res) {
                setConnectedBreeder(searchBreeder);
                toast.success('Breeder connected successfully');
                window.location.reload();
            } else {
                toast.error('Breeder already connected');
            }
        });
    };

    // removing connect api call
    const removeConnection = (id) => {
        deleteRequest(
            `${API_ENDPOINT_URL}/veterinarian/connect/removeConnect/${id}`,
            true
        ).then((res) => {
            if (res) {
                toast.success('Connection removed successfully');
                window.location.reload();
            }
        });
    };

    // get advert function
    const getAdvertList = (id) => {
        setShowAdvList(true);
        setBreederId(id);
        getRequest(
            `${API_ENDPOINT_URL}/veterinarian/connect/breeder/advert/${id}`,
            true
        ).then((res) => {
            if (res) {
                setAdvertList(res?.data?.data);
            }
        });
    };

    return (
        <>
            <div className="aaContentWidget">
                {showAdvList ? (
                    <>
                        <AdvertList
                            breederId={breederId}
                            setShowAdvList={setShowAdvList}
                            advrtList={advrtList}
                        />
                    </>
                ) : (
                    <>
                        <header className="aaPtbdHeader">
                            <h1 className="h3 aaPtbHeading">
                                Connect to Breeder
                            </h1>
                        </header>
                        <div className="connectbreeder">
                            <form
                                onSubmit={(event) =>
                                    searchConnectBreeder(event)
                                }
                            >
                                <div className="row margin-bottom-10">
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <input
                                                value={email}
                                                type="email"
                                                className="form-control"
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                                placeholder="Breeder Email Address...."
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <button
                                                type="submit"
                                                className="btn btnFormWidth btnFormStyle small"
                                            >
                                                Search for Breeder
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="row margin-bottom-50">
                                <div className="col-12 col-md-12">
                                    <h3>Search Results</h3>
                                    {searchBreeder ? (
                                        <div className="overflow-hidden py-2">
                                            <span className="left float-left">
                                                {searchBreeder.first_name}{' '}
                                                {searchBreeder.last_name} -{' '}
                                                {searchBreeder.email} -{' '}
                                                {searchBreeder.phone_number}
                                            </span>
                                            <span className="right float-right">
                                                {
                                                    !!connectedRegisteredBreeder.find(crb => crb.id === searchBreeder.id) ?
                                                        <div className="right">
                                                            <button
                                                                onClick={() => {
                                                                    removeConnection(
                                                                        searchBreeder.id
                                                                    );
                                                                }}
                                                                type="button"
                                                                className="btn btnFormWidth btnFormStyle small-xs red"
                                                            >
                                                                Remove
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => getAdvertList(searchBreeder.id)}
                                                                className="btn btnFormWidth btnFormStyle small-xs"
                                                            >
                                                                View Adverts
                                                            </button>
                                                        </div> :
                                                        <button
                                                            onClick={(event) => {
                                                                connectBreeder(event);
                                                            }}
                                                            type="button"
                                                            className="btn btnFormWidth btnFormStyle small-xs"
                                                        >
                                                            Connect to Breeder
                                                        </button>
                                                }
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                        <header className="aaPtbdHeader">
                            <h1 className="h3 aaPtbHeading">
                                Connected Breeder
                            </h1>
                        </header>
                        <div className="connectbreeder">
                            <ul className="list-unstyled ftList">
                                {connectedRegisteredBreeder &&
                                    connectedRegisteredBreeder.map(
                                        (breeder, index) => (
                                            <li key={index}>
                                                <span className="left">
                                                    {breeder.first_name}{' '}
                                                    {breeder.last_name} -{' '}
                                                    {breeder.email} -{' '}
                                                    {breeder.phone_number}
                                                </span>
                                                <div className="right">
                                                    <button
                                                        onClick={() => {
                                                            removeConnection(
                                                                breeder.id
                                                            );
                                                        }}
                                                        type="button"
                                                        className="btn btnFormWidth btnFormStyle small-xs red"
                                                    >
                                                        Remove
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => getAdvertList(breeder.id)}
                                                        className="btn btnFormWidth btnFormStyle small-xs"
                                                    >
                                                        View Adverts
                                                    </button>
                                                </div>
                                            </li>
                                        )
                                    )}
                                {connectedBreeder ? (
                                    <li>
                                        <span className="left">
                                            {connectedBreeder.first_name} -{' '}
                                            {connectedBreeder.last_name} -{' '}
                                            {connectedBreeder.email} -{' '}
                                            {connectedBreeder.phone_number}
                                        </span>
                                        <div className="right">
                                            <a
                                                href="#"
                                                className="btn btnFormWidth btnFormStyle small-xs red"
                                            >
                                                Remove
                                            </a>
                                            <a
                                                href="#"
                                                className="btn btnFormWidth btnFormStyle small-xs"
                                            >
                                                View Adverts
                                            </a>
                                        </div>
                                    </li>
                                ) : (
                                    ''
                                )}
                            </ul>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default VetConnectedBreeder;
