import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { postRequest } from "../../utils/requests";
import { API_ENDPOINT_URL, RECAPTCHA_SITE_KEY } from "../../utils/appConstants";
import { toast } from "react-toastify";
import Logo from "../../assets/images/logo.png";
import pO from "../../assets/images/pO.png";
import oO from "../../assets/images/oO.png";
import {enforceFormat, formatToPhone} from "../../utils";

const ContactUs = (props) => {
  const [query, setQuery] = useState({
    name: "",
    email: "",
    message: "",
    phone: "+44",
  });
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    const captchaScript = document.createElement("script");
    captchaScript.src = "https://www.google.com/recaptcha/api.js";
    captchaScript.async = true;
    captchaScript.defer = true;
    document.body.appendChild(captchaScript);
    props?.captcha?.setIsCaptchaValid(false);
    return () => {
      document.body.removeChild(captchaScript);
    };
  }, []);

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!query.name) {
      isValid = false;
      errors["name"] = "This field is required";
    }
    if (!query.message) {
      isValid = false;
      errors["message"] = "This field is required";
    }
    if (!query.phone) {
      isValid = false;
      errors["phone"] = "This filed is required.";
    }
    if (query.phone) {
      let res = query.phone.replace(/\D/g, "");
      if (res.length < 10) {
        isValid = false;
        errors["phone"] = "Please enter a Valid Phone number.";
      }
    }
    if (!query.email) {
      isValid = false;
      errors["email"] = "This field is required";
    }

    setErrors(errors);
    return isValid;
  };

  const sendQuery = (event) => {
    event.preventDefault();
    if (validate() && props?.captcha?.isCaptchaValid) {
      postRequest(`${API_ENDPOINT_URL}/f-end/contact-us`, query).then((res) => {
        if (res) {
          toast.success("Your message has been sent successfully");
          window.location.reload();
        }
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | PetBond</title>
        <meta
          name="description"
          content="PetBond is the safest and most ethical way to buy dogs in UK. We work with buyers, breeders, and pet professionals. Contact us with questions!"
        />
      </Helmet>
      {/* HEADER */}
      <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
        <div className="container">
          <div className="arextraWrap">
            <h2 className="h1">
              from good homes… <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes
            </h2>
          </div>
          <div className="arbhImgHolder d-none d-md-block position-absolute">
            <img src="/images/img07.png" className="img-fluid" alt="petbond" />
          </div>
        </div>
        <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
          <i className="aricomoon-hearts">
            <span className="sr-only">icon</span>
          </i>
        </span>
        <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
          <i className="aricomoon-starDots">
            <span className="sr-only">icon</span>
          </i>
        </span>
      </header>
      {/* MAIN */}
      <section className="contactUsBlock mb-10 mt-10">
        <div className="container">
          <div className="aaContentWidget bg-warning">
            <img
              className="logoimagei img-fluid mb-3 "
              src={Logo}
              width="250"
              alt="petbond"
            />
            <h4 className="mb-4">General Inquiries</h4>

            <form onSubmit={sendQuery}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control shadow-sm w-100 d-block"
                  placeholder="Name*"
                  value={query.name}
                  onChange={(event) =>
                    setQuery({ ...query, name: event.target.value })
                  }
                />
                {errors.name && (
                  <small className="support-error">{errors.name}</small>
                )}
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control shadow-sm w-100 d-block"
                  placeholder="Email*"
                  value={query.email}
                  onChange={(event) =>
                    setQuery({ ...query, email: event.target.value })
                  }
                />
                {errors.email && (
                  <small className="support-error">{errors.email}</small>
                )}
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  id="phone"
                  placeholder="Phone*"
                  className={`shadow-sm form-control w-100 d-block ${
                    errors?.phone && "fcError"
                  }`}
                  maxLength={14}
                  onKeyUp={formatToPhone}
                  onKeyDown={enforceFormat}
                  value={query.phone}
                  onChange={(event) =>
                    setQuery({ ...query, phone: event.target.value })
                  }
                />
                {errors.phone && (
                  <small className="support-error">{errors.phone}</small>
                )}
              </div>
              <div className="form-group">
                <textarea
                  className="form-control w-100 d-block shadow-sm"
                  placeholder="Message"
                  value={query.message}
                  onChange={(event) =>
                    setQuery({ ...query, message: event.target.value })
                  }
                />
                {errors.message && (
                  <small className="support-error">{errors.message}</small>
                )}
              </div>
              <div
                className="my-3 g-recaptcha"
                data-sitekey={RECAPTCHA_SITE_KEY}
                data-callback="captchaConfirmation"
              />
              <div className="form-group mt-5">
                <button
                  type="submit"
                  className="btn btn-primary shadow text-left arbtnHasIcn"
                >
                  Send Message
                </button>
              </div>
            </form>
            {/* BOXES */}
            <div className="row mt-5 d-flex">
              {/*  */}
              <div className="col-12 col-xl-4 mb-8 mb-xl-0 flex-fill">
                <div
                  className="mw-md mx-auto p-5 rounded bg-white shadow-sm"
                  style={{ height: "250px" }}
                >
                  <div className="d-sm-flex">
                    <div className="mt-3">
                      <h5 className="text-black mb-6">
                        <img
                          src={oO}
                          className="img-fluid"
                          alt="dogstrust"
                          style={{ height: "30px" }}
                        />{" "}
                        Address
                      </h5>
                      <p className="text-secondary-light">
                        {" "}
                        <img
                          src={pO}
                          className="img-fluid"
                          alt="dogstrust"
                          style={{ height: "15px" }}
                        />{" "}
                        PetBond, 20 Bugsby's Way, London SE7 7SJ
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="col-12 col-xl-4 mb-8 mb-xl-0">
                <div
                  className="mw-md mx-auto p-5 rounded bg-white shadow-sm"
                  style={{ height: "250px" }}
                >
                  <div className="d-sm-flex">
                    <div className="mt-3">
                      <h5 className="text-black mb-6">
                        {" "}
                        <img
                          src={oO}
                          className="img-fluid"
                          alt="dogstrust"
                          style={{ height: "30px" }}
                        />{" "}
                        Email
                      </h5>
                      <p className="text-secondary-light">
                        {" "}
                        <img
                          src={pO}
                          className="img-fluid"
                          alt="dogstrust"
                          style={{ height: "15px" }}
                        />{" "}
                        hello@mypetbond.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="col-12 col-xl-4 mb-8 mb-xl-0">
                <div
                  className="mw-md mx-auto  p-5 rounded bg-white shadow-sm"
                  style={{ height: "250px" }}
                >
                  <div className="d-sm-flex">
                    <div className="mt-3">
                      <h5 className="text-black mb-6">
                        {" "}
                        <img
                          src={oO}
                          className="img-fluid"
                          alt="dogstrust"
                          style={{ height: "30px" }}
                        />{" "}
                        Socials
                      </h5>
                      {/* <a
                        href="https://www.instagram.com/pet_bond/"
                        target="_blank"
                        className="text-dark"
                      >
                        {" "}
                        <p className="text-secondary-light">
                          {" "}
                          <img
                            src={pO}
                            className="img-fluid"
                            alt="dogstrust"
                            style={{ height: "15px" }}
                          />{" "}
                          Instagram @pet_bond
                        </p>
                      </a> */}
                      {/* <a
                        href="https://twitter.com/petbond_ireland"
                        target="_blank"
                        className="text-dark"
                      >
                        {" "}
                        <p className="text-secondary-light">
                          {" "}
                          <img
                            src={pO}
                            className="img-fluid"
                            alt="dogstrust"
                            style={{ height: "15px" }}
                          />{" "}
                          Twitter @petbond_ireland
                        </p>
                      </a> */}
                      {/* <a
                        href="https://www.facebook.com/petbondireland"
                        target="_blank"
                        className="text-dark"
                      >
                        {" "}
                        <p className="text-secondary-light">
                          {" "}
                          <img
                            src={pO}
                            className="img-fluid"
                            alt="dogstrust"
                            style={{ height: "15px" }}
                          />{" "}
                          Facebook @petbondireland
                        </p>
                      </a>{" "} */}
                      <ul className="list-unstyled ftSocialList d-flex justify-content-center align-items-center mb-0">
                        <li>
                          <a
                            target="_blank"
                            href="https://www.facebook.com/petbondireland"
                            className="fab fa-facebook shadow-sm"
                          ></a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.instagram.com/pet_bond/"
                            className="fab fa-instagram shadow-sm"
                          ></a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://twitter.com/petbond_ireland"
                            className="fab fa-twitter shadow-sm"
                          ></a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.youtube.com/channel/UCjL7H8i0eZmDCDs21CbiWkQ"
                            className="fab fa-youtube shadow-sm"
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </section>
      {/* BOXES */}
    </>
  );
};

export default ContactUs;
