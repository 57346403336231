import React, { useState } from 'react';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { postRequest } from '../../../../utils/requests';
import { API_ENDPOINT_URL } from '../../../../utils/appConstants';
import { toast } from 'react-toastify';
import { validatePassword } from '../../../../utils';

const BuyerChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showCP, setShowCP] = useState(false);
    const [showCNP, setShowCNP] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState(false);
    const passwordInfo = 'Minimum 8 Characters, must contain at least one uppercase letter, one number. Special character (like []/()*%#$) are optional';

    // validation
    const validate = () => {
        let errors = {};
        let isValid = true;
        const vp = validatePassword(newPassword);

        if (!oldPassword) {
            isValid = false;
            errors['oldPassword'] = 'This field is required';
        }
        if (!vp.isValid) {
            isValid = false;
            errors['newPassword'] = vp.message;
        }
        if (!newPassword) {
            isValid = false;
            errors['newPassword'] = 'This field is required';
        }
        if (newPassword !== confirmNewPassword) {
            isValid = false;
            errors['matchPassword'] = 'New passwords do not match';
        }
        setErrors(errors);
        return isValid;
    };

    //Send the password on form submission
    const updatePassword = (event) => {
        event.preventDefault();
        if (validate()) {
            const requestBody = {
                current_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmNewPassword
            };
            postRequest(`${API_ENDPOINT_URL}/buyer/profile/change-password`, requestBody, true).then((res) => {
                if (res) {
                    toast.success('Password updated successfully');
                    setOldPassword('');
                    setNewPassword('');
                    setConfirmNewPassword('');
                }
            });
        }
    };

    return (
        <>
            <div className="aaContentWidget buyerDashboardView">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Change Password</h1>
                </header>
                <div className="aaDbRow aaDbInfoRow">
                    {/* <h6 className="h6 aaRwTitle fontNoto">Reset Password</h6> */}
                    <form className="um-bddFormWrap um-chrtFormInf" onSubmit={(event) => updatePassword(event)}>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="um-bddInputWrap">
                                    <label>Current Password</label>
                                    <div className="position-relative icnInputWrap">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className="form-control"
                                            value={oldPassword}
                                            onChange={(e) => setOldPassword(e.target.value)}/>
                                        {
                                            showPassword === false ?
                                                <i className="far fa-eye-slash icn"
                                                   onClick={() => setShowPassword(true)}/>
                                                : <i className="far fa-eye icn" onClick={() => setShowPassword(false)}/>
                                        }
                                    </div>
                                    {errors.oldPassword && (
                                        <small className="support-error">
                                            {errors.oldPassword}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="um-bddInputWrap">
                                    <label>New Password</label>
                                    <div className="position-relative icnInputWrap">
                                        <input
                                            id="password"
                                            type={showCP ? 'text' : 'password'}
                                            className="form-control"
                                            value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                        {
                                            showCP === false ?
                                                <i className="far fa-eye-slash icn" onClick={() => setShowCP(true)}/>
                                                : <i className="far fa-eye icn" onClick={() => setShowCP(false)}/>
                                        }
                                    </div>
                                    <small>{passwordInfo}</small>
                                    {errors.newPassword && (
                                        <small className="support-error">
                                            {errors.newPassword}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="um-bddInputWrap">
                                    <label>Confirm Password</label>
                                    <div className="position-relative icnInputWrap">
                                        <input
                                            type={showCNP ? 'text' : 'password'}
                                            id="confirmPassword"
                                            className="form-control"
                                            value={confirmNewPassword}
                                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                                        />
                                        {
                                            showCNP === false ?
                                                <i className="far fa-eye-slash icn" onClick={() => setShowCNP(true)}/>
                                                : <i className="far fa-eye icn" onClick={() => setShowCNP(false)}/>
                                        }
                                    </div>
                                    {errors.matchPassword && (
                                        <small className="support-error">
                                            {errors.matchPassword}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="um-formBtnWrap">
                                    <button
                                        type="submit"
                                        className="btn btn-warning btnFormStyle btnFormWidth"
                                        disabled={showLoader}
                                    >
                                        {showLoader && (
                                            <HalfCircleSpinner
                                                size={18}
                                                style={{
                                                    display: 'inline-block',
                                                    marginRight: '5px'
                                                }}
                                            />
                                        )}
                                        Update Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );

};

export default BuyerChangePassword;
