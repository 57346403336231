import PetbondBenefitItem from "../../../../../components/shared/PetbondBenefits/PetbondBeneiftItem";
import React from "react";
import {ReactComponent as BadgePercentIcon} from '../../../../../assets/icons/badge-percent-icon.svg'
import {ReactComponent as SyringeIcon} from '../../../../../assets/icons/syringe-icon.svg'
import {ReactComponent as WalletIcon} from '../../../../../assets/icons/wallet-icon.svg'
import {ReactComponent as CircleCheckIcon} from '../../../../../assets/icons/circle-check-icon.svg'
import {ReactComponent as ShieldPlusIcon} from '../../../../../assets/icons/shield-plus-icon.svg'

const Benefits = () => {
  return (
    <div className="seller-benefits">
      <div className="intro">
        <div className="intro-text">
          <h1>How can I sell my puppy?</h1>

          <p>
            You’ve created your advert, now we’ll get to work on finding you the perfect home for your puppies. It’s important to us that all the animals re-homed through our site are healthy and happy. We only introduce qualified, serious buyers to you. Help us get you the best price by keeping your conversation on PetBond.
          </p>
        </div>

        <div className="intro-image">
          <img src="/images/dog-isolated.png" alt="" />
        </div>
      </div>

      <div className="benefits-purple-list">
        <ul className="list">
          <PetbondBenefitItem icon={<BadgePercentIcon />} benefit="No Cost Sale" className="item-1" />
          <PetbondBenefitItem icon={<SyringeIcon />} benefit="£30 vaccine rebate per puppy" className="item-2" />
          <PetbondBenefitItem icon={<WalletIcon />} benefit="Achieve the best price for each animal" className="item-3" />
          <PetbondBenefitItem icon={<CircleCheckIcon />} benefit="Vetted Buyers" className="item-4§" />
          <PetbondBenefitItem icon={<ShieldPlusIcon />} benefit="24/7 Pet assistance" className="item-5" />
        </ul>

        <div className="deposits">
          <h2>100% Secure Deposits</h2>
          <img src="/images/stripe-payments.png" alt="" />
        </div>
      </div>

      <div className="benefit-section first-section">
        <div className="section-description">
          <div className="section-title">
            <div className="section-id">01</div>
            <h2>We'll review your advert</h2>
          </div>
          <p>
            Before your advert goes live, we’ll review it. It helps you and us to make sure your pictures are great and you’ve included all the relevant info. We’ll aim to have this done in 24 hours.
          </p>
        </div>
        <div className="section-image">
          <img src="/images/create-advert.png" alt="" />
        </div>
      </div>

      <div className="benefit-section second-section">
        <div className="section-description">
          <div className="section-title">
            <div className="section-id">02</div>
            <h2>Get the best price</h2>
          </div>
          <p>
            We ask interested buyers to leave a 20% deposit before they meet the puppy to give you both peace of mind. We charge the buyer a service fee to cover the PetBond health checks and rebates. You get 100% of the price you set.
          </p>
        </div>
        <div className="section-image">
          <img src="/images/stripe-checkout.png" alt="" />
        </div>
      </div>

      <div className="benefit-section third-section">
        <div className="section-description">
          <div className="section-title">
            <div className="section-id">03</div>
            <h2>Start a conversation</h2>
          </div>
          <p>
            After you have placed an advert you’ll be able to communicate with the buyers so you can answer their questions and arrange for
            them to meet the puppy.
          </p>
        </div>
        <div className="section-image">
          <img src="/images/chat.png" alt="" />
        </div>
      </div>

      <div className="benefit-section fourth-section">
        <div className="section-description">
          <div className="section-title">
            <div className="section-id">04</div>
            <h2>Arrange for the buyer to meet your amazing puppy today :)</h2>
          </div>
          <p>
            We guarantee peace of mind that you’ll be paid 100% once the buyer decides to take the puppy home.
          </p>
        </div>
        <div className="section-image">
          <img src="/images/puppy-in-box.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Benefits;
