// APP
export const APP_PREFIX = '/app';
export const AUTH_PREFIX = '/auth';
export const APP_URL = 'https://mypetbond.com';
export const CURRENCY_SYMBOL = '£';

// API
export const API_BASE_URL = 'https://api.mypetbond.com';
export const API_ENDPOINT_URL = 'https://api.mypetbond.com/api';
export const STORIES_ENDPOINT_URL = 'https://mypetbond.com/petscorner/wp-json/wp/v2/posts/?order=desc&per_page=3&status=publish';
export const STORIES_MEDIA_URL = 'https://mypetbond.com/petscorner/wp-json/wp/v2/media';
export const PET_LISTING_LOCATION_CONTENT_API_URL = 'https://mypetbond.com/petscorner/wp-json/wp/v2/pet-listing-location?location=';
export const PET_LISTING_BREED_CONTENT_API_URL = 'https://mypetbond.com/petscorner/wp-json/wp/v2/pet-listing-breed?breed=';

// CONSTANTS
export const MIX_BREED_ID = 98;
export const RECAPTCHA_SITE_KEY = '6LewQDgfAAAAALPc6xyiprB_VOxVq-l3UyGvsVGM';
export const GOOGLE_KEY = 'AIzaSyCT6I4mqYvFGa3DBihGm8iFyA7wRz7ap7w';
export const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-131032600-1';

// PUSHER CONFIGURATION
export const PUSHER_APP_KEY = "5f526fa356074f0c8e5a";
export const PUSHER_CLUSTER = "eu";
