import React, { useEffect, useState } from 'react';

const Loader = () => {

    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        window.setShowLoader = setShowLoader;
    }, []);

    return (
        <>
            {
                showLoader &&
                <div className="loaderWrap">
                    <div className="loaders">
                        <div className="loader">
                            <div className="loader-inner ball-clip-rotate-multiple">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );

};

export default Loader;