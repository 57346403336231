import React, { useState } from "react";
import { getRequest } from "../../utils/requests";
import { API_ENDPOINT_URL, AUTH_PREFIX } from "../../utils/appConstants";
import { HalfCircleSpinner } from "react-epic-spinners";
import { Link } from "react-router-dom";
import { getStars } from "../../utils";
import { Helmet } from "react-helmet-async";
import {CURRENCY_SYMBOL} from "../../utils/appConstants";

const PetbondQuiz = () => {
  const [ownerCheck, setOwnerCheck] = useState(""); // Question no. 1
  const [trainingCheck, setTrainingCheck] = useState(""); // Question no. 2
  const [exerciseTimeCheck, setExerciseTimeCheck] = useState(""); // Question no. 3
  const [livingSpaceCheck, setLivingSpaceCheck] = useState(""); // Question no. 4
  const [healthCheck, setHealthCheck] = useState(""); // Question no. 5
  const [errors, setErrors] = useState(false);
  const [errorsTwo, setErrorsTwo] = useState(false);
  const [errorsThree, setErrorsThree] = useState(false);
  const [errorsFour, setErrorsFour] = useState(false);
  const [errorsFive, setErrorsFive] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showAdvertError, setShowAdvertError] = useState(false);
  const [formStep, setFormStep] = useState(1);

  const [adverts, setAdverts] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  // Next step function
  const stepForward = () => {
    setFormStep((cur) => cur + 1);
  };

  const stepOneValidation = () => {
    let errors = {};
    let isValid = true;

    if (ownerCheck === "") {
      isValid = false;
      errors["ownerCheck"] = "Please choose an option.";
    }
    setErrors(errors);
    return isValid;
  };

  const stepOneHanlder = async (a) => {
    a.preventDefault();
    if (stepOneValidation()) {
      stepForward();
    }
  };

  const stepTwoValidation = () => {
    let errorsTwo = {};
    let isValidTwo = true;

    if (trainingCheck === "") {
      isValidTwo = false;
      errorsTwo["trainingCheck"] = "Please choose an option.";
    }
    setErrorsTwo(errorsTwo);
    return isValidTwo;
  };

  const stepTwoHanlder = async (a) => {
    a.preventDefault();
    if (stepTwoValidation()) {
      stepForward();
    }
  };

  const stepThreeValidation = () => {
    let errorsThree = {};
    let isValidThree = true;

    if (exerciseTimeCheck === "") {
      isValidThree = false;
      errorsThree["exerciseTimeCheck"] = "Please choose an option.";
    }
    setErrorsThree(errorsThree);
    return isValidThree;
  };

  const stepThreeHanlder = async (a) => {
    a.preventDefault();
    if (stepThreeValidation()) {
      stepForward();
    }
  };

  const stepFourValidation = () => {
    let errorsFour = {};
    let isValidFour = true;

    if (livingSpaceCheck === "") {
      isValidFour = false;
      errorsFour["livingSpaceCheck"] = "Please choose an option.";
    }
    setErrorsFour(errorsFour);
    return isValidFour;
  };

  const stepFourHanlder = async (a) => {
    a.preventDefault();
    if (stepFourValidation()) {
      stepForward();
    }
  };

  const stepFiveValidation = () => {
    let errorsFive = {};
    let isValidFive = true;

    if (healthCheck === "") {
      isValidFive = false;
      errorsFive["healthCheck"] = "Please choose an option.";
    }
    setErrorsFive(errorsFive);
    return isValidFive;
  };

  const searchrequest = (event) => {
    event.preventDefault();

    if (stepFiveValidation()) {
      setShowLoader(true);

      const params = {
        exercise: exerciseTimeCheck,
        living_space: livingSpaceCheck,
      };

      getRequest(`${API_ENDPOINT_URL}/f-end/advert/list`, true, params).then(
        (res) => {
          if (res) {
            if (res.data.data.data.length) {
              // const slicedAdvert = res.data.data.data.slice(0, 3);
              setAdverts(res.data.data.data);
              setShowForm(false);
            } else {
              setShowForm(false);
              setShowAdvertError(true);
            }
          }
        }
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Quiz: Find the Perfect Dog Breed in the UK | PetBond</title>
        <meta
          name="description"
          content="Take the PetBond Match quiz and find your perfect dog or puppy match. Finding your perfect puppy is just a few questions away."
        />
      </Helmet>
      {/* HEADER */}
      <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
        <div className="container">
          <div className="arextraWrap">
            <h2 className="h1">
              from good homes… <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes
            </h2>
          </div>
          <div className="arbhImgHolder d-none d-md-block position-absolute">
            <img src="/images/img07.png" className="img-fluid" alt="petbond" />
          </div>
        </div>
        <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
          <i className="aricomoon-hearts">
            <span className="sr-only">icon</span>
          </i>
        </span>
        <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
          <i className="aricomoon-starDots">
            <span className="sr-only">icon</span>
          </i>
        </span>
      </header>
      <section className="um-petBondQuizSec um-spbQuizSec pt-10 pb-10">
        <div className="container">
          {adverts.length > 0 && (
            <>
              <h2 className="text-center">Some Pet Bond Quiz Matches</h2>
              <div className="row pt-10">
                {adverts?.map((ad) => (
                  <div key={ad.id} className="col-12 col-sm-6 col-md-4">
                    <article className="um-petInfoIndiWrap rounded shadow bg-white">
                      <div className="um-petInfoIndi">
                        <div className="petPrice rounded shadow">
                          <strong className="d-block font-weight-normal">
                            {CURRENCY_SYMBOL}{ad.price}
                          </strong>
                        </div>
                        <div className="imgHolderPet rounded">
                          <img
                            src={
                              ad.cover_photo
                                ? `${API_ENDPOINT_URL}/image-retrieve/${ad.cover_photo}`
                                : "/images/image-placeholder.png"
                            }
                            className="img-fluid rounded w-100 h-100 position-absolute"
                            alt="img"
                          />
                        </div>
                        <div className="petInfoWrap">
                          <h3>{ad.name}</h3>
                          <ul className="petInfoList list-unstyled">
                            <li className="d-flex align-items-center justify-content-between">
                              <strong>Size</strong>
                              <ul className="gradList list-unstyled d-flex">
                                {getStars(ad.size).map((star, i) => (
                                  <li
                                    className={star ? "active" : ""}
                                    key={i}
                                  />
                                ))}
                              </ul>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <strong>Energy</strong>
                              <ul className="gradList list-unstyled d-flex">
                                {getStars(ad.energy).map((star, i) => (
                                  <li
                                    className={star ? "active" : ""}
                                    key={i}
                                  />
                                ))}
                              </ul>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <strong>Lifespan</strong>
                              <ul className="gradList list-unstyled d-flex">
                                {getStars(ad.life_span).map((star, i) => (
                                  <li
                                    className={star ? "active" : ""}
                                    key={i}
                                  />
                                ))}
                              </ul>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <strong>Grooming</strong>
                              <ul className="gradList list-unstyled d-flex align-items-center">
                                {getStars(ad.grooming).map((star, i) => (
                                  <li
                                    className={star ? "active" : ""}
                                    key={i}
                                  />
                                ))}
                              </ul>
                            </li>
                          </ul>
                          <div className="um-btnformWrap">
                            <Link
                              to={ad.advert_url}
                              className="btn btnScnWidth btn-info btnScnStyl text-uppercase"
                            >
                              View Pet
                            </Link>
                            <Link
                              to="#"
                              className="btn btnScnWidth btn-warning btnScnStyl text-uppercase"
                            >
                              Favourite
                            </Link>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                ))}
              </div>
              <div className="um-btnformWrap text-center">
                <Link
                  to={`/pet-listing`}
                  className="btn btnScnWidth btn-primary btnScnStyl text-uppercase btnQuiz"
                >
                  View All Adverts
                </Link>
                <Link
                  to={`/petbond-match`}
                  className="btn btnScnWidth btnGrayStyle btnScnStyl btnQuiz text-uppercase"
                >
                  Start Quiz Again
                </Link>
              </div>
            </>
          )}
          {showForm && (
            <>
              <form
                className="um-petBondQuiz rounded shadow bg-white"
                onSubmit={(event) => searchrequest(event)}
              >
                <h1 className="h2">Petbond quiz find your perfect match</h1>
                {formStep === 1 && (
                  <>
                    <div className="um-quizHeadHeading">
                      <p>Lets find the perfect match for you</p>
                      <div className="quizDots d-flex justify-content-between">
                        <strong className="d-block font-weight-normal">
                          Question 1 of 5
                        </strong>
                        <ul className="gradList list-unstyled d-flex align-items-center">
                          <li className="active"></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                      </div>
                    </div>
                    <div className="quizAnserBlock">
                      <span className="d-block textWrap">
                        Answer the questions below to help us match you to the
                        right pet
                      </span>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group um-bddFormSlct um-slctEtcDesg">
                            <label className="form-label">
                              Q1 - Are you a first time pet owner?
                            </label>
                            <div className="form-group d-flex flex-wrap">
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="ownerCheck"
                                  value={"yes"}
                                  checked={ownerCheck === "yes"}
                                  onChange={(event) =>
                                    setOwnerCheck(event.target.value)
                                  }
                                />
                                Yes
                              </label>
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="ownerCheck"
                                  value={"no"}
                                  checked={ownerCheck === "no"}
                                  onChange={(event) =>
                                    setOwnerCheck(event.target.value)
                                  }
                                />
                                No
                              </label>
                            </div>
                            {errors.ownerCheck && (
                              <small className="support-error mt-n3 mb-2">
                                {errors.ownerCheck}
                              </small>
                            )}
                            <div className="row">
                              <div className="col-12">
                                <div className="um-bddInputWrap">
                                  {ownerCheck === "yes" && (
                                    <>
                                      <p>
                                        Please visit our{" "}
                                        <a href="https://mypetbond.com/petscorner/for-breeders/">
                                          Breed info page
                                        </a>{" "}
                                        to read about various breeds and
                                        consider what Breed would best suite
                                        your lifestyle.
                                      </p>
                                      <p>
                                        Also consider talking with a{" "}
                                        <a href="/find-a-vet">
                                          Petbond recomended Veterinarian
                                        </a>{" "}
                                        to consider what Breed would best suite
                                        your lifestyle.
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {ownerCheck !== "yes" && (
                            <>
                              <div className="um-formBtnWrap">
                                <button
                                  type="button"
                                  onClick={(a) => {
                                    stepOneHanlder(a);
                                  }}
                                  className="btn btnFormWidth btn-warning btnFormStyle"
                                >
                                  Next Question
                                  <i className="aricomoon-umarrowright arumicn">
                                    <span className="sr-only">icon</span>
                                  </i>
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {formStep === 2 && (
                  <>
                    <div className="um-quizHeadHeading">
                      <p>Lets find the perfect match for you</p>
                      <div className="quizDots d-flex justify-content-between">
                        <strong className="d-block font-weight-normal">
                          Question 2 of 5
                        </strong>
                        <ul className="gradList list-unstyled d-flex align-items-center">
                          <li></li>
                          <li className="active"></li>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                      </div>
                    </div>
                    <div className="quizAnserBlock">
                      <span className="d-block textWrap">
                        Answer the questions below to help us match you to the
                        right pet
                      </span>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group um-bddFormSlct um-slctEtcDesg">
                            <label className="form-label">
                              Q2 - Will you or another adult be at home everyday
                              to ensure that 'puppy' is properly trained and not
                              left alone for more than 2 hours?
                            </label>
                            <div className="form-group d-flex flex-wrap">
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="trainingCheck"
                                  value={"yes"}
                                  checked={trainingCheck === "yes"}
                                  onChange={(event) =>
                                    setTrainingCheck(event.target.value)
                                  }
                                />
                                Yes
                              </label>
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="trainingCheck"
                                  value={"no"}
                                  checked={trainingCheck === "no"}
                                  onChange={(event) =>
                                    setTrainingCheck(event.target.value)
                                  }
                                />
                                No
                              </label>
                            </div>
                            {errorsTwo.trainingCheck && (
                              <small className="support-error mt-n3 mb-2">
                                {errorsTwo.trainingCheck}
                              </small>
                            )}
                            <div className="row">
                              <div className="col-12">
                                <div className="um-bddInputWrap">
                                  {trainingCheck === "no" && (
                                    <>
                                      <p>
                                        Petbond would advise that training and
                                        welcoming a new puppy to your life is a
                                        hugely rewarding and time consuming
                                        experience.
                                      </p>
                                      <p>
                                        It seems that you currently do not have
                                        the time to invest in training a puppy,
                                        therefore waiting until your life is
                                        less busy would be a much better idea
                                        for you and your new puppy.
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {trainingCheck !== "no" && (
                            <div className="um-formBtnWrap">
                              <button
                                type="button"
                                onClick={(a) => {
                                  stepTwoHanlder(a);
                                }}
                                className="btn btnFormWidth btn-warning btnFormStyle"
                              >
                                Next Question
                                <i className="aricomoon-umarrowright arumicn">
                                  <span className="sr-only">icon</span>
                                </i>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {formStep === 3 && (
                  <>
                    <div className="um-quizHeadHeading">
                      <p>Lets find the perfect match for you</p>
                      <div className="quizDots d-flex justify-content-between">
                        <strong className="d-block font-weight-normal">
                          Question 3 of 5
                        </strong>
                        <ul className="gradList list-unstyled d-flex align-items-center">
                          <li></li>
                          <li></li>
                          <li className="active"></li>
                          <li></li>
                          <li></li>
                        </ul>
                      </div>
                    </div>
                    <div className="quizAnserBlock">
                      <span className="d-block textWrap">
                        Answer the questions below to help us match you to the
                        right pet
                      </span>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group um-bddFormSlct ">
                            <label className="form-label">
                              Q3 - How much time can you spend each day
                              exercising a new dog?
                            </label>
                            <div className="form-group d-flex flex-wrap">
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="exerciseTimeCheck"
                                  value={"1"}
                                  checked={exerciseTimeCheck === "1"}
                                  onChange={(event) =>
                                    setExerciseTimeCheck(event.target.value)
                                  }
                                />
                                Less than 30 minutes
                              </label>
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="exerciseTimeCheck"
                                  value={"2"}
                                  checked={exerciseTimeCheck === "2"}
                                  onChange={(event) =>
                                    setExerciseTimeCheck(event.target.value)
                                  }
                                />
                                Upto 1 hour per day
                              </label>
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="exerciseTimeCheck"
                                  value={"3"}
                                  checked={exerciseTimeCheck === "3"}
                                  onChange={(event) =>
                                    setExerciseTimeCheck(event.target.value)
                                  }
                                />
                                1-2 hours per day
                              </label>
                            </div>
                            {errorsThree.exerciseTimeCheck && (
                              <small className="support-error mt-n3 mb-2">
                                {errorsThree.exerciseTimeCheck}
                              </small>
                            )}
                          </div>
                          <div className="um-formBtnWrap">
                            <button
                              type="button"
                              onClick={(a) => {
                                stepThreeHanlder(a);
                              }}
                              className="btn btnFormWidth btn-warning btnFormStyle"
                            >
                              Next Question
                              <i className="aricomoon-umarrowright arumicn">
                                <span className="sr-only">icon</span>
                              </i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {formStep === 4 && (
                  <>
                    <div className="um-quizHeadHeading">
                      <p>Lets find the perfect match for you</p>
                      <div className="quizDots d-flex justify-content-between">
                        <strong className="d-block font-weight-normal">
                          Question 4 of 5
                        </strong>
                        <ul className="gradList list-unstyled d-flex align-items-center">
                          <li></li>
                          <li></li>
                          <li></li>
                          <li className="active"></li>
                          <li></li>
                        </ul>
                      </div>
                    </div>
                    <div className="quizAnserBlock">
                      <span className="d-block textWrap">
                        Answer the questions below to help us match you to the
                        right pet
                      </span>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group um-bddFormSlct ">
                            <label className="form-label">
                              Q4 - What size is your current living space (
                              bearing in mind the size puppy will grow to as an
                              adult dog)!?
                            </label>
                            <div className="form-group d-flex flex-wrap">
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="exerciseTimeCheck"
                                  value={"1"}
                                  checked={livingSpaceCheck === "1"}
                                  onChange={(event) =>
                                    setLivingSpaceCheck(event.target.value)
                                  }
                                />
                                Apartment
                              </label>
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="exerciseTimeCheck"
                                  value={"2"}
                                  checked={livingSpaceCheck === "2"}
                                  onChange={(event) =>
                                    setLivingSpaceCheck(event.target.value)
                                  }
                                />
                                House &amp; no garden
                              </label>
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="exerciseTimeCheck"
                                  value={"3"}
                                  checked={livingSpaceCheck === "3"}
                                  onChange={(event) =>
                                    setLivingSpaceCheck(event.target.value)
                                  }
                                />
                                House &amp; moderate garden
                              </label>
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="exerciseTimeCheck"
                                  value={"4"}
                                  checked={livingSpaceCheck === "4"}
                                  onChange={(event) =>
                                    setLivingSpaceCheck(event.target.value)
                                  }
                                />
                                Large House &amp; Large Garden
                              </label>
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="exerciseTimeCheck"
                                  value={"5"}
                                  checked={livingSpaceCheck === "5"}
                                  onChange={(event) =>
                                    setLivingSpaceCheck(event.target.value)
                                  }
                                />
                                Country House &amp; Wide Open Spaces
                              </label>
                            </div>
                            {errorsFour.livingSpaceCheck && (
                              <small className="support-error mt-n3 mb-2">
                                {errorsFour.livingSpaceCheck}
                              </small>
                            )}
                          </div>
                          <div className="um-formBtnWrap">
                            <button
                              type="button"
                              onClick={(a) => {
                                stepFourHanlder(a);
                              }}
                              className="btn btnFormWidth btn-warning btnFormStyle"
                            >
                              Next Question
                              <i className="aricomoon-umarrowright arumicn">
                                <span className="sr-only">icon</span>
                              </i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {formStep === 5 && (
                  <>
                    <div className="um-quizHeadHeading">
                      <p>Lets find the perfect match for you</p>
                      <div className="quizDots d-flex justify-content-between">
                        <strong className="d-block font-weight-normal">
                          Question 5 of 5
                        </strong>
                        <ul className="gradList list-unstyled d-flex align-items-center">
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li className="active"></li>
                        </ul>
                      </div>
                    </div>
                    <div className="quizAnserBlock">
                      <span className="d-block textWrap">
                        Answer the questions below to help us match you to the
                        right pet
                      </span>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group um-bddFormSlct um-slctEtcDesg">
                            <label className="form-label">
                              Q5 - Have you discussed the health & welfare of
                              your new puppy with your PetBond Approved
                              Veterinary Practice?
                            </label>
                            <div className="form-group d-flex flex-wrap">
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="healthCheck"
                                  value={"yes"}
                                  checked={healthCheck === "yes"}
                                  onChange={(event) =>
                                    setHealthCheck(event.target.value)
                                  }
                                />
                                Yes
                              </label>
                              <label className="checkboxlabel d-flex">
                                <input
                                  className="css3checkbox ml-0 mr-2"
                                  type="radio"
                                  name="healthCheck"
                                  value={"no"}
                                  checked={healthCheck === "no"}
                                  onChange={(event) =>
                                    setHealthCheck(event.target.value)
                                  }
                                />
                                No
                              </label>
                            </div>
                            {errorsFive.healthCheck && (
                              <small className="support-error mt-n3 mb-2">
                                {errorsFive.healthCheck}
                              </small>
                            )}
                            <div className="row">
                              <div className="col-12">
                                <div className="um-bddInputWrap">
                                  {healthCheck === "no" && (
                                    <>
                                      <p>
                                        Visit out Petbond recomended
                                        Veterinarian pracice map to find a
                                        Petbond{" "}
                                        <a href="https://mypetbond.com/find-a-vet">
                                          recomended Veterinarin near you
                                        </a>
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="um-formBtnWrap">
                            <button
                              type="submit"
                              className="btn btnFormWidth btn-warning btnFormStyle"
                              disabled={showLoader}
                            >
                              {showLoader && (
                                <HalfCircleSpinner
                                  size={18}
                                  style={{
                                    display: "inline-block",
                                    marginRight: "5px",
                                  }}
                                />
                              )}
                              Get Match
                              <i className="aricomoon-umarrowright arumicn">
                                <span className="sr-only">icon</span>
                              </i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </>
          )}
          {showAdvertError && (
            <div className="matchResponseWrap text-center">
              <h3 className="h3">We have no matches currently?</h3>
              <div className="aa-msgWidget text-center">
                <h3 className="h3 aa-msgHeading mb-6 text-white">
                  Why not create an account and a pet alert and we will notify
                  you when your match is available.
                </h3>
                <Link
                  to={`${AUTH_PREFIX}/buyer-signup`}
                  className="btn btn-warning btnMsgMatch"
                >
                  Create Account and Pet Alert
                </Link>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default PetbondQuiz;
