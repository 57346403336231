import  {ReactComponent as VerifiedPuppyCheckIcon}  from '../../assets/images/verifiedPuppyCheck.svg';
import  {ReactComponent as GuarantedIcon}  from '../../assets/images/guaranted.svg';
import  {ReactComponent as PuppyIcon}  from '../../assets/images/puppy.svg';
import  {ReactComponent as VaccinatedIcon}  from '../../assets/images/vaccinated.svg';
import  {ReactComponent as MicochipedIcon}  from '../../assets/images/microchipped.svg';
import  {ReactComponent as VetAssistanceIcon}  from '../../assets/images/vet-assistance.svg';

// Add props for light styling
const PuppyDepositInfoCard = (props) => {
    const classes = `row puppyInfoCard fontFredoka ${props.light ? 'puppyInfoCard--light' : ''}`;

    return (
        <>
            <div className="container">
                <div className={classes}>
                    <div className="col-lg-5 col-md-7">
                        <ul className="list">
                            <li>
                            <span className="icon">
                                <VerifiedPuppyCheckIcon/>
                              </span>
                                <span>Verified puppy health check</span>
                            </li>
                            <li>
                            <span className="icon">
                              <GuarantedIcon/>
                            </span>
                                <span>Guaranteed from ethical breeder</span>
                            </li>
                            <li>
                            <span className="icon">
                                <PuppyIcon/>
                              </span>
                                <span>Puppy's parents health &amp; welfare verified</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-5 pt-4 pt-md-0">
                        <ul className="list">
                            <li>
                            <span className="icon">
                                <VaccinatedIcon/>
                              </span>
                                <span>Fully vaccinated</span>
                            </li>
                            <li>
                            <span className="icon">
                                <MicochipedIcon/>
                              </span>
                                <span>Microchipped</span>
                            </li>
                            <li>
                            <span className="icon">
                                <VetAssistanceIcon/>
                              </span>
                                <span>24/7 Vet assistance</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-4 pt-4 pt-lg-0 deposits">
                        <div className="deposits__heading">100% secure deposits</div>
                        <img
                            className="deposits__image"
                            src="/images/stripe-payments.png"
                            alt="stripe payments"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PuppyDepositInfoCard;