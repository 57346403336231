import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getRequest } from '../../utils/requests';
import { API_ENDPOINT_URL } from '../../utils/appConstants';

const PaymentCancel = () => {

    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const sessionId = search.get('session_id');
        const type = JSON.parse(localStorage.getItem('currentUser')).role === 'buyer' ? 'buyer' : 'guest';
        getRequest(`${API_ENDPOINT_URL}/${type}/checkout/cancel?session_id=${sessionId}`, true).then((res) => {
            if (res) {
                if (res?.data?.data?.advert_url) {
                    navigate(res?.data?.data?.advert_url);
                }
            }
        });
    }, []);

    return (
        <div className="loaderWrap">
            <div className="loaders">
                <div className="loader">
                    <div className="loader-inner ball-clip-rotate-multiple">
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default PaymentCancel;