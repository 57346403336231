import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { API_ENDPOINT_URL, APP_PREFIX } from '../../../utils/appConstants';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { postRequest } from '../../../utils/requests';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

const Download = () => {

    const [dateRange, setDateRange] = useState({
        start_date: '',
        end_date: ''
    });
    const [errors, setErrors] = useState(false);

    const validate = () => {
        let errors = {};
        let isValid = true;

        if (!dateRange.start_date) {
            isValid = false;
            errors['start_date'] = 'This filed is required.';
        }
        if (!dateRange.end_date) {
            isValid = false;
            errors['end_date'] = 'This filed is required.';
        }
        setErrors(errors);
        return isValid;
    };

    const downloadCsv = (event) => {
        event.preventDefault();
        if (validate()) {
            postRequest(`${API_ENDPOINT_URL}/veterinarian/exports/pet-verifications`, dateRange, true, {}, {'time-zone': moment.tz.guess()}, 'blob').then((res) => {
                if (res) {
                    const url = URL.createObjectURL(new Blob([res.data]));
                    const tag = document.createElement('a');
                    document.body.appendChild(tag);
                    tag.setAttribute('style', 'display: none');
                    tag.href = url;
                    tag.download = `PetVerifications-${moment().format('DD-MM-YYYY')}.csv`;
                    tag.target = '_blank';
                    tag.click();
                    tag.remove();
                    setDateRange({
                        start_date: '',
                        end_date: ''
                    });
                }
            });
        }
    };

    return (
        <>
            <div className="aaContentWidget">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Download</h1>
                </header>
                <div className="row mb-3">
                    <div className="col-12 col-md-12">
                        <form className="um-bddFormWrap" onSubmit={(event) => downloadCsv(event)}>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="start_date" className="form-label">From date</label>
                                        <input className="form-control" type="date" placeholder="From date..."
                                               value={dateRange.start_date} id="start_date"
                                               max={moment().format('YYYY-MM-DD')}
                                               onChange={(e) => {
                                                   setDateRange({...dateRange, start_date: e.target.value});
                                               }}
                                        />
                                        {errors.start_date && (
                                            <small className="support-error">
                                                {errors.start_date}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="end_date" className="form-label">To date</label>
                                        <input className="form-control" type="date" placeholder="To date..."
                                               value={dateRange.end_date} id="end_date"
                                               max={moment().format('YYYY-MM-DD')}
                                               onChange={(e) => {
                                                   setDateRange({...dateRange, end_date: e.target.value});
                                               }}
                                        />
                                        {errors.end_date && (
                                            <small className="support-error">
                                                {errors.end_date}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="um-formBtnWrap pt-4">
                                <button type="submit" className="btn btn-warning small">
                                    Download CSV
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );

};

export default Download;
