import React, { useEffect, useState } from 'react';
import { getRequest, deleteRequest } from '../../../../utils/requests';
import { API_ENDPOINT_URL, APP_PREFIX } from '../../../../utils/appConstants';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';


const BuyerPetAlerts = () => {
    const [petAlertList, setPetAlertList] = useState([]);

    useEffect(() => {
        getRequest(`${API_ENDPOINT_URL}/buyer/alert/index`, true).then((res) => {
            setPetAlertList(res?.data?.data);
        });
    }, []);

    const removeAlert = (id) => {

        deleteRequest(`${API_ENDPOINT_URL}/buyer/alert/delete/${id}`, true).then((res) => {
            if(res) {
                toast.success('Alert remove successfully');
                window.location.reload();
            }
        });
    };

    return (
        <>
            <div className="aaContentWidget">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Pets Alerts</h1>
                </header>
                <div className="aaDbRow aaDbPetsListRow">
                    <ul className="aaAdvtPetList list-unstyled m-0 p-0">
                        {
                            petAlertList && petAlertList.map((alert, index) => (
                                <li key={index}>
                                    <article className="aaAdvPetPost mb-3">
                                        <div className="aaRightWrap">
                                            {
                                                alert.alert_name &&
                                                <h6 className="h6 aaAdvTitle fontNoto">Alert name: {alert.alert_name}</h6>
                                            }
                                            <ul className="aaAdvdetailList list-unstyled p-0 m-0">
                                                {
                                                    alert.breed_name &&
                                                    <li>Breed: {alert.breed_name}</li>
                                                }
                                                {
                                                    alert.size &&
                                                    <li>Size: {alert.size}</li>
                                                }
                                                {
                                                    alert.city_name &&
                                                    <li>Location: {alert.city_name}</li>
                                                }
                                                {
                                                    alert.living_space &&
                                                    <li>Living Space: {alert.living_space}</li>
                                                }
                                            </ul>
                                            <div className="btnWrap pt-2">
                                                <Link to={`${APP_PREFIX}/create-pet-alert?alert_id=${alert.id}`}
                                                      className="btn btnGrey btnFormWidth btnFormStyle small-xs mr-3"
                                                >
                                                    Edit Alert
                                                </Link>
                                                <button
                                                    onClick={() => {
                                                        removeAlert(alert.id);
                                                    }}
                                                    type="button"
                                                    className="btn btn-danger btnFormWidth btnFormStyle small-xs red"
                                                >
                                                    Delete Alert
                                                </button>
                                            </div>
                                        </div>
                                    </article>
                                </li>
                            ))
                        }
                    </ul>
                    {
                        petAlertList.length <= 0 &&
                        <p>No data found</p>
                    }
                    <div className="aa-formBtnWrap mb-7">
                        <Link to={`${APP_PREFIX}/create-pet-alert`}
                              className="btn btn-warning btnFormStyle small-xs"
                        >
                            Create new alert
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BuyerPetAlerts;
