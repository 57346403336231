import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validatePassword } from '../../../utils';
import { postRequest } from '../../../utils/requests';
import { API_ENDPOINT_URL, AUTH_PREFIX } from '../../../utils/appConstants';
import { toast } from 'react-toastify';

const ResetPassword = (props) => {

    const [data, setData] = useState({
        password: '',
        password_confirmation: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showCP, setShowCP] = useState(false);
    const [search, setSearch] = useSearchParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState(false);

    const validate = () => {
        let errors = {};
        let isValid = true;
        const vp = validatePassword(data.password);

        if (!vp.isValid) {
            isValid = false;
            errors['password'] = vp.message;
        }
        if (!data.password) {
            isValid = false;
            errors['password'] = 'This filed is required.';
        }
        if (data.password !== data.password_confirmation) {
            isValid = false;
            errors['matchPassword'] = 'Passwords do not match.';
        }
        setErrors(errors);
        return isValid;
    };

    const resetPassword = (event) => {
        event.preventDefault();
        if (validate()) {
            const email = search.get('email');
            const token = search.get('token');
            postRequest(`${API_ENDPOINT_URL}/password/reset`, {...data, email, token}).then((res) => {
                if (res) {
                    navigate(`${AUTH_PREFIX}/login`);
                    toast.success('Password changed successfully');
                }
            });
        }
    };

    return (
        <section className="um-sectionWrap um-loginPgsSec pb-15">
            <div className="container">
                <div className="um-lgLogWrap rounded shadow">
                    <header className="um-formHddComp text-center">
                        <h2>Reset Password</h2>
                    </header>
                    <form className="um-loginPgForm" onSubmit={resetPassword}>
                        <div className="um-logInWrap">
                            <label className="form-label">New Password</label>
                            <div className="position-relative icnInputWrap">
                                <input type={showPassword ? 'text' : 'password'} className="form-control w-100"
                                       placeholder="Enter new password here..." value={data.password}
                                       onChange={(e) => setData({...data, password: e.target.value})}
                                />
                                {
                                    showPassword === false ?
                                        <i className="far fa-eye-slash icn"
                                           onClick={() => setShowPassword(true)}/>
                                        : <i className="far fa-eye icn"
                                             onClick={() => setShowPassword(false)}/>
                                }
                            </div>
                            <small>{props.passwordInfo}</small>
                            {errors.password && (
                                <small className="support-error">
                                    {errors.password}
                                </small>
                            )}
                        </div>
                        <div className="um-logInWrap">
                            <label className="form-label">Confirm Password</label>
                            <div className="position-relative icnInputWrap">
                                <input type={showCP ? 'text' : 'password'} className="form-control w-100"
                                       placeholder="Confirm new password here..." value={data.password_confirmation}
                                       onChange={(e) => setData({...data, password_confirmation: e.target.value})}
                                />
                                {
                                    showCP === false ?
                                        <i className="far fa-eye-slash icn"
                                           onClick={() => setShowCP(true)}/>
                                        : <i className="far fa-eye icn"
                                             onClick={() => setShowCP(false)}/>
                                }
                            </div>
                            {errors.matchPassword && (
                                <small className="support-error">
                                    {errors.matchPassword}
                                </small>
                            )}
                        </div>
                        <div className="um-formBtnWrap">
                            <button className="btn w-100 btn-warning" type="submit">
                                <svg xmlns="http://www.w3.org/2000/svg" width="206.839" height="41.9"
                                     viewBox="0 0 206.839 41.9">
                                    <g id="Group_1385" data-name="Group 1385"
                                       transform="translate(-495.812 -1338.86)">
                                        <rect id="Rectangle_536" data-name="Rectangle 536"
                                              width="206.839"
                                              height="41.9" rx="7"
                                              transform="translate(495.812 1338.86)"
                                              fill="none"/>
                                        <text id="Save_progress" data-name="Save progress"
                                              transform="translate(514.534 1366.02)" fill="#fff"
                                              fontSize="17"
                                              fontFamily="NunitoSans-Regular, Nunito Sans">
                                            <tspan x="0" y="0">Reset password</tspan>
                                        </text>
                                        <g id="Group_1132" data-name="Group 1132"
                                           transform="translate(-16.204 -10141.388)">
                                            <g id="Group_1131" data-name="Group 1131">
                                                <path id="Path_121340" data-name="Path 121340"
                                                      d="M676.994,11496.692a5.186,5.186,0,0,1,1.774.208,8.3,8.3,0,0,1,8.034-6.156,8.514,8.514,0,0,1,8.139,6.156,4.334,4.334,0,0,1,1.669-.208,7.2,7.2,0,1,1,0,14.4H676.994a7.2,7.2,0,1,1,0-14.4Zm19.616,12.625a5.389,5.389,0,0,0,5.426-5.427,5.478,5.478,0,0,0-5.426-5.529,5.182,5.182,0,0,0-1.252.209,1.87,1.87,0,0,1-2.191-1.252,6.514,6.514,0,0,0-6.365-4.8,6.7,6.7,0,0,0-6.365,4.8,1.708,1.708,0,0,1-2.087,1.252,6.038,6.038,0,0,0-1.356-.209,5.478,5.478,0,0,0-5.426,5.529,5.389,5.389,0,0,0,5.426,5.427Z"
                                                      fill="#fff" fillRule="evenodd"/>
                                                <path id="Path_121341" data-name="Path 121341"
                                                      d="M682.524,11503.369a.85.85,0,0,1,1.148-1.252l1.878,1.878,4.382-4.382a.887.887,0,0,1,1.252,1.252l-5.008,5.009a.951.951,0,0,1-1.252,0Z"
                                                      fill="#fff"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );

};

export default ResetPassword;