import { Helmet } from 'react-helmet-async';
import React from 'react';

const CookiePolicy = () => {

    return (
        <>
            <Helmet>
                <title>Cookie Policy | PetBond UK</title>
                <meta name="description"
                      content='PetBond uses "cookies" to help improve your browsing experience of our website. This Cookie Policy should be read in conjunction with our Privacy Policy'/>
            </Helmet>
            <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
                <div className="container">
                    <div className="arextraWrap">
                        <h2 className="h1">from good homes… <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes</h2>
                    </div>
                    <div className="arbhImgHolder d-none d-md-block position-absolute">
                        <img src="/images/img07.png" className="img-fluid" alt="petbond"/>
                    </div>
                </div>
                <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
							<i className="aricomoon-hearts"><span className="sr-only">icon</span></i>
						</span>
                <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
							<i className="aricomoon-starDots"><span className="sr-only">icon</span></i>
						</span>
            </header>
            <section className="contentSection pt-5 pb-15 pb-lg-20">
                <div className="container">
                    <article className="contentWrap rounded shadow bg-white">
                        <h1 className="h2">Cookie Policy</h1>
                        <p>PetBond uses “cookies” to help improve your browsing experience of our website<em>.</em> This
                            Cookie Policy should be read in conjunction with our Privacy Policy <a
                                href="https://mypetbond.com/privacy-policy/">https://mypetbond.com/privacy-policy/</a>.</p>
                        <h6 className="fontNoto h6 pt-5">WHAT IS A COOKIE?</h6>
                        <p>A cookie is a small data file that is placed on your computer or mobile device (“Device”). It
                            allows a website to remember your actions or preferences over a length of time. Some of the
                            cookies we use are essential for our website to work. We also use some non-essential cookies
                            to collect information for making reports and to help us improve the website.</p>
                        <h6 className="fontNoto h6 pt-5">TYPES OF COOKIES USED</h6>
                        <p>The table below explains the cookies we use and why.</p>
                        <div className="tableWrap table-responsive">
                            <table className="table mt-10 cookieTable">
                                <thead>
                                <tr>
                                    <th><strong>Cookie Name</strong></th>
                                    <th><strong>Purpose </strong></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><em>_gid</em></td>
                                    <td>This cookie is associated with Google Universal Analytics. It is used to store
                                        and update a unique value for each page visited.
                                    </td>
                                </tr>
                                <tr>
                                    <td>_ga</td>
                                    <td>This cookie is associated with Google Universal Analytics. This cookie is used
                                        to distinguish unique users by assigning a randomly generated number as a client
                                        identifier. It is included in each page request in a site and used to calculate
                                        visitor, session and campaign data for the sites analytics reports. By default
                                        it is set to expire after 2 years, although this is customisable by website
                                        owners.
                                    </td>
                                </tr>
                                <tr>
                                    <td>test_cookie</td>
                                    <td>This cookie is set by DoubleClick (which is owned by Google) to determine if the
                                        website visitor’s browser supports cookies.
                                    </td>
                                </tr>
                                <tr>
                                    <td>_gat_gtag</td>
                                    <td>Used to throttle request rate. If Google Analytics is deployed via Google Tag
                                        Manager
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p><br/></p>
                        <h6 className="fontNoto h6 pt-5">MANAGING AND DISABLING COOKIES </h6>
                        <p>Most web browsers give you some control over cookies through the browser settings. However,
                            turning cookies off may interfere with your browsing experience.</p>
                        <p>For instructions on how to manage and disable cookies, visit the browser developer's website.
                            If you use multiple Devices and/or browsers you will need to disable cookies on each Device
                            and on each browser separately.</p>
                        <p className="pt-3"><strong>Find out how to manage and disable cookies on popular
                            browsers:</strong></p>
                        <ul className="contChildList list-unstyled pl-3">
                            <li><a
                                href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en">Google
                                Chrome</a></li>
                            <li><a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Microsoft
                                Edge</a></li>
                            <li><a
                                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla
                                Firefox</a></li>
                            <li><a
                                href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Microsoft
                                Internet Explorer</a></li>
                            <li><a href="https://www.opera.com/help/tutorials/security/privacy/">Opera</a></li>
                            <li><a href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac">Apple Safari</a>
                            </li>
                        </ul>
                        <p>To opt out of being tracked by Google Analytics across all websites, visit <a
                            href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>.
                        </p>
                        <h6 className="fontNoto h6 pt-5">CHANGES TO THIS POLICY</h6>
                        <p>We reserve the right to change this Cookie Policy from time to time at our sole discretion.
                            If we make any changes, we will post those changes here and update the “Last Updated” date
                            at the bottom of this Cookie Policy. However, if we make material changes, we will notify
                            you by means of a prominent notice on our website prior to the change becoming
                            effective.</p>
                        <h6 className="fontNoto h6 pt-5">CONTACT US</h6>
                        <p>Questions, comments, requests and complaints regarding this Cookie Policy are welcome and
                            should be addressed to <a href="mailto:info@mypetbond.com">info@mypetbond.com</a>. All requests
                            will be dealt with promptly and efficiently.</p>
                        <p><strong>Last Updated: &nbsp;[22/11/2019]</strong></p>
                    </article>
                </div>
            </section>
        </>
    );

};

export default CookiePolicy;
