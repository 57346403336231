import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../../../utils/requests';
import {API_ENDPOINT_URL, GOOGLE_KEY} from '../../../../utils/appConstants';
import { toast } from 'react-toastify';
import {enforceFormat, formatToPhone, validatePassword} from '../../../../utils';
import {ProfileMapComponent} from "../../../../components/shared/map/profile_map.component";
import {Wrapper} from "@googlemaps/react-wrapper";
import {Map} from "../../../../components/shared/map/map.component";
import groupCitiesByCountry from "../../../../utils/groupCitiesByCountry";

const CharitySettings = (props) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('+44');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [county, setCounty] = useState('');
    const [charityName, setCharityName] = useState('');
    const [charityNumber, setCharityNumber] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errors, setErrors] = useState(false);
    const [fetchedCities, setFetchedCities] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showCP, setShowCP] = useState(false);
    const [showCNP, setShowCNP] = useState(false);
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        getRequest(`${API_ENDPOINT_URL}/basic-listing/cities`).then((res) => {
            setFetchedCities(groupCitiesByCountry(res?.data?.data));
        });
        getRequest(`${API_ENDPOINT_URL}/charity/profile/view`, true).then((res) => {
            if (res) {
                setProfileData(res.data.data);
                setFirstName(res.data.data.first_name);
                setLastName(res.data.data.last_name);
                setMobile(res.data.data.phone_number);
                setEmail(res.data.data.email);
                setAddress(res.data.data.address);
                setPostalCode(res.data.data.postal_code);
                setCounty(res.data.data.city_id);
                setCharityNumber(res.data.data.detail.charity_number);

                if (res.data.data.detail.charity_name) {
                    setCharityName(res.data.data.detail.charity_name);
                }
            }
        });
    }, []);

    // validation
    const validateChangeAddress = () => {
        let errors = {};
        let isValid = true;

        if (!firstName) {
            isValid = false;
            errors['firstName'] = 'This filed is required.';
        }
        if (!lastName) {
            isValid = false;
            errors['lastName'] = 'This filed is required.';
        }
        if (!mobile) {
            isValid = false;
            errors['mobile'] = 'This filed is required.';
        }
        if (mobile) {
            let res = mobile.replace(/\D/g, '');
            if (res.length < 10) {
                isValid = false;
                errors['mobile'] = 'Please enter a Valid Phone number.';
            }
        }
        if (!address) {
            isValid = false;
            errors['address'] = 'This filed is required.';
        }
        if (!postalCode) {
            isValid = false;
            errors['postalCode'] = 'This filed is required.';
        }
        if (!county) {
            isValid = false;
            errors['city'] = 'This filed is required.';
        }
        if (!charityNumber) {
            isValid = false;
            errors['city'] = 'This filed is required.';
        }
        setErrors(errors);
        return isValid;
    };

    const changeAddress = async (e) => {
        e.preventDefault();
        if (validateChangeAddress()) {
            try {
                const geocoder = new window.google.maps.Geocoder();
                const response = await geocoder.geocode({'address': address});

                const unFormattedPhone = mobile
                  .replaceAll('(', '')
                  .replaceAll(')', '')
                  .replaceAll('-', '')
                  .replaceAll(' ', '');

                const requestBody = {
                    first_name: firstName,
                    last_name: lastName,
                    phone_number: unFormattedPhone,
                    postal_code: postalCode,
                    city_id: county,
                    address,
                    charity_number: charityNumber,
                    lat: response.results[0].geometry.location.lat(),
                    long: response.results[0].geometry.location.lng(),
                };

                if (charityName !== '') {
                    requestBody.charity_name = charityName;
                }

                postRequest(`${API_ENDPOINT_URL}/charity/profile/update`, requestBody, true).then((res) => {
                    if (res) {
                        toast.success('Address updated successfully');
                    }
                });
            } catch (err) {
                console.log(err);
                toast.error("Unable to validate address.");
            }
        }
    };

    const validatePasswords = () => {
        let errors = {};
        let isValid = true;
        const vp = validatePassword(newPassword);

        if (!oldPassword) {
            isValid = false;
            errors['oldPassword'] = 'This filed is required.';
        }
        if (!vp.isValid) {
            isValid = false;
            errors['newPassword'] = vp.message;
        }
        if (!newPassword) {
            isValid = false;
            errors['newPassword'] = 'This filed is required.';
        }
        if (newPassword !== confirmNewPassword) {
            isValid = false;
            errors['matchPassword'] = 'New passwords do not match.';
        }

        setErrors(errors);
        return isValid;
    };

    const changePassword = (e) => {
        e.preventDefault();
        if (validatePasswords()) {
            const requestBody = {
                current_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmNewPassword
            };
            postRequest(`${API_ENDPOINT_URL}/charity/profile/change-password`, requestBody, true).then((res) => {
                if (res) {
                    toast.success('Password updated successfully');
                    setOldPassword('');
                    setNewPassword('');
                    setConfirmNewPassword('');
                }
            });
        }
    };

    return (
        <>
            <div className="aaContentWidget forDashboardViews">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Account Settings</h1>
                </header>

                <div className="aaDbRow aaDbInfoRow">
                    {profileData && <ProfileMapComponent profileData={profileData} setProfileData={setProfileData}></ProfileMapComponent>}
                </div>
                <div className="row margin-bottom-50">
                    <div className="col-12 col-md-6">
                        <form className="um-bddFormWrap" onSubmit={changeAddress}>
                            <label className="um-bddFormlb">Change of address</label>
                            <div className="um-bddInputWrap">
                                <input type="text" className="form-control" placeholder="First Name&hellip;"
                                       value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                                {errors.firstName && (
                                    <small className="support-error">
                                        {errors.firstName}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap">
                                <input type="text" className="form-control" placeholder="Last Name&hellip;"
                                       value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                                {errors.lastName && (
                                    <small className="support-error">
                                        {errors.lastName}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap">
                                <input type="tel" className="form-control" placeholder="Mobile&hellip;"
                                       maxLength={20}
                                       onKeyUp={formatToPhone}
                                       onKeyDown={enforceFormat}
                                       value={mobile} onChange={(e) => setMobile(e.target.value)}/>
                                {errors.mobile && (
                                    <small className="support-error">
                                        {errors.mobile}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap">
                                <input type="email" className="form-control" placeholder="Email&hellip;" value={email}
                                       readOnly={true}/>
                            </div>
                            <div className="um-bddInputWrap">
                                <input type="text" className="form-control" placeholder="Charity number&hellip;"
                                       value={charityNumber} onChange={(e) => setCharityNumber(e.target.value)}/>
                                {errors.charityNumber && (
                                    <small className="support-error">
                                        {errors.charityNumber}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap">
                                <input type="text" className="form-control" placeholder="Address 1&hellip;"
                                       value={address} onChange={(e) => setAddress(e.target.value)}/>
                                {errors.address && (
                                    <small className="support-error">
                                        {errors.address}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap">
                                <input type="text" className="form-control" placeholder="Post Code&hellip;"
                                       value={postalCode} onChange={(e) => setPostalCode(e.target.value)}/>
                                {errors.postalCode && (
                                    <small className="support-error">
                                        {errors.postalCode}
                                    </small>
                                )}
                            </div>
                            <div className="form-group um-bddFormSlct">
                                <select className={`form-control  ${errors?.city && 'fcError'}`}
                                        value={county} onChange={(e) => setCounty(e.target.value)}>
                                    <option>Select a county</option>
                                    {Object.keys(fetchedCities).map(country => (
                                        <optgroup label={country.toUpperCase()} key={country}>
                                            {fetchedCities[country].map(city => (
                                                <option key={city.id} value={city.id}>
                                                    {city.name}
                                                </option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </select>
                                {errors.county && (
                                    <small className="support-error">
                                        {errors.county}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap">
                                <input
                                    type="text"
                                    id="charity_name"
                                    className="form-control"
                                    placeholder="Charity name..."
                                    value={charityName}
                                    onChange={(e) => setCharityName(e.target.value)}
                                />
                            </div>
                            <div className="um-formBtnWrap">
                                <button type="submit" className="btn btnFormWidth btnFormStyle">
                                    Submit change of address
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-12 col-md-6">
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <form className="um-bddFormWrap" onSubmit={changePassword}>
                            <div className="um-bddInputWrap">
                                <div className="position-relative icnInputWrap">
                                    <input type={showPassword ? 'text' : 'password'} className="form-control"
                                           placeholder="Old Password"
                                           value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                    {
                                        showPassword === false ?
                                            <i className="far fa-eye-slash icn" onClick={() => setShowPassword(true)}/>
                                            : <i className="far fa-eye icn" onClick={() => setShowPassword(false)}/>
                                    }
                                </div>
                                {errors.oldPassword && (
                                    <small className="support-error">
                                        {errors.oldPassword}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap">
                                <div className="position-relative icnInputWrap">
                                    <input type={showCP ? 'text' : 'password'} className="form-control"
                                           placeholder="New Password"
                                           value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    {
                                        showCP === false ?
                                            <i className="far fa-eye-slash icn" onClick={() => setShowCP(true)}/>
                                            : <i className="far fa-eye icn" onClick={() => setShowCP(false)}/>
                                    }
                                </div>
                                <small>{props.passwordInfo}</small>
                                {errors.newPassword && (
                                    <small className="support-error">
                                        {errors.newPassword}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap extrMbClsii">
                                <div className="position-relative icnInputWrap">
                                    <input type={showCNP ? 'text' : 'password'} className="form-control"
                                           placeholder="Confirm New Password"
                                           value={confirmNewPassword}
                                           onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    />
                                    {
                                        showCNP === false ?
                                            <i className="far fa-eye-slash icn" onClick={() => setShowCNP(true)}/>
                                            : <i className="far fa-eye icn" onClick={() => setShowCNP(false)}/>
                                    }
                                </div>
                                {errors.matchPassword && (
                                    <small className="support-error">
                                        {errors.matchPassword}
                                    </small>
                                )}
                            </div>
                            <div className="um-formBtnWrap">
                                <button type="submit" className="btn btnFormWidth btnFormStyle">Change Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Wrapper apiKey={GOOGLE_KEY}>
                <Map
                  center={{
                      lat: 52.3555,
                      lng: 1.1743
                  }}
                  zoom={16}
                  style={{flexGrow: '1', height: '0%'}}
                  options={'s'}
                >
                </Map>
            </Wrapper>
        </>
    );

};

export default CharitySettings;
