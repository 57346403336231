import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toFormData } from "../../../../utils";
import { getRequest, postRequest } from "../../../../utils/requests";
import {API_ENDPOINT_URL, APP_PREFIX, CURRENCY_SYMBOL} from "../../../../utils/appConstants";
import { Link } from "react-router-dom";
import BottomStripe from "../../bottomStripe";
import Logo from "../../../../assets/images/logo.png";

const BreederOverview = () => {
  const [editInfoModel, setEditInfoModelShow] = useState(false);
  const [uploadImagePreview, setUploadImagePreview] = useState("");
  const [bio, setBio] = useState("");
  const [popUpBio, setPopUpBio] = useState("");
  const [popUpUploadImage, setPopUpUploadImage] = useState(null);
  const [popUpUploadImagePreview, setPopUpUploadImagePreview] = useState("");
  const [advrtList, setAdvertList] = useState([]);

  useEffect(() => {
    getRequest(`${API_ENDPOINT_URL}/breeder/profile/view`, true).then((res) => {
      if (res) {
        if (res.data.data.avatar) {
          setUploadImagePreview(
            `${API_ENDPOINT_URL}/image-retrieve/${res.data.data.avatar}`
          );
        }
        setBio(res.data.data.detail.bio);
      }
    });
    getRequest(`${API_ENDPOINT_URL}/breeder/advert/list`, true).then((res) => {
      if (res) {
        setAdvertList(res?.data?.data);
      }
    });
  }, []);

  const showModal = () => {
    setPopUpBio(bio);
    setPopUpUploadImagePreview(uploadImagePreview);
    setEditInfoModelShow(true);
  };

  const hideModal = () => {
    setEditInfoModelShow(false);
    setPopUpUploadImage(null);
    setPopUpUploadImagePreview("");
    setPopUpBio("");
  };

  const updateBioFunction = () => {
    let requestBody = {
      avatar: popUpUploadImage,
      bio: popUpBio,
    };
    requestBody = toFormData(requestBody);

    postRequest(
      `${API_ENDPOINT_URL}/breeder/profile/info/update`,
      requestBody,
      true
    ).then((res) => {
      if (res) {
        setEditInfoModelShow(false);
        window.location.reload();
      }
    });
  };

  return (
    <>
      <div className="aaContentWidget">
        <p className="bg-primary text-light p-1 mb-5 rounded shadow-sm text-center">
          This is a Seller Account
        </p>{" "}
        <section className="py-2 mb-5 bg-warning rounded shadow-sm">
          <div className="row">
            <div className="col-12 mx-auto text-center">
              <img
                className="logoimagei img-fluid m-1"
                src={Logo}
                width="200"
                height="65"
                alt="petbond"
              />
              <Link
                to={`/app/sell-pet/payment-options`}
                className="arbtnWhite bg-primary shadow-sm btn text-white text-uppercase w-80 m-1"
              >
                Create an advert
              </Link>
              <Link
                to={`/app/listed-pets`}
                className="arbtnWhite bg-primary shadow-sm btn text-white text-uppercase w-80 m-1"
              >
                View Listings & DRAFTS
              </Link>
            </div>
          </div>
        </section>
        <section className="py-2  bg-warning rounded shadow-sm">
          <div className="row">
            <div className="col-12 mx-auto text-center">
              <p className="m-1 text-white">
                Click here to find out more about our free vaccination services
                and free health tests!
              </p>
              <Link
                to={`/app/freeservices`}
                className="arbtnWhite bg-primary shadow-sm btn text-white text-uppercase w-80 m-1"
              >
                FREE SERVICES
              </Link>
            </div>
          </div>
        </section>
        <div className="aaDbRow aaDbInfoRow mt-5">
          <div className="bio-holder">
            <h6 className="h6 aaRwTitle fontNoto">
              <h6>Profile Picture</h6>
            </h6>

            <div className="bio-pic">
              <div className="pichold">
                {uploadImagePreview ? (
                  <img
                    src={uploadImagePreview}
                    className="img-fluid"
                    alt="uploaded-img"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="76.817"
                    height="76.817"
                    viewBox="0 0 76.817 76.817"
                  >
                    <g
                      id="Group_1343"
                      data-name="Group 1343"
                      transform="translate(-462.746 -649.968)"
                    >
                      <path
                        id="Path_139040"
                        data-name="Path 139040"
                        d="M501.155,649.968a38.408,38.408,0,1,1-38.409,38.409A38.336,38.336,0,0,1,501.155,649.968Zm0,18.586A14.276,14.276,0,0,1,515.4,682.8a14.009,14.009,0,0,1-8.672,13.009,23.8,23.8,0,0,1,17.347,17.551,33.866,33.866,0,1,0-45.845,0,23.791,23.791,0,0,1,17.347-17.551,14.186,14.186,0,0,1,5.576-27.256Zm19,47.909a.753.753,0,0,0-.207-.619,19.013,19.013,0,0,0-37.58,0c0,.207-.207.415-.207.619a34.1,34.1,0,0,0,37.994,0Zm-19-43.366a9.7,9.7,0,1,0,9.7,9.7A9.693,9.693,0,0,0,501.155,673.1Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                )}
              </div>
            </div>
            <div className="biotextblock">
              <h6 className="h6 aaRwTitle fontNoto">
                <h6>Your Bio</h6>
              </h6>
              <p>{bio}</p>
              <button
                className="editButton border-0 bg-white"
                onClick={showModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="103.023"
                  height="32.597"
                  viewBox="0 0 103.023 32.597"
                >
                  <g
                    id="Group_1347"
                    data-name="Group 1347"
                    transform="translate(-602.89 -780.565)"
                  >
                    <rect
                      id="Rectangle_630"
                      data-name="Rectangle 630"
                      width="103.022"
                      height="32.597"
                      rx="5.446"
                      transform="translate(602.89 780.565)"
                      fill="#ccc"
                    />
                    <text
                      id="Edit_bio"
                      data-name="Edit bio"
                      transform="translate(617.456 801.693)"
                      fill="#fff"
                      fontSize="14"
                      fontFamily="NunitoSans-Regular, Nunito Sans"
                    >
                      <tspan x="0" y="0">
                        Edit Profile
                      </tspan>
                    </text>
                    <g id="Group_1346" data-name="Group 1346"></g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="aaDbRow aaDbPetsListRow">
          <h6 className="aaRwTitle">
            <h6>Your Recently Aded Adverts</h6>
          </h6>
          <ul className="aaAdvtPetList list-unstyled m-0 p-0">
            {advrtList &&
              advrtList.slice(0, 3).map((advert, index) => (
                <li key={index}>
                  <article className="aaAdvPetPost">
                    <div className="aaLeftWrap">
                      <div className="petImgWrap">
                        <img
                          src={
                            advert.cover_photo
                              ? `${API_ENDPOINT_URL}/image-retrieve/${advert.cover_photo}`
                              : "/images/image-placeholder.png"
                          }
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      {!(
                        advert.status === "approved" &&
                        advert.pet_count === advert.sold_count
                      ) && (
                        <Link to={`${APP_PREFIX}/sell-pet?id=${advert.id}`}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="130.022"
                            height="32.597"
                            viewBox="0 0 130.022 32.597"
                          >
                            <g
                              id="Group_1349"
                              data-name="Group 1349"
                              transform="translate(-435.31 -1098.222)"
                            >
                              <rect
                                id="Rectangle_631"
                                data-name="Rectangle 631"
                                width="130.022"
                                height="32.597"
                                rx="5.446"
                                transform="translate(435.31 1098.222)"
                                fill="#ccc"
                              />
                              <text
                                id="Edit_listing"
                                data-name="Edit listing"
                                transform="translate(449.876 1119.35)"
                                fill="#fff"
                                fontSize="14"
                                fontFamily="NunitoSans-Regular, Nunito Sans"
                              >
                                <tspan x="0" y="0">
                                  Edit listing
                                </tspan>
                              </text>
                              <g id="Group_1348" data-name="Group 1348">
                                <path
                                  id="Path_139043"
                                  data-name="Path 139043"
                                  d="M552.1,1105.533a1.732,1.732,0,0,0-2.443,0l-6.839,6.845a.835.835,0,0,0-.208.36l-.71,2.761a.8.8,0,0,0,.208.76.784.784,0,0,0,.563.235.826.826,0,0,0,.2-.027l2.761-.711a.819.819,0,0,0,.361-.208l6.844-6.838a1.733,1.733,0,0,0,0-2.444Z"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_139044"
                                  data-name="Path 139044"
                                  d="M550.505,1113.457a.793.793,0,0,0-.793.793v5.272a.322.322,0,0,1-.322.321H538.848a.321.321,0,0,1-.321-.321v-10.543a.321.321,0,0,1,.321-.321h5.271a.793.793,0,1,0,0-1.587h-5.271a1.91,1.91,0,0,0-1.909,1.908v10.543a1.911,1.911,0,0,0,1.909,1.908H549.39a1.911,1.911,0,0,0,1.909-1.908v-5.272A.794.794,0,0,0,550.505,1113.457Z"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                          </svg>
                        </Link>
                      )}
                      <div className="btnbox">
                        {!(
                          advert.status === "approved" &&
                          advert.pet_count === advert.sold_count
                        ) && (
                          <Link to={`${APP_PREFIX}/sell-pet?id=${advert.id}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="35.691"
                              height="37.444"
                              viewBox="0 0 35.691 37.444"
                            >
                              <g
                                id="Group_1350"
                                data-name="Group 1350"
                                transform="translate(-588.379 -950.888)"
                              >
                                <path
                                  id="Rectangle_632"
                                  data-name="Rectangle 632"
                                  d="M5,0H28.959a6.732,6.732,0,0,1,6.732,6.732v23.98a6.732,6.732,0,0,1-6.732,6.732H6.732A6.732,6.732,0,0,1,0,30.712V5A5,5,0,0,1,5,0Z"
                                  transform="translate(588.379 950.888)"
                                  fill="#00d819"
                                />
                                <path
                                  id="Path_139045"
                                  data-name="Path 139045"
                                  d="M612.806,956.3a.722.722,0,0,0-1.021,1.021l1.069,1.07-3.8,3.8-.059-.059-.009-.009-1.879-1.879a.722.722,0,0,0-1.022,1.021l1.378,1.378-9.41,9.409a.723.723,0,0,0-.178.295l-1.839,5.868-2.161,2.162A.722.722,0,0,0,594.9,981.4l2.162-2.162,5.868-1.838a.733.733,0,0,0,.295-.178l9.41-9.41,1.378,1.377a.722.722,0,0,0,1.021-1.021l-1.883-1.883-.006-.006-.06-.061,3.8-3.8,1.071,1.072a.723.723,0,0,0,1.022-1.022l-1.551-1.55-.031-.033-3.005-3.005-.033-.032Zm1.069,3.112,1.984,1.984-3.651,3.651-1.984-1.984Zm-5.39,4.251,3.125,3.125-9.284,9.285-4.55,1.424,1.425-4.549,1.669-1.669,1.562,1.562a.722.722,0,0,0,1.022-1.021l-1.563-1.563,1.115-1.115,1.563,1.563a.722.722,0,1,0,1.021-1.022l-1.562-1.562,1.115-1.115,1.562,1.562a.723.723,0,0,0,1.022-1.022l-1.563-1.562Z"
                                  fill="#fff"
                                  fillRule="evenodd"
                                />
                              </g>
                            </svg>
                          </Link>
                        )}
                        <Link to={advert?.advert_url}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35.691"
                            height="37.444"
                            viewBox="0 0 35.691 37.444"
                          >
                            <g
                              id="Group_1353"
                              data-name="Group 1353"
                              transform="translate(-588.379 -997.086)"
                            >
                              <path
                                id="Rectangle_633"
                                data-name="Rectangle 633"
                                d="M5,0H28.959a6.732,6.732,0,0,1,6.732,6.732v23.98a6.732,6.732,0,0,1-6.732,6.732H6.732A6.732,6.732,0,0,1,0,30.712V5A5,5,0,0,1,5,0Z"
                                transform="translate(588.379 997.086)"
                                fill="#00d819"
                              />
                              <g id="Group_1352" data-name="Group 1352">
                                <g id="Group_1351" data-name="Group 1351">
                                  <path
                                    id="Path_139046"
                                    data-name="Path 139046"
                                    d="M595.38,1014.51c2.723-3.21,7.082-6.174,11.451-6.174,4.464,0,8.838,3.039,11.5,6.426a1.816,1.816,0,0,1,0,2.25,19.159,19.159,0,0,1-2.657,2.782,14.687,14.687,0,0,1-8.839,3.527c-2.774,0-6.261-1.632-8.38-3.293a21.136,21.136,0,0,1-3.071-2.984,2.057,2.057,0,0,1,0-2.534Zm10.313-1.2a1.138,1.138,0,1,1-1.138,1.138A1.139,1.139,0,0,1,605.693,1013.314Zm1.138-1.01a3.525,3.525,0,1,1-3.524,3.524A3.524,3.524,0,0,1,606.831,1012.3Zm6.369-.422a7.49,7.49,0,0,1-.1,8.045,16.36,16.36,0,0,0,4.109-3.792.393.393,0,0,0,0-.5A17.072,17.072,0,0,0,613.2,1011.882Zm-12.792,7.806a7.5,7.5,0,0,1,.077-7.845,18.209,18.209,0,0,0-4.022,3.585.665.665,0,0,0,0,.7A18.607,18.607,0,0,0,600.408,1019.688Zm6.423-9.807a5.947,5.947,0,1,1-5.947,5.947A5.948,5.948,0,0,1,606.831,1009.881Z"
                                    fill="#fff"
                                    fillRule="evenodd"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </Link>
                      </div>
                    </div>
                    <div className="aaRightWrap">
                      {advert.advert_name && (
                        <h6 className="h6 aaAdvTitle fontNoto">
                          {advert.advert_name}
                        </h6>
                      )}
                      <ul className="aaAdvdetailList list-unstyled p-0 m-0">
                        {advert.name && <li>Breed: {advert.name}</li>}
                        {advert.pet_count > 0 && (
                          <li>
                            Puppies sold: {advert.sold_count} of{" "}
                            {advert.pet_count}
                          </li>
                        )}
                        {advert.dob && <li>Date of birth: {advert.dob}</li>}
                        {advert.pets && (
                          <li>
                            Price from:{" "}
                            {advert.pets &&
                              advert.pets.map((price, index) => (
                                <span key={index}>
                                  {CURRENCY_SYMBOL}{price?.price?.toFixed(2)}
                                  {advert?.pets?.length - 1 !== index && (
                                    <span>, </span>
                                  )}
                                </span>
                              ))}
                          </li>
                        )}
                        {advert.mother_name && (
                          <li>Mother: {advert.mother_name}</li>
                        )}
                        {advert.father_name && (
                          <li>Father: {advert.father_name}</li>
                        )}
                        {advert.status && (
                          <li className="text-capitalize">
                            Status: {advert.status}
                          </li>
                        )}
                      </ul>
                    </div>
                  </article>
                </li>
              ))}
          </ul>
          {advrtList.length <= 0 && <p>No data found</p>}
        </div>
        <BottomStripe />
      </div>

      <Modal
        show={editInfoModel}
        className="editInfoModal generalPopupStyles"
        centered
        onHide={hideModal}
      >
        <Modal.Body>
          <div id="popupbio">
            <form className="bio-form">
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-4 text-center">
                  <div className="bio-pic">
                    <div className="pichold">
                      {popUpUploadImagePreview ? (
                        <img
                          src={popUpUploadImagePreview}
                          className="img-fluid"
                          alt="uploaded-img"
                        />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="76.817"
                          height="76.817"
                          viewBox="0 0 76.817 76.817"
                        >
                          <g
                            id="Group_1343"
                            data-name="Group 1343"
                            transform="translate(-462.746 -649.968)"
                          >
                            <path
                              id="Path_139040"
                              data-name="Path 139040"
                              d="M501.155,649.968a38.408,38.408,0,1,1-38.409,38.409A38.336,38.336,0,0,1,501.155,649.968Zm0,18.586A14.276,14.276,0,0,1,515.4,682.8a14.009,14.009,0,0,1-8.672,13.009,23.8,23.8,0,0,1,17.347,17.551,33.866,33.866,0,1,0-45.845,0,23.791,23.791,0,0,1,17.347-17.551,14.186,14.186,0,0,1,5.576-27.256Zm19,47.909a.753.753,0,0,0-.207-.619,19.013,19.013,0,0,0-37.58,0c0,.207-.207.415-.207.619a34.1,34.1,0,0,0,37.994,0Zm-19-43.366a9.7,9.7,0,1,0,9.7,9.7A9.693,9.693,0,0,0,501.155,673.1Z"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      )}
                    </div>
                  </div>
                  <span className="customFileBtnWrap w-100 text-center">
                    <input
                      id="proofOAddress"
                      type="file"
                      accept="image/jpeg"
                      onChange={(e) => {
                        setPopUpUploadImage(e.target.files[0]);
                        setPopUpUploadImagePreview(
                          URL.createObjectURL(e.target.files[0])
                        );
                      }}
                    />
                    <label htmlFor="proofOAddress" className="edit_photo">
                      Edit photo
                    </label>
                  </span>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-8 textarea">
                  <textarea
                    id="bio"
                    value={popUpBio}
                    onChange={(e) => {
                      setPopUpBio(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Type your info&hellip;"
                  ></textarea>
                </div>
              </div>
              <div className="row no-margin">
                <div className="col-xs-12 col-sm-12">
                  <button
                    type="button"
                    onClick={updateBioFunction}
                    className="btn-warning btn btnFormWidth btnFormStyle small"
                  >
                    <span>Save</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26.462"
                      height="15.829"
                      viewBox="0 0 26.462 15.829"
                    >
                      <g
                        id="Group_1294"
                        data-name="Group 1294"
                        transform="translate(-787.452 -2816.781)"
                      >
                        <g id="Group_1293" data-name="Group 1293">
                          <path
                            id="Path_134323"
                            data-name="Path 134323"
                            d="M793.053,2821.408a4.007,4.007,0,0,1,1.38.163,6.454,6.454,0,0,1,6.25-4.79,6.625,6.625,0,0,1,6.332,4.79,3.344,3.344,0,0,1,1.3-.163,5.6,5.6,0,0,1,0,11.2h-15.26a5.6,5.6,0,0,1,0-11.2Zm15.26,9.822a4.192,4.192,0,0,0,4.221-4.221,4.261,4.261,0,0,0-4.221-4.3,4.1,4.1,0,0,0-.974.162,1.452,1.452,0,0,1-1.7-.974,5.069,5.069,0,0,0-4.952-3.734,5.214,5.214,0,0,0-4.952,3.734,1.326,1.326,0,0,1-1.623.974,4.777,4.777,0,0,0-1.055-.162,4.261,4.261,0,0,0-4.221,4.3,4.192,4.192,0,0,0,4.221,4.221Z"
                            fill="#fff"
                            fillRule="evenodd"
                          ></path>
                          <path
                            id="Path_134324"
                            data-name="Path 134324"
                            d="M797.355,2826.6a.661.661,0,0,1,.893-.975l1.461,1.462,3.409-3.41a.69.69,0,0,1,.974.974l-3.9,3.9a.74.74,0,0,1-.974,0Z"
                            fill="#fff"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BreederOverview;
