import { Link, NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Logo from "../../../assets/images/logo.png";
import LogoAlt from "../../../assets/images/logo2.png";
import { APP_PREFIX, AUTH_PREFIX } from "../../../utils/appConstants";

const Header = (props) => {
  const [scroll, setScroll] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });
  }, []);

  const afterLogin = () => {
    const userRole = JSON.parse(localStorage.getItem("currentUser"))?.role;
    if (userRole === "sub_veterinarian") {
      return `${APP_PREFIX}/connected-breeder`;
    }
    return `${APP_PREFIX}/overview`;
  };

  return (
    <>
      <div className={`phStickyWrap ${showMenu ? "mainMenuActive" : ""}`}>
        <header id="pageHeader" className={scroll ? "headerSticky" : ""}>
          <div className="arContainerLarge clearfix">
            <div className="logo">
              <Link to="/">
                {scroll ? (
                  <img
                    className=" img-fluid"
                    src={LogoAlt}
                    width="255"
                    height="65"
                    alt="petbond"
                  />
                ) : (
                  <img
                    className="logoimagei img-fluid"
                    src={Logo}
                    width="255"
                    height="65"
                    alt="petbond"
                  />
                )}
              </Link>
            </div>
            <nav
              id="pageNav"
              className="navbar navbar-expand-lg navbar-dark justify-content-end"
            >
              <div
                className="collapse navbar-collapse pageMainNavigationCollapse"
                id="pageMainNavigationCollapse"
              >
                <div className="mnInternalWrap">
                  <ul className="navbar-nav mainNavigation">
                    <li className="nav-item">
                      <NavLink
                        to="/pet-listing"
                        onClick={() => setShowMenu(false)}
                        className={(n) =>
                          n.isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        Find my pet
                      </NavLink>
                    </li>
                    <li className="nav-item niItemHidden">
                      <NavLink
                        to={`${APP_PREFIX}/`}
                        onClick={() => setShowMenu(false)}
                        className={(n) =>
                          n.isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        News &amp; Stories
                      </NavLink>
                    </li>
                    <li className="nav-item niItemHidden">
                      <NavLink
                        to={`${APP_PREFIX}/`}
                        onClick={() => setShowMenu(false)}
                        className={(n) =>
                          n.isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        Breeder Sign Up
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={`/auth/breeder-signup`}
                        onClick={() => setShowMenu(false)}
                        className={(n) =>
                          n.isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        Advertisers
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={`/find-a-vet`}
                        onClick={() => setShowMenu(false)}
                        className={(n) =>
                          n.isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        Vet Teams
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={`/petbond-match`}
                        onClick={() => setShowMenu(false)}
                        className={(n) =>
                          n.isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        Perfect matches
                      </NavLink>
                    </li>
                    <li className="nav-item niItemHidden">
                      <NavLink
                        to={`${APP_PREFIX}/`}
                        onClick={() => setShowMenu(false)}
                        className={(n) =>
                          n.isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        FAQs
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={`/about-us`}
                        onClick={() => setShowMenu(false)}
                        className={(n) =>
                          n.isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        About Us
                      </NavLink>
                    </li>
                  </ul>
                  <div className="mnLogo d-md-none">
                    <img
                      className="img-fluid"
                      src="/images/logo2.svg"
                      width="255"
                      height="65"
                      alt="petbond"
                    />
                  </div>
                  <ul className="list-unstyled arPriveList justify-content-center flex-wrap mb-0">
                    {/* <li>
                      <NavLink
                        to={`/mission-statement`}
                        onClick={() => setShowMenu(false)}
                      >
                        Mission Statement
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink to={`/faq`} onClick={() => setShowMenu(false)}>
                        FAQS
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/contact-us`}
                        onClick={() => setShowMenu(false)}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <a href="https://mypetbond.com/petscorner">Pets Corner</a>
                    </li>
                    <li>
                      <NavLink
                        to={`/terms-conditions`}
                        onClick={() => setShowMenu(false)}
                      >
                        Terms &amp; Conditions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={`/privacy-policy`}
                        onClick={() => setShowMenu(false)}
                      >
                        Privacy Policy
                      </NavLink>
                    </li>
                  </ul>
                  <ul className="list-unstyled ftSocialList justify-content-center align-items-center mb-0">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/petbondireland"
                        className="fab fa-facebook"
                      ></a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/pet_bond/"
                        className="fab fa-instagram"
                      ></a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://twitter.com/petbond_ireland"
                        className="fab fa-twitter"
                      ></a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/channel/UCjL7H8i0eZmDCDs21CbiWkQ"
                        className="fab fa-youtube"
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <ul className="list-unstyled userActionsList d-flex align-items-center mb-0 justify-content-end">
                <li>
                  {props.isLoggedIn ? (
                    <Link to={afterLogin()} onClick={() => setShowMenu(false)}>
                      Dashboard
                      <i className="aricomoon-arclaw icn"></i>
                    </Link>
                  ) : (
                    <>
                      <Link
                        to={`${AUTH_PREFIX}/login`}
                        onClick={() => setShowMenu(false)}
                      >
                        Login
                        <i className="aricomoon-locked icn">
                          <span className="sr-only">icon</span>
                        </i>
                      </Link>
                    </>
                  )}
                </li>
                <li>
                  {!props.isLoggedIn && (
                    <Link
                      to={`${AUTH_PREFIX}/signup-selector`}
                      onClick={() => setShowMenu(false)}
                    >
                      Register
                      <i className="aricomoon-clipboardUserSolid icn">
                        <span className="sr-only">icon</span>
                      </i>
                    </Link>
                  )}
                </li>
                {/*<li>*/}
                {/*    <a href="#">*/}
                {/*        <i className="aricomoon-pet icnCart"><span className="sr-only">icon</span></i>*/}
                {/*    </a>*/}
                {/*</li>*/}
              </ul>
              <button
                style={{ background: "transparent" }}
                type="button"
                className="ardeskMenuOpener menuOpenerAlt d-none d-lg-block"
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
              >
                <span className="icnBar">
                  <span className="sr-only">menu</span>
                </span>
              </button>
              <button
                className="navbar-toggler mainMenuToggler menuOpenerAlt"
                type="button"
                data-toggle="collapse"
                data-target="#pageMainNavigationCollapse"
                aria-controls="pageMainNavigationCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </nav>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
