import React, { useEffect, useState } from 'react';
import { getRequest, deleteRequest, postRequest } from '../../../../utils/requests';
import { API_ENDPOINT_URL } from '../../../../utils/appConstants';
import { useInput } from '../../../../components/hooks/useInput';
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';
import Modal from 'react-bootstrap/Modal';
import { validatePassword } from '../../../../utils';

const VetSettings = (props) => {
    const [vetStaffList, setVetStaffList] = useState([]);

    const [firstName, bindFirstName] = useInput('');
    const [lastName, bindLastName] = useInput('');
    const [email, bindEmail] = useInput('');
    const [password, bindPassword] = useInput('');
    const [phone, bindPhone] = useInput('');
    const [showPassword, setShowPassword] = useState(false);
    const [showCP, setShowCP] = useState(false);
    const [showCNP, setShowCNP] = useState(false);
    const [showModalPassword, setShowModalPassword] = useState(false);


    const [popupStaffId, setPopupStaffId] = useState('');
    const [popupFirstName, setPopupFirstName] = useState('');
    const [popupLastName, setPopupLastName] = useState('');
    const [popupEmail, setPopupEmail] = useState('');


    const [addStaffModal, setAddStaffModalShow] = useState(false);
    const [editStaffModal, setEditStaffModalShow] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState(false);

    const validatePasswords = () => {
        let errors = {};
        let isValid = true;
        const vp = validatePassword(newPassword);

        if (!oldPassword) {
            isValid = false;
            errors['oldPassword'] = 'This filed is required.';
        }
        if (!vp.isValid) {
            isValid = false;
            errors['newPassword'] = vp.message;
        }
        if (!newPassword) {
            isValid = false;
            errors['newPassword'] = 'This filed is required.';
        }
        if (newPassword !== confirmNewPassword) {
            isValid = false;
            errors['matchPassword'] = 'New passwords do not match.';
        }

        setErrors(errors);
        return isValid;
    };

    //Send the password on form submission
    const updatePassword = (event) => {
        event.preventDefault();
        if (validatePasswords()) {
            const requestBody = {
                current_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmNewPassword
            };
            postRequest(`${API_ENDPOINT_URL}/veterinarian/profile/change-password`, requestBody, true).then((res) => {
                if (res) {
                    toast.success('Password updated successfully');
                    setOldPassword('');
                    setNewPassword('');
                    setConfirmNewPassword('');
                }
            });
        }
    };

    useEffect(() => {
        getRequest(`${API_ENDPOINT_URL}/veterinarian/sub-veterinarian/list`, true).then((res) => {
            setVetStaffList(res?.data?.data);
        });
    }, []);

    const editVetStaffInfo = (id) => {

        getRequest(`${API_ENDPOINT_URL}/veterinarian/sub-veterinarian/edit/${id}`, true).then((res) => {
            setPopupStaffId(res.data.data.id);
            setPopupFirstName(res.data.data.first_name);
            setPopupLastName(res.data.data.last_name);
            setPopupEmail(res.data.data.email);
            setEditStaffModalShow(true);
        });
    };

    // Sub vet registertion validation
    const checkUpdateValidation = () => {
        let errors = {};
        let isValid = true;

        if (!popupFirstName) {
            isValid = false;
            errors['popupFirstName'] = 'This filed is required.';
        }
        if (!popupLastName) {
            isValid = false;
            errors['popupLastName'] = 'This filed is required.';
        }
        if (!popupEmail) {
            isValid = popupEmail;
            errors['popupEmail'] = 'This filed is required.';
        }
        if (popupEmail) {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(popupEmail)) {
                isValid = false;
                errors['popupEmail'] = 'Please enter valid email address.';
            }
        }
        setErrors(errors);
        return isValid;
    };

    //Send the Updated data on form submission
    const updateVetStaffinfo = (event) => {
        event.preventDefault();
        if (checkUpdateValidation()) {
            postRequest(`${API_ENDPOINT_URL}/veterinarian/sub-veterinarian/update?user_id=${popupStaffId}&first_name=${popupFirstName}&last_name=${popupLastName}&email=${popupEmail}`, null, true).then((res) => {
                if (res) {
                    toast.success('Staff info updated successfully');
                    window.location.reload();
                }
            });
        }
    };

    const removeStaff = (id) => {

        deleteRequest(`${API_ENDPOINT_URL}/veterinarian/sub-veterinarian/delete/${id}`, true).then((res) => {
            if (res) {
                toast.success('Staff removed successfully');
                window.location.reload();
            }
        });
    };

    // Sub vet registration validation
    const checkValidation = () => {
        let errors = {};
        let isValid = true;
        const vp = validatePassword(password);

        if (!firstName) {
            isValid = false;
            errors['firstName'] = 'This filed is required.';
        }
        if (!lastName) {
            isValid = false;
            errors['lastName'] = 'This filed is required.';
        }
        if (!email) {
            isValid = false;
            errors['email'] = 'This filed is required.';
        }
        if (email) {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(email)) {
                isValid = false;
                errors['email'] = 'Please enter valid email address.';
            }
        }
        if (!vp.isValid) {
            isValid = false;
            errors['password'] = vp.message;
        }
        if (!password) {
            isValid = false;
            errors['password'] = 'This filed is required.';
        }
        setErrors(errors);
        return isValid;
    };

    //Send the Info data on form submission
    const registerVetStaff = (event) => {
        event.preventDefault();
        if (checkValidation()) {

            const requestBody = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password
            };

            postRequest(`${API_ENDPOINT_URL}/veterinarian/sub-veterinarian/store`, requestBody, true).then((res) => {
                if (res) {
                    toast.success('Staff registered successfully');
                    window.location.reload();
                }
            });

        }
    };

    return (
        <>
            <div className="aaContentWidget">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Account Settings</h1>
                </header>
                <div className="aaDbRow aaDbInfoRow">
                    <h6 className="h6 aaRwTitle fontNoto">Veterinarian staff</h6>
                    <div className="connectbreeder">
                        <ul className="list-unstyled ftList">
                            {
                                vetStaffList && vetStaffList.map((staff, index) => (
                                    <li key={index}>
                                        <span className="left">{staff.first_name} - {staff.last_name} - {staff.email}</span>
                                        <div className="right">
                                            <button
                                                onClick={() => {
                                                    editVetStaffInfo(staff.id);
                                                }}
                                                type="button"
                                                className="btn btnFormWidth btnFormStyle small-xs"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() => {
                                                    removeStaff(staff.id);
                                                }}
                                                type="button"
                                                className="btn btnFormWidth btnFormStyle small-xs red"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="um-formBtnWrap pt-4">
                        <button
                            className="btn btn-warning small"
                            onClick={() => {
                                setAddStaffModalShow(true);
                            }}
                        >
                            Add Staff
                        </button>
                    </div>
                </div>
                <div className="aaDbRow aaDbInfoRow">
                    <h6 className="h6 aaRwTitle fontNoto">Reset Password</h6>
                    <form className="um-bddFormWrap um-chrtFormInf" onSubmit={(event) => updatePassword(event)}>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="um-bddInputWrap">
                                    <label>Current Password</label>
                                    <div className="position-relative icnInputWrap">
                                        <input type={showPassword ? 'text' : 'password'} className="form-control"
                                               value={oldPassword}
                                               onChange={(e) => setOldPassword(e.target.value)}/>
                                        {
                                            showPassword === false ?
                                                <i className="far fa-eye-slash icn"
                                                   onClick={() => setShowPassword(true)}/>
                                                : <i className="far fa-eye icn" onClick={() => setShowPassword(false)}/>
                                        }
                                    </div>
                                    {errors.oldPassword && (
                                        <small className="support-error">
                                            {errors.oldPassword}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="um-bddInputWrap">
                                    <label>New Password</label>
                                    <div className="position-relative icnInputWrap">
                                        <input
                                            id="password"
                                            type={showCP ? 'text' : 'password'}
                                            className="form-control"
                                            value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                        {
                                            showCP === false ?
                                                <i className="far fa-eye-slash icn" onClick={() => setShowCP(true)}/>
                                                : <i className="far fa-eye icn" onClick={() => setShowCP(false)}/>
                                        }
                                    </div>
                                    <small>{props.passwordInfo}</small>
                                    {errors.newPassword && (
                                        <small className="support-error">
                                            {errors.newPassword}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="um-bddInputWrap">
                                    <label>Confirm Password</label>
                                    <div className="position-relative icnInputWrap">
                                        <input
                                            type={showCNP ? 'text' : 'password'}
                                            id="confirmPassword"
                                            className="form-control"
                                            value={confirmNewPassword}
                                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                                        />
                                        {
                                            showCNP === false ?
                                                <i className="far fa-eye-slash icn" onClick={() => setShowCNP(true)}/>
                                                : <i className="far fa-eye icn" onClick={() => setShowCNP(false)}/>
                                        }
                                    </div>
                                    {errors.matchPassword && (
                                        <small className="support-error">
                                            {errors.matchPassword}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="um-formBtnWrap pt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-warning btnFormStyle btnFormWidth"
                                        disabled={showLoader}
                                    >
                                        {showLoader && (
                                            <HalfCircleSpinner
                                                size={18}
                                                style={{
                                                    display: 'inline-block',
                                                    marginRight: '5px'
                                                }}
                                            />
                                        )}
                                        Update Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* Registered Staff Modal */}
                <Modal
                    show={addStaffModal}
                    className="addVetStaffModal generalPopupStyles"
                    centered
                    onHide={() => {
                        setAddStaffModalShow(false);
                    }}
                >
                    <Modal.Body>
                        <div className="aaDbRow aaDbInfoRow contWrap mb-0">
                            <form className="um-bddFormWrap" onSubmit={(event) => registerVetStaff(event)}>
                                <h1 className="h4 aaPtbHeading text-center border-0 mb-10">Add Veterinarian Staff</h1>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <label>First Name:</label>
                                            <input
                                                type="text"
                                                id="first_name"
                                                className="form-control"
                                                {...bindFirstName}
                                            />
                                            {errors.firstName && (
                                                <small className="support-error">
                                                    {errors.firstName}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <label>Last Name:</label>
                                            <input
                                                type="text"
                                                id="last_name"
                                                className="form-control"
                                                {...bindLastName}
                                            />
                                            {errors.firstName && (
                                                <small className="support-error">
                                                    {errors.lastName}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <label>Email:</label>
                                            <input
                                                type="email"
                                                id="email"
                                                className="form-control"
                                                {...bindEmail}
                                            />
                                            {errors.email && (
                                                <small className="support-error">
                                                    {errors.email}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <label>Password:</label>
                                            <div className="position-relative icnInputWrap">
                                                <input
                                                    type={showModalPassword ? 'text' : 'password'}
                                                    id="password"
                                                    className="form-control"
                                                    {...bindPassword}
                                                />
                                                {
                                                    showModalPassword === false ?
                                                        <i className="far fa-eye-slash icn"
                                                           onClick={() => setShowModalPassword(true)}/>
                                                        : <i className="far fa-eye icn"
                                                             onClick={() => setShowModalPassword(false)}/>
                                                }
                                            </div>
                                            <small>{props.passwordInfo}</small>
                                            {errors.password && (
                                                <small className="support-error">
                                                    {errors.password}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="um-formBtnWrap pt-4">
                                            <button
                                                type="submit"
                                                className="btn btn-warning small"
                                                disabled={showLoader}
                                            >
                                                {showLoader && (
                                                    <HalfCircleSpinner
                                                        size={18}
                                                        style={{
                                                            display: 'inline-block',
                                                            marginRight: '5px'
                                                        }}
                                                    />
                                                )}
                                                Register Staff
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* Edit Staff Info Modal */}
                <Modal
                    show={editStaffModal}
                    className="addVetStaffModal generalPopupStyles edit"
                    centered
                    onHide={() => {
                        setEditStaffModalShow(false);
                    }}
                >
                    <Modal.Body>
                        <div className="aaDbRow aaDbInfoRow contWrap mb-0">
                            <form className="um-bddFormWrap" onSubmit={(event) => updateVetStaffinfo(event)}>
                                <h1 className="h4 aaPtbHeading text-center border-0 mb-10">Edit Veterinarian Staff
                                    Info</h1>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <label>First Name:</label>
                                            <input
                                                type="text"
                                                id="popupFirstName"
                                                className="form-control"
                                                value={popupFirstName}
                                                onChange={(e) => setPopupFirstName(e.target.value)}
                                            />
                                            {errors.popupFirstName && (
                                                <small className="support-error">
                                                    {errors.popupFirstName}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <label>Last Name:</label>
                                            <input
                                                type="text"
                                                id="popupLastName"
                                                className="form-control"
                                                value={popupLastName}
                                                onChange={(e) => setPopupLastName(e.target.value)}
                                            />
                                            {errors.popupLastName && (
                                                <small className="support-error">
                                                    {errors.popupLastName}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="um-bddInputWrap">
                                            <label>Email:</label>
                                            <input
                                                type="email"
                                                id="popupEmail"
                                                className="form-control"
                                                value={popupEmail}
                                                onChange={(e) => setPopupEmail(e.target.value)}
                                            />
                                            {errors.popupEmail && (
                                                <small className="support-error">
                                                    {errors.popupEmail}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="um-formBtnWrap pt-4">
                                            <button
                                                type="submit"
                                                className="btn btn-warning small"
                                                disabled={showLoader}
                                            >
                                                {showLoader && (
                                                    <HalfCircleSpinner
                                                        size={18}
                                                        style={{
                                                            display: 'inline-block',
                                                            marginRight: '5px'
                                                        }}
                                                    />
                                                )}
                                                Update Info
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );

};

export default VetSettings;
