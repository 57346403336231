import React, { useState, useRef, useEffect, Children, isValidElement, cloneElement } from 'react';

export const Map = ({center, zoom, children, style, ...options}) => {

    const ref = useRef(null);
    const [map, setMap ] = useState();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {
                center,
                zoom
            }));
        }
    }, [ref, map]);

    useEffect(() => {
        if (map) {
            ['click', 'idle'].forEach((eventName) =>
                new window.google.maps.event.clearListeners(map, eventName)
            );
        }
    }, [map]);

    return (
        <>
            <div ref={ref} style={style}/>
            {Children.map(children, (child) => {
                if (isValidElement(child)) {
                    return cloneElement(child, {map});
                }
            })}
        </>
    );
};
