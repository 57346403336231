import Pusher from "pusher-js";
import {API_ENDPOINT_URL, PUSHER_APP_KEY, PUSHER_CLUSTER} from "../../utils/appConstants";
import {evaluateChatUserName, markAsRead} from "./chat.service";

const initPusher = () => {
    const pusher = new Pusher(PUSHER_APP_KEY, {
        cluster: PUSHER_CLUSTER,
        encrypted: true,
        channelAuthorization: {
            endpoint: `${API_ENDPOINT_URL}/pusher/authenticate-channel`,
            transport: 'ajax',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`
            },
        }
    });

    pusher.connection.bind("connected", function () {
        // console.log('connected!');
    });

    return pusher;
}

const subscribeChat = (pusherInstance, chatId, user) => {
    const channel = pusherInstance.subscribe(`presence-chat-${chatId}`);
    channel.bind("pusher:subscription_succeeded", () => {
        // console.log('subscription succeeded');
    });
    channel.bind('message-receive', (data) => {
        if (data.message.sender_id !== user.id) {
            window.setMessages((prevState) => [
                ...prevState,
                {
                    ...data.message,
                    userName: evaluateChatUserName(user, data.message, prevState),
                }
            ])

            if (data.message.is_read === false) {
                markAsRead(user?.role, chatId, [data.message.id]);
            }
        }
    });

    return channel;
}

const generalNotifications = (pusherInstance, user) => {
    const channel = pusherInstance.subscribe(`private-general-notifications-${user.id}`);
    channel.bind("pusher:subscription_succeeded", () => {
        // console.log('subscription succeeded to private-general-notifications-' + user.id);
    });
    channel.bind('unread-messages', (data) => {
        if (data.data) {
            window.setUnreadMessagesObj((prevState) => ({
                ...(data.data)
            }));
        }

    });

    return channel;
}

export {
    initPusher,
    subscribeChat,
    generalNotifications,
}