import React, { useState } from 'react';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { postRequest } from '../../../../utils/requests';
import { API_ENDPOINT_URL } from '../../../../utils/appConstants';
import { useInput } from '../../../../components/hooks/useInput';
import { toast } from 'react-toastify';
import {enforceFormat, formatToPhone} from "../../../../utils";

const BuyerReportIssue = () => {
    const [issueName, bindIssueName] = useInput('');
    const [message, bindMessage] = useInput('');
    const [phone, bindPhone] = useInput('+44');
    const [errors, setErrors] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    
    // validation
    const validateUpdateInfo = () => {
        let errors = {};
        let isValid = true;

        if (!issueName) {
            isValid = false;
            errors['issueName'] = 'This filed is required.';
        }
        if (!phone) {
            isValid = false;
            errors['phone'] = 'This filed is required.';
        }
        if (phone) {
            let res = phone.replace(/\D/g, '');
            if (res.length < 10) {
                isValid = false;
                errors['phone'] = 'Please enter a Valid Phone number.';
            }
        }
        if (!message) {
            isValid = false;
            errors['message'] = 'This filed is required.';
        }
        setErrors(errors);
        return isValid;
    };
    
    //Send the Info data on form submission
    const reportIssue = (event) => {
        event.preventDefault();
        if (validateUpdateInfo()) {

            const requestBody = {
                subject: issueName,
                phone_number: phone,
                message: message,
                // user_id,
            };
            
            postRequest(`${API_ENDPOINT_URL}/buyer/complain/store`, requestBody, true).then((res) => {
                if (res) {
                    toast.success('Issue reported successfully');
                    window.location.reload();
                }
            });
        }
    };

    return (
        <>
			<div className="aaContentWidget buyerDashboardView">
				<header className="aaPtbdHeader">
					<h1 className="h3 aaPtbHeading">Report an Issue With Breeder</h1>
				</header>
                <div className="aaDbRow aaDbInfoRow">
                    {/* <h6 className="h6 aaRwTitle fontNoto">Reset Password</h6> */}
                    <form className="um-bddFormWrap um-chrtFormInf" onSubmit={(event) => reportIssue(event)}>
                        <div className="row">
                            <div className="col-12">
                                <div className="um-bddInputWrap">
                                    <label>Issue Subject</label>
                                    <input 
                                        type="text"
                                        id="issueName"
                                        className={`form-control  ${errors?.issueName && 'fcError'}`}
                                        {...bindIssueName}
                                    />
                                    {errors.issueName && (
                                        <small className="support-error">
                                            {errors.issueName}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="um-bddInputWrap">
                                    <label>Contact Number (optional but can help speed up resolutions)</label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        className={`form-control  ${errors?.phone && 'fcError'}`}
                                        maxLength={20}
                                        onKeyUp={formatToPhone}
                                        onKeyDown={enforceFormat}
                                        {...bindPhone}
                                    />
                                    {errors.phone && (
                                        <small className="support-error">
                                            {errors.phone}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="um-bddInputWrap pb-2">
                                    <label>Description</label>
									<textarea
                                        id="message"
                                        className="form-control w-100"
                                        style={{
                                            minHeight: '150px',
                                            height: '100%',
                                            resize: 'none'
                                        }}
                                        {...bindMessage}
                                    >
									</textarea>
                                    {errors.message && (
                                        <small className="support-error">
                                            {errors.message}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="um-bddInputWrap pb-1">
                                    <p className='mb-0'>The Petbond account holder details are attached to this report</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="um-formBtnWrap">
                                    <button
                                        type="submit"
                                        className="btn btn-warning btnFormStyle btnFormWidth"
                                        disabled={showLoader}
                                    >
                                        {showLoader && (
                                            <HalfCircleSpinner
                                                size={18}
                                                style={{
                                                    display: 'inline-block',
                                                    marginRight: '5px'
                                                }}
                                            />
                                        )}
                                        Send details to Petbond
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
			</div>
        </>
    );

};

export default BuyerReportIssue;
