import allianz from "../../assets/images/allianz.jpg";
import dogstrust from "../../assets/images/dogs-trust.jpg";
import ikc from "../../assets/images/uk-kennel-club-logo.jpg";
import gain from "../../assets/images/GainNutri.png";
import veterinaryuk from "../../assets/images/veterinary-ireland.jpg";
import ivna from "../../assets/images/IVNA_logo.jpg";

import poster from "../../assets/images/mbg.jpg";
import "tippy.js/dist/tippy.css";

import {
  API_ENDPOINT_URL,
  STORIES_ENDPOINT_URL,
  STORIES_MEDIA_URL,
} from "../../utils/appConstants";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../utils/requests";

import axios from "axios";
import { Helmet } from "react-helmet-async";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slickNext ssArrowVii d-none d-md-block aricomoon-ararrowRight position-absolute slick-arrow`}
      style={{ ...style, display: "block", color: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slickPrev ssArrowVii d-none d-md-block aricomoon-ararrowLeft position-absolute slick-arrow`}
      style={{ ...style, display: "block", color: "black" }}
      onClick={onClick}
    />
  );
}

const MissionStatement = () => {
  const settings = {
    dots: true,
    arrows: true,
    appendDots: (dots) => (
      <div>
        <ul className="ardotsList ardotsListvi d-flex justify-content-center d-md-block list-unstyled mb-0">
          {" "}
          {dots}{" "}
        </ul>
      </div>
    ),
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    speed: 500,
    fade: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const [adverts, setAdverts] = useState([]);
  const [stories, setStories] = useState([]);

  useEffect(() => {
    getRequest(`${API_ENDPOINT_URL}/f-end/advert/random-adverts`).then(
      (res) => {
        if (res) {
          setAdverts(res.data.data);
        }
      }
    );
    axios.get(STORIES_ENDPOINT_URL).then(async (res) => {
      if (res) {
        const posts = [];
        const slicedStories = res.data.slice(0, 3);
        const shortContentElement = document.createElement("p");
        for (const story of slicedStories) {
          shortContentElement.innerHTML = story.excerpt.rendered.replace(
            /<[^>]*>?/gm,
            ""
          );
          let tempObject = {
            id: story.id,
            link: story.link,
            title: story.title.rendered,
            shortContent: shortContentElement.innerHTML,
            image: "",
          };

          const storyMedia = await axios.get(
            `${STORIES_MEDIA_URL}/${story.featured_media}`
          );
          if (storyMedia.data) {
            tempObject.image = storyMedia.data.source_url;
          }
          posts.push(tempObject);
        }
        setStories(posts);
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Mission Statement | Buy Dogs & Puppies | PetBond</title>
        <meta
          name="description"
          content="PetBond is the safest and most ethical way to buy dogs in UK. We work with approved breeders to help you find a new best friend. Find your dog or puppy today."
        />
      </Helmet>
      {/* VIDEO HEADER */}
      <div className="MmainVid">
        <div className="MoverlayVid">
          <video
            className="MvideoVid"
            // src={vid2}
            autoPlay
            loop
            muted
            poster={poster}
          />
          <div className="McontentVid mt-10">
            {/* <img
              className="logoimagei img-fluid mb-3 "
              src={Logo}
              width="350"
              alt="petbond"
            /> */}

            <h2 className="text-center text text-white">
              On a mission for ethical pet ownership
            </h2>

            <p>Proudly Supported By</p>
            <p className="lead pt-4">
              <ul className="list-unstyled arsabLogosList d-flex flex-wrap justify-content-center mb-0 w-50 mx-auto">
                <li className="col-lg-2 col-md-3 col-6">
                  <img
                    src={allianz}
                    className="img-fluid shadow"
                    alt="allianz"
                  />
                </li>
                <li className="col-lg-2 col-md-3 col-6">
                  <img
                    src={dogstrust}
                    className="img-fluid shadow-sm"
                    alt="dogstrust"
                  />
                </li>
                <li className="col-lg-2 col-md-3 col-6">
                  <img src={ikc} className="img-fluid shadow-sm" alt="ikc" />
                </li>
                <li className="col-lg-2 col-md-3 col-6">
                  <img
                    src={veterinaryuk}
                    className="img-fluid shadow-sm"
                    alt="veterinaryuk"
                  />
                </li>
                <li className="col-lg-2 col-md-3 col-6">
                  <img src={ivna} className="img-fluid shadow-sm" alt="ivna" />
                </li>
                <li className="col-lg-2 col-md-3 col-6">
                  <img src={gain} className="img-fluid shadow-sm" alt="ivna" />
                </li>
              </ul>
            </p>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              style={{ height: "2rem" }}
              stroke="#fff"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
        </div>
      </div>
      {/* VIDEOHEADER END */}
      <section
        className="bg-warning img-fluid"
        style={{ backgroundImage: "url(/images/ardevpattern03.png)" }}
      >
        <aside className="arsupportedAsideBlock text-center"></aside>
      </section>
      {/* TEXT */}
      <br />
      <div>
        <h4>HIGH IMPACT STATEMENT</h4>
      </div>
      <br />

      {/* STATS */}
      <div>
        <h4>STATS SECTION</h4>
      </div>
      <br />
      {/* CTA */}
      <div>
        <h4>CALL TO ACTION</h4>
      </div>
      <br />
      {/* MISSION STATEMENT */}
      <section className="arwhyBlock text-white bg-warning position-relative overflow-hidden text-center text-lg-left">
        <div className="container">
          <header className="arHeadingHead text-center">
            <h2 className="text-white">Why We Started Petbond</h2>
          </header>
        </div>
        <div className="aaContainerLarge container">
          <div className="row">
            <div className="col-12 offset-lg-5 col-lg-6 col-xlwd-4 position-static">
              <div className="ardescrWrap">
                <p>
                  In this digital age, 1 in 3 of all pups sourced online either
                  die or become very ill during their first year of life! – Most
                  are never vaccinated nor clinically examined by a veterinary
                  surgeon before you invest in a puppy.
                </p>
                <p>
                  Far from being a happy experience, 1 in 3 people who bought
                  online said their buying experience caused emotional hardship,
                  and financial hardship for 1 in 4 people! – Their lack of care
                  costs you!
                </p>
                <p>
                  PetBond makes meeting your new “best friend” safe, easy &
                  enjoyable. We are managed by a team of experienced & caring
                  vets, who ensure that all pets from our Approved Breeders &
                  Registered Rescue Centres meet the highest health & welfare
                  standards. We really care about you & your pet, that is why we
                  are PetBond
                </p>
                <div className="arauthWrap">
                  <div className="imgWrap">
                    <img
                      src="/images/logoTimKirby.png"
                      className="img-fluid"
                      alt="img description"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 position-static">
              <div className="arwhbImgHolder">
                <img
                  src="/images/arimgdev012.png"
                  className="img-fluid"
                  alt="img description"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ahyAwardImg">
          <img
            src="/images/3stepsaward.svg"
            className="img-fluid"
            width="203"
            height="203"
            alt="the petbond 3 steps process"
          />
        </div>
      </section>
    </>
  );
};

export default MissionStatement;
