import React, { useEffect, useState } from "react";
import { getRequest } from "../../../../utils/requests";
import { API_ENDPOINT_URL } from "../../../../utils/appConstants";
import StepOne from "./StepOne/stepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import pO from "../../../../assets/images/pO.png";
import {useSearchParams} from "react-router-dom";
import PetbondBenefitsWrapper from "../../../../components/shared/PetbondBenefits/PetbondBenefitsWrapper";
import PetbondBenefitItem from "../../../../components/shared/PetbondBenefits/PetbondBeneiftItem";
import {ReactComponent as BadgePercentIcon} from '../../../../assets/icons/badge-percent-icon.svg'
import {ReactComponent as SyringeIcon} from '../../../../assets/icons/syringe-icon.svg'
import {ReactComponent as CircleCheckIcon} from '../../../../assets/icons/circle-check-icon.svg'
import {ReactComponent as ShieldPlusIcon} from '../../../../assets/icons/shield-plus-icon.svg'
import {ReactComponent as WalletIcon} from '../../../../assets/icons/wallet-icon.svg'

const BreederAdvert = () => {
  const [search, setSearch] = useSearchParams();
  const [step, setStep] = useState(1);
  const [commissionPercent, setCommissionPercent] = useState();
  const [taxPercent, settaxPercent] = useState();
  const [advertId, setAdvertId] = useState(null);

  useEffect(() => {
    const id = search.get("id");

    if (id) {
      // in case of update we should have id
      setAdvertId(id);
    }

    getRequest(`${API_ENDPOINT_URL}/breeder/advert/validate`, true).then(
      (res) => {
        if (res) {
          setStep(res.data.data.step);
        }
      }
    );
  }, []);

  useEffect(() => {
    getRequest(
      `${API_ENDPOINT_URL}/breeder/sale/commission-and-tax`,
      true
    ).then((res) => {
      if (res?.data?.status == 200) {
        const data = res?.data?.data;
        setCommissionPercent(data?.commission_in_percent);
        settaxPercent(data?.tax_in_percent);
      }
    });
  }, []);

  return (
    <div>
      <div className="">
        <div className="row">
          <div className="col-12 col-lg-8" style={{padding: 0}}>
            <section className="mb-10">
              <form className="um-bddFormWrap">
                <div className="container">
                  <div className="shadow-sm p-5 rounded-lg">
                    <header className="aaPtbdHeader margin-zero">
                      <h4 className="aaPtbHeading">Create an advert</h4>
                      <hr />
                    </header>
                    <div className="advertpet">
                      <div className="row">
                        <div className="col-12 col-md-7">
                          <p>
                            To create your advert fill out the next three steps, after
                            you complete each section press save and continue.
                          </p>
                          <p>
                            {" "}
                            <img
                              src={pO}
                              className="img-fluid"
                              alt="dogstrust"
                              style={{height: "15px"}}
                            />
                            {" "}
                            Step 1: Other Details
                          </p>
                          <p>
                            {" "}
                            <img
                              src={pO}
                              className="img-fluid"
                              alt="dogstrust"
                              style={{height: "15px"}}
                            />
                            {" "}
                            Step 2: Advert Details
                          </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </form>
            </section>
            <StepOne />
            {/*<StepTwo advertId={advertId} setAdvertId={setAdvertId} />*/}
            <StepThree advertId={advertId} setAdvertId={setAdvertId} commissionPercent={commissionPercent} taxPercent={taxPercent} />
          </div>

          <div className="col-12 col-lg-4 benefits-box-wrapper p-2 p-lg-0">
            <PetbondBenefitsWrapper noStretch={true}>
              <h3 className="petbond-benefits__heading d-none d-md-block">Petbond Benefits</h3>
              <PetbondBenefitItem icon={<BadgePercentIcon />} benefit="No cost sale" />
              <PetbondBenefitItem icon={<SyringeIcon />} benefit="£30 vaccine rebate per puppy" />
              <PetbondBenefitItem icon={<WalletIcon />} benefit="Achieve the best price for each animal" />
              <PetbondBenefitItem icon={<ShieldPlusIcon />} benefit="24/7 Follow on Vet Support for life" />
              <PetbondBenefitItem icon={<CircleCheckIcon />} benefit="Vetted Buyers" />
            </PetbondBenefitsWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreederAdvert;
