import React from 'react';
import BreederOverview from './BreederOverview';
import CharityOverview from './CharityOverview';
import VetOverview from './VetOverview';
import BuyerOverview from './BuyerOverview';
import {Navigate} from "react-router-dom";

const OverView = () => {

    const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

    if (userRole === 'breeder') {
        return (
            <BreederOverview/>
        );
    } else if (userRole === 'charity') {
        return (
            <CharityOverview/>
        );
    } else if (userRole === 'veterinarian') {
        return (
            <VetOverview/>
        );
    } else if (userRole === 'buyer') {
        return (
            <BuyerOverview/>
        );
    }

    return <Navigate to='/' />
};

export default OverView;