import React from 'react';
import {Navigate} from "react-router-dom";
import BuyerChangePassword from "./BuyerChangePassword";


const ChangePassword = () => {
    const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

    if (userRole === 'buyer') {
        return <BuyerChangePassword />;
    }

    return <Navigate to='/' />;
};

export default ChangePassword;
