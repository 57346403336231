import React, { useEffect, useState } from 'react';
import { getRequest } from '../../../../utils/requests';
import {API_ENDPOINT_URL, APP_PREFIX} from '../../../../utils/appConstants';
import PuppyList from './PuppyList';
import {Link} from "react-router-dom";

const AdvertList = ({setShowAdvList, advrtList, breederId}) => {
    const [showPuppyList, setShowPuppyList] = useState(false);
    const [puppyList, setPuppyList] = useState([]);
    const [selectedAdvert, setSelectedAdvert] = useState('');

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    // get advert function
    const editAdvert = (id) => {
        getRequest(
            `${API_ENDPOINT_URL}/veterinarian/connect/breeder/advert/view/${id}`,
            true
        ).then((res) => {
            if (res) {
                setPuppyList(res?.data?.data?.pets);
            }
        });
    };

    return (
        <>
            {showPuppyList ? (
                <>
                    <>
                        <PuppyList
                            setShowPuppyList={setShowPuppyList}
                            puppyList={puppyList}
                            selectedAdvert={selectedAdvert}
                        />
                    </>
                </>
            ) : (
                <>
                    <header className="aaPtbdHeader">
                        <h1 className="h3 aaPtbHeading">
                            Upload/Edit Breeder Adverts
                        </h1>
                    </header>
                    <div className="connectbreeder">
                        <ul className="list-unstyled ftList">
                            {advrtList &&
                                advrtList?.map((advert, index) => (
                                    <li key={index}>
                                        <span className="left">
                                            {advert.name}
                                        </span>
                                        <div className="right">
                                            <Link to={`${APP_PREFIX}/sold-pets?id=${advert.id}&user_id=${breederId}`} className="btn btnFormWidth btnFormStyle small-xs ml-1">
                                                View Sold
                                            </Link>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    editAdvert(advert.id);
                                                    setSelectedAdvert(advert.name);
                                                    setShowPuppyList(true);
                                                }}
                                                className="btn btnFormWidth btnFormStyle small-xs ml-1"
                                            >
                                                Edit Adverts
                                            </button>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                        {advrtList <= 0 && <p>No advert found</p>}
                        <button
                            type="button"
                            onClick={() => {
                                setShowAdvList(false);
                            }}
                            className="btn btnFormWidth btnFormStyle small-xs p-0 ml-0 mt-4"
                            style={{
                                background: 'transparent'
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="227.394"
                                height="41.9"
                                viewBox="0 0 227.394 41.9"
                            >
                                <g
                                    id="Group_1385"
                                    data-name="Group 1385"
                                    transform="translate(-445.974 -796.786)"
                                >
                                    <rect
                                        id="Rectangle_235"
                                        data-name="Rectangle 235"
                                        width="227.394"
                                        height="41.9"
                                        rx="7"
                                        transform="translate(445.974 796.786)"
                                        fill="#e4a039"
                                    ></rect>
                                    <text
                                        id="Back_to_Breeders_List"
                                        data-name="Back to Breeders List"
                                        transform="translate(494.069 824.945)"
                                        fill="#fff"
                                        fontSize="17"
                                        fontFamily="NunitoSans-Regular, Nunito Sans"
                                    >
                                        <tspan x="0" y="0">
                                            Back to Breeders List
                                        </tspan>
                                    </text>
                                    <path
                                        id="Path_60025"
                                        data-name="Path 60025"
                                        d="M4454.664,5767.453a1.554,1.554,0,0,1,.008-2.222l7.463-7.328a1.264,1.264,0,1,1,1.789,1.785l-4.987,4.972c-.246.245-.159.444.189.444h13.758a1.262,1.262,0,0,1,1.269,1.262h0a1.265,1.265,0,0,1-1.269,1.263h-13.758c-.35,0-.434.2-.189.443l4.987,4.973a1.264,1.264,0,1,1-1.789,1.785Z"
                                        transform="translate(-3988.334 -4947.7)"
                                        fill="#fff"
                                        fillRule="evenodd"
                                    ></path>
                                </g>
                            </svg>
                        </button>
                    </div>
                </>
            )}
        </>
    );
};

export default AdvertList;
