import React from 'react';
import BreederSettings from './BreederSettings';
import CharitySettings from './CharitySettings';
import VetSettings from './VetSettings';
import {Navigate} from "react-router-dom";

const Settings = () => {

    const passwordInfo = 'Minimum 8 Characters, must contain at least one uppercase letter, one number. Special character (like []/()*%#$) are optional';
    const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

    if (userRole === 'breeder') {
        return (
            <BreederSettings passwordInfo={passwordInfo}/>
        );
    } else if (userRole === 'charity') {
        return (
            <CharitySettings passwordInfo={passwordInfo}/>
        );
    } else if (userRole === 'veterinarian') {
        return (
            <VetSettings passwordInfo={passwordInfo}/>
        );
    }

    return <Navigate to='/' />
};

export default Settings;