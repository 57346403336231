import React, { useState } from 'react';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { postRequest } from '../../../utils/requests';
import { API_ENDPOINT_URL } from '../../../utils/appConstants';
import { useInput } from '../../../components/hooks/useInput';
import { toast } from 'react-toastify';
import {enforceFormat, formatToPhone, validatePassword} from '../../../utils';


const RegisterBreeder = () => {
    const [firstName, bindFirstName, resetFirstName] = useInput('');
    const [lastName, bindLastName, resetLastName] = useInput('');
    const [email, bindEmail, resetEmail] = useInput('');
    const [phone, bindPhone, resetPhone] = useInput('+44');
    const [password, bindPassword, resetPassword] = useInput('');
    const [errors, setErrors] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const passwordInfo = 'Minimum 8 Characters, must contain at least one uppercase letter, one number. Special character (like []/()*%#$) are optional';

    const validateUpdateInfo = () => {
        let errors = {};
        let isValid = true;
        const vp = validatePassword(password);

        if (!firstName) {
            isValid = false;
            errors['firstName'] = 'This filed is required.';
        }
        if (!lastName) {
            isValid = false;
            errors['lastName'] = 'This filed is required.';
        }
        if (!email) {
            isValid = false;
            errors['email'] = 'This filed is required.';
        }
        if (email) {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(email)) {
                isValid = false;
                errors['email'] = 'Please enter valid email address.';
            }
        }
        if (!vp.isValid) {
            isValid = false;
            errors['password'] = vp.message;
        }
        if (!password) {
            isValid = false;
            errors['password'] = 'This filed is required.';
        }
        if (!phone) {
            isValid = false;
            errors['phone'] = 'This filed is required.';
        }
        if (phone) {
            let res = phone.replace(/\D/g, '');
            if (res.length < 10) {
                isValid = false;
                errors['phone'] = 'Please enter a Valid Phone number.';
            }
        }
        setErrors(errors);
        return isValid;
    };

    //Send the Info data on form submission
    const registerBreeder = (event) => {
        event.preventDefault();
        if (validateUpdateInfo()) {

            const unFormattedPhone = phone
                .replaceAll('(', '')
                .replaceAll(')', '')
                .replaceAll('-', '')
                .replaceAll(' ', '');

            const requestBody = {
                first_name: firstName,
                last_name: lastName,
                password,
                phone_number: unFormattedPhone,
                email
            };

            postRequest(`${API_ENDPOINT_URL}/veterinarian/breeder/register`, requestBody, true).then((res) => {
                if (res) {
                    toast.success('Breeder register successfully');
                    resetFirstName();
                    resetLastName();
                    resetEmail();
                    resetPhone();
                    resetPassword();
                }
            });

        }
    };

    return (
        <>
            <div className="aaContentWidget">
                <header className="aaPtbdHeader">
                    <h1 className="h3 aaPtbHeading">Register Breeder</h1>
                </header>
                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <form className="um-bddFormWrap" onSubmit={(event) => registerBreeder(event)}>
                            <div className="um-bddInputWrap">
                                <input
                                    type="text"
                                    id="first_name"
                                    placeholder="First Name&hellip;"
                                    className={`form-control  ${errors?.firstName && 'fcError'}`}
                                    {...bindFirstName}
                                />
                                {errors.firstName && (
                                    <small className="support-error">
                                        {errors.firstName}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap">
                                <input
                                    type="text"
                                    id="last_name"
                                    placeholder="Last Name&hellip;"
                                    className={`form-control  ${errors?.lastName && 'fcError'}`}
                                    {...bindLastName}
                                />
                                {errors.firstName && (
                                    <small className="support-error">
                                        {errors.lastName}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap">
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Email&hellip;"
                                    className={`form-control  ${errors?.email && 'fcError'}`}
                                    {...bindEmail}
                                />
                                {errors.email && (
                                    <small className="support-error">
                                        {errors.email}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap">
                                <input
                                    type="tel"
                                    id="phone"
                                    placeholder="Mobile&hellip;"
                                    className={`form-control w-100 ${errors?.phone && 'fcError'}`}
                                    maxLength={20}
                                    onKeyUp={formatToPhone}
                                    onKeyDown={enforceFormat}
                                    {...bindPhone}
                                />
                                {errors.phone && (
                                    <small className="support-error">
                                        {errors.phone}
                                    </small>
                                )}
                            </div>
                            <div className="um-bddInputWrap">
                                <div className="position-relative icnInputWrap">
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        placeholder="Password&hellip;"
                                        className={`form-control w-100 ${errors?.password && 'fcError'}`}
                                        {...bindPassword}
                                    />
                                    {
                                        showPassword === false ?
                                            <i className="far fa-eye-slash icn" onClick={() => setShowPassword(true)}/>
                                            : <i className="far fa-eye icn" onClick={() => setShowPassword(false)}/>
                                    }
                                </div>
                                <small>{passwordInfo}</small>
                                {errors.password && (
                                    <small className="support-error">
                                        {errors.password}
                                    </small>
                                )}
                            </div>
                            <div className="um-formBtnWrap pt-4">
                                <button
                                    type="submit"
                                    className="btn btn-warning small"
                                    disabled={showLoader}
                                >
                                    {showLoader && (
                                        <HalfCircleSpinner
                                            size={18}
                                            style={{
                                                display: 'inline-block',
                                                marginRight: '5px'
                                            }}
                                        />
                                    )}
                                    Register Breeder
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );

};

export default RegisterBreeder;
