
const ThankYou = () => {

    return (
        <>
            <header className="arbannerHeader position-relative overflow-hidden w-100 mb-5 mg-md-10 mb-lg-15">
                <div className="container">
                    <div className="arextraWrap">
                        <h2 className="h1">from good homes… <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to good homes</h2>
                    </div>
                    <div className="arbhImgHolder d-none d-md-block position-absolute">
                        <img src="/images/img07.png" className="img-fluid" alt="petbond"/>
                    </div>
                </div>
                <span className="arabPetIcn arabPetIcnvix position-absolute d-none d-md-block">
							<i className="aricomoon-hearts"><span className="sr-only">icon</span></i>
						</span>
                <span className="arabPetIcn arabPetIcnx position-absolute d-md-none">
							<i className="aricomoon-starDots"><span className="sr-only">icon</span></i>
						</span>
            </header>
			<article className="um-thkSubmttPage pt-5 pb-15 pb-lg-20">
				<div className="container">
					<div className="um-thkPageWrap rounded shadow text-center">
						<div className="thkTextWrap">
							<h1 className="h2">Congratulations !<br/> We Are Reviewing You’re Petbond Charity Account</h1>
							<p>You can log into your draft account but features are restructed until <br/>
                               you confirm your email address and your account is fully approved by a <br/>
                               Petbond admin.</p>
							<div className="um-formBtnWrapPurp">
								<a href="#" className="btn btnFormWidth btnPurpStyle btnWhiteStyle">View Dashboard</a>
								<a href="#" className="btn btnFormWidth  btn-primary btnPurpStyle">Place Advert</a>
							</div>
						</div>
						<span className="bgCover d-block imgBgWarn" style={{backgroundImage: "url(/images/imgdev1.png)"}}></span>
					</div>
				</div>
			</article>
        </>
    );

};

export default ThankYou;
