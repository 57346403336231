import React, {useEffect, useState} from 'react';
import {getRequest, postRequest} from '../../../../utils/requests';
import {API_ENDPOINT_URL, APP_PREFIX} from '../../../../utils/appConstants';
import {toast} from 'react-toastify';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {toFormData} from '../../../../utils';
import Modal from 'react-bootstrap/Modal';

const CharityAdvert = () => {

    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();
    const [fetchedBreeds, setFetchedBreeds] = useState([]);
    const [errors, setErrors] = useState({});
    const [puppy, setPuppy] = useState({
        pet_id: '',
        chip_number: '',
        breed_id: '',
        name: '',
        description: '',
        advert_name: '',
        gender: '',
        price: '',
        color: '',
        cover_photo: '',
        coverPhotoPreview: '',
        photo: '',
        photoPreview: ''
    });
    const [modalChipNumber, setModalChipNumber] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalErrors, setModalErrors] = useState(false);
    const [serverErrors, setServerErrors] = useState([]);

    useEffect(() => {
        const id = search.get('id');
        getRequest(`${API_ENDPOINT_URL}/basic-listing/breeds`).then((res) => {
            if (res) {
                setFetchedBreeds(res.data.data);
            }
        });
        if (id) {
            getRequest(`${API_ENDPOINT_URL}/charity/advert/edit/${id}`, true).then((res) => {
                if (res) {
                    const data = res.data.data?.pets[0];
                    setPuppy({
                        pet_id: data?.pet_id,
                        chip_number: data?.chip_number?.includes('pet_bond_chip') ? '' : data?.chip_number,
                        breed_id: res?.data?.data?.breed_id,
                        name: data?.name,
                        description: res?.data?.data?.description,
                        advert_name: res?.data?.data?.advert_name,
                        gender: data?.gender,
                        price: data?.price,
                        color: data?.color,
                        cover_photo: '',
                        photo: '',
                        coverPhotoPreview: res?.data?.data?.cover_photo ? `${API_ENDPOINT_URL}/image-retrieve/${res?.data?.data?.cover_photo}` : '',
                        photoPreview: data?.photo ? `${API_ENDPOINT_URL}/image-retrieve/${data?.photo}` : ''
                    });
                }
            });
        }
    }, []);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setPuppy(prev => ({...prev, [name]: value}));
    };

    const handleImageChange = (event) => {
        const {name, files} = event.target;
        const data = {
            ...puppy,
            [name]: files[0],
            ...((name === 'photo') ? {photoPreview: URL.createObjectURL(files[0])} : {coverPhotoPreview: URL.createObjectURL(files[0])})
        };
        setPuppy(data);
    };

    const validate = (isPublish = false) => {
        setServerErrors([]);
        let errors = {};
        let isValid = true;
        if (!puppy.advert_name) {
            isValid = false;
            errors['advertName'] = 'This field is required'
        }
        if (!puppy.breed_id) {
            isValid = false;
            errors['breedId'] = 'This field is required'
        }
        if (!puppy.name) {
            isValid = false;
            errors['name'] = 'This field is required'
        }
        if (isPublish && !puppy.price) {
            isValid = false;
            errors['price'] = 'This field is required'
        }
        if (isPublish && !puppy.coverPhotoPreview) {
            isValid = false;
            errors['coverImage'] = 'This field is required'
        }

        if (puppy.chip_number && puppy.chip_number.length !== 15) {
            isValid = false;
            errors['chip_number'] = 'Please add a chip number containing 15 characters';
        }
        setErrors(errors);

        if (!isValid) {
            toast.error("You have validation errors");
        }

        return isValid;
    };

    const saveProgress = (event, type = 'save') => {
        event.preventDefault();
        if (validate(type === 'finish')) {
            const id = search.get('id');
            let url = id ? `${API_ENDPOINT_URL}/charity/advert/update/${id}` : `${API_ENDPOINT_URL}/charity/advert/store`;
            let body = {...puppy};
            body.is_publish = type === 'finish';
            delete body.coverPhotoPreview;
            delete body.photoPreview;
            if (body.price === '') {
                body.price = '0';
            }
            if (id) {
                if (body.cover_photo === '') {
                    delete body.cover_photo;
                }
                if (body.photo === '') {
                    delete body.photo;
                }
            } else {
                delete body.pet_id;
            }
            body = toFormData(body);
            postRequest(url, body, true).then((res) => {
                if (res) {
                    toast.success('Progress saved successfully');
                    navigate(`${APP_PREFIX}/listed-pets`);
                }
            }).catch(err => {
                const validationErrors = err?.response?.data?.errors;
                if (validationErrors) {
                    setServerErrors(Object.values(validationErrors));
                }
            });
        }
    };

    const validateChipNumber = () => {
        let errors = {};
        let isValid = true;

        if (!modalChipNumber) {
            isValid = false;
            errors['modalChipNumber'] = 'This field is required';
        }
        if (modalChipNumber) {
            if (modalChipNumber.length !== 15) {
                isValid = false;
                errors['modalChipNumber'] = 'Please add a chip number containing 15 characters';
            }
        }
        setModalErrors(errors);
        return isValid;
    };

    const updateChipNumber = (e) => {
        e.preventDefault();
        if (validateChipNumber()) {
            const body = {
                chip_number: modalChipNumber,
                pet_id: puppy?.pet_id
            };
            postRequest(`${API_ENDPOINT_URL}/charity/advert/${search.get('id')}/pet/update-micro-chip-number`, body, true).then((res) => {
                if (res) {
                    setPuppy({...puppy, chip_number: body.chip_number});
                    setShowModal(false);
                }
            });
        }
    };

    return (
        <div>
            <section className="um-brdRegForm">
                <div className="container">
                    <div className="um-bdShadowComp shadow rounded-lg">
                        <header className="aaPtbdHeader margin-zero">
                            <h1 className="h3 aaPtbHeading">Advertise a pet</h1>
                            <p>Please complete the upload process to advertise a pet.</p>
                        </header>
                        <div className="um-formBrdd advertpet">
                            <form
                                className="um-bddFormWrap"
                                onSubmit={saveProgress}>
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div>
                                            <div className="row mb-2">
                                                <div className="col-12 col-lg-4">
                                                    <div className="um-bddInputWrap">
                                                        <input
                                                            type="text"
                                                            className="form-control w-100"
                                                            placeholder="Advert Name"
                                                            name="advert_name"
                                                            value={puppy.advert_name}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.advertName && (
                                                            <small className="support-error">
                                                                {errors.advertName}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="um-bddInputWrap">
                                                        <select
                                                            className="form-control"
                                                            placeholder="Breed"
                                                            name="breed_id"
                                                            value={puppy.breed_id}
                                                            onChange={handleChange}>
                                                            <option value="">Select a breed</option>
                                                            {
                                                                fetchedBreeds?.map((breed) => (
                                                                    <option
                                                                        value={breed.id}
                                                                        key={breed.id}>{breed.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        {errors.breedId && (
                                                            <small className="support-error">
                                                                {errors.breedId}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="um-bddInputWrap">
                                                        <input
                                                            type="text"
                                                            className="form-control w-100"
                                                            placeholder="Puppy Name"
                                                            name="name"
                                                            value={puppy.name}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.name && (
                                                            <small className="support-error">
                                                                {errors.name}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="um-bddInputWrap position-relative icnInputWrap">
                                                        <input
                                                            type="number"
                                                            className="form-control w-100"
                                                            placeholder="Microchip No"
                                                            name="chip_number"
                                                            value={puppy.chip_number}
                                                            disabled={search.get('id')}
                                                            onChange={handleChange}
                                                        />
                                                        {
                                                            search.get('id') &&
                                                            <i
                                                                className="far fa-edit icn"
                                                                onClick={() => setShowModal(true)}/>
                                                        }
                                                        {errors.chip_number && (
                                                            <small className="support-error">
                                                                {errors.chip_number}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="um-bddInputWrap">
                                                        <input
                                                            type="number"
                                                            className="form-control w-100"
                                                            min={0}
                                                            placeholder="Price you want for puppy"
                                                            name="price"
                                                            value={puppy.price}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.price && (
                                                            <small className="support-error">
                                                                {errors.price}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="um-bddInputWrap">
                                                        <input
                                                            type="text"
                                                            className="form-control w-100"
                                                            placeholder="Puppy Colour"
                                                            name="color"
                                                            value={puppy.color}
                                                            onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <div className="um-bddInputWrap">
                                                        <select
                                                            className="form-control"
                                                            placeholder="Puppy Gender"
                                                            name="gender"
                                                            value={puppy.gender}
                                                            onChange={handleChange}>
                                                            <option value="">Select a gender</option>
                                                            <option value="male"> Male</option>
                                                            <option value="female"> Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-5">
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Write advert description here..."
                                                        name="description"
                                                        value={puppy.description}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <div className="um-bddInputWrap">
                                        <span
                                            className="customFileBtnWrap w-100 text-center btncert mr-0">
                                            <input
                                                id="photo"
                                                type="file"
                                                name="photo"
                                                accept="image/jpeg"
                                                onChange={handleImageChange}/>
                                            <label
                                                htmlFor="photo"
                                                className="fileBtn btn-info text-uppercase">
                                                UPLOAD PUPPY PHOTO <i
                                                className="umicomoonGroup-1095 btnIcnUpload"/>
                                            </label>
                                        </span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="um-bddInputWrap">
                                        <span
                                            className="customFileBtnWrap w-100 text-center btncert mr-0">
                                            <input
                                                id="cover_photo"
                                                type="file"
                                                name="cover_photo"
                                                accept="image/jpeg"
                                                onChange={handleImageChange}/>
                                            <label
                                                htmlFor="cover_photo"
                                                className="fileBtn btn-info text-uppercase">
                                                UPLOAD PUPPY COVER PHOTO <i
                                                className="umicomoonGroup-1095 btnIcnUpload"/>
                                            </label>
                                        </span>
                                            {errors.coverImage && (
                                                <small className="support-error">
                                                    {errors.coverImage}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-6">
                                        <div className="position-relative mrdpEditorView">
                                            <img
                                                src={puppy.photoPreview}
                                                width="30%"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="position-relative mrdpEditorView">
                                            <img
                                                src={puppy.coverPhotoPreview}
                                                width="30%"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {serverErrors && serverErrors.length > 0 && (
                                            <small className="support-error server-errors">
                                                {serverErrors.map((error, i) => <p key={i}>{error}</p>)}
                                            </small>
                                        )}

                                        <div className="btn-box">
                                            <button
                                                className="nodesign"
                                                type="submit">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="206.839"
                                                    height="41.9"
                                                    viewBox="0 0 206.839 41.9">
                                                    <g
                                                        id="Group_1385"
                                                        data-name="Group 1385"
                                                        transform="translate(-495.812 -1338.86)">
                                                        <rect
                                                            id="Rectangle_536"
                                                            data-name="Rectangle 536"
                                                            width="206.839"
                                                            height="41.9"
                                                            rx="7"
                                                            transform="translate(495.812 1338.86)"
                                                            fill="#999"/>
                                                        <text
                                                            id="Save_progress"
                                                            data-name="Save progress"
                                                            transform="translate(514.534 1366.02)"
                                                            fill="#fff"
                                                            fontSize="17"
                                                            fontFamily="NunitoSans-Regular, Nunito Sans">
                                                            <tspan
                                                                x="0"
                                                                y="0">Save progress
                                                            </tspan>
                                                        </text>
                                                        <g
                                                            id="Group_1132"
                                                            data-name="Group 1132"
                                                            transform="translate(-16.204 -10141.388)">
                                                            <g
                                                                id="Group_1131"
                                                                data-name="Group 1131">
                                                                <path
                                                                    id="Path_121340"
                                                                    data-name="Path 121340"
                                                                    d="M676.994,11496.692a5.186,5.186,0,0,1,1.774.208,8.3,8.3,0,0,1,8.034-6.156,8.514,8.514,0,0,1,8.139,6.156,4.334,4.334,0,0,1,1.669-.208,7.2,7.2,0,1,1,0,14.4H676.994a7.2,7.2,0,1,1,0-14.4Zm19.616,12.625a5.389,5.389,0,0,0,5.426-5.427,5.478,5.478,0,0,0-5.426-5.529,5.182,5.182,0,0,0-1.252.209,1.87,1.87,0,0,1-2.191-1.252,6.514,6.514,0,0,0-6.365-4.8,6.7,6.7,0,0,0-6.365,4.8,1.708,1.708,0,0,1-2.087,1.252,6.038,6.038,0,0,0-1.356-.209,5.478,5.478,0,0,0-5.426,5.529,5.389,5.389,0,0,0,5.426,5.427Z"
                                                                    fill="#fff"
                                                                    fillRule="evenodd"/>
                                                                <path
                                                                    id="Path_121341"
                                                                    data-name="Path 121341"
                                                                    d="M682.524,11503.369a.85.85,0,0,1,1.148-1.252l1.878,1.878,4.382-4.382a.887.887,0,0,1,1.252,1.252l-5.008,5.009a.951.951,0,0,1-1.252,0Z"
                                                                    fill="#fff"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </button>
                                            <button
                                                className="nodesign"
                                                type="button"
                                                onClick={(event) => saveProgress(event, 'finish')}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="157.421"
                                                    height="41.9"
                                                    viewBox="0 0 157.421 41.9">
                                                    <g
                                                        id="Group_1386"
                                                        data-name="Group 1386"
                                                        transform="translate(-722.624 -1338.86)">
                                                        <rect
                                                            id="Rectangle_535"
                                                            data-name="Rectangle 535"
                                                            width="157.421"
                                                            height="41.9"
                                                            rx="7"
                                                            transform="translate(722.624 1338.86)"
                                                            fill="#e4a039"/>
                                                        <text
                                                            id="Next_Step"
                                                            data-name="Next Step"
                                                            transform="translate(741.717 1367.017)"
                                                            fill="#fff"
                                                            fontSize="17"
                                                            fontFamily="NunitoSans-Regular, Nunito Sans">
                                                            <tspan
                                                                x="0"
                                                                y="0">Finish
                                                            </tspan>
                                                        </text>
                                                        <path
                                                            id="Path_121339"
                                                            data-name="Path 121339"
                                                            d="M875.209,11503.215a1.552,1.552,0,0,0-.009-2.222l-7.462-7.329a1.264,1.264,0,1,0-1.789,1.786l4.987,4.972c.245.245.159.443-.189.443H856.988a1.263,1.263,0,0,0-1.268,1.263h0a1.265,1.265,0,0,0,1.268,1.263h13.759c.35,0,.434.2.189.443l-4.987,4.972a1.264,1.264,0,1,0,1.789,1.786Z"
                                                            transform="translate(-16.204 -10141.388)"
                                                            fill="#fff"
                                                            fillRule="evenodd"/>
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                show={showModal}
                className="editInfoModal addChipModal generalPopupStyles"
                centered
                onHide={() => setShowModal(false)}
            >
                <Modal.Body>
                    <h4 className="h4 text-center mb-8">Update Chip Number</h4>
                    <form onSubmit={updateChipNumber}>
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder="Enter new chip number here..."
                                        value={modalChipNumber}
                                        onChange={(e) => setModalChipNumber(e.target.value)}
                                    />
                                    {modalErrors.modalChipNumber && (
                                        <small className="support-error">
                                            {modalErrors.modalChipNumber}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="um-formBtnWrap text-center">
                                <button
                                    type="submit"
                                    className="btn btnFormWidth btnFormStyle small btn-warning mx-auto">Update chip
                                    number
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );

};

export default CharityAdvert;
