import React from 'react';
import {Navigate} from "react-router-dom";
import BuyerPaymentsDeposit from "./BuyerPaymentDeposit";

const PaymentsDeposit = () => {
    const userRole = JSON.parse(localStorage.getItem('currentUser'))?.role;

    if (userRole === 'buyer') {
        return <BuyerPaymentsDeposit />;
    }

    return <Navigate to='/' />;
};

export default PaymentsDeposit;
