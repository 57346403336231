import React from "react";

import { Link } from "react-router-dom";

const BreederFreeServices = () => {
  return (
    <>
      <div className="aaContentWidget">
        <section>
          <div className="container">
            <div className="row mb-5">
              <div className="col-12 col-lg-6 mb-4">
                <div className="p-4 bg-warning rounded shadow-sm">
                  <h5 className="mb-3 text-white">Free Vaccinations</h5>
                  <p className="text-white">
                    Find a PetBond approved clinic near you and get your FREE
                    puppy vaccinations.
                  </p>
                  <Link
                    to={`/find-a-vet`}
                    className="arbtnWhite bg-primary shadow-sm btn text-white text-uppercase w-100 m-1"
                  >
                    View Map
                  </Link>
                </div>
              </div>
              <div className="col-12 col-lg-6 mb-4">
                <div className="p-4 bg-warning rounded shadow-sm">
                  <h5 className="mb-3 text-white">Vaccine Refund</h5>
                  <p className="text-white">
                    Puppies already vaccinated ? No problem go back to your vet
                    clinic and get them to contact us for reimbursment.
                  </p>
                  <a
                    href="https://api.whatsapp.com/message/HXCBD4SYVI65H1?autoload=1&app_absent=0"
                    target="_blank"
                    className="arbtnWhite bg-primary shadow-sm btn text-white text-uppercase w-100 m-1"
                  >
                    WhatsApp Us
                  </a>
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-4">
                <div className="p-4 bg-warning rounded shadow-sm">
                  <h5 className="mb-3 text-white">
                    Pre-Breeding Genetic Testing
                  </h5>
                  <p className="text-white">
                    PetBond Pre-Breeding offer, you can have both the dam & sire
                    health tested at a PetBond Centre of Excellence. Book Now:
                    healthtesting@mypetbond.com
                  </p>
                  <Link
                    to={`/genetic-testing`}
                    className="arbtnWhite bg-primary shadow-sm btn text-white text-uppercase w-100 m-1"
                  >
                    More Info
                  </Link>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="p-4 bg-warning rounded shadow-sm">
                  <h5 className="mb-3 text-white">
                    Pre-Breeding Airways Testing
                  </h5>
                  <p className="text-white">
                    Pre-Breeding Brachycephalic Airway Testing to assess the
                    suitability of both the male and female parent dogs! Book
                    Now: healthtesting@mypetbond.com
                  </p>
                  <Link
                    to={`/airway-testing`}
                    className="arbtnWhite bg-primary shadow-sm btn text-white text-uppercase w-100 m-1"
                  >
                    More Info
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BreederFreeServices;
